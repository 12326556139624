document.addEventListener('DOMContentLoaded', () => {
    document.querySelector('.deploy-to-wp-depot')?.addEventListener('click', (e) => {
        e.preventDefault();
        e.target.classList.add('loading');
        fetch(`/ajax/task/${window.TASK_ID}/wp-depot`, {
            method: "POST",
        })
            .then(r => r.json())
            .then(result => {
                if (result.status && result.status === "OK") {
                    return fetch(window.location.toString()).then(r => r.text())
                } else {
                    alert(result);
                }
            })
            .then(html => {
                const resp = $(`<div>${html}</div>`);

                $('.meta-tabs>ul>li:first').after(resp.find('li#tab-title-wp-depot'));
                $('.meta-tab-container').append(resp.find('#wp-wp-depot-tab'));

                window.initializeWpDepot();

                $('li#tab-title-wp-depot a').trigger('click');

            })
            .catch((error) => {
                console.error(error);
                alert("Something is wrong ... ");
            }).finally(() => {
                e.target.classList.add('loading');
            })
    });
});
