<template>
	<ModalBase
		v-if="isVisible"
		@close="onClose"
		width="960px"
		class="file-explorer-modal"
	>
		<template slot="title">
			<h3 v-html="getModalTitle()"></h3>
		</template>

		<div class="ftp-explorer">
			<div
				v-if="status.message"
				v-text="status.message"
				class="message error"
			>
			</div>

			<div
				class="file-explorer"
				v-if="!status.message"
			>
				<div
					:class="{
						'file-explorer-navigation': true,
						'is-loading': showInputLoader
					}"
				>
					<input
						type="text"
						:class="{
							'file-explorer-path-input': true,
							'field': true,
							'disabled': status.isLoading,
						}"
						:value="this.currentDirectory"
						:disabled="status.isLoading"
						@keyup="onFileExplorerInputKeyUp"
					/>

					<span
						v-if="showInputLoader"
						class="loader"
					></span>
				</div>

				<FileTree
					:value="fileTree"
					:class="{
						'file-explorer-tree': true,
						'loading-in-progress': status.isLoading,
					}"
					@select="handleFileTreeSelect"
					@toggle="handleFileTreeToggle"
					@nodedblclick="handleFileTreeDoubleClick"
					ref="slVueTree"
					:allowMultiselect="false"
				/>
			</div>

			<Sidebar
				v-if="!status.message"
				:droneLinks="droneLinks()"
				class="explorer-sidebar"
				:currentDirectory="currentDirectory"
				:wpRoot="wpRoot"
				:entry="entry"
				:connectionStrings="connectionStrings()"
			/>
		</div>
	</ModalBase>
</template>

<script>
/**
 * The external dependencies.
 */
import { mapGetters } from 'vuex';

/**
 * The internal dependencies.
 */
import ModalBase from 'components/Modal.vue';
import Sidebar from 'components/access-depot/ftp-explorer/Sidebar.vue';
import FileTree from 'components/access-depot/ftp-explorer/FileTree.vue';
import {
	getNodeFromFileTree,
	getCurrentDirectoryFromPathParts,
	getPathPartsIndexesFromFileTree,
	updateFileTreeNode,
	getWpRootFromPathParts,
	resetSelectedDirectoriesInFileTree,
} from '../../../helpers/access-depot';

export default {
	components: {
		ModalBase,
		FileTree,
		Sidebar,
	},

	data() {
		return {};
	},

	computed: {
		...mapGetters({
			fileTree: 'accessDepot/getFtpExplorerFileTree',
			isVisible: 'accessDepot/getFtpExplorerIsVisible',
			status: 'accessDepot/getFtpExplorerStatus',
			ftpDirectorySeparator: 'accessDepot/getFtpExplorerDirectorySeparator',
			currentDirectory: 'accessDepot/getFtpExplorerCurrentDirectory',
			entry: 'accessDepot/getFtpExplorerEntry',
			entryId: 'accessDepot/getFtpExplorerEntryId',
			connectionInfo: 'accessDepot/getFtpExplorerConnectionInfo',
			wpRoot: 'accessDepot/getWpRoot',
			ajaxSource: 'accessDepot/getAjaxSource',
			showInputLoader: 'accessDepot/showInputLoader',
		}),
	},

	methods: {
		handleFileTreeSelect(nodes, event) {
			const node = nodes[0];
			node.isSelected = true;

			let updatedFileTree = resetSelectedDirectoriesInFileTree(this.fileTree);
			_.assign(updatedFileTree, updateFileTreeNode(node, updatedFileTree));

			this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_RESET_FILE_TREE', { fileTree: updatedFileTree });

			let currentDirectoryPath = this.sanitizePath(node.data.path);

			this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_SET_CURRENT_DIR', { currentDirectory: this.sanitizePath(currentDirectoryPath) });
			this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_REFRESH_WP_ROOT');
		},

		/*
		 * Updates the fileTree to include an updated version of the toggled node,
		 * with a proper `isExpanded` property, as the slVueTree component doesn't keep
		 * an updated reference of the state
		 */
		handleFileTreeToggle(node, event) {
			const isFetched = node.hasOwnProperty('data') && node.data.hasOwnProperty('isFetched') && node.data.isFetched;

			node.isExpanded = !node.isExpanded;
			if (!isFetched) {
				node.data.isLoading = true;
			}

			let updatedFileTree = updateFileTreeNode(node, this.fileTree);

			this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_RESET_FILE_TREE', { fileTree: updatedFileTree });

			let currentDirectoryPath = this.sanitizePath(node.data.path);

			if (!isFetched) {
				this.$store.dispatch('accessDepot/getFtpFileStructureByPath', { entryId: this.entryId, lookupPath: currentDirectoryPath });
			} else {
				this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_SET_CURRENT_DIR', { currentDirectory: currentDirectoryPath });
				this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_REFRESH_WP_ROOT');
				this.$store.dispatch('accessDepot/clearFileTreeLoading');
			}
		},

		handleFileTreeDoubleClick(node, event) {
			if (node.isLeaf) {
				return;
			}

			node.data.isLoading = true;

			this.handleFileTreeToggle(node, event);
		},

		onClose() {
			this.$store.dispatch('accessDepot/cancelAjaxCall');

			this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_SET_IS_VISIBLE', { isVisible: false });
			this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_RESET_FILE_TREE', { fileTree: [] });
			this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_SET_WP_ROOT', { wpRoot: '' });
			this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_SET_STATUS', { isLoading: false });
			this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_SET_CURRENT_DIR', { currentDirectory: this.ftpDirectorySeparator });
		},

		onFileExplorerInputKeyUp(e) {
			if (e.keyCode !== 13) {
				return;
			}

			const currentActiveDirectory = this.currentDirectory;
			const userEnteredDirectory   = this.sanitizePath(e.target.value);

			// the initial loading has root
			// so we don't need to fetch it again
			if (userEnteredDirectory === this.ftpDirectorySeparator) {
				this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_SET_CURRENT_DIR', { currentDirectory: userEnteredDirectory });
				return;
			}

			const pathParts  = userEnteredDirectory.split(this.ftpDirectorySeparator).filter(Boolean);
			const nodePath   = getPathPartsIndexesFromFileTree(pathParts.slice(0), this.fileTree);

			// the nodePath contains only the existing directories from the fileTree
			// it doesn't contain the ones from the path in the userEnteredDirectory
			const fileTreeNode  = getNodeFromFileTree(nodePath, this.fileTree);
			const nodeIsFetched = fileTreeNode && fileTreeNode.hasOwnProperty('data') && fileTreeNode.data.hasOwnProperty('isFetched') && fileTreeNode.data.isFetched;
			const nodeExists    = pathParts.length === nodePath.length;
			if (nodeIsFetched && nodeExists) {
				if (currentActiveDirectory === userEnteredDirectory) {
					return;
				}

				const updatedFileTree = resetSelectedDirectoriesInFileTree(this.fileTree);
				this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_RESET_FILE_TREE', { fileTree: updatedFileTree });

				this.$refs.slVueTree.select(nodePath);
				this.$store.commit('accessDepot/ACCESS_DEPOT_FTP_EXPLORER_SET_CURRENT_DIR', { currentDirectory: userEnteredDirectory });
			} else {
				this.$store.dispatch('accessDepot/getFtpFileStructureByPath', { entryId: this.entryId, lookupPath: userEnteredDirectory, calledFromInput: true });
			}
		},

		wpInstanceConnectionString() {
			const wpConnectionInfo = Object.assign({}, this.connectionInfo);
			wpConnectionInfo.directory = this.wpRoot;

			return getFilezillaConnectionString(wpConnectionInfo);
		},

		connectionString() {
			return getFilezillaConnectionString(this.connectionInfo);
		},

		droneLinks() {
			const currentDirectoryParams = {
				command: 'filezilla',
				targets: [ this.connectionString(), this.$store.getters['accessDepot/getVcsPath']],
			};

			const wpRootDirectoryParams = {
				command: 'filezilla',
				targets: [ this.wpInstanceConnectionString(), this.$store.getters['accessDepot/getVcsPath']],
			};

			return {
				'currentDirectory': `drone://${btoa(JSON.stringify(currentDirectoryParams))}`,
				'wpRootDirectory': `drone://${btoa(JSON.stringify(wpRootDirectoryParams))}`,
			};
		},

		connectionStrings() {
			return {
				'current': this.connectionString(),
				'wp': this.wpInstanceConnectionString(),
			};
		},

		getModalTitle() {
			return `<span>FTP Explorer</span>`;
		},

		sanitizePath(path) {
			// add a directory separator so the directory always ends with one
			let sanitizedPath = this.ftpDirectorySeparator + path + this.ftpDirectorySeparator;
			const regexp = new RegExp(`${this.ftpDirectorySeparator}+`, 'g');
			sanitizedPath = sanitizedPath.replace(regexp, this.ftpDirectorySeparator);
			return sanitizedPath ? sanitizedPath : this.ftpDirectorySeparator;
		},

	},
}
</script>

<style>
	.modal-title h3 { display: flex; }
	.modal-title h3 span { white-space: nowrap; }
	.file-explorer-navigation { margin-bottom: 12px; }
	.file-explorer-navigation .file-explorer-path-input { width: 100%; box-sizing: border-box; background: transparent !important; }
	.file-explorer-navigation .file-explorer-path-input.disabled { background: #f5f5f5 !important; }
	.file-explorer-navigation .loader { position: absolute; top: 4px; right: 4px; }

	.ftp-explorer { position: relative; overflow: hidden; }
	.ftp-explorer .message { max-width: 60%; margin: 0 auto; padding: 3px; background-color: #f2dede; border: 1px solid #ebccd1; color: #a94442; border-radius: 4px; text-align: center; }

	.ftp-explorer .file-explorer,
	.ftp-explorer .explorer-sidebar { width: 49%; box-sizing: border-box; }
	.ftp-explorer .file-explorer-tree { min-height: 300px; max-height: calc(100vh - 135px); overflow: auto; }

	.ftp-explorer .loading-wrapper .loader { display: inline-block; width: 32px; height: 32px; }
	.ftp-explorer .loading-wrapper .loader:before,
	.ftp-explorer .loading-wrapper .loader:after { width: 12px; height: 12px; }

	.ftp-explorer .file-explorer { position: relative; float: left; }
	.ftp-explorer .file-explorer-tree { padding: 4px; border: 1px solid #aaa; }

	.ftp-explorer .file-explorer-tree.loading-in-progress .sl-vue-tree-nodes-list { cursor: progress; }
	.ftp-explorer .file-explorer-tree.loading-in-progress .sl-vue-tree-nodes-list:before { position: absolute; top: 0; left: 0; width: 100%; height: 100%; display: block; content: ''; background: transparent; z-index: 10; }

	.ftp-explorer .explorer-sidebar { float: right; }

	.sl-vue-tree {
		position: relative;
		cursor: default;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none; /* Safari */
		-khtml-user-select: none; /* Konqueror HTML */
		-moz-user-select: none; /* Firefox */
		-ms-user-select: none; /* Internet Explorer/Edge */
		user-select: none;
	}

	.sl-vue-tree-root > .sl-vue-tree-nodes-list {
		min-width: 100%;
		float: left;
		position: relative;
		padding-bottom: 4px;
	}

	.sl-vue-tree-selected > .sl-vue-tree-node-item {
		background-color: #e1ecfe;
	}

	.sl-vue-tree-node-list {
		position: relative;
		display: flex;
		flex-direction: row;
	}

	.sl-vue-tree-node-item {
		position: relative;
		display: flex;
		flex-direction: row;
	}
	.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
		outline: 1px solid #e1ecfe;
	}

	.sl-vue-tree-gap {
		width: 20px;
		min-width: 20px;
		min-height: 1px;

	}

	.sl-vue-tree-sidebar {
		margin-left: auto;
	}

	.sl-vue-tree-cursor {
		position: absolute;
		border: 1px solid #e1ecfe;
		height: 1px;
		width: 100%;
	}

	.sl-vue-tree-drag-info {
		position: absolute;
		background-color: rgba(0,0,0,0.5);
		opacity: 0.5;
		margin-left: 20px;
		margin-bottom: 20px;
		padding: 5px 10px;
	}
</style>
