<template>
	<div class="spinner">
		<i :class="classList" aria-hidden="true"></i>
	</div><!-- /.spinner -->
</template>

<style scoped>
	.spinner { text-align: center; }
</style>

<script>
	export default {
		/**
		 * Component Name.
		 *
		 * @type {String}
		 */
		name: 'spinner',

		/**
		 * Component Props.
		 *
		 * @type {Object}
		 */
		props: {
			size: String,
		},

		/**
		 * Computed Data.
		 *
		 * @type {Object}
		 */
		computed: {
			classList() {
				return 'fa fa-spinner fa-spin ' + this.size;
			}
		}
	}
</script>