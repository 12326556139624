<template>
	<codemirror v-model="innerValue" :options="options" :keymap="keymap" @input="handleInput" />
</template>

<script>
import Codemirror from '../components/Codemirror.vue';
import ChecklistEntry from '../models/ChecklistEntry';

export default {
	name: 'checklist-editor',

	props: ['value'],

	components: {
		Codemirror,
	},

	data () {
		return {
			innerValue: this.value || '',
		};
	},

	computed: {
		options() {
			return {
				mode: 'yaml',
				lineNumbers: true,
				tabSize: 2,
				indentUnit: 2,
			};
		},

		keymap() {
			return {
				'Tab': (cm) => {
					if (cm.somethingSelected()) {
						var sel = cm.getSelection('\n');
						// Indent only if there are multiple lines selected, or if the selection spans a full line
						if (sel.length > 0 && (sel.indexOf('\n') > -1 || sel.length === cm.getLine(cm.getCursor().line).length)) {
							cm.indentSelection('add');
							return;
						}
					}

					if (cm.options.indentWithTabs)
						cm.execCommand('insertTab');
					else
						cm.execCommand('insertSoftTab');
				},
				'Shift-Tab': (cm) => {
					cm.indentSelection('subtract');
				},
				'Ctrl-D': (cm) => {
					// Toggle Checked
					const currentLine = cm.getCursor().line;
					const line = cm.getLineHandle(currentLine);
					const matches = line.text.replace(/\'$/, '').match(/^\s*-\s*\'?(.+)/);

					if (matches) {
						const checklistItem = new ChecklistEntry(matches[1]);

						if (checklistItem.state === 'checked' || checklistItem.state === 'unchecked') {
							const lineBegining = line.text.substring(0, line.text.indexOf('-') + 1);
							let checklistItemString = checklistItem.toggleState();

							const newLineText = `${lineBegining} ${checklistItemString}`;
							cm.replaceRange(newLineText, { line: currentLine, ch: 0 }, { line: currentLine, ch: Infinity });
						}
					}
				}
			};
		},
	},

	methods: {
		handleInput(val) {
			this.innerValue = val;
			this.$emit('input', this.innerValue);
		},
	},

	watch: {
		value() {
			if (this.value !== this.innerValue) {
				this.handleInput(this.value);
			}
		},
	},
};
</script>
