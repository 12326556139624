<template>
    <div class="note__add">
        <note-form
            v-if="adding"
            :note="note"
            @cancel="onCancel"
            @submit="onSubmit"
        />

        <div v-else>
            <button class="btn note__btn" @click.prevent="onAdd">Add New</button>
        </div>
    </div>
</template>

<script>
/**
 * External dependencies:
 */
import { mapActions } from 'vuex';

/**
 * Internal dependencies:
 */
import NoteForm from 'components/client-notes/NoteForm.vue';

const emptyNote = {
    note: '',
    excerpt: '',
    title: '',
    needed_permission_level: 2,
    id: 0,
    date_added: '',
    added_by_name: '',
    is_sticky: false,
};

export default {
    components: {
        NoteForm,
    },

    data() {
        return {
            note: emptyNote,
            adding: false,
        };
    },

    methods: {
        ...mapActions({
            addNote: 'clientNotes/addNote',
        }),

        onAdd() {
            this.adding = true;
        },

        onSubmit(data) {
            this.addNote(data);

            this.adding = false;
            this.note = emptyNote;
        },

        onCancel() {
            this.adding = false;
            this.note = emptyNote;
        },
    },
}
</script>
