import ajax, { createFormData } from "services/ajax"
import { get } from "lodash";

export const saveNote = ({ commit, getters }, { id }) => {
    if (getters.getIsLoading) {
        return;
    }

    commit('setIsLoading', true);

    const note = getters.getNoteById(id);
    const formData = createFormData({
        id,
        body: note.note,
        excerpt: get(note, 'excerpt', ''),
        needed_permission_level: note.needed_permission_level,
        title: note.title,
        is_sticky: note.is_sticky && getters.getCanMakeStickyNotes ? 1 : 0,
        project_id: getters.getProjectId,
    });

    ajax.post(
        '/client-notes/save',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then(response => {
        const status = get(response, 'data.status');
        const note_parsed = get(response, 'data.data.note_parsed', null);
        const is_sticky = get(response, 'data.data.is_sticky');
        if (status !== 'success' || note_parsed === null) {
            throw new Error('Error while saving note');
        }

        commit('updateNote', {
            id,
            fields: {
                note_beautified: note_parsed,
                is_sticky,
            },
        });
    }).catch(error => {
        console.error(error);
    }).finally(() => {
        commit('setIsLoading', false);
    });
}

export const addNote = ({ commit, getters }, note) => {
    if (getters.getIsLoading) {
        return;
    }
    commit('setIsLoading', true);

    const formData = createFormData({
        ...note,
        client_id: getters.getClientId,
        is_sticky: note.is_sticky && getters.getCanMakeStickyNotes ? 1 : 0,
        project_id: getters.getProjectId,
    });

    ajax.post(
        '/client-notes/add',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then((response) => {
        const status = get(response, 'data.status');
        const note = get(response, 'data.data', null);
        if (status !== 'success' || note === null) {
            throw new Error('Error while adding note');
        }
        commit('addNote', note);
    })
    .catch(error => {
        console.error(error);
    })
    .finally(() => {
        commit('setIsLoading', false);
    });
}

export const deleteNote = ({ commit, getters }, { id }) => {
    if (getters.getIsLoading) {
        return;
    }

    commit('setIsLoading', true);
    ajax.post(
        '/client-notes/delete',
        createFormData({ id }),
        { headers: { 'Content-Type': 'multipart/form-data' } }
    ).then(response => {
        const status = get(response, 'data.status');
        if (status !== 'success') {
            throw new Error('Error while deleting note');
        }
        commit('deleteNote', { id });
    }).catch(error => {
        console.error(error);
    }).finally(() => {
        commit('setIsLoading', false);
    });
};
