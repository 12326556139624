<script>
	/**
	 * The external dependencies.
	 */
	import { mapGetters, mapActions } from 'vuex';
	import moment from 'moment';

	/**
	 * The internal dependencies.
	 */
	import TimeTrackingCalendarModal from '~/time-tracking-components/Modal.vue';

	export default {
		/**
		 * Instance Name.
		 *
		 * @type {String}
		 */
		name: 'time-tracking',

		/**
		 * The local components.
		 *
		 * @type {Object}
		 */
		components: {
			TimeTrackingCalendarModal,
		},

		/**
		 * The local state.
		 *
		 * @return {Object}
		 */
		data() {
			return {
				isTrackingActive: null,
				isCheckingStatus: null,
				showTimeTracking: false,
				afterHoursCheckInterval: null,
				afterHoursInterval: false,
			}
		},

		/**
		 * The computed properties.
		 *
		 * @type {Object}
		 */
		computed: {
			/**
			 * Map the store's state
			 */
			...mapGetters({
				taskId: 'getTrackingTaskId',
				tracking: 'getTrackingStatus',
				canTrack: 'getTrackingPermission',
				currentlyActiveTask: 'getTrackingCurrentlyActiveTask',
				userTrackingId: 'getTrackingUserTrackingId',
				estimate: 'getTrackingEstimate',
			}),
		},

		/**
		 * The public API.
		 *
		 * @type {Object}
		 */
		methods: {
			/**
			 * Map the store's actions.
			 */
			...mapActions([
				'updateTimeTrackingStatus',
				'updateCurrentlyActiveTask',
				'forceStartTracking',
				'updateCurrentTaskEstimate',
			]),

			openCalendarModal() {
				this.showTimeTracking = true;
				$('body').addClass('calendar-modal-visible');
			},

			closeCalendarModal() {
				this.showTimeTracking = false;
				$('body').removeClass('calendar-modal-visible');
			},

			forceStart() {
				this.forceStartTracking({ taskId: this.taskId })
			},

			/**
			 * Beam Subscription.
			 */
			subscribe() {
				const beam = window.$$beam;
				const trackingChannel = `task-tracking-${this.userTrackingId}`;
				const taskTrackingChannel = `task-tracking-${this.taskId}`;

				// TODOTIMETRACKING update currently active task
				beam.join(trackingChannel);
				beam.join(taskTrackingChannel);

				beam.on(trackingChannel, 'task-tracking:active', this.handleActiveTaskUpdate);

				beam.on(taskTrackingChannel, 'task-tracking:estimate', this.updateCurrentTaskEstimate);
				beam.on(taskTrackingChannel, 'task-tracking:status', this.updateTimeTrackingStatus);
			},

			handleActiveTaskUpdate(taskData) {
				if (taskData && this.taskId === taskData.id) {
					return;
				}

				return setTimeout(() => this.updateCurrentlyActiveTask(taskData), 150);
			},

			initAfterHoursCheckInterval() {
				 if (this.afterHoursCheckInterval) {
					clearInterval(this.afterHoursCheckInterval);
				}

				this.afterHoursCheckInterval = setInterval(() => {
					//Check for after hours
					const isInAfterHours = this.shouldInitAfterHoursAlerts()

					if (isInAfterHours && !this.afterHoursInterval) {
						this.initAfterHoursAltersInterval();
					} else {
						this.afterHoursInterval = null;
					}
				}, 60 * 1000);
			},

			shouldInitAfterHoursAlerts() {
				const currenTimestamp = moment().format('x');
				const morningTimestamp = moment(moment().format('YYYY-MM-DD 07:30')).format('x');
				const eveningTimestamp = moment(moment().format('YYYY-MM-DD 18:10')).format('x');

				return currenTimestamp > eveningTimestamp || currenTimestamp < morningTimestamp;
			},

			initAfterHoursAltersInterval() {
				this.afterHoursInterval = setInterval(() => {
					if (!this.tracking) {
						return;
					}
					// alert('Засича се таск след работно време. Моля игнорирай в случай на отработване.');
				}, 20 * 60 * 1000); // every 20 minutes
			},
		},

		mounted() {
			if (this.canTrack) {
				// Imeediate update
				if (this.tracking) {
					$(document).ready(() => $('.start-timer').trigger('click'));
				}

				this.initAfterHoursCheckInterval();
				this.subscribe();
			}
		},

		/**
		 * The watchers.
		 *
		 * @type {Object}
		 */
		watch: {
			tracking(newVal) {
				const $timer = $('.start-timer');

				const isRunning = $timer.hasClass('icon-pause');

				const shouldTrackingUpdate = (isRunning && !newVal)
					|| (!isRunning && newVal);

				if (shouldTrackingUpdate) {
					if ($timer.hasClass('timer-disabled')) {
						$timer.removeClass('timer-disabled');
					}

					$timer.trigger('click');
				}
			},
		}
	}
</script>

<style>
	.calendar-modal-visible #cnt { z-index: 20; }
	.calendar-modal-visible .calendar-modal-wrapper { position: relative; z-index: 100010; }
</style>
