/**
 * The internal dependencies.
 */
import ajax, { CancelToken } from 'services/ajax';
import { ACCESS_DEPOT_UNSAVED_ID, ACCESS_DEPOT_FTP_EXPLORER_EMPTY_DIR_DUMMY_FILE_NAME } from '~/lib/constants';
import {
	clearFileTreeNodeLoading,
} from '../../helpers/access-depot';

export const addAccessCredentials = ({ commit, dispatch }, { credentials }) => {
	commit('ACCESS_DEPOT_SAVING');

	return ajax.post('/save-access-credentials', credentials)
		.then(({ data }) => {
			const { status, id } = data;
			if (status === 'OK') {
				commit('ACCESS_DEPOT_SAVING_SUCCESS', { id });
				commit('ACCESS_DEPOT_SET_IS_ACTIVE', {
					id,
					isActive: false,
				});
				if (credentials.should_reset_main) {
					commit('ACCESS_DEPOT_RESET_IS_MAIN', { exclude: id });
				}
				if(credentials.protocol === 'seat' && !credentials.active_reservations) {
					commit('ACCESS_DEPOT_SET_ACTIVE_RESERVATIONS', { id: data.id });
				}
				if(credentials.totp_secret) {
					dispatch('loadTotps', { id });
				}
				return data;
			} else {
				commit('ACCESS_DEPOT_SAVING_ERROR', { message: 'Error while saving credentials.' });
			}
		})
		.catch(({ message }) => {
			commit('ACCESS_DEPOT_SAVING_ERROR', { message });
		});
};

export const updateAccessCredentials = ({ commit, dispatch }, { credentials, password_hidden }) => {
	const { 'credentials-id': id } = credentials;

	commit('ACCESS_DEPOT_UPDATING', { id });

	ajax.post('/update-access-credentials', {
		...credentials,
		password_hidden
	})
		.then(({ data: { status, message, data } }) => {
			if (status && status === 'OK') {
				commit('ACCESS_DEPOT_UPDATING_SUCCESS', { id });
				commit('ACCESS_DEPOT_UPDATING_ENTRY', { data });
				if (credentials.should_reset_main) {
					commit('ACCESS_DEPOT_RESET_IS_MAIN', { exclude: id });
				}
				if(credentials.protocol === 'seat') {
					commit('ACCESS_DEPOT_SET_ACTIVE_RESERVATIONS', { id });
				}
				if(credentials.totp_secret) {
					dispatch('loadTotps', { id });
				}
			} else {
				commit('ACCESS_DEPOT_UPDATING_ERROR', { id, message });
			}
		})
		.catch(({ message }) => {
			commit('ACCESS_DEPOT_UPDATING_ERROR', { id, message });
		});
};

export const testAccessCredentials = ({ commit }, { id, credentials }) => {
	commit('ACCESS_DEPOT_TESTING', { id });

	ajax.post('/test-access-credentials', credentials)
		.then(({ data: { status, message } }) => {
			let alertMessage = message;
			let alertStatus = status;
			if (/^Root is invalid or does not exist/.test(message)) {
				alertMessage = `Връзката успешна. Предоставената директория <strong>${credentials['directory']}</strong>, не е writable или не съществува.`;
				alertStatus = 'Warning';
			}

			if (/Couldn't upload transit file/.test(message) || /Test file uploaded to/.test(message)) {
				alertMessage = `Връзката успешна. Няма предоставенa директория.`;
				alertStatus = 'Warning';
			}

			if (alertStatus === 'OK') {
				if (credentials['home-url'] === '') {
					alertMessage += `<br/>Липсва URL за достъп до сайта.`;

					commit( 'ACCESS_DEPOT_TESTING_WARNING', {
						id,
						message: alertMessage
					});
				} else {
					commit( 'ACCESS_DEPOT_TESTING_SUCCESS', {
						id,
						message: alertMessage
					});
				}
			} else if (alertStatus === 'Warning') {
				commit( 'ACCESS_DEPOT_TESTING_WARNING', {
					id,
					message: alertMessage
				});
			} else {
				commit('ACCESS_DEPOT_TESTING_ERROR', {
					id,
					message: alertMessage,
				});
			}
		})
		.catch(({ message }) => {
			commit('ACCESS_DEPOT_TESTING_ERROR', {
				id,
				message: `Something went wrong: ${message}`,
			});
		});
};

export const deleteAccessCredentials = ({ commit }, { id }) => {
	commit('ACCESS_DEPOT_DELETING', { id });

	ajax.post('/delete-access-credentials', { id })
		.then(() => {
			commit('ACCESS_DEPOT_DELETING_SUCCESS', { id });
			commit('ACCESS_DEPOT_SET_FIRST_FOCUS');
		})
		.catch(() => {
			commit('ACCESS_DEPOT_DELETING_ERROR', {
				id,
				message: 'Error while deleting credentials.',
			});
		});
};

export const deleteUnsavedCredentials = ({ commit }) => {
	commit('ACCESS_DEPOT_REMOVE', { id: ACCESS_DEPOT_UNSAVED_ID });
	commit('ACCESS_DEPOT_SET_IS_ADDING', { isAdding: false });
	commit('ACCESS_DEPOT_SET_FIRST_FOCUS');
};

export const openFtpExplorer = ({ commit, state }, { entryId, lookupPath }) => {
	commit('ACCESS_DEPOT_FTP_EXPLORER_SET_STATUS', { isLoading: true });
	commit('ACCESS_DEPOT_FTP_EXPLORER_SET_IS_VISIBLE', { isVisible: true });
	commit('ACCESS_DEPOT_FTP_EXPLORER_SET_ENTRY_ID', { entryId });
	commit('ACCESS_DEPOT_FTP_EXPLORER_SHOW_INPUT_LOADER', { showInputLoader: true });

	helperGetFtpFileStructureByPath( { commit, state }, { entryId, lookupPath, includeAncestorsContents: true, initialCall: true } );
};

export const getFtpFileStructureByPath = ({ commit, state }, { entryId, lookupPath, calledFromInput = false }) => {
	commit('ACCESS_DEPOT_FTP_EXPLORER_SET_STATUS', { isLoading: true });
	if (calledFromInput) {
		commit('ACCESS_DEPOT_FTP_EXPLORER_SHOW_INPUT_LOADER', { showInputLoader: true });
	}

	helperGetFtpFileStructureByPath( { commit, state }, { entryId, lookupPath } );
};

function helperGetFtpFileStructureByPath({ commit, state }, { entryId, lookupPath, includeAncestorsContents = false, initialCall = false }) {
	const ajaxSource = CancelToken.source();

	commit('ACCESS_DEPOT_FTP_EXPLORER_SET_AJAX_SOURCE', { ajaxSource });

	ajax.get('/get-ftp-file-structure', {
		params: { entryId, lookupPath, includeAncestorsContents, initialCall, taskId: TASK_ID },
		cancelToken: ajaxSource.token,
	})
		.then(({ data }) => {
			commit('ACCESS_DEPOT_FTP_EXPLORER_SET_STATUS', { isLoading: false });
			commit('ACCESS_DEPOT_FTP_EXPLORER_SHOW_INPUT_LOADER', { showInputLoader: false });

			if (data.status === 'Error') {
				clearFileTreeLoading({state});

				if (/^Could not login with connection/.test(data.message) || /^Unable to connect/.test(data.message)) {
					commit('ACCESS_DEPOT_FTP_EXPLORER_SET_STATUS', { isLoading: false, message: 'Неуспешна връзка със сървъра. Моля провери данните за връзка.' });
				}

				if (initialCall && /^Invalid directory path/.test(data.message)) {
					commit('ACCESS_DEPOT_FTP_EXPLORER_SET_STATUS', { isLoading: false, message: 'Директорията не беше намерена.' });
				}

				return;
			}

			let fileTree = [];
			let hasActualFileTree = data.hasOwnProperty('items') && data.items.hasOwnProperty('file_tree') && data.items.file_tree.length;
			if (hasActualFileTree) {
				fileTree = data.items.file_tree;
			} else {
				fileTree = [
					{
						basename: ACCESS_DEPOT_FTP_EXPLORER_EMPTY_DIR_DUMMY_FILE_NAME,
						dirname: data.items.current_path,
						extension: '',
						filename: ACCESS_DEPOT_FTP_EXPLORER_EMPTY_DIR_DUMMY_FILE_NAME,
						path: data.items.current_path,
						size: 0,
						type: 'dummy-file',
						visibility: 'public',
					}
				];
			}

			commit('ACCESS_DEPOT_FTP_EXPLORER_SET_CURRENT_DIR', { currentDirectory: data.items.current_path });

			commit('ACCESS_DEPOT_FTP_EXPLORER_SET_FILE_TREE', { fileTree: fileTree });

			commit('ACCESS_DEPOT_FTP_EXPLORER_REFRESH_WP_ROOT');

			commit('ACCESS_DEPOT_FTP_EXPLORER_SET_AJAX_SOURCE', { ajaxSource: false });
		})
		.catch((e) => {
			commit('ACCESS_DEPOT_FTP_EXPLORER_SET_AJAX_SOURCE', { ajaxSource: false });
			commit('ACCESS_DEPOT_FTP_EXPLORER_SET_STATUS', { isLoading: false, message: 'Възникна неочаквана грешка. Моля провери данните за връзка.' });
			commit('ACCESS_DEPOT_FTP_EXPLORER_SHOW_INPUT_LOADER', { showLoader: false });
		});

}

export const cancelAjaxCall = ({ state }) => {
	if (!state.ftpExplorer.ajaxSource) {
		return;
	}

	state.ftpExplorer.ajaxSource.cancel('Closing FTP explorer');
};

export const clearFileTreeLoading = ({ state }) => {
	state.ftpExplorer.fileTree = _.assign([], clearFileTreeNodeLoading(state.ftpExplorer.fileTree));
};

export const loadTotps = ({ commit }, { id }) => {
	const ajaxPromise = ajax.get('/load-access-totp', { params: { id } });

	ajaxPromise.then(response => {
		const totps = response.data;

		if (totps) {
			commit('ACCESS_DEPOT_LOADED_TOTPS', { id, totps } );
		}
	});

	return ajaxPromise;
};

export const reserveSeat = ({ commit }, { id, reservationPeriod, reservationPeriodUnit }) => {
	commit('ACCESS_DEPOT_SAVING');

	ajax.post('/reserve-seat', {id, reservationPeriod, reservationPeriodUnit})
		.then(({ data: { seat } }) => {
            commit('ACCESS_DEPOT_RESERVATIONS_UPDATED', { entry: seat });
		})
		.catch(({ message }) => {
			commit('ACCESS_DEPOT_SAVING_ERROR', { message });
		});
};

export const freeSeat = ({ commit }, { id }) => {
	commit('ACCESS_DEPOT_SAVING');

	ajax.post('/free-seat', {id})
		.then(({ data: { seat } }) => {
            commit('ACCESS_DEPOT_RESERVATIONS_UPDATED', { entry: seat });
			commit('ACCESS_DEPOT_UPDATING_ENTRY', { data: seat });
		})
		.catch(({ message }) => {
			commit('ACCESS_DEPOT_SAVING_ERROR', { message });
		});
};

export const setFirstFocus = ({ commit }) => {
	commit('ACCESS_DEPOT_SET_FIRST_FOCUS');
}

export const resetValues = ({ commit }, { entry }) => {
	commit('ACCESS_DEPOT_RESET_VALUES', { entry });
}

export const getLatestReservation = ({ commit }, { task_id, id }) => {
	ajax.get(`/active-reservations/${task_id}`)
		.then(({ data }) => {
			commit('ACCESS_DEPOT_SET_LATEST_RESERVATIONS', { data });
		})
		.catch(({ message }) => {
			commit('ACCESS_DEPOT_SAVING_ERROR', { message });
		});
}

export const getPassword = ({ commit }, { id, task_id }) => {
	ajax.get(`/get-access-credentials/${task_id}/${id}`)
		.then(({ data }) => {
			commit('ACCESS_DEPOT_SET_PASSWORD', { data, id });
		})
		.catch(({ message }) => {
			commit('ACCESS_DEPOT_SAVING_ERROR', { message });
		});
}

export const getPasswordWithoutReveal= ({ commit }, { id, task_id }) => {
	return ajax.get(`/get-access-credentials/${task_id}/${id}`)
		.then(({ data }) => {
			return data;
		})
		.catch(({ message }) => {
			commit('ACCESS_DEPOT_SAVING_ERROR', { message });
		});
}