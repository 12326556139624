/**
 * External Dependencies.
 */
import Vuex from 'vuex';
import { merge, cloneDeep } from 'lodash';

/**
 * Internal Dependencies.
 */
import comments from './comments';
import currentUser from './current-user';
import notifications from './notifications';
import timeTracking from './time-tracking';
import accessDepot from './access-depot';
import companyEmail from './company-email';
import clientNotes from './client-notes';
import projectNotes from './project-notes';

/**
 * Export Store.
 */
const store = new Vuex.Store({
	strict: false,
	modules: {
		currentUser,
		comments,
		notifications,
		timeTracking,
		accessDepot,
		companyEmail,
		clientNotes,
		projectNotes,
	},
});

/**
 * Hydrate the store.
 */
document.addEventListener('DOMContentLoaded', () => {
	if (window.$$initialState) {
		store.replaceState(merge(
			{},
			cloneDeep(store.state),
			cloneDeep(window.$$initialState)
		));
	}
});

export default store;