<template>
	<div v-if="logs.length" class="checklist__logs">
		<ul v-if="!collapsed">
			<li v-for="log in visibleLog" :title="log.created_at" v-html="renderLog(log)"></li>
		</ul>

		<a href="#" @click.prevent="toggleAll">
			<template v-if="collapsed">Виж повече</template>
			<template v-else>Скрий</template>
		</a>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'checklist-logs',

	props: {
		logs: {
			default: () => [],
			type: Array,
		},
	},

	data() {
		return {
			collapsed: true,
		};
	},

	computed: {
		visibleLog() {
			let recentLogs = [].concat(this.logs);
			recentLogs.reverse();

			return recentLogs;
		},

		users() {
			let users = {};

			Object.keys(all_users).forEach(division => {
				users = Object.assign(users, all_users[division]);
			});

			return users;
		},
	},

	methods: {
		getActionLabel(type) {
			switch(type) {
				case 'item_delete':
					return 'deleted';
				case 'item_unchecked':
					return 'marked incompleted'
				case 'item_checked':
					return 'marked completed'
				case 'checklist_edit':
				default:
					return 'edited';
			}
		},

		renderLog(log) {
			const user = this.users[log.user_id].replace(/\s\-.+/, '') || 'Unknown';
			const action = this.getActionLabel(log.type);

			const timestamp = typeof log.created_at === 'object' && log.created_at ? log.created_at.date : log.created_at;
			const time = moment(timestamp).fromNow();

			return `<strong>${user}</strong> ${action} <strong>${log.description}</strong> <small>${time}</small>`;
		},

		toggleAll() {
			this.collapsed = !this.collapsed;
		},
	},
}
</script>
