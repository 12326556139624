export const getKitchenSubdomain = source => {
	switch(source) {
		case 'htmlburger':
		case 'mailbakery':
			return 'kitchen';
		case 'htmlboutique':
			return 'workshop';
		case 'htmlmafia':
			return 'barbershop';
		default:
			return 'kitchen';
	}
}

export const getPmSystemDomain = source => {
	switch(source) {
		case 'kitchenapp-htmlburger':
			return 'kitchen.htmlburger.com';
		case 'kitchenapp-mailbakery':
			return 'kitchen.mailbakery.com';
		case 'kitchenapp-htmlboutique':
			return 'kitchen.htmlboutique.com';
		case 'kitchenapp-htmlmafia':
			return 'htmlmafia.kitchen.co';
		case 'kitchenapp-graphicmama':
			return 'kitchen.graphicmama.com';
	}
}

export const getProjectUrl = (source, token) => {
	// match 'graphicmama' and "graphicmama-custom-order"
	if (source.indexOf('graphicmama') === 0) {
		return `https://graphicmama.com/support/tickets/${token}`;
	}

	if (source.indexOf('kitchenapp') !== -1) {
        return 'https://' + getPmSystemDomain(source) + '/projects/' + token;
	}

	const domain = `${getKitchenSubdomain(source)}.${source}.com`;
	return `https://${domain}/project/${token}`;
}

/**
 * Get UTC from date
 * @param  {Date} date
 * @return {Date}
 */
export const toUTC = date => {
	return new Date(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds()
	);
}

export const getBase64FromFile = file => {
	return new Promise((resolve, reject) => {
		const reader = new window.FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = () => reject;

		reader.readAsDataURL(file);
	});
}

export const getImageFromPasteEvent = event => {
	return new Promise((resolve, reject) => {
		if (!event.clipboardData.items) {
			const waitInterval = setInterval(() => {
				if (event.target.children.length > 0) {
					clearInterval(waitInterval);

					resolve({
						base64string: event.target.children[0].src,
						type: getFileTypeFromBase64string(event.target.children[0].src)
					});

					event.target.innerHTML = '';
				}
			}, 1000);

			// Set max execution timeout
			setTimeout(() => {
				if (waitInterval) {
					clearInterval(waitInterval);
				}
			}, 5000);
		} else {
			Array.from(event.clipboardData.items).some((item) => {
				if (item.kind === 'file' && (item.type === 'image/png' || item.type === 'image/jpeg')) {
					const file = item.getAsFile();

					const imageType = item.type;

					getBase64FromFile(file).then((result) => {
						resolve({
							base64string: result,
							type: imageType
						});
					});

					return true;
				}
			});
		}
	});
}

export const dataURItoBlob = dataURI => {
	// Convert base64/URLEncoded data component to raw binary data held in a string
	var byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0) {
		byteString = atob(dataURI.split(',')[1]);
	}
	else {
		byteString = unescape(dataURI.split(',')[1]);
	}

	// Separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// Write the bytes of the string to a typed array
	var ia = new Uint8Array(byteString.length);

	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type:mimeString });
}

export const insertAtCaret = (element, text) => {
	var txtarea = element;
	var scrollPos = txtarea.scrollTop;
	var strPos = 0;
	var br = ((txtarea.selectionStart || txtarea.selectionStart == '0') ?
		"ff" : (document.selection ? "ie" : false ) );

	if (br == "ie") {
		txtarea.focus();
		var range = document.selection.createRange();
		range.moveStart ('character', -txtarea.value.length);
		strPos = range.text.length;
	}

	else if (br == "ff") strPos = txtarea.selectionStart;

	var front = (txtarea.value).substring(0,strPos);
	var back = (txtarea.value).substring(strPos,txtarea.value.length);
	txtarea.value=front+text+back;
	strPos = strPos + text.length;

	if (br == "ie") {
		txtarea.focus();
		var range = document.selection.createRange();

		range.moveStart ('character', -txtarea.value.length);
		range.moveStart ('character', strPos);
		range.moveEnd ('character', 0);
		range.select();
	}

	else if (br == "ff") {
		txtarea.selectionStart = strPos;
		txtarea.selectionEnd = strPos;
		txtarea.focus();
	}

	txtarea.scrollTop = scrollPos;
}
