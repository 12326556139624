<template>
	<div class="quote-info" v-if="quote">
		<div class="quote-systems-project">
			<div class="quote-project-token">
				<span>Проект от система:</span>

				<strong>{{systemsProjects.token}}</strong>
			</div>

			<div v-if="systemsProjects.id">
				<input type="hidden" name="system_project_id" class="field" id="system_project_id_field" v-bind:value="systemsProjects.id">

				<a v-bind:href="systemsProjectsLink" class="button" v-if="systemsProjects.id" target="_blank">
					<i class="fa fa-arrow-circle-right"></i> Виж проекта
				</a>

				<a v-bind:href="quoteLink" class="button" v-if="quote.id" target="_blank">
					<i class="fa fa-arrow-circle-right"></i> Виж Quote
				</a>
			</div>

			<div class="quote-error" v-else>
				<p>
					<strong>{{quote.posted_in_project}}</strong> не съществува в офис.
				</p>

				<a href="https://office.2c-studio.com/?p=systems_projects" class="button">Добави в офис</a>
			</div>
		</div>

		<ul class="quote-meta" v-if="quote">
			<li>
				<span>Option:</span>
				<strong>{{quote.accepted_option.name}}</strong>
			</li>

			<li v-bind:title="quote.accepted_option.accepted_on">
				<span>Accepted:</span>
				<strong>{{quote.accepted_option.accepted_on | dateFormat}} ({{quote.accepted_option.accepted_on | fromNow}})</strong>
			</li>

			<li v-bind:title="quote.accepted_option.delivery_date">
				<span>Delivery date:</span>
				<strong>{{quote.accepted_option.delivery_date | dateFormat}} ({{quote.accepted_option.delivery_date | fromNow}})</strong>
			</li>
		</ul>
	</div>
</template>

<script>
import moment from 'moment';
import { getKitchenSubdomain } from '../utils/helpers';

export default {
	name: 'quote-info',

	computed: {
		quote() {
			return this.$store.state.quote;
		},

		systemsProjects() {
			return this.$store.state.systemsProjects;
		},

		kitchenBaseUrl() {
			const sourceDomain = this.systemsProjects.source;
			const kitchenSubdomain = this.getKitchenSubdomain(this.systemsProjects.source);

			return `https://${kitchenSubdomain}.${sourceDomain}.com`;
		},

		systemsProjectsLink() {
			const token = this.systemsProjects.token;

			return `${this.kitchenBaseUrl}/project/${token}`;
		},

		quoteLink() {
			const quoteId = this.quote.id;

			return `${this.kitchenBaseUrl}/quote/${quoteId}`;
		},
	},

	methods: {
		getKitchenSubdomain
	},

	filters: {
		fromNow(val) {
			return moment.utc(val).fromNow();
		},
		dateFormat(val) {
			return moment.utc(val).format('L');
		}
	}
}
</script>
