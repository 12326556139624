/**
 * Normalize the JSON response of a single comment.
 */
export const normalizeComment = (comment) => {
	comment.author      = (('data' in comment.author) ? comment.author.data : null);
	comment.files       = (('data' in comment.files) ? comment.files.data : null);
	comment.tags        = (('data' in comment.tags) ? comment.tags.data : null);
	comment.last_editor = (('last_editor' in comment) && ('data' in comment.last_editor) ? comment.last_editor.data : null);

	if ('reference' in comment) {
		comment.reference = (('data' in comment.reference) ? comment.reference.data : null);
	}

	return comment;
}
