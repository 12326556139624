/**
 * External dependencies:
 */
import { findIndex } from "lodash";
import { getNotes } from "./getters";

export const PROJECT_NOTES_DELETE = (state, { id, type }) => {
	const notes = getNotes(state);
	const index = findIndex(notes, note => note.id === id && note.type === type);
	if (index === -1) {
		return console.error('Tried to delete note that was not found in the vuex store.');
	}

	notes.splice(index, 1);
};

export const PROJECT_NOTES_ADD = (state, payload) => {
	const notes = getNotes(state);
	notes.push(payload);
};

export const PROJECT_NOTES_EDIT = (state, payload) => {
	const notes = getNotes(state);
	const index = findIndex(notes, note => note.id === payload.id && note.type === payload.type);
	if (index === -1) {
		return console.error('Tried to edit note that was not found in the vuex store.');
	}

	notes.splice(index, 1, payload);
};

export const PROJECT_NOTES_UPDATE_CURRENT_EDIT_NOTE = (state, payload) => {
	state.currentEditNote = { ...state.currentEditNote, ...payload };
};

export const PROJECT_NOTES_UPDATE_SHOW_FORM = (state, payload) => {
	state.showForm = payload;
};
