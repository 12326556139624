<template>
	<div class="comment__revisions">
		<div class="history-note" v-if="! displayRevisions">
			Последна промяна {{ lastChanged }} от <em>{{ comment.last_editor.name }}</em>

			<a href="#" class="reveal-history" @click.prevent="handleLoadRevisions">Виж повече <i v-if="isLoading" class="fa fa-refresh fa-spin" title="Loading..."></i></a>
		</div><!-- /.history-note -->

		<div class="history" v-else>
			<strong>История</strong>
			<a href="#" @click.prevent="displayRevisions = ! displayRevisions" v-if="displayRevisions">скрий</a>

			<ul>
				<li>
					<a
						href="#"
						:class="'see-revision ' + (currentRevision === comment.id ? 'reviewed-revision' : '')"
						:rel="comment.id"
						@click.prevent="handleReset"
					>версия {{ revisions.length + 1 }}</a>

					( <a href="#" :class="'see-diff ' + (currentDiff === comment.id ? 'reviewed-revision' : '')" @click.prevent="handleLoadRevisionDiff(comment.id, revisions[0].id)">diff</a> )

					от <strong>{{ comment.last_editor.name }}</strong> {{ lastChanged }}
				</li>

				<li v-for="(revision, revisionIndex) in revisions" :key="revision.id">
					<a
						href="#"
						:class="'see-revision ' + (currentRevision === revision.id ? 'reviewed-revision' : '')"
						:rel="revision.id"
						@click.prevent="handleSwitchToRevision(revision.id)"
					>Версия {{ revisions.length - revisionIndex }}</a>

					<template v-if="revisionIndex !== revisions.length - 1">
						( <a
							href="#"
							:class="'see-diff ' + (currentDiff === revision.id ? 'reviewed-revision' : '')"
							target="_blank"
							@click.prevent="handleLoadRevisionDiff(revision.id, revisions[revisionIndex + 1].id)"
						>diff</a> )
					</template>

					от <strong>{{ revision.author.name }}</strong> {{ formatRevisionDate(revision) }}
				</li>
			</ul>
		</div><!-- /.history -->
	</div><!-- /.comment__revisions -->
</template>

<script>
	/**
	 * External Dependencies.
	 */
	import moment from 'moment';

	/**
	 * Internal Dependencies.
	 */
	import { fetchCommentRevisions } from './utils';
	import { normalizeComment } from '../../store/comments/utils';


	export default {
		/**
		 * Component Name.
		 *
		 * @type {String}
		 */
		name: 'comment-revisions',

		/**
		 * Component Props.
		 *
		 * @type {Object}
		 */
		props: {
			comment: Object,
		},

		/**
		 * Component Data.
		 */
		data() {
			return {
				revisions: [],
				displayRevisions: false,
				currentRevision: this.comment.id,
				currentDiff: null,
				isLoading: false,
			}
		},

		/**
		 * Computed Data.
		 */
		computed: {
			lastChanged() {
				return moment(this.comment.date_added).fromNow();
			}
		},

		watch: {
			comment() {
				if (this.displayRevisions) {
					this.handleLoadRevisions();
				}
			},
		},

		/**
		 * Component Methods.
		 */
		methods: {
			handleReset() {
				this.$emit('on-reset');

				this.currentRevision = this.comment.id;
				this.currentDiff     = null;
			},

			handleLoadRevisionDiff(revision1, revision2) {
				this.$emit('on-revision-diff', { revision1, revision2 });

				this.currentDiff     = revision1;
				this.currentRevision = null;
			},

			handleSwitchToRevision(revisionId) {
				let revision = this.revisions.filter((revision) => {
					return revision.id == revisionId;
				});

				revision = revision[0];

				this.$emit('on-revision-switch', { revision });

				this.currentRevision = revision.id;
				this.currentDiff     = null;
			},

			handleLoadRevisions() {
				if (this.isLoading) {
					return;
				}

				this.isLoading = true;

				fetchCommentRevisions(this.comment.id)
					.then(({ data }) => {
						this.isLoading        = false;
						this.revisions        = data.data.map(revision => normalizeComment(revision));
						this.displayRevisions = true;
					});
			},

			formatRevisionDate(revision) {
				return moment(revision.date_added).fromNow();
			}
		}
	}
</script>