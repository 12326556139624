// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.note[data-v-70d6786e] { margin-top: 5px;
}
.note label[data-v-70d6786e] { font-size: 14px;
}
.note input[type=checkbox][data-v-70d6786e] { margin-right: 8px;
}
.note .excerpt[data-v-70d6786e] { margin: 5px 0 10px;
}
`, "",{"version":3,"sources":["webpack://./js/components/AddTaskNotes.vue"],"names":[],"mappings":";AA8BA,yBAAA,eAAA;AAAA;AACA,+BAAA,eAAA;AAAA;AACA,8CAAA,iBAAA;AAAA;AACA,kCAAA,kBAAA;AAAA","sourcesContent":["<template>\n\t<div v-if=\"notes && notes.length\">\n\t\tДобави важни бележки към проекта:\n\t\t<div class=\"project-notes\">\n\t\t\t<div class=\"note\" v-for=\"note in notes\" :key=\"note.id\">\n\t\t\t\t<label>\n\t\t\t\t\t<input type=\"checkbox\" name=\"notes[]\" :value=\"note.id\">{{ note.title }}\n\t\t\t\t</label>\n\n\t\t\t\t<div class=\"excerpt\">{{ note.excerpt }}</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</template>\n\n<script>\nimport { mapGetters } from 'vuex';\n\nexport default {\n\tprops: ['projectId'],\n\n\tcomputed: {\n\t\t...mapGetters({\n\t\t\tnotes: 'getNotes',\n\t\t}),\n\t}\n}\n</script>\n\n<style scoped>\n.note { margin-top: 5px; }\n.note label { font-size: 14px; }\n.note input[type=checkbox] { margin-right: 8px; }\n.note .excerpt { margin: 5px 0 10px; }\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
