/**
 * The external dependencies.
 */
import io from 'socket.io-client';

export default class Beam {
	/**
	 * The constructor.
	 *
	 * @param  {String} options.server
	 * @param  {String} options.id
	 * @param  {String} options.key
	 */
	constructor({ server, id, key }) {
		const socket = io(`${server}/${id}`, {
			query: { key }
		});

		this.socket = socket;
	}

	/**
	 * Attach event handler.
	 * @param  {String}   event
	 * @param  {Function} callback
	 * @return {Beam}
	 */
	on(...args) {
		if (args.length === 3) {
			const channel = args[0]
			const event = args[1];
			const callback = args[2];

			this.socket.on(event, (data, channelContext) => {
				if (channel === channelContext) {
					callback(data);
				}
			});
		} else if (args.length === 2) {
			const event = args[0];
			const callback = args[1];

			this.socket.on(event, callback);
		}

		return this;
	}

	/**
	 * Emit an event.
	 *
	 * @param  {String} event
	 * @param  {Any} data
	 * @return {Beam}
	 */
	emit(event, data) {
		this.socket.emit(event, data);

		return this;
	}

	/**
	 * Join a channel.
	 * @param  {String} channel
	 * @return {Beam}
	 */
	join(channel) {
		return this.emit('join', channel);
	}
}
