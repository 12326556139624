<template>
	<div class="gitlab-issues">
		<div class="gitlab-issues-loading" v-if="state === 'mounted'">
			<i class="fa fa-spinner fa-spin"></i>
		</div>

		<div :class="['gitlab-issues-content', {'is-loading': state === 'loading'}]" v-if="state !== 'mounted'">
			<div class="gitlab-issues-head">
				<div class="gitlab-issues-count">
					<p>
						<template v-if="issuesTotal">Брой отвoрени issues: {{ issuesTotal }}.</template>

						<template v-else>Няма отворени issues за: <strong>{{ vcsPath }}</strong></template>

						<a v-bind:href="`${codeServerUrl}/issues`" target="_blank">Виж всички <i class="fa fa-angle-right"></i></a>
					</p>
				</div>

				<div class="gitlab-issues-actions">
					<i class="fa fa-spinner fa-spin" v-if="qaState === 'loading'"></i>

					<p v-else-if="qaState === 'loaded'">Успешно добавен QA Task.</p>

					<form v-else-if="taskType === 'task' && !qaTaskId" action="?" method="post" @submit="handleQAFormSubmit" >
						<users-dropdown name="qa" class="field" v-bind:users="users" optionValue="username" v-model="qa" :returnOnlyValue="true" />

						<datepicker name="qa_deadline" class="field field-date" :placeholder="projectDeadline" />

						<input type="submit" class="submit" value="Създай QA Task" />

						<input type="hidden" name="task_id" :value="taskId">
						<input type="hidden" name="project_deadline" :value="projectDeadline">
					</form>
				</div>
			</div>

			<ul class="gitlab-issues-list">
				<li v-for="issue in issues">
					<a :href="issue.web_url" target="_blank">
						<strong>{{ issue.title }}</strong>

						<small>(Issue #{{ issue.iid }})</small>

						<span v-for="label in issue.labels">{{ label }}</span>
					</a>
				</li>
			</ul>

			<ul class="gitlab-issues-paging" v-if="totalPages > 1">
				<li v-for="page in totalPages" :class="{ current: page === currentPage }">
					<a href="#" @click.prevent="handlePageClick(page)">{{page}}</a>
				</li>
			</ul>

			<div class="gitlab-issues-content-loading" v-if="state === 'loading'">
				<i class="fa fa-spinner fa-spin"></i>
			</div>
		</div>
	</div>
</template>

<script>
import Datepicker from '../components/Datepicker.vue';
import UsersDropdown from '../components/UsersDropdown.vue';

export default {
	name: 'gitlab-issues',
	components: {
		Datepicker,
		UsersDropdown
	},
	data() {
		return {
			state:           'mounted',
			vcsPath:         $$taskData.vcsPath,
			projectDeadline: $$taskData.projectDeadline,
			taskId:          $$taskData.taskId,
			taskType:        $$taskData.taskType,
			qaTaskId:        $$taskData.qaTaskId,
			qa:              $$taskData.currentUser,
			qaState:         null,
			codeServerUrl:   $$taskData.codeServerUrl,
			issues:          [],
			issuesTotal:     0,
			currentPage:     1,
			totalPages:      0,
		};
	},
	computed: {
		users() {
			const users = $$taskData.users || [];
			const usernames = Object.keys(users);

			const usersMapped = usernames.map(username => {
				return {
					username: username,
					user_real_name: users[username]
				};
			});

			return usersMapped;
		}
	},
	mounted() {
		this.fetchIssues();
	},
	methods: {
		fetchIssues() {
			if (this.state === 'loaded') {
				this.state = 'loading';
			}

			$.ajax({
				url: '/ajax/get-gitlab-wp-issues',
				type: 'POST',
				data: {
					vcs_path: this.vcsPath,
					page: this.currentPage
				},
				success: (resp) => {
					if (resp.status === 200) {
						this.issuesTotal = resp.headers['X-Total'] ? parseInt(resp.headers['X-Total'][0]) : 0;
						this.currentPage = resp.headers['X-Page'] ? parseInt(resp.headers['X-Page'][0]) : 1;
						this.totalPages = resp.headers['X-Total-Pages'] ? parseInt(resp.headers['X-Total-Pages'][0]) : 1;

						this.issues = resp.payload;
						window.$$gitlabOpenIssues = this.issues.length;

						this.updateTabCount();
					}
				},
				complete: (resp) => {
					this.state = 'loaded';
				}
			});
		},
		updateTabCount() {
			$('#qa-wp-gitlab span').text('(' + this.issues.length + ')');
		},
		handlePageClick(page) {
			this.currentPage = page;
			this.fetchIssues();
		},
		handleQAFormSubmit(event) {
			event.preventDefault();

			const formData = $(event.target).serializeArray();
			this.qaState = 'loading';

			$.ajax({
				url: '/ajax/qa-task-save',
				type: 'POST',
				data: formData,
				success: (resp) => {
					this.qaTaskId = resp;
				},
				complete: (resp) => {
					this.qaState = 'loaded';
				}
			});
		}
	}
}
</script>
