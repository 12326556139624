<style scoped>
    tfoot .th { text-align: right; font-weight: bold; } 
</style>
<template>
	<div>
        <fieldset>
            <h2>Статистика на засечените времена</h2>


            <a href="#" @click="seeLastMonth">Последния месец</a> | 
            <a href="#" @click="seeLastWeek">Тази седмица</a> | 
            <a href="#" @click="seeYesterday">Вчера</a>
            <br>
            <br>

            <div class="frow">
                <label for="field-from-date">От дата</label>
                <input type="date" id="field-from-date" v-model="start_date">
            </div>

            <div class="frow">
                <label for="field-to-date">До дата</label>
                <input type="date" id="field-to-date" v-model="end_date">
            </div>

            <div class="frow">
                <label>&nbsp;</label>
                <input type="button" value="Go" @click.prevent="fetchStats" class="button submit-field" />
            </div>

            <strong>Засечени времена по проекти</strong>


            <div class="points-levels">
                <table class="points__table" cellspacing="0" border="0">
                    <thead>
                        <tr>
                            <th>Проект</th>
                            <th>Засечено Време</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="project in trackedTimes">
                            <td>{{ project.project_title }}</td>
                            <td>{{ formatMinutes(project.tracked_minutes) }}</td>
                        </tr>
                        <tr v-if="trackedTimes.length === 0">
                            <td colspan="2">Няма засечени проекти за този период</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Засечено време: </th>
                            <th>{{ formatMinutes(totalTrackedMinutes) }}</th>
                        </tr>

                        <tr>
                            <th>Присъствено време(по schedule)</th>
                            <th>{{ formatMinutes(presenceMinutes) }}</th>
                        </tr>

                        <tr>
                            <th>Ефективно засечено време</th>
                            <th>{{ effectiveWorkTime }}</th>
                        </tr>

                    </tfoot>
                </table>
            </div>
        </fieldset>
	</div>
</template>

<script>
    import moment from 'moment';
    import axios from 'axios';
	export default {
		/**
		 * The local components.
		 *
		 * @type {Object}
		 */
		components: {

		},

		/**
		 * The local state.
		 *
		 * @return {Object}
		 */
		data() {
			return {
				shouldShow: null,
                start_date: this.getYesterday().subtract(1, 'months').format('YYYY-MM-DD'),
                end_date: this.getYesterday().format('YYYY-MM-DD'),
                presenceMinutes: 0,
                trackedTimes: [],
			};
		},

		/**
		 * The public API.
		 *
		 * @type {Object}
		 */
		methods: {
            getYesterday() {
                return moment().subtract(1, 'day');
            },
            seeLastMonth() {
                this.start_date = moment().subtract(1, 'months').format('YYYY-MM-DD');
            },
            seeLastWeek() {
                this.start_date = moment().subtract(1, 'week').format('YYYY-MM-DD');
            },
            seeYesterday() {
                this.start_date = moment().subtract(1, 'day').format('YYYY-MM-DD');
            },
            pad(n) {
                return n < 10 ? '0' + n : n;
            },
            formatMinutes(minutes) {
                const hours = this.pad(Math.floor(minutes / 60));
                const remainingMinutes = this.pad( minutes % 60);
                return hours + ':' + remainingMinutes;
            },

            fetchStats() {
                axios.get('/api/task-time-tracking/overview/' + $$initialState.username, {
                    params: {
                        start: this.start_date,
                        end: this.end_date,
                    }
                })
                .then(result => {
                    this.trackedTimes = result.data.tracked_times;
                    this.presenceMinutes = result.data.user_presence;
                });
            }
		},
        computed: {
            totalTrackedMinutes() {
                return this.trackedTimes.reduce((acc, proj) => acc += proj.tracked_minutes, 0);
            },
            effectiveWorkTime() {
                return Math.round(100 * this.totalTrackedMinutes / this.presenceMinutes) + '%'
            }
        },

		mounted() {
            this.fetchStats();
		}
	}
</script>