<template>
	<div>
		<ul class="monitor-comments" v-if="comments.length">
			<li v-for="comment in commentsSorted" class="monitor-comment">
				<div class="monitor-comment-author">
					<time :title="comment.date_added">{{comment.date_added | fromNow}}</time>

					<strong :style="{ color: comment.user.color }">{{comment.user.name}}:</strong>
				</div>

				<div class="monitor-comment-entry" v-html="comment.comment"></div>
			</li>
		</ul>

		<form class="monitor-commentform" @submit.prevent="handleCommentFormSubmit">
			<textarea name="" id="" cols="30" rows="4" class="form-textarea" v-model="commentEntry" required></textarea>

			<button type="submit" class="btn">Изпрати</button>
		</form>
	</div>
</template>

<script>
import { fromNow } from '../utils/filters';

export default {
	name: 'monitor-milestone-comments',

	props: {
		milestoneId: {
			required: true
		},

		comments: {
			default: []
		},
	},

	data() {
		return {
			commentEntry: '',
		};
	},

	computed: {
		commentsSorted() {
			return _.sortBy(this.comments, 'date_added')
		}
	},

	methods: {
		addMilestoneComment(entry) {
			const data = {
				milestone_id: this.milestoneId,
				comment_entry: entry
			}

			this.$store.commit('CHANGE_MILESTONES_STATE', 'loading');;

			$.ajax({
				url: '/monitor/add/comment',
				type: 'POST',
				data,

				success: (resp) => {
					this.commentEntry = '';
					this.$store.dispatch('fetchMilestones');
				},

				error: (err) => {
					commit('CHANGE_MILESTONES_STATE', 'loaded');
				}
			});
		},

		handleCommentFormSubmit(event) {
			this.addMilestoneComment(this.commentEntry);
		}
	},

	filters: {
		fromNow,
	}
}
</script>
