<template>
	<div>
		<div class="estimate-task add-task">
			<form action="/ajax/estimate-task" method="post" @submit.prevent="handleFormSubmit">
				<h3 class="add-task-title">Прогнозиране на задача</h3>

				<div class="add-task-body">
					<div class="add-task-row">
						<div class="add-task-cols">
							<div class="add-task-col add-task-col-medium">
								<label class="add-task-label">Отдел:</label>

								<select v-model="departmentId" class="field" name="department" required>
									<option></option>
									<option v-for="department in departments" :value="department.id">{{ department.title | uppercase }}</option>
								</select>
							</div>

							<div class="add-task-col add-task-col-medium" v-show="departmentDivisions.length">
								<label class="add-task-label">Дивизия:</label>

								<select v-model="divisionId" class="field" name="division">
									<option></option>
									<option v-for="division in departmentDivisions" :value="division.id">{{ division.title | uppercase }}</option>
								</select>
							</div>
						</div>
					</div>

					<div class="add-task-row">
						<div class="add-task-cols">
							<div class="add-task-col add-task-col-third">
								<label class="add-task-label">Точки:</label>

								<input class="field int-field" name="points" id="points" type="number" max="10000" required />
							</div>

							<div class="add-task-col add-task-col-third">
								<label class="add-task-label">Краен срок:</label>

								<deadline-select name="dead_line" :dates="dates"></deadline-select>
							</div>

							<div class="add-task-col add-task-col-third">
								<label class="add-task-label">Сложност:</label>

								<select v-model="severity" class="field" name="severity">
									<option v-for="severity in severities" :value="severity.value">
										{{ severity.label }}
									</option>
								</select>
							</div>
						</div>
					</div>

					<div class="add-task-row">
						<label class="add-task-label">Заглавие:</label>

						<input type="text" class="field" name="title" required>
					</div>

					<div class="add-task-actions">
						<i class="fa fa-spinner fa-spin" v-if="loading"></i>

						<div class="add-task-buttons" v-else>
							<input type="submit" class="submit" name="" value="Estimate" id="">
						</div>
					</div>
				</div>
			</form>
		</div>

		<div class="estimate-task" v-if="this.estimates.length">
			<h3 class="add-task-title">Резултати</h3>

			<div id="dashboard">
				<div class="person" v-for="estimate in this.estimates">
					<div class="user-profile">
						<strong class="user-profile-img has-uploaded-avatar">
							<a :href="'/' + estimate.user.username">
								<img :src="'/avatars/' + estimate.user.username + '.jpg'" alt="" width="26" height="26">
								{{ estimate.user.name }}
							</a>
						</strong>
					</div>
					<div class="user-tasks estimated-tasks">
						<div class="tasks-date-group">
							<ul>
								<li v-for="time in estimate.times" :class="{
									'active': time.task.currently_estimated,
									'overdue': time.overdue,
								}">
									<a :href="'/' + estimate.user.username + '/task/' + time.task.id" v-if="time.task.id" class="task-title">
										{{ time.task.title }}
									</a>

									<template v-else>{{ time.task.title }}</template>

									<div class="estimate-task-details">
										<span class="estimate-task-deadline">
											<i class="fa fa-calendar"></i>
											Deadline: {{ time.task.dead_line.date | formatDate('DD/MMM') }}
										</span>

										<span class="estimate-task-eta">
											<i :class="[
												'fa',
												time.overdue ? 'fa-exclamation-circle' : 'fa-calendar-check-o'
											]"></i>
											ETA: {{ time.eta_date | formatDate('DD/MMM') }}, in {{ time.eta_days }} days
										</span>

										<span class="estimate-task-hours">
											<i class="fa fa-hourglass-o"></i>
											{{ time.hours_remaining }} hours remaining
										</span>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DeadlineSelect from '../components/DeadlineSelect.vue';
import moment from 'moment';

export default {
	name: 'estimate-task',

	components: {
		DeadlineSelect
	},

	data() {
		return {
			dates: $$estimateData.dates,
			severities: $$estimateData.severities,
			departments: $$estimateData.departments,
			severity: 'normal',
			loading: false,
			departmentId: null,
			divisionId: null,
			estimates: []
		}
	},

	computed: {
		department() {
			if (!this.departmentId) {
				return null;
			}

			return this.departments.find(department => department.id == this.departmentId);
		},

		departmentDivisions() {
			return this.department ? this.department.divisions : [];
		}
	},

	methods: {
		handleFormSubmit(event) {
			const formData = $(event.target).serializeArray();

			this.loading = true;

			const request = $.ajax({
				url: '/ajax/estimate-task',
				type: 'GET',
				data: formData
			});

			request.done((resp) => {
				if (resp.results.estimates.length) {
					this.estimates = resp.results.estimates;
				} else {
					this.estimates = [];
					alert('No results...');
				}
			});

			request.fail(() => {
				alert('Something went wrong...');
			});

			request.always(() => {
				this.loading = false;
			});
		}
	},

	filters: {
		uppercase(value) {
			return value.toUpperCase();
		},

		formatDate(value, format) {
			return moment(value).format(format);
		}
	}
}
</script>

<style>
</style>
