var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar-body"},[_c('div',{staticClass:"tracking-calendar"},[(_vm.username)?_c('full-calendar',{ref:"calendar",attrs:{"event-sources":_vm.eventSources,"editable":false,"selectable":false,"config":{
                    header: {
                        right: 'prev,next',
                        center: '',
                        left: 'title',
                    },
                    navLinks: false,
                    minTime: '07:00:00',
                    maxTime: '24:00:00',
                    displayEventTime: true,
                    displayEventEnd: true,
                    contentHeight: 'auto',
                    firstDay: 1,
                    slotEventOverlap: false,
                    allDaySlot: false,
                    eventRender: _vm.render,
                    agendaEventMinHeight: 7,
                    slotEventOverlap: true
                }}}):_vm._e()],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"buffer-values"},[_c('h4',[_vm._v("Ръчни промени : ")]),_vm._v(" "),(_vm.events && _vm.events.buffers)?_vm._l((_vm.events.buffers),function(data,key){return _c('div',{staticClass:"buffer-list"},[_c('p',[_vm._v("\n                        "+_vm._s(key)+"\n                        "),_c('br'),_vm._v("\n                        ( Total : "+_vm._s(_vm.sumBy(data, 'buffer'))+"   min. )\n                    ")]),_vm._v(" "),_c('ol',_vm._l((data),function(entry){return _c('li',[_vm._v("\n                            "+_vm._s(entry.created_at)+" : "+_vm._s(entry.buffer)+" мин.\n                        ")])}),0)])}):[_c('p',[_vm._v("В момента няма записани буферни времена")])]],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }