/**
 * Internal Dependencies.
 */
import api from 'services/api.js';


export const fetchCurrentUser = ({ commit }) => {
	commit('CURRENT_USER_FETCHING', true);

	api.get('/me')
		.then(({ data }) => {
			commit('CURRENT_USER_FETCHING_SUCCESS', data.data);
		})
		.catch((error) => {
			commit('CURRENT_USER_FETCHING_ERROR', error.message);
		});
}
