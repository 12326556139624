<template>
    <div class="note-form">
        <div class="row">
            <label>
                <input v-model="title" class="note__input field"/>
            </label>
        </div>

        <div class="row">
            <div v-if="canMakeStickyNotes">Body:</div>
            <textarea class="note__textarea field" v-model="body"></textarea>
        </div>

        <div class="row" v-if="canMakeStickyNotes">
            <label>
                <input type="checkbox" v-model="is_sticky"/>
                Sticky
            </label>
        </div>

        <div class="row" v-if="canMakeStickyNotes && is_sticky" >
            Excerpt (up to 128 characters):<br>
            <textarea
                :class="{ 'note__excerpt': true , 'field': true, 'error': isExcerptHighlighted }"
                v-model="excerpt"
                @keydown="onExcerptChange"
                ref="excerpt"
            ></textarea>
        </div>

        <div class="row" v-if="canEditVisibility">
            <ul class="radios">
                <li>
                    <label>
                        <input type="radio" :name="radioName" v-model="needed_permission_level" :value="2">

                        Visible for everyone
                    </label>
                </li>

                <li>
                    <label>
                        <input type="radio" :name="radioName" v-model="needed_permission_level" :value="1">
                        Visible for managers Only
                    </label>
                </li>
            </ul>
        </div>

        <div class="row note__footer">
            <button class="submit note__btn" @click.prevent="handleSubmit">Submit</button>
            <a href="#" class="text-danger" @click.prevent="handleDelete">Delete</a>
            <a href="#" class="text-muted" @click.prevent="handleCancel">Cancel editing</a>
        </div>

        <div class="clear"></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        note: Object,
    },

    data() {
        return {
            title: this.note.title,
            body: this.note.note,
            excerpt: this.note.excerpt,
            needed_permission_level: this.note.needed_permission_level,
            radioName: 'needed_permission_level_' + this.note.id,
            is_sticky: this.note.is_sticky,
            isExcerptHighlighted: false,
        };
    },

    computed: {
        ...mapGetters({
            canEditVisibility: 'clientNotes/getCanEditVisibility',
            canMakeStickyNotes: 'clientNotes/getCanMakeStickyNotes',
        }),
    },

    methods: {
        validate() {
            if (this.canMakeStickyNotes && !this.excerpt && this.is_sticky) {

                return false;
            }

            return true;
        },

        highlightExcerpt(isHighlighted) {
            if (isHighlighted) {
                this.$refs.excerpt.focus();
            }

            this.isExcerptHighlighted = isHighlighted;
        },

        onExcerptChange(event) {
            if (event.target.value) {
                this.highlightExcerpt(false);
            }
        },

        handleSubmit() {
            if (!this.validate()) {
                return this.highlightExcerpt(true);
            }

            this.$emit('submit', {
                title: this.title,
                body: this.body,
                excerpt: this.excerpt,
                needed_permission_level: this.needed_permission_level,
                is_sticky: this.is_sticky,
            });
        },

        handleDelete() {
            if (!confirm('Are you sure you want to delete this note?')) {
                return;
            }

            this.deleteNote({ id: this.note.id });
        },

        handleCancel() {
            this.$emit('cancel');
        },

        ...mapActions({
            deleteNote: 'clientNotes/deleteNote',
        }),
    },
}
</script>
