<template>
   <button type="button" class="entry-copy" v-clipboard:copy="getStringValue" @click="handleCopyIndicator">
        <span class="button">{{text}}</span>
    </button>
</template>

<script>
export default {
    props: {
        value: {
            default: ''
        }
    },

    data() {
        return {
            textChanges: ['Copy', 'Copied'],
            text: "Copy",
            time: 1000,
            disable: false,
        }
    },

    computed: {
        getStringValue() {
            return this.value.toString();
        }
    },

    methods: {
        handleCopyIndicator() {
            clearTimeout(this.timeout);
            this.text = this.textChanges[1];
            this.timeout = setTimeout(() => {
                this.text = this.textChanges[0];
            },this.time)
        }
    },
}
</script>