// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.field[data-v-69fdda2c] { min-width: 250px;
}
table[data-v-69fdda2c] { margin: 15px 0;
}
td[data-v-69fdda2c]:first-child { padding-right: 5px;
}
ul li[data-v-69fdda2c] { list-style-position: inside;
}
.company-mailbox-aliases[data-v-69fdda2c] { margin: 10px 20px
}
`, "",{"version":3,"sources":["webpack://./js/components/company-email/details.vue"],"names":[],"mappings":";AA8CA,0BAAA,gBAAA;AAAA;AACA,yBAAA,cAAA;AAAA;AACA,kCAAA,kBAAA;AAAA;AACA,yBAAA,2BAAA;AAAA;AACA,4CAAA;AAAA","sourcesContent":["<template>\n    <div>\n        <p>\n            Можеш да се логнеш през <a href=\"https://email.2create.studio/mail/\">webmail</a> със следните данни:\n        </p>\n\n        <table>\n            <tr>\n                <td>Email:</td>\n\n                <td><input class=\"field credential-field\" type=\"text\" tabindex=\"1\" :value=\"details.email\" readonly></td>\n            </tr>\n\n            <tr>\n                <td>Password:</td>\n\n                <td><input class=\"field credential-field\" type=\"text\" tabindex=\"2\" :value=\"details.password\" readonly></td>\n            </tr>\n\n            <tr>\n                <td>Webmail:</td>\n\n                <td><input class=\"field credential-field\" type=\"text\" tabindex=\"3\" value=\"https://email.2create.studio/mail/\" readonly></td>\n            </tr>\n        </table>\n\n        <p v-if=\"details.aliases && details.aliases.length\">\n            Всички имейли, които пристигат в тази кутия, ще бъдат препращани и към следните адреси:\n            <ul class=\"company-mailbox-aliases\">\n                <li v-for=\"alias in details.aliases\" :key=\"alias\">\n                    {{ alias }}\n                </li>\n            </ul>\n        </p>\n    </div>\n</template>\n\n<script>\nexport default {\n    props: {\n        details: Object,\n    },\n}\n</script>\n\n<style scoped>\n.field { min-width: 250px; }\ntable { margin: 15px 0; }\ntd:first-child { padding-right: 5px; }\nul li { list-style-position: inside; }\n.company-mailbox-aliases { margin: 10px 20px }\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
