/**
 * External Dependencies.
 */
import Vue from 'vue';
import Vuex from 'vuex';


/**
 * Internal Dependencies.
 */
import * as getters from './getters.js';
import * as actions from './actions.js';
import * as mutations from './mutations.js';


/**
 * Module State.
 */
const state = {
	comments: [],
	revisions: [],

	isFetchingComments: false,
	isFetchingComment: false,

	targetCommentId: null,
};


/**
 * Export Module.
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
