// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.calendar-modal-wrapper .modal-inner { max-width: 900px !important;
}
`, "",{"version":3,"sources":["webpack://./js/time-tracking-components/Modal.vue"],"names":[],"mappings":";AA+BA,uCAAA,2BAAA;AAAA","sourcesContent":["<template>\n\t<div class=\"calendar-modal-wrapper\">\n\t\t<modal @close=\"$emit('close')\">\n\t\t\t<time-tracking-calendar />\n\t\t</modal>\n\t</div>\n</template>\n\n<script>\n\t/**\n\t * The internal dependencies.\n\t */\n\timport Modal from 'components/Modal.vue';\n\timport TimeTrackingCalendar from '~/time-tracking-components/Calendar.vue';\n\n\texport default {\n\t\t/**\n\t\t * The local components.\n\t\t *\n\t\t * @type {Object}\n\t\t */\n\t\tcomponents: {\n\t\t\tModal,\n\t\t\tTimeTrackingCalendar,\n\t\t},\n\t}\n</script>\n\n<style>\n\t.calendar-modal-wrapper .modal-inner { max-width: 900px !important; }\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
