export const updateNote = (state, { id, fields }) => {
    const foundIndex = state.notes.findIndex(note => note.id === id);
    if (foundIndex === -1) {
        return;
    }

    state.notes.splice(foundIndex, 1, {
        ...state.notes[foundIndex],
        ...fields,
    });
}

export const setIsLoading = (state, isLoading) => {
    state.isLoading = isLoading;
}

export const addNote = (state, note) => {
    state.notes.push(note);
}

export const deleteNote = (state, { id }) => {
    const foundIndex = state.notes.findIndex(note => note.id === id);
    if (foundIndex === -1) {
        return;
    }

    state.notes.splice(foundIndex, 1);
}
