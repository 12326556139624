<template>
	<div v-if="only !== 'sticky' || hasStickyNotes" class="client-notes-wrap">
		<h4 v-if="only === 'sticky'">Important Client Notes</h4>

		<note-list :notes="notes" :only="only" />

		<add-note v-if="only !== 'sticky'"/>
	</div>
</template>

<script>
/**
 * External dependencies:
 */
import { mapGetters } from 'vuex';

/**
 * Internal dependencies:
 */
import NoteList from 'components/client-notes/NoteList.vue';
import AddNote from 'components/client-notes/AddNote.vue';

export default {
	props: ['only'],

	components: {
		NoteList,
		AddNote,
	},

	computed: {
		...mapGetters({
			notes: 'clientNotes/getNotes',
		}),

		hasStickyNotes() {
			return this.notes.reduce((acc, note) => note.is_sticky || acc, false)
		}
	},
}
</script>
