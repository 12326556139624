<template>
	<span class="comment-progress">
		<i class="fa fa-check-square-o" aria-hidden="true"></i>

		<span class="comment-progress__status">
			{{ this.completed }}/{{ this.total }}
		</span><!-- /.comment-progress__status -->
	</span><!-- /.comment-progress -->
</template>

<script>
	export default {
		/**
		 * Component Name.
		 *
		 * @type {String}
		 */
		name: 'comment-progress',

		/**
		 * Component Props.
		 *
		 * @type {Object}
		 */
		props: {
			completed: Number,
			total: Number,
		},
	}
</script>