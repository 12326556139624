<template>
	<div class="calendar-body">
        <div class="tracking-calendar">
            <full-calendar
                v-if="username"
                :event-sources="eventSources"
                :editable="false"
                :selectable="false"
                ref="calendar"
                :config="{
                    header: {
                        right: 'prev,next',
                        center: '',
                        left: 'title',
                    },
                    navLinks: false,
                    minTime: '07:00:00',
                    maxTime: '24:00:00',
                    displayEventTime: true,
                    displayEventEnd: true,
                    contentHeight: 'auto',
                    firstDay: 1,
                    slotEventOverlap: false,
                    allDaySlot: false,
                    eventRender: render,
                    agendaEventMinHeight: 7,
                    slotEventOverlap: true
                }"
                />
        </div>

        <div class="buffer-values" v-show="false">
            <h4>Ръчни промени : </h4>

            <template v-if="events && events.buffers">
                <div v-for="(data, key) in events.buffers" class="buffer-list">
                    <p>
                        {{ key }}
                        <br>
                        ( Total : {{ sumBy(data, 'buffer') }}   min. )
                    </p>

                    <ol>
                        <li v-for="entry in data">
                            {{ entry.created_at }} : {{ entry.buffer }} мин.
                        </li>
                    </ol>
                </div>
            </template>

            <template v-else>
                <p>В момента няма записани буферни времена</p>
            </template>
        </div>
	</div>
</template>


<script>
	/**
	 * The external dependencies.
	 */
	import Tooltip from 'tooltip.js';
	import { sumBy } from 'lodash';
	import { FullCalendar } from 'vue-full-calendar';
	import { mapGetters, mapActions } from 'vuex';
	import moment from 'moment';

	export default {
        name: "TimeTrackingCalendar",
		/**
		 * The local components.
		 *
		 * @type {Object}
		 */
		components: {
			FullCalendar,
		},

		/**
		 * The local state.
		 *
		 * @return {Object}
		 */
		data() {
			return {
                username: null,
				hasLoaded: false,
			};
		},

		/**
		 * The computed properties.
		 *
		 * @type {Object}
		 */
		computed: {
			/**
			 * Map the store's state
			 */
			...mapGetters({
				events: 'getTrackingEvents',
			}),
			trackedEvents() {
				return this.events.tracked;
			},
            eventSources() {
                return [
                    `/api/task-time-tracking/events/${this.username}`,
			    ]
            },
		},

		/**
		 * The public API.
		 *
		 * @type {Object}
		 */
		methods: {
			/**
			 * Map the store's actions.
			 */
			...mapActions([
				'requestTrackingEvents',
			]),


			render(args) {
				let {
					event: {
						title,
						start,
						end,
						interruptions,
						manually_set_buffer,
						manual_buffer_actual_shift,
						manual_buffer_visualized_shift
					},
					el,
				} = args;

				start = moment(start);
				end = moment(end);

				let tooltipContent = `
                <p><strong>${title}</strong></p>`;

                if ( !manually_set_buffer ) {
                    tooltipContent += `
                        <p>${start.format('HH:mm')} ${
                            end
                                ? `- ${end.format('HH:mm')}`
                                : ''
                        }
                        </p>
                        <p>Duration: ${
                        end
                            ? `${end.diff(start, 'minutes')} min.`
                            : ''
                    }</p>`;
                    if ( interruptions ) {
                        tooltipContent += `
                            <p style="text-align: left">${interruptions.length} Interruptions:</p>
                            <ul>
                                ${interruptions.map( interruption => `
                                    <li>${interruption.duration} minute${interruption.duration === 1 ? '' : 's'} ${moment(interruption.start).format('HH:mm')} - ${moment(interruption.end).format('HH:mm')}</li>
                                `).join('')}
                            </ul>
                        `;
                    }
                } else {
                    tooltipContent += `
                        <p><strong>Time manually ${manual_buffer_actual_shift < 0 ? "decreased" : "increased"} by: </strong> ${manual_buffer_actual_shift} minutes </p>
                    `;

                }

				new Tooltip(el, {
					// placement: 'left',
                    title: tooltipContent,
                    html: true
				});
			},

			sumBy,
		},

		mounted() {
			if (!this.events) {
				if (!window.$$initialState.username) {
					const username = window.$$initialState.username;
				} else {
					const username = window.location.pathname.replace(/\/([^\/]*)\/.*$/, '$1');
				}

				if (!username) {
					return;
				}

                this.username = username;
			} else {
				this.hasLoaded = true;
			}
		}
	}
</script>

<style>
	@import '~fullcalendar/dist/fullcalendar.css';

	.calendar-body { display: flex; }
	.calendar-body .tracking-calendar { margin-right: 20px; position: relative; z-index: 10; }
	.calendar-body .buffer-values { width: 25%;  max-height: 700px; overflow: auto; }

	.calendar-body .tracking-calendar h4,
	.calendar-body .buffer-values h4 { margin-bottom: 13px; }

	.calendar-body .buffer-values .buffer-list {margin-bottom: 25px; }
	.calendar-body .buffer-values .buffer-list ol { max-height: 350px; overflow: auto; }

	.calendar-body .fc-content-skeleton td { position: relative; z-index: -1; }
	.calendar-body .fc-content-skeleton td:hover { z-index: 9999;}
	.calendar-body .fc-content-skeleton .fc-content-col { position: relative; z-index: -1; }

	.calendar-body .tooltip {position: absolute; background: #FFC107; color: black; min-width: 220px; max-width: 300px; border-radius: 3px; box-shadow: 0 0 2px rgba(0,0,0,0.5); padding: 10px; z-index: 9999;}

	.calendar-body .tooltip .tooltip-arrow {width: 0; height: 0; border-style: solid; position: absolute; margin: 5px; }

	.calendar-body .tooltip .tooltip-arrow {border-color: #FFC107; }
	.calendar-body .tooltip ul { text-align: left; list-style-type: circle; padding-left: 20px }

	.calendar-body .tooltip[x-placement^="top"] {margin-bottom: 5px; }

	.calendar-body .tooltip[x-placement^="top"] .tooltip-arrow {border-width: 5px 5px 0 5px; border-left-color: transparent; border-right-color: transparent; border-bottom-color: transparent; bottom: -5px; left: calc(50% - 5px); margin-top: 0; margin-bottom: 0; }

	.calendar-body .tooltip[x-placement^="bottom"] {margin-top: 5px; }

	.calendar-body .tooltip[x-placement^="bottom"] .tooltip-arrow {border-width: 0 5px 5px 5px; border-left-color: transparent; border-right-color: transparent; border-top-color: transparent; top: -5px; left: calc(50% - 5px); margin-top: 0; margin-bottom: 0; }
	.calendar-body .tooltip[x-placement^="right"] {margin-left: 5px; }

	.calendar-body .tooltip[x-placement^="right"] .tooltip-arrow {border-width: 5px 5px 5px 0; border-left-color: transparent; border-top-color: transparent; border-bottom-color: transparent; left: -5px; top: calc(50% - 5px); margin-left: 0; margin-right: 0; }

	.calendar-body .tooltip[x-placement^="left"] {margin-right: 5px; }

	.calendar-body .tooltip[x-placement^="left"] .tooltip-arrow {border-width: 5px 0 5px 5px; border-top-color: transparent; border-right-color: transparent; border-bottom-color: transparent; right: -5px; top: calc(50% - 5px); margin-left: 0; margin-right: 0; }
</style>