<template>
	<div id="allcomments">
		<spinner size="fa-2x" v-if="isFetching" />

		<a
			href="#" id="show-old-comments"
			v-if="hasHiddenComments && hiddenCommentsCount && comments.length > maxVisibleComments"
			@click.prevent="displayHiddenComments"
		>
			Покажи {{ hiddenCommentsCount }} стари
		</a>



		<transition-group ref="comments" name="fade" tag="div">
			<comment
				v-for="(comment, index) in visibleComments"
				:key="comment ? comment.id : index/* DO NOT CHANGE TO comment.id -- it won't work */"
				:comment="comment"
				:is-focused="focusCommentId === comment.id || archiveNewCommentsIds.indexOf(comment.id) > -1"
			/>
		</transition-group>
	</div><!-- #allcomments -->
</template>

<style scoped>
	.fade-leave-active { transition: opacity .5s; }
	.fade-leave-to { opacity: 0; }
</style>

<script>
	/**
	 * External Dependencies.
	 */
	import { mapActions, mapGetters, mapMutations } from 'vuex';

	/**
	 * Internal Dependencies.
	 */
	import Comment from './Comment.vue';
	import Spinner from '~/components/spinner/spinner.vue';

	import { bindShortcut } from '~/utils/shortcuts';


	export default {
		/**
		 * Component Name.
		 *
		 * @type {String}
		 */
		name: 'comments',

		/**
		 * Child Components.
		 *
		 * @type {Object}
		 */
		components: {
			Comment,
			Spinner
		},

		/**
		 * Component Data.
		 *
		 * @return {Object}
		 */
		data() {
			return {
				maxVisibleComments: 10,

				hasHiddenComments: true,

				archiveNewCommentsIds: [],
				newCommentsIds: [],

				newCommentsNotificationId: null,
			};
		},

		/**
		 * Component Methods.
		 *
		 * @type {Object}
		 */
		methods: {
			...mapActions({
				fetchComments: 'comments/fetchComments',
				addComment: 'comments/addComment',
				addNotification: 'notifications/addNotification',
				editNotification: 'notifications/editNotification',
				removeNotification: 'notifications/removeNotification',
			}),

			...mapMutations({
				removeComment: 'comments/COMMENT_REMOVE',
			}),

			displayHiddenComments() {
				this.hasHiddenComments = false;

				this.$nextTick(() => {
					const comments = this.$refs.comments;

					if (comments) {
						comments.$children.forEach(($children) => {
							$children.forceOpen();
						});
					}
				})
			},

			/**
			 * Beam Subscription.
			 */
			subscribe() {
				const beam = window.$$beam;
				const channel = `task-${window.TASK_ID}`;

				beam.join(channel);

				beam.on(channel, 'comments:new', (commentId) => {
					this.newCommentsIds.push(Number(commentId));

					this.triggerNewCommentsNotification();
				});

				beam.on(channel, 'comments:deleted', (commentId) => {
					this.removeComment({ commentId });
				});
			},

			triggerNewCommentsNotification() {
				let notificationData = {
					message: this.newCommentsIds.length === 1 ? 'Нов коментар' : `${this.newCommentsIds.length} нови коментара`,
					type: 'info',
					dismissable: false,
					onClick: this.attachNewComments,
				};

				if (this.newCommentsNotificationId) {
					this.editNotification({
						id: this.newCommentsNotificationId,
						data: notificationData,
					});
				} else {
					this.addNotification(notificationData).then((id) => {
						this.newCommentsNotificationId = id;
					});
				}
			},

			attachNewComments() {
				this.removeNotification(this.newCommentsNotificationId);

				let from = this.comments.length > 0 ? this.comments[ this.comments.length - 1 ].id : null;
				let to   = this.newCommentsIds.length > 0 ? this.newCommentsIds[ this.newCommentsIds.length - 1 ] : null;

				this.archiveNewCommentsIds = this.archiveNewCommentsIds.concat(this.newCommentsIds);

				this.fetchComments({
					from,
					to,
				}).then(() => {
					this.newCommentsNotificationId = null;
					this.newCommentsIds            = [];
				});
			},

			scrollToComment(commentId) {
				const $comment = $(`#comment-${commentId}`);

				$('html, body').animate({
					scrollTop: $comment.offset().top - 50,
				}, 500);
			}
		},

		/**
		 * Computed Data.
		 */
		computed: {
			...mapGetters({
				isFetching: 'comments/getIsFetchingComments',
				comments: 'comments/getComments',
				targetCommentId: 'comments/getTargetCommentId',
			}),

			hiddenCommentsCount() {
				return this.comments.length - this.maxVisibleComments;
			},

			focusCommentId() {
				if (this.archiveNewCommentsIds.length > 0) {
					return this.archiveNewCommentsIds[0];
				}

				if (window.location.hash) {
					return Number(window.location.hash.replace('#comment-', ''));
				}

				return false;
			},

			visibleComments() {
				return this.comments.filter((comment, index) => {
					if (! this.hasHiddenComments) {
						return true;
					}

					if (this.archiveNewCommentsIds.indexOf(comment.id) > -1) {
						return true;
					}

					if (this.comments.length - index <= this.maxVisibleComments) {
						return true;
					}

					return false;
				});
			},
		},

		watch: {
			comments: {
				immediate: true,
				handler() {
					Vue.nextTick(() => {
						if (this.comments.length && this.focusCommentId) {
							this.scrollToComment(this.focusCommentId);
						}
					})
				}
			}
		},

		/**
		 * Lifecycle Hook.
		 */
		mounted() {
			if (this.focusCommentId) {
				this.hasHiddenComments = false;
			}

			this.subscribe();

			bindShortcut(['command+alt+c', 'ctrl+alt+c', 'command+shift+c', 'ctrl+shift+c'], (event) => {
				event.stopPropagation();
				event.preventDefault();

				$(`#comment-${this.targetCommentId}`).find('.copy-comment-markdown').get(0).click();
			});

			this.fetchComments()
				.then(() => {
					if (this.comments.length <= this.maxVisibleComments) {
						this.hasHiddenComments = false;
					}
				});
		}
	}
</script>
