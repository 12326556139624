<template>
    <div class="sidebar-item-badge-holder">
        <div class="sidebar-item-badge is-reserved" :class="{'is-reserved-own': ownReserve}" v-if="isReserved(entry)">
            <div class="badge-status">
                <div class="badge-user" v-for="(item, index) in entry.active_reservations" :key="index">
                    <img :src="item.user.avatar" />
                </div>
                Reserved
            </div>
        </div>
        <div class="sidebar-item-badge" v-else>
            <div class="badge-status">Available</div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['entry'],

    computed: {
        ownReserve() {
            if(this.entry.active_reservations) {
                return this.entry.active_reservations.find(item => item.user.currentUser);
            }

            return false;
        }
    },

    methods: {
        isReserved(entry) {
            return entry.active_reservations && entry.active_reservations.length > 0;
        }
    },
}
</script>