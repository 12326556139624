/**
 * Internal Dependencies.
 */
import * as getters from './getters.js';
import * as actions from './actions.js';
import * as mutations from './mutations.js';

/**
 * Module State.
 *
 * @type {Object}
 */


/**
 * Export Module
 */
export default {
	namespaced: true,
	state: {
		isLoading: false,
		notes: [],
		client_id: 0,
		can_add_notes: true,
		can_change_note_visibility: false,
	},
	getters,
	actions,
	mutations,
};
