export const addNotification = ({ commit }, data) => {
	let id = Date.now();

	commit('ADD_NOTIFICATION', {
		id,
		...data
	});

	return id;
};

export const editNotification = ({ commit }, { id, data }) => {
	commit('EDIT_NOTIFICATION', {
		id,
		data,
	});
};

export const removeNotification = ({ commit }, id) => {
	commit('REMOVE_NOTIFICATION', id);
}
