/**
 * Internal Dependencies.
 */
import api from 'services/api';


/**
 * Requests comments from the server.
 * 
 * @return {Void}
 */
export const fetchComments = ({ commit }, range) => {
	commit('COMMENTS_FETCHING', true);

	const taskId = window.TASK_ID;

	let endpoint = (typeof range === 'undefined') ? `/tasks/${taskId}/comments` : `/tasks/${taskId}/comments-in-range?from=${range.from}&to=${range.to}`;

	return api.get(endpoint, range)
		.then(({ data }) => {
			commit('COMMENTS_FETCHING_SUCCESS', data.data);
		})
		.catch((error) => {
			commit('COMMENTS_FETCHING_ERROR', error.message);
		});
}

/**
 * Requests comments in a given range from the server.
 * 
 * @return {Void}
 */
export const fetchCommentsInRange = ({ commit }) => {
	commit('COMMENTS_FETCHING', true);

	const taskId = window.TASK_ID;

	return api.get(`/tasks/${taskId}/comments`)
		.then(({ data }) => {
			commit('COMMENTS_FETCHING_SUCCESS', data.data);
		})
		.catch((error) => {
			commit('COMMENTS_FETCHING_ERROR', error.message);
		});
}

/**
 * Adds a new Comment to the set.
 *
 * @return {Void}
 */
export const addComment = ({ commit }, comment) => {
	commit('COMMENT_ADD', comment);
}


/**
 * Requests a single comment from the server.
 *
 * @param  {Number} commentId
 * 
 * @return {Promise}
 */
export const fetchComment = ({ commit }, commentId) => {
	return api.get(`/comments/${commentId}`)
		.then(({ data }) => {
			commit('COMMENT_REFRESH', {
				commentId,
				comment: data.data,
			});

			return data;
		})
		.catch((error) => {
			commit('COMMENT_REFRESH_ERROR', error.message);
		});
}

/**
 * Posts an update of the given comment.
 *
 * @param  {Object} comment
 * @return void
 */
export const updateComment = ({ commit }, { commentId, value, revision }) => {
	return api.patch(`/comments/${commentId}`, {
		value,
		revision,
	})
	.then(({ data }) => {
		commit('COMMENT_REFRESH', {
			commentId,
			comment: data.data,
		})

		return data;
	});
}

/**
 * Posts a request to update the estimate time of a comment.
 *
 * @param  {Object} comment
 * @return void
 */
export const updateCommentEstimate = ({ commit }, { commentId, estimate }) => {
	return api.patch(`/comments/${commentId}/estimate`, { estimate })
		.then(({ data }) => {
			commit('COMMENT_REFRESH', {
				commentId,
				comment: data.data,
			})

			return data;
		});
}

/**
 * Handles the comment deletion.
 */
export const deleteComment = ({ commit }, commentId) => {
	return api.delete(`/comments/${commentId}`)
		.then(({ data }) => {
			commit('COMMENT_REMOVE', {
				commentId
			});
		})
		.catch((error) => {
			console.log( 'here..' );
		});
}

/**
 * Handles the Target Comment update.
 */
export const updateTargetComment = ({ commit }, commentId) => {
	commit('UPDATE_TARGET_COMMENT', commentId);
};
