<template>
	<div
		class="connection-string-holder acd-actions connection-string-holder-tr"
		v-if="entry.protocol !== 'general' && entry.protocol !== 'seat'"
	>
		<ftp-connection
			:droneLink="droneLink"
			:connectionString="connectionString"
			:visibility="visibility"
		/>
	</div>
</template>

<script>
/**
 * Internal dependencies:
 */
import FtpConnection from '~/components/FtpConnection.vue';

export default {
	props: ['entry', 'droneLink', 'connectionString', 'visibility'],

	components: {
		FtpConnection,
	},
};
</script>
