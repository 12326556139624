<template>
	<div :class="classes" :title="`Прогноза: ${task.time_estimated || 'N/A'}мин.`">
		<div v-if="task.time_estimated" class="progress-bar" :style="{ width: `${percent}%` }" :title="`Време: ${task.time_recorded}мин.`">
			<i v-if="task.has_time_estimate_placeholder" class="fa fa-question-circle fa--danger" title="Временна прогноза 'Не Знам'"></i>

			<i v-if="task.quoted_time_estimate > 0" class="fa fa-exclamation-circle fa--danger" :title="`Автоматична прогноза, реално време: ${task.quoted_time_estimate} мин.`"></i>

			{{visiblePercent}}%
		</div>

		<template v-else>N/A</template>
	</div>
</template>

<script>
export default {
	name: 'task-progress',

	props: {
		task: {
			required: true
		},
	},

	computed: {
		classes() {
			return [
				'progress',
				{ 'progress-inactive': this.task.time_is_wip === 'n' },
				{ 'progress--warning': this.percent >= 90 && this.percent < 100 },
				{ 'progress--danger': this.percent >= 100 }
			];
		},

		percent() {
			const timeEstimated = parseInt(this.task.time_estimated) || 0;
			const timeRecorded = parseInt(this.task.time_recorded) || 0;

			const percent = timeEstimated === 0 ? 0 : Math.round((timeRecorded / timeEstimated) * 100);

			return percent;
		},

		visiblePercent() {
			return this.percent > 100 ? '100+' : this.percent;
		}
	}
}
</script>
