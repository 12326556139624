<template>
	<div>
		<monitor-milestones-filters />

		<div :class="['listview', 'monitor-milestones', { 'monitor-milestones-search': hasSearchQuery }]">
			<div class="listview-head listview-row">
				<div class="listview-cell">
					<h4 class="listview-heading">Проект</h4>
				</div>

				<div class="listview-cell listview-cell-s">
					<h4 class="listview-heading">Краен срок</h4>
				</div>
			</div>

			<div class="monitor-loading" v-if="milestonesState === 'loading'" >
				<i class="fa fa-spinner fa-spin"></i>
			</div>


			<template v-if="hasMilestones">
				<div v-if="!hasSearchQuery">
					<div v-for="(milestones, deadline) in milestonesGroups" class="listview-group">
						<h4 class="listview-group-title">
							<strong>{{deadline | calendarDay}}</strong>,
							<span>{{deadline | dayOfMonth}}</span>
							<small v-text="`(${milestones.length} ${milestones.length > 1 ? 'проекта' : 'проект'})`"></small>
						</h4>
						
						<monitor-milestone v-for="milestone in milestones" :milestoneId="milestone.id" :key="milestone.id" />
					</div>
				</div>
				
				<div v-else>
					<monitor-milestone v-for="milestone in milestones" :milestoneId="milestone.id" :key="milestone.id" />
				</div>
			</template>

			<div v-else-if="milestonesState !== 'loading'" class="monitor-no-results">
				<p>Няма намерени резултатите.</p>
			</div>

			<monitor-milestones-paging />

			<monitor-new-milestone :visible="milestonesNewModalVisible" @close="handleNewMilestoneClose" />
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import MonitorMilestone from '../components/MonitorMilestone.vue';
import MonitorMilestonesPaging from '../components/MonitorMilestonesPaging.vue';
import MonitorMilestonesFilters from '../components/MonitorMilestonesFilters.vue';
import MonitorNewMilestone from '../components/MonitorNewMilestone.vue';
import { calendarDay, dayOfMonth } from '../utils/filters';

export default {
	name: 'monitor-milestones',

	components: {
		MonitorMilestone,
		MonitorMilestonesFilters,
		MonitorMilestonesPaging,
		MonitorNewMilestone,
	},

	data() {
		return {
			updateInterval: null,
		};
	},

	computed: {
		milestones() {
			return _.orderBy(
				this.$store.state.milestones,
				['finished', 'dead_line', milestone => milestone.project && milestone.project.client ? milestone.project.client.title : null ],
				['asc', 'asc', 'asc']
			);
		},

		milestonesState() {
			return this.$store.state.milestonesState;
		},

		milestonesFilters() {
			return this.$store.state.milestonesFilters;
		},

		milestonesGroups() {
			const milestones = _.chain(this.milestones)
				.values()
				.map(milestone => {
					milestone.dead_line = moment(milestone.dead_line).format('YYYY-MM-DD');

					return milestone;
				})
				.value();
			const sortType = this.milestonesFilters.finished === 'y' ? 'desc': 'asc';

			return _
				.chain(milestones)
				.orderBy('dead_line', sortType)
				.groupBy('dead_line')
				.value();
		},

		milestonesNewModalVisible: {
			get() {
				return this.$store.state.milestonesNewModalVisible;
			},

			set(value) {
				return this.$store.commit('TOGGLE_MILESTONES_NEW_MODAL', value);
			}
		},

		hasMilestones() {
			if (this.milestones) {
				return Object.keys(this.milestones).length > 0;
			}
		},

		hasSearchQuery() {
			return this.$store.getters.hasSearchQuery;
		},
	},

	methods: {
		setUpdateInterval() {
			const updateIntervalTime = 10 * 1000;

			this.updateInterval = setInterval(() => {
				this.$store.dispatch('fetchMilestones', { silent: true });
			}, updateIntervalTime);
		},

		handleNewMilestoneClose() {
			this.milestonesNewModalVisible = false;
		}
	},

	mounted() {
		this.setUpdateInterval();
	},

	filters: {
		calendarDay,
		dayOfMonth,
	}
}
</script>
