<template>
	<div>
		<form action="/ajax/task-save" method="post" class="form" @submit="handleFormSubmit">
			<div class="form-body">
				<div class="form-content">
					<div class="add-task-section">
						<div class="add-task-row">
							<div class="add-task-cols">
								<div class="add-task-col add-task-col-medium">
									<label class="add-task-label">Потребител:</label>

									<users-dropdown name="user_id" class="field" v-bind:users="users" v-model="currentUserSelection" :returnOnlyValue="true" />
								</div>
							</div>
						</div>
					</div>

					<div class="add-task-section">
						<div class="add-task-row">
							<label class="add-task-label">Заглавие:</label>

							<input type="text" class="field" name="title" v-model="taskTitle" required>
						</div>

						<div class="add-task-row" >
							<label class="add-task-label">Описание:</label>

							<textarea class="field" id="long_desc" name="long_desc" rows="8" v-autogrow v-model="taskDescription"></textarea>

							<small>syntax: <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank">markdown</a></small>
						</div>
					</div>

					<div v-show="!!project.project_id">
						<div class="add-task-section">
							<div v-show="!addingIssueTask">
								<label class="add-task-label">
									<input type="checkbox" name="use-vcs" v-model="view.vcsEnabled" @change="handleVcsEnabledChange">

									<span>GIT Repo</span>
								</label>
							</div>

							<div class="selected-vcs-path" v-if="view.vcsEnabled">
								<a href="#" class="button" v-if="!addingIssueTask" @click.prevent="view.modalVcs = true">Select VCS</a>

								<i class="fa fa-spinner fa-spin" v-if="vcsResponse.state === 'loading'"></i>

								<span class="selected-vcs-path-error" v-if="vcsResponse.error">{{vcsResponse.error}}</span>

								<strong v-if="vcsResponse.state === ''">{{vcsPath !== 'new' ? vcsPath : '-'}}</strong>
							</div>
						</div>

						<div class="add-task-section">
							<div class="add-task-cols">
								<div class="add-task-col add-task-col-medium">
									<label class="add-task-label add-task-label-qa">
										<input type="checkbox" id="qa_enabled" name="qa_enabled" v-model="view.qaEnabled">

										<span>QA</span>
									</label>

									<div class="add-task-qa" v-show="view.qaEnabled">
										<users-dropdown name="qa_username" class="field" optionValue="username" v-bind:users="users" v-model="currentQASelection" :returnOnlyValue="true" />
									</div>
								</div>

								<div class="add-task-col" v-if="view.qaEnabled">
									<datepicker name="qa_deadline" class="field field-date" v-bind:placeholder="projectDeadline" v-bind:value="qaDefaultDeadline" dateFormat="yy-mm-dd" v-once />
								</div>
							</div>
						</div>

						<div class="add-task-section">
							<label class="add-task-label">
								<input type="checkbox" name="add-milestone" value="1" class="" id="add-milestone" v-model="view.milestoneEnabled">

								<span>Добави milestone в monitor</span>
							</label>

							<div class="add-task-milestone" v-show="view.milestoneEnabled">
								<div class="add-task-cols">
									<div class="add-task-col add-task-col-medium">
										<label>Title</label>

										<input type="text" name="milestone-name" class="field milestone-name-field wide-col-field" placeholder="Име на milestone" v-model="milestoneTitle">
									</div>

									<div class="add-task-col">
										<label>Date</label>

										<datepicker name="milestone-date" class="field field-date" placeholder="Дата в monitor" v-model="milestoneDeadline" dateFormat="yy-mm-dd" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="add-task-row">
						<div class="add-task-cols">
							<div class="add-task-col">
								<label class="add-task-label">Сума $:</label>

								<input class="field int-field" name="payment_amount" id="payment_amount" v-model="taskPaymentsAmount" />
							</div>

							<div class="add-task-col">
								<label class="add-task-label">Ideal Coding Time:</label>

								<input class="field int-field" name="points" id="points" :readonly="checklistPreviewEstimate > 0" v-model="pointsValue" />

								<div
									v-if="checklistPreview && checklistPreview.quoted_time"
									class="add-task-quoted-time"
								>
									<input
										v-model="checklistPreview.quoted_time"
										type="hidden"
										name="quoted_time"
									/>

									<small>Quoted time: {{ Math.round(checklistPreview.quoted_time * 60) }}min.</small>
								</div>
							</div>

							<div class="add-task-col">
								<label class="add-task-label">Краен срок:</label>

								<deadline-select name="dead_line" :dates="dates"></deadline-select>
							</div>

							<div class="add-task-col">
								<label class="add-task-label">Сложност:</label>

								<select class="field" name="severity" v-model="selectedSeverity">
									<option v-for="severity in severities" :value="severity.value">{{severity.label}}</option>
								</select>
							</div>
						</div>
					</div>

					<div class="add-task-row add-task-checklist" v-if="quote">
						<div class="add-task-label">
							<label>
								<input type="checkbox" v-model="checklistEnabled"> Добави Checklist от Quote
							</label>
						</div>

						<div v-if="checklistEnabled" class="add-task-checklist-holder">
							<div class="add-task-label">
								<label>
									<input type="checkbox" @change="handleChecklistAdditionalItemsChange" checked> Добави време за планиране и комуникиране?
								</label>
							</div>

							<div class="add-task-label">
								<label>
									<input type="checkbox" v-model="halfwayThroughNotification"> Изпрати известие по Slack при достигане на 50% от IDC прогнозата
								</label>
							</div>

							<checklist-editor v-model="checklistData" @input="handleChecklistInput" />

							<checklist-preview :checklist-data="checklistPreview" @estimate:change="handleEstimateChange" />
						</div>
					</div>

					<div class="add-task-actions">
						<i class="fa fa-spinner fa-spin" v-if="loading || vcsResponse.state === 'loading'"></i>
						<div class="add-task-buttons" v-else>
							<input type="submit" class="submit" value="Запази задачата" :disabled="checklistEnabled && checklistPreview && !checklistPreview.valid">
						</div>
					</div>

					<div class="add-task-result" v-if="result">
						Задачата беше добавена!
						<a v-bind:href="`/${result.username}/task/${result.taskId}`" target="_blank">Към страницата на задачата</a>
					</div>

					<input type="hidden" name="project_id" id="project_id" v-bind:value="project.project_id" />
					<input type="hidden" name="project_deadline" id="project_deadline" v-bind:value="project.dead_line" />
					<input type="hidden" name="svn_path" id="svn_path" v-bind:value="vcsPath !== 'new' && view.vcsEnabled ? vcsPath : ''" />
					<input type="hidden" name="desc_syntax" id="desc_syntax" value="parsedown" />
				</div>

				<div class="form-aside">
					<related-tasks v-if="this.quote" />

					<quote-info />
				</div>
			</div>
		</form>

		<modal v-show="view.modalVcs" @close="view.modalVcs = false">
			<h3 slot="title">Select VCS Path</h3>

			<select-vcs
				v-bind:vcs-paths="project.svn_paths"
				v-bind:project="project"
				v-show="view.vcsEnabled"
			/>
		</modal>
	</div>
</template>

<script>
import { toUTC } from '../utils/helpers';
import Datepicker from '../components/Datepicker.vue';
import DeadlineSelect from '../components/DeadlineSelect.vue';
import UsersDropdown from '../components/UsersDropdown.vue';
import Modal from '../components/Modal.vue';
import SelectVcs from '../components/SelectVcs.vue';
import QuoteInfo from '../components/QuoteInfo.vue';
import RelatedTasks from '../components/RelatedTasks.vue';
import ChecklistEditor from '../checklist-components/ChecklistEditor.vue';
import ChecklistPreview from '../checklist-components/ChecklistPreview.vue';

import moment from 'moment';

export default {
	name: 'add-task-form',

	components: {
		ChecklistEditor,
		Datepicker,
		DeadlineSelect,
		UsersDropdown,
		Modal,
		SelectVcs,
		QuoteInfo,
		RelatedTasks,
		ChecklistPreview
	},

	props: ['users', 'addingIssueTask'],

	data() {
		const initialUser = $$taskData.users.find(user => user.username === $$taskData.currentUser);
		const initialUserId = initialUser ? initialUser.id : 0;
		const vcsEnabledDefault = !!$$taskData.taskDefaultSvnPath;
		const initialQAUser = $$taskData.lastQA;

		return {
			currentUserSelection: initialUserId,
			currentQASelection: initialQAUser,

			milestoneTitle: 'Task Completion',
			milestoneDeadline: null,

			view: {
				vcsEnabled: vcsEnabledDefault,
				qaEnabled: false,
				milestoneEnabled: this.addingIssueTask,
				newRepoEnabled: false,
				modalVcs: false
			},

			taskPaymentsAmount: 0,
			pointsValue: 0,

			dates: $$taskData.dates,
			severities: $$taskData.severities,
			selectedSeverity: 'normal',

			taskTitle: $$taskData.taskDefaultTitle,
			taskDescription: '',

			loading: false,
			result: null,

			checklistEnabled: false,
			checklistData: null,
			checklistPreview: null,
			checklistPreviewEstimate: null,
			checklistUpdateTimeout: null,

			shouldPreventClose: false,

			halfwayThroughNotification: true,
		};
	},

	computed: {
		project: {
			get() {
				return this.$store.state.project;
			},

			set(payload) {
				this.$store.dispatch('changeProject', payload);
			}
		},

		payments: {
			get() {
				return this.$store.state.payments;
			},

			set(payload) {
				this.$store.dispatch('changePayments', payload);
			}
		},

		quote: {
			get() {
				return this.$store.state.quote;
			},

			set(payload) {
				this.$store.dispatch('changeQuote', payload);
			}
		},

		quotePayload() {
			if (!this.quote || !this.quote.definitions) {
				return null;
			}

			const quote = this.quote;

			const pm_sys_project_id = quote.systems_project.id;
			const pm_sys_quote_id = quote.id;
			const yaml = quote.definitions.source;
			const posted_on = quote.posted_on;
			const posted_by = quote.author.username;
			const accepted_on = quote.accepted_option.accepted_on;
			const accepted_option = quote.accepted_option.name;

			return {
				pm_sys_project_id,
				pm_sys_quote_id,
				yaml,
				posted_on,
				posted_by,
				accepted_on,
				accepted_option
			};
		},

		vcsResponse() {
			return this.$store.state.vcsResponse;
		},

		vcsPath() {
			return this.$store.state.vcsPath;
		},

		taskType() {
			return this.$store.state.taskType;
		},

		projectDeadline() {
			if (this.project) {
				return moment(this.project.dead_line).format('YYYY-MM-DD');
			}

			return moment().format('YYYY-MM-DD');
		},

		activeTab() {
			return this.$store.state.activeTab;
		},

		qaDefaultDeadline() {
			if (this.quote && this.quote.accepted_option && this.quote.accepted_option.delivery_date) {
				return moment(this.quote.accepted_option.delivery_date).utc().format('YYYY-MM-DD');
			}

			return this.projectDeadline;
		},

		/**
		 * Get quote definition.
		 * @return {String}
		 */
		quoteDefinition() {
			const payload = this.quote;

			if (!payload) {
				return null;
			}

			if (payload.definitions && payload.definitions.source) {
				return payload.definitions.source;
			}

			// Use 'mail' context if implementation is email, othewise fallback to 'html'
			const title = payload.title.replace('\'', '\'\'');
			const amount = payload.delivery_options[0].price;
			const context = payload.implementation === 'email' ? 'mail' : 'html';
			let turnaround = payload.accepted_option.name;

			if (context !== 'mail' && turnaround === 'expedited') {
				turnaround = 'standard';
			}

			return `${title}:\n    _options: ${turnaround}\n    ${context} as Task:\n        Coding: ${amount}`;
		},

		/**
		 * Checklist additional items string
		 * @return {String}
		 */
		checklistAdditionalItems() {
			let items = [
				'Read the instructions and review the design mocks: 0',
				'Ping the project manager to discuss the project: 0',
			];

			for (const tagName of this.projectTagsWithGroup) {
				items.push(`Read the ${tagName} instructions: 0`);
			}

			const checklistString = this.checklistData || this.quoteDefinition;
			const isWp = /wp|wordpress|woocommerce/i.test(checklistString);
			if (isWp) {
				items.push('Check the spelling with "drone spellcheck": 0');
			}

			// Join the Additional Items with a new line and two tabs (8 spaces).
			// End with two new lines to separate from other checkboxes
			return `    html as Planning:\n        ${items.join('\n        ')}\n\n    `;
		},

		projectTagsWithGroup() {
			return (this.project.tags || [])
				.filter(tag => tag.group && tag.description_link)
				.map(tag => tag.name);
		}
	},

	mounted() {
		this.populateFields();
		this.preventAccidentalClose();
	},

	methods: {
		populateFields() {
			if (this.quote) {
				this.taskTitle = this.getTitleFromQuote();
				this.populateMilestoneFields();

				this.checklistData = this.quoteDefinition && this.toggleChecklistAdditionalItems(this.quoteDefinition);
				this.getChecklistPreview();
			} else if (this.project.project_title) {
				this.taskTitle = this.project.project_title;
			}

			const addingIssueTaskSuffix = ' -- Issues';

			if (this.addingIssueTask) {
				this.taskTitle = (this.taskTitle + ' ').replace(/\s+(-- Issues)?(\s)*$/, addingIssueTaskSuffix);
			}

			if (this.projectTagsWithGroup.length) {
				this.taskTitle = `[${this.projectTagsWithGroup.join('][')}] ${this.taskTitle}`;
			}

			if (this.project.svn_paths && this.project.svn_paths.length) {
				this.$store.commit('CHANGE_VCS_PATH', this.project.svn_paths[0]);
			}
		},

		populateMilestoneFields() {
			if (this.quote && this.quote.related_projects.length && this.project.project_id) {
				this.view.milestoneEnabled = true;

				this.milestoneTitle = this.getTitleFromQuote();
				this.milestoneDeadline = toUTC(new Date(this.quote.accepted_option.delivery_date));
			}
		},

		getTitleFromQuote() {
			return this.quote.title + (this.quote.subtitle ? ` -- ${this.quote.subtitle}` : '');
		},

		getPointsFromPaymentAmount() {
			if (this.checklistPreviewEstimate) {
				return this.checklistPreviewEstimate;
			}

			const amountPerPoint = 68;
			const amount = this.taskPaymentsAmount || 0;
			const taskTypeCoef = this.taskType === 'mail' ? 2 : 1;

			const points = Math.floor(amount / amountPerPoint) / taskTypeCoef;

			return points;
		},

		resetFormData() {
			this.taskDescription = '';
			this.view.qaEnabled = false;
			this.view.milestoneEnabled = false;

			this.milestoneTitle = null;
			this.milestoneDeadline = null;
			this.populateMilestoneFields();

			this.shouldPreventClose = false;
		},

		handleFormSubmit(event) {
			event.preventDefault();

			if (!this.currentUserSelection) {
				alert('Изберете потребител!');
				return;
			}

			const formData = $(event.target).serializeArray();

			if (this.checklistEnabled) {
				formData.push({
					name: 'checklist_data',
					value: this.checklistData,
				});

				formData.push({
					name: 'checklist_source',
					value: this.quote ? this.quote.systems_project.source_system : 'htmlburger',
				});
			}

			if (this.addingIssueTask) {
				const duplicateParamMatches = window.location.search.match(/^\??.*duplicate_task=(\d+)/, '');
				const duplicateId = duplicateParamMatches && duplicateParamMatches[1];

				formData.push({
					name: 'duplicate_task',
					value: duplicateId,
				});
			}

			if (this.quote && this.checklistEnabled && this.halfwayThroughNotification) {
				formData.push({
					name: 'halfway_through_notification',
					value: true,
				});
			}

			if (this.quotePayload) {
				formData.push({
					name: 'quote',
					value: this.quotePayload,
				});
			}

			const formDataObject = {};
			formData.forEach(item => {
				formDataObject[item.name] = item.value;
			});

			this.loading = true;

			$.ajax({
				url: '/ajax/task-save',
				type: 'POST',
				data: formDataObject,

				success: (resp) => {
					this.loading = false;
					this.result = {
						taskId: resp.added_tasks[0],
						username: this.users.find(user => user.id === this.currentUserSelection).username
					}

					this.$store.dispatch('fetchPaymentsAmount', this.project.project_id);
					this.resetFormData();
				},
			});
		},

		handleVcsEnabledChange(event) {
			if (this.project.svn_paths.length === 0 && this.view.vcsEnabled === true) {
				this.view.modalVcs = true;
			}
		},

		handleChecklistInput() {
			clearTimeout(this.checklistUpdateTimeout);

			this.checklistUpdateTimeout = setTimeout(() => {
				this.getChecklistPreview();
			}, 1000);
		},

		getChecklistPreview() {
			const source = this.quote ? this.quote.systems_project.source_system : 'htmlburger';

			$.ajax({
				url: '/ajax/checklists/validate',
				type: 'POST',
				data: {
					source,
					yaml: this.checklistData,
				},
				success: (response) => {
					this.checklistPreview = response;
				},
			});
		},

		handleEstimateChange(val) {
			const estimateInMinutes = val;
			const estimate = Math.ceil(estimateInMinutes * 10 / 6) / 100;

			this.checklistPreviewEstimate = estimate;
		},

		preventAccidentalClose() {
			setTimeout(() => {
				window.onbeforeunload = () => {
					if (this.shouldPreventClose) {
						return 'Do you really want to close Add Task tab?';
					}
				}
			}, 10);
		},

		toggleChecklistAdditionalItems(checklist) {
			const additionalItems = this.checklistAdditionalItems;
			if (checklist.indexOf(additionalItems) > -1) {
				return checklist.replace(additionalItems, '    ');
			} else {
				return checklist.replace(/    /, this.checklistAdditionalItems);
			}
		},

		handleChecklistAdditionalItemsChange() {
			this.checklistData = this.toggleChecklistAdditionalItems(this.checklistData);
		},
	},

	directives: {
		autogrow: {
			inserted(el) {
				autosize(el);
			}
		}
	},

	watch: {
		payments: {
			handler(newPayments, oldPayments) {
				if (newPayments.total_amount !== oldPayments.total_amoun || newPayments.associated_amount !== oldPayments.associated_amount) {
					const nonAssociatedPaymentsAmount = this.payments.total_amount - this.payments.associated_amount;

					this.taskPaymentsAmount = nonAssociatedPaymentsAmount > 0 ? nonAssociatedPaymentsAmount : 0;
					this.pointsValue = this.getPointsFromPaymentAmount();
				}
			},
			deep: true
		},

		project: {
			handler(newProject, oldProject) {
				if (oldProject.project_id !== newProject.project_id) {
					if (this.project.svn_paths && this.project.svn_paths.length > 0) {
						this.view.vcsEnabled = true;
					}

					this.populateFields();
				}
			},

			deep: true
		},

		taskPaymentsAmount(newValue, oldValue) {
			if (newValue !== oldValue) {
				this.pointsValue = this.getPointsFromPaymentAmount();
			}
		},

		activeTab() {
			if (this.activeTab === 'tab-task') {
				this.checklistEnabled = true;
			}
		},

		checklistPreviewEstimate() {
			if (this.checklistPreviewEstimate) {
				this.pointsValue = this.checklistPreviewEstimate.toFixed(1);
			} else {
				this.pointsValue = this.getPointsFromPaymentAmount();
			}
		},

		taskTitle(val, prevVal) {
			if (prevVal !== null) {
				this.shouldPreventClose = !!this.taskTitle;
			}
		},

		taskDescription() {
			this.shouldPreventClose = !!this.taskDescription;
		},

		checklistAdditionalItems(newVal, oldVal) {
			if (this.checklistData) {
				this.checklistData = this.checklistData.replace(oldVal.trim(), newVal.trim());
			}
		}
	}
}
</script>
