<template>
	<div>
		<form action="?" method="post" id="form-new-checklist" @submit.prevent="handleChecklistSubmit">
			<div class="form__errors" v-if="error">
				{{error}}
			</div>


			<i v-if="loading" class="fa fa-spinner fa-spin"></i>

			<div class="form__row" v-if="isAdmin">
				<div class="form__controls">
					<label>
						<input type="checkbox" name="title" v-model="quote" /> <span>From quote</span>
					</label>
				</div><!-- /.form__controls -->
			</div><!-- /.form__row -->

			<div v-if="!quote" class="form__row">
				<div class="form__controls">
					<input ref="title" type="text" class="form-field field-sm" name="title" v-model="title" placeholder="Title" />
				</div><!-- /.form__controls -->
			</div><!-- /.form__row -->

			<div v-if="quote" class="form__row">
				<div class="form__controls">
					<checklist-editor v-model="yaml" />
				</div><!-- /.form__controls -->
			</div><!-- /.form__row -->

			<div class="form__actions">
				<input type="submit" name="commit" value="Submit" class="submit" />

				<input type="button" name="cancel" value="Cancel" class="cancel" @click="$emit('checklist:cancel')" />
			</div><!-- /.form__actions -->
		</form>
	</div>
</template>

<script>
import yaml from 'js-yaml';
import ChecklistEditor from './ChecklistEditor.vue';

export default {
	name: 'checklist-new',

	props: {
		taskId: {
			required: true,
		},
	},

	components: {
		ChecklistEditor,
	},

	data() {
		return {
			loading: false,
			title : '',
			yaml : '',
			error: null,
			quote: false,
		};
	},

	computed: {
		isAdmin() {
			// Global variable
			return user_is_admin;
		},

		computedYaml() {
			if (this.quote) {
				return this.yaml;
			}

			if (!this.title) {
				return null;
			}

			const computedYaml = {};
			computedYaml[this.title] = {};

			return yaml.dump(computedYaml, { lineWidth: -1 });
		},
	},

	methods: {
		resetState() {
			this.error = null;
			this.title = '';
			this.yaml = '';
		},

		handleChecklistSubmit() {
			if (!this.computedYaml) {
				return;
			}

			this.loading = true;

			$.ajax({
				url: '/ajax/checklists/add-new',
				type: 'POST',
				data: {
					task_id: this.taskId,
					is_quote: this.quote,
					yaml: this.computedYaml,
				},
				success: (response) => {
					this.$emit('checklist:created', response.data);
					this.resetState();
				},
				error: (err) => {
					let error = '';

					try {
						const errorObj = err.responseJSON;
						error = errorObj.error;
					} catch(e) {
						error = 'Unable to parse quote'
					}

					this.error = error;
					this.$emit('checklist:error', this.error);
				},
				complete: () => {
					this.loading = false;
				}
			});
		},
	},

	mounted() {
		this.quote = this.isAdmin;

		setTimeout(() => {
			this.$refs.title && this.$refs.title.focus()
		}, 10);
	},

	watch: {
		quote() {
			if (!this.quote) {
				this.title = 'Checklist';

				setTimeout(() => {
					this.$refs.title.select()
				}, 10);
			}
		},
	},
}

</script>
