<template>
	<div id="credentials-list">


        <div class="access-depot">
            <div class="access-depot-sidebar">
                <form class="access-depot-form" v-if="entries.length">
                    <input class="field" v-model="search" placeholder="Search..." />
                    <div class="access-depot-form__icon">
                        <i class="fa fa-search"></i>
                    </div>
                </form>
                <ul class="access-depot-sidebar-items">
                    <li
                        v-for="(entry, i) in filteredEntries"
                        class="access-depot-sidebar-item" 
                        :class="{'is-active': entry.isFocused }"
                        @click="focus(entry)"
                    >
                        <div class="access-depot-sidebar-item__title">
                            <div style="float: left; background: red url('/css/img/ad-icon-placeholder.png') cover; width: 16px; height: 16px; margin: 3px">
                                <img :src="entry.favicon" alt="icon" v-if="entry.favicon" style="" />
                            </div>

                            <span
                                v-if="entry.is_main"
                                class="ac-star"
                                title="Главна инстанция"
                            >
                                <i class="fa fa-circle"></i>
                            </span>
                            

                            {{ entry.title }}
                        </div>
                        <Status :entry="entry" v-if="entry.protocol === 'seat'" />
                    </li>
                </ul>
                <button class="button" v-if="!isAdding" @click="addEntry">+ Add new</button>
            </div>

            <div class="access-depot-entries">
                <entry 
                    v-for="entry in filteredEntries"
                    v-if="entry.isFocused"
                    :key="entry.id"
                    v-bind:entry="entry"
                    :deleted="handleDeleted"
                    :focus="focus"
                />
                <div class="access-depot-entries__empty" v-if="!entries.length || !filteredEntries.length">No credentials</div>
            </div>
        </div>


	</div>
</template>

<script>
/**
 * The external dependencies.
 */
import { mapGetters } from 'vuex';

/**
 * The internal dependencies.
 */
 import { ACCESS_DEPOT_UNSAVED_ID } from '~/lib/constants';
import Entry from 'components/access-depot/Entry.vue';
import Status from 'components/access-depot/Status.vue';
import FtpExplorer from 'components/access-depot/ftp-explorer/FtpExplorer.vue';
import { createForAdding } from '~/helpers/access-depot';

const searchKeys = [
    'title',
    'notes',
    'username',
    'host',
    'home_url'
]

export default {
	components: {
		Entry,
		FtpExplorer,
        Status,
	},

	computed: {
		...mapGetters({
			entries: 'accessDepot/getEntries',
			isAdding: 'accessDepot/getIsAdding',
			svn_path: 'accessDepot/getVcsPath',
		}),

        filteredEntries() {
            if(this.search != '') {
                const searchLower = this.search.toLowerCase();
                return this.entries.filter(entry => searchKeys.find(key => entry[key]?.indexOf(searchLower) > -1));
            }
            return this.entries;
        },

        getFirstAvailableSeat() {
            return this.entries.find(entry => {
                if(entry.protocol !== 'seat') {
                    this.hasNoneSeatEntries = true;
                }
                if(entry.active_reservations && !entry.active_reservations.length) {
                    return entry;
                }
            })
        }
	},

    watch: {
        search() {
            if(this.filteredEntries.length) {
                const findFirstAvailable = this.filteredEntries
                    .find(item => item.protocol != 'seat' || (item.active_reservations && !item.active_reservations.length)) || this.filteredEntries[0];

                this.focus(findFirstAvailable);
            }
        }
    },

    data() {
        return {
            search: '',
            hasNoneSeatEntries: false
        }
    },

	methods: {
        isActive(entry) {
            return this.$store.getters['accessDepot/getIsActive'](entry.id);
        },
        getBaseHostname(urlString) {
            try {
                const url = new URL(urlString);
                return url.hostname ? url.hostname : urlString;
            } catch (e) {
                return urlString;
            }
        },

        replaceState(entry) {
            if(entry.id === ACCESS_DEPOT_UNSAVED_ID) {
                return;
            }
            const searchParam = new URLSearchParams({ depot_id: entry.id }).toString();
            const url = `${window.location.origin}${window.location.pathname}?${searchParam}${window.location.hash}`;
            history.replaceState({ depot_id: entry.id }, '', url);
        },

        focus(entry) {
            if (entry.isFocused) {
                return;
            }
			this.$store.commit('accessDepot/ACCESS_DEPOT_FOCUS', { id: entry.id });
            this.replaceState(entry);
        },

		addEntry() {
			const svn_path = this.svn_path;
            const entry = createForAdding({ svn_path });
            entry.title = 'New Credentials';
			this.$store.commit('accessDepot/ACCESS_DEPOT_SET_IS_ADDING', { isAdding: true });
			this.$store.commit('accessDepot/ACCESS_DEPOT_ADD', { entry });
            this.focus(entry);
		},

        handleDeleted() {
            if(this.entries.length) {
                this.entries[0].isFocused = true;
            }
        },

        initialFocus() {
            const params = new URLSearchParams(window.location.search);
            const id = parseInt(params.get('depot_id'));
            if (id) {
                this.focus({ id })
            } else {
                if(!this.hasNoneSeatEntries && this.getFirstAvailableSeat) {
                    this.focus({ id: this.getFirstAvailableSeat.id })
                }
            }
        }
	},

    mounted() {
        this.initialFocus();
    },
}
</script>
