/**
 * External Dependencies.
 */
import Clipboard from 'clipboard';


/**
 * Registers a new Clipboard.
 *
 * @param {DOMElement} domElement
 * @param {string}     text
 */
export const initClipboard = (domElement, text) => {
	return new Clipboard(domElement, {
		text() {
			return text;
		}
	});
};
