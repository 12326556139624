<template>
	<chosen v-bind:value="value" v-model="innerValue" :onMounted="onMounted">
		<option v-for="user in users" v-bind:value="user[userOptionValue]">
			{{ user.user_real_name }} - {{ user.username }}
		</option>
	</chosen>
</template>

<script>
import Chosen from './Chosen.vue'

export default {
	name: 'users-dropdown',

	props: ['value', 'users', 'optionValue', 'returnOnlyValue'],

	data() {
		return {
			innerValue: this.value
		};
	},

	computed: {
		userOptionValue() {
			return this.optionValue || 'id';
		}
	},

	components: {
		Chosen
	},

	methods: {
		onMounted($el) {
			setTimeout(() => {
				const vm = this;
				const $items = $($el).next('.chzn-container').find('.chzn-results li');

				$items.each(function(index, el) {
					$(this).css({ backgroundImage: 'url(/avatars/' + vm.users[index].username + '.jpg)' });
				});
			}, 10);
		}
	},

	watch: {
		innerValue(val) {
			const value = this.returnOnlyValue ? val.value : val;
			this.$emit('input', value);
		}
	}
}
</script>
