<template>
	<div class="comment__actions comment-actions" v-if="['comment', 'action-time-estimate'].indexOf(comment.type) !== -1">
		<a href="#" class="comment-action-dropdown-ctrl">&nbsp;</a>

		<ul class="action-list">
			<li v-if="isAdmin">
				<a class="copy-comment-markdown" ref="copyButton">Копирай <small class="comment__actions-hint" v-html="'(' + copyShortcutHint + ')'"></small></a>
			</li>

			<li>
				<a class="copy-comment-link">Копирай линк</a>
			</li>

			<li v-if="currentUser && $can(currentUser, 'edit-comment', comment)">
				<a href="#" class="edit-comment" @click.prevent="$emit('edit')">Редактирай</a>
			</li>

			<li v-if="currentUser && $can(currentUser, 'delete-comment', comment)">
				<a href="#" class="delete-comment" @click.prevent="$emit('delete')">Изтрий</a>
			</li>

			<li>
				<a href="#" class="time-estimate-ctrl">Въведи прогнозно време</a>

				<form action="/ajax/set-estimate" method="POST" class="time-estimate-form" @submit.prevent="onEstimate">
					<input type="text" name="estimate" class="estimate-field" v-model="estimate" />
					<label>мин</label>
					<input type="hidden" name="comment_id" :value="comment.id" />
					<input type="submit" name="commit" value="OK" />
				</form>
			</li>
		</ul>
	</div>
</template>

<script>
	/**
	 * External Dependencies.
	 */
	import { mapGetters, mapActions } from 'vuex';

	/**
	 * Internal Dependencies.
	 */
	import { $can } from '~/helpers/permissions';
	import { initClipboard } from '~/utils/clipboard';


	export default {
		/**
		 * Component Name.
		 *
		 * @type {String}
		 */
		name: 'comment-actions',

		/**
		 * Component Props.
		 *
		 * @type {Object}
		 */
		props: {
			comment: {
				type: Object,
				default() {
					return null;
				}
			}
		},

		/**
		 * Component Data.
		 */
		data() {
			return {
				clipboard: null,

				estimate: this.comment.time_estimate || '',
			};
		},

		/**
		 * Computed Data.
		 *
		 * @type {Object}
		 */
		computed: {
			...mapGetters({
				currentUser: 'currentUser/getCurrentUser',
				isAdmin: 'currentUser/isAdmin',
			}),

			copyShortcutHint() {
				return navigator.platform.indexOf('Mac') !== -1 ? '&#8984; + &#8997; + C' : 'Ctrl + Alt + C';
			}
		},

		/**
		 * Lifecycle Hook.
		 */
		mounted() {
			if (this.isAdmin && ! this.clipboard) {
				this.setupClipboard();
			}

			this.setupClipboardLink();
		},

		updated() {
			if (this.isAdmin && ! this.clipboard) {
				this.setupClipboard();
			}
		},

		destroyed() {
			if (this.clipboardLink) {
				this.clipboardLink.destroy();
			}
		},

		/**
		 * Watched Data.
		 */
		watch: {
			comment: {
				deep: true,
				handler() {
					if (this.clipboard) {
						this.clipboard.destroy();
					}

					this.$nextTick(() => {
						this.setupClipboard();
					});
				}
			}
		},

		/**
		 * Component Methods.
		 *
		 * @type {Object}
		 */
		methods: {
			$can,

			...mapActions({
				updateCommentEstimate: 'comments/updateCommentEstimate',
			}),

			setupClipboard() {
				if (this.comment.type !== 'comment') {
					return;
				}

				const copyTrigger = $(this.$el).find('.copy-comment-markdown').get(0);
				const copyText    = this.comment.body_raw.replace(/\* \[[\sx]\]/img, '*');

				if(!copyTrigger) {
					return;
				}

				this.clipboard = initClipboard(copyTrigger, copyText);
				
				this.clipboard.on('success', () => {
					this.$emit('copy-success');
				});

				this.clipboard.on('error', () => {
					this.$emit('copy-error');
				});
			},

			setupClipboardLink() {
				const link = this.getCommentLink();
				const target = $(this.$el).find('.copy-comment-link').get(0);
				if (target) {
					this.clipboardLink = initClipboard(
						target,
						link
					);
				}
			},

			getCommentLink() {
				const loc = document.location;
				let base = loc.href;
				if (loc.hash && loc.hash.length) {
					base = base.replace(loc.hash, '');
				}

				return base + '#comment-' + this.comment.id;
			},

			onEstimate(event) {
				let $form = $(event.target);

				this.updateCommentEstimate({
					commentId: this.comment.id,
					estimate: this.estimate,
				});

				$('.action-list').hide();

				window.task_time_estimate_updated = true;
			}
		},
	}
</script>