<template>
	<div class="checklist">
		<template v-if="!editing">
			<div class="checklist__head">
				<i v-if="syncing" class="fa fa-refresh fa-spin" title="Syncing..."></i>
				<i v-if="syncingFailed" class="fa fa-exclamation-triangle" title="Failed to sync checklist. Local data is out of date."></i>

				<a v-if="canEdit" href="#" class="btn" @click.prevent="handleEditInit">
					<i class="fa fa-pencil"></i> Edit
				</a>

				<div class="progress">
					<div v-if="totalEstimate > 0" class="progress-bar" :style="{ width: `${progress}%`}">{{progress}}%</div><!-- /.progress-bar -->
					<div v-else class="progress-bar" :style="{ width: `${progressCount || 0}%`}">{{progressCount || 0}}%</div><!-- /.progress-bar -->
				</div><!-- /.progress -->
			</div><!-- /.checklist__head -->

			<div class="checklist__body">
				<checklist-items v-if="Array.isArray(checklist)" v-model="checklist" :title="'Checklist'" :isNew="checklistData.isNew" :canEdit="canEdit" @log="handleLog" />

				<checklist-items v-else-if="!checklist" v-model="checklist" :title="'Add Groups or Items'" :isNew="checklistData.isNew" :canEdit="canEdit" @log="handleLog" />

				<checklist-items v-else v-for="(checklistItems, key) in checklist" v-model="checklist[key]" :key="key" :title="key" :isNew="checklistData.isNew" :canEdit="canEdit" @log="handleLog" />
			</div><!-- /.checklist__body -->

			<checklist-logs :logs="checklistLogs" />
			<!-- <checklist-revisions :revisions="checklistRevisions" /> -->
		</template>

		<div v-if="editing" class="checklist__edit">
			<checklist-editor v-model="checklistYaml" />

			<div v-if="checklistYamlError" class="checklist__edit-error">
				{{checklistYamlError}}
			</div><!-- /.checklist__edit-error -->

			<div class="checklist__edit-actions">
				<button type="submit" class="btn" :disabled="editingOutdated" @click.prevent="handleEditSave">
					<i class="fa fa-save"></i>

					{{editingOutdated ? 'Checklist is outdated. Cancel and try again.' : 'Save'}}
				</button>

				<button type="reset" class="btn" @click.prevent="handleEditCancel">Cancel</button>

				<button type="reset" class="btn btn--delete" @click.prevent="handleDelete">
					<i class="fa fa-trash"></i> Delete
				</button>
			</div><!-- /.checklist__edit-actions -->
		</div><!-- /.checklist__edit -->
	</div>
</template>

<script>
import yaml from 'js-yaml';

import ChecklistEntry from '../models/ChecklistEntry';
import ChecklistItems from './ChecklistItems.vue';
import ChecklistLogs from './ChecklistLogs.vue';
import ChecklistRevisions from './ChecklistRevisions.vue';
import ChecklistEditor from './ChecklistEditor.vue';

export default {
	name: 'checklist',

	model: {
		prop: 'checklistData',
		event: 'update'
	},

	props: {
		checklistData: {
			type: Object,
			required: true,
		}
	},

	components: {
		ChecklistItems,
		ChecklistLogs,
		ChecklistEditor,
		ChecklistRevisions,
	},

	data() {
		return {
			checklist:          null,
			checklistId:        null,
			checklistYaml:      null,
			checklistYamlError: null,
			checklistLogs:      [],

			editing:            false,
			editingOutdated:    false,

			syncing:            false,
			syncingFailed:      false,
			syncingRetry:       null,

			log:                [],

			silentUpdate:       false,
		}
	},

	computed: {
		isAdmin() {
			// Global variable
			return window.user_is_admin;
		},

		userId() {
			// Global variable
			return window.USER_ID;
		},

		checklistAuthorId() {
			return this.checklistData.author_id;
		},

		checklistOriginalAuthorId() {
			return this.checklistData.original_author;
		},

		checklistDescription() {
			return Object.keys(this.checklist)[0] || null;
		},

		checklistRevisions() {
			const { id, author_id, updated_at, revisions } = this.checklistData;

			return [].concat(revisions, {
				id,
				author_id,
				updated_at,
			});
		},

		canEdit() {
			// Disable UI for archived checklists
			if (this.checklistData && this.checklistData.is_archived) {
				return false;
			}

			return this.isAdmin || this.checklistOriginalAuthorId === this.userId;
		},

		totalEstimate() {
			return this.checklist ? this.sumEstimates(this.checklist) : 0;
		},

		totalEstimateUnchecked() {
			return this.checklist ? this.sumEstimates(this.checklist, 'unchecked') : 0;
		},


		totalCount() {
			return this.checklist ? this.sumEstimates(this.checklist, null, true) : 0;
		},

		totalCountUnchecked() {
			return this.checklist ? this.sumEstimates(this.checklist, 'unchecked', true) : 0;
		},

		progress() {
			const { totalEstimate, totalEstimateUnchecked} = this;

			return Math.round((totalEstimate - totalEstimateUnchecked) / totalEstimate * 100);
		},

		progressCount() {
			const { totalCount, totalCountUnchecked } = this;

			return Math.round((totalCount - totalCountUnchecked) / totalCount * 100);
		},
	},

	methods: {
		init() {
			this.checklist      = this.checklistData.body;
			this.checklistId    = this.checklistData.id;
			this.checklistLogs  = this.checklistData.logs;

			this.updateChecklistYaml();
		},

		updateChecklistYaml() {
			this.checklistYaml = yaml.dump(this.checklist, { lineWidth: -1 });
			this.checklistYamlError = null;
		},

		updateChecklistData() {
			this.checklistYamlError = null;

			try {
				const checklistYaml = !!this.checklistYaml ? this.checklistYaml : '\'\'\n';
				const checklistYamlSanitized = checklistYaml.replace(/(^\s*-\s*)([^\'\n]*[\:].*)/gm, '$1\'$2\'');
				const checklistData = yaml.load(checklistYamlSanitized);
				const checklistObj  = typeof checklistData !== 'object' && !!checklistData ? _.castArray(checklistData) : checklistData;

				this.checklist = checklistObj;
			} catch(exception) {
				this.checklistYamlError = exception.message;

				const editor = this.$children && this.$children[0] && this.$children[0].$data.editor;
				editor && editor.focus();
			}
		},

		handleEditInit() {
			this.editingOutdated = false;
			this.editing = true;
			this.updateChecklistYaml();
		},

		handleEditCancel() {
			this.editing = false;
			this.updateChecklistYaml();
		},

		handleEditSave() {
			if (this.editingOutdated) {
				return;
			}

			this.updateChecklistData();

			if (!this.checklistYamlError) {
				const checklistDataUpdated = Object.assign({}, this.checklistData, {
					body: this.checklist
				});

				this.log.push({
					checklist_id: this.checklistId,
					type: 'edit_checklist',
					description: this.checklistDescription,
				});

				this.$emit('update', checklistDataUpdated);
				this.editing = false;
			}
		},

		handleDelete() {
			const confirm = window.confirm('Are you sure you want to delete the checklist?')

			if (confirm) {
				this.deleteChecklist();
				this.$emit('delete', this.checklistId);
				this.editing = false;
			}
		},

		drainLastActions() {
			const logData = _.cloneDeep(this.log);
			this.log = [];

			return logData;
		},

		updateChecklist() {
			if (this.syncingFailed) {
				return;
			}

			if (this.syncing) {
				this.syncRetry = () => {
					this.syncRetry = null;
					this.updateChecklist()
				};
				return;
			}

			const logData = this.drainLastActions();

			Vue.nextTick(() => {
				this.syncing = true;

				return $.ajax({
					url: '/ajax/checklists/update',
					type: 'POST',
					data: {
						'checklist_id': this.checklistId,
						'checklist_data': JSON.stringify(this.checklist),
						'updated_at': this.checklistData.updated_at,
						'log_data': logData,
					},
					success: (response) => {
						if (response.data) {
							const timestamp = response.data.updated_at;
							const checklistDataUpdated = Object.assign({}, this.checklistData, {
								updated_at:  timestamp
							});

							this.$emit('update', checklistDataUpdated);

							if (response.data.log) {
								this.checklistLogs = [].concat(this.checklistLogs, response.data.log);
							}
						}
					},
					error: (error) => {
						this.syncingFailed = true;
						window.alert('Checklist synchronization failed!');
					},
					complete: (response) => {
						this.syncing = false;
						this.syncRetry && this.syncRetry();
					}
				});
			});
		},

		handleLog(log) {
			const logData = Object.assign(log, {
				checklist_id: this.checklistId
			});

			this.log.push(logData);
		},

		deleteChecklist() {
			return $.ajax({
				url: '/ajax/checklists/delete',
				type: 'POST',
				data: {
					'checklist_id': this.checklistId,
					'log_data': [{
						checklist_id: this.checklistId,
						type: 'delete_checklist',
						description: this.checklistDescription,
					}],
				}
			});
		},

		sumEstimates(data, state, onlyCount) {
			if (!data) {
				return 0;
			}

			let total = 0;

			if (typeof data === 'string') {
				const entry = new ChecklistEntry(data);

				if (!state || state === entry.state) {
					total += onlyCount ? 1 : entry.getEstimate();
				}
			} else {
				Object.keys(data).forEach(key => {
					total += this.sumEstimates(data[key], state, onlyCount);
				});
			}

			return total;
		},
	},

	mounted() {
		this.init();
	},

	watch: {
		checklist: {
			deep: true,
			handler(newData, oldData) {
				if (oldData !== null && this.checklistId && !this.silentUpdate) {
					this.updateChecklist();
				}

				this.silentUpdate = false;
			}
		},

		checklistData(newData, oldData) {
			if (JSON.stringify(this.checklistData.body) !== JSON.stringify(this.checklist)) {
				this.silentUpdate = true;
			}

			if (!this.syncingFailed) {
				this.checklist = this.checklistData.body;
			}

			if (newData.updated_at !== oldData.updated_at) {
				this.editingOutdated = true;
			}
		},
	},
}

</script>
