<template>
	<input />
</template>

<script>
export default {
	name: 'datepicker',

	props: ['value', 'dateFormat', 'firstDay', 'onSelect', 'numberOfMonths'],

	mounted() {
		const vm = this;

		$(this.$el)
			.val(this.value)
			.datepicker({
				dateFormat: vm.dateFormat || 'dd/mm/yy',
				firstDay: vm.firstDay || 1,
				numberOfMonths: vm.numberOfMonths || 2,

				onSelect: function(selectedDate) {
					vm.$emit('input', selectedDate);

					if (typeof vm.onSelect === 'function') {
						vm.onSelect(selectedDate);
					}
				}
			});
	},

	watch: {
		value(value) {
			$(this.$el).val(value);

			if (value instanceof Date) {
				$(this.$el).datepicker('setDate', value);
			}
		}
	},

	destroyed() {
		$(this.$el).datepicker('destroy');
	}
}
</script>
