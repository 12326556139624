var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sl-vue-tree",class:{'sl-vue-tree-root': _vm.isRoot },on:{"mousemove":_vm.onMousemoveHandler,"mouseleave":_vm.onMouseleaveHandler,"dragend":function($event){return _vm.onDragendHandler(null, $event)}}},[_c('div',{ref:"nodes",staticClass:"sl-vue-tree-nodes-list"},[_vm._l((_vm.nodes),function(node,nodeInd){return _c('div',{staticClass:"sl-vue-tree-node",class:{
				'sl-vue-tree-selected': node.isSelected,
				'sl-vue-tree-is-wp-root': node.data.hasOwnProperty('isWpRoot') ? node.data.isWpRoot : false,
			}},[_c('div',{staticClass:"sl-vue-tree-cursor sl-vue-tree-cursor_before",style:({ visibility:
					_vm.cursorPosition &&
					_vm.cursorPosition.node.pathStr === node.pathStr &&
					_vm.cursorPosition.placement === 'before' ?
					 'visible' :
					 'hidden'
				 }),on:{"dragover":function($event){$event.preventDefault();}}}),_vm._v(" "),_c('div',{staticClass:"sl-vue-tree-node-item",class:{
					'sl-vue-tree-cursor-hover':
						_vm.cursorPosition &&
						_vm.cursorPosition.node.pathStr === node.pathStr,

					'sl-vue-tree-cursor-inside':
						_vm.cursorPosition &&
						_vm.cursorPosition.placement === 'inside' &&
						_vm.cursorPosition.node.pathStr === node.pathStr,
					'sl-vue-tree-node-is-dummy-file' : node.data.hasOwnProperty('isDummyFile') ? node.data.isDummyFile : false,
					'sl-vue-tree-node-is-leaf' : node.isLeaf,
					'sl-vue-tree-node-is-folder' : !node.isLeaf,
					'sl-vue-tree-node-is-loading' : !node.isLeaf && node.data.hasOwnProperty('isLoading') ? node.data.isLoading : false,
					'sl-vue-tree-node-is-harmful' : node.data.hasOwnProperty('isHarmful') ? node.data.isHarmful : false,
					'sl-vue-tree-toggle-collapsed': !node.isLeaf && !node.isExpanded,
					'sl-vue-tree-toggle-expanded': !node.isLeaf && node.isExpanded,
				},attrs:{"path":node.pathStr},on:{"mousedown":function($event){return _vm.onNodeMousedownHandler($event, node)},"mouseup":function($event){return _vm.onNodeMouseupHandler($event, node)},"contextmenu":function($event){return _vm.emitNodeContextmenu(node, $event)},"dblclick":function($event){return _vm.emitNodeDblclick(node, $event)},"click":function($event){return _vm.emitNodeClick(node, $event)},"dragover":function($event){return _vm.onExternalDragoverHandler(node, $event)},"drop":function($event){return _vm.onExternalDropHandler(node, $event)}}},[_vm._l((_vm.gaps),function(gapInd){return _c('div',{staticClass:"sl-vue-tree-gap"})}),_vm._v(" "),(_vm.level && _vm.showBranches)?_c('div',{staticClass:"sl-vue-tree-branch"},[_vm._t("branch",function(){return [(!node.isLastChild)?_c('span',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(String.fromCharCode(0x251C))+_vm._s(String.fromCharCode(0x2500))+" \n\t\t\t\t\t")]):_vm._e(),_vm._v(" "),(node.isLastChild)?_c('span',[_vm._v("\n\t\t\t\t\t\t"+_vm._s(String.fromCharCode(0x2514))+_vm._s(String.fromCharCode(0x2500))+" \n\t\t\t\t\t")]):_vm._e()]},{"node":node})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"sl-vue-tree-title"},[(node.isLeaf)?_c('span',{staticClass:"ui-icon-item ui-icon-leaf"}):_vm._e(),_vm._v(" "),(!node.isLeaf)?_c('span',{staticClass:"sl-vue-tree-toggle",on:{"click":function($event){return _vm.onToggleHandler($event, node)}}},[_vm._t("toggle",function(){return [_c('span',[(!node.isLeaf)?_c('span',{class:{
										'ui-icon-item': true,
										'ui-icon-toggle': true,
										'ui-icon-toggle-expanded': node.isExpanded
									}}):_vm._e(),_vm._v(" "),_c('span',{class:{
										'ui-icon-item': true,
										'ui-icon-leaf': node.isLeaf,
										'ui-icon-folder': !node.isLeaf,
									}})])]},{"node":node})],2):_vm._e(),_vm._v(" "),_vm._t("title",function(){return [_vm._v(_vm._s(node.title))]},{"node":node}),_vm._v(" "),(!node.isLeaf && node.children.length == 0 && node.isExpanded)?_vm._t("empty-node",null,{"node":node}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"harmful-icon",attrs:{"title":"Файла е потенциално опасен. Не го оставяй тук."}}),_vm._v(" "),(!node.isLeaf && node.data.hasOwnProperty('isLoading') && node.data.isLoading)?_c('span',{staticClass:"loader"}):_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"sl-vue-tree-sidebar"},[_vm._t("sidebar",null,{"node":node})],2)],2),_vm._v(" "),(node.children && node.children.length && node.isExpanded)?_c('sl-vue-tree',{attrs:{"value":node.children,"level":node.level,"parentInd":nodeInd,"allowMultiselect":_vm.allowMultiselect,"allowToggleBranch":_vm.allowToggleBranch,"edgeSize":_vm.edgeSize,"showBranches":_vm.showBranches},on:{"dragover":function($event){$event.preventDefault();}},scopedSlots:_vm._u([{key:"title",fn:function({ node }){return [_vm._t("title",function(){return [_vm._v(_vm._s(node.title))]},{"node":node})]}},{key:"toggle",fn:function({ node }){return [_vm._t("toggle",function(){return [_c('span',[(!node.isLeaf)?_c('span',{class:{
								'ui-icon-item': true,
								'ui-icon-toggle': true,
								'ui-icon-toggle-expanded': node.isExpanded
							}}):_vm._e(),_vm._v(" "),_c('span',{class:{
								'ui-icon-item': true,
								'ui-icon-leaf': node.isLeaf,
								'ui-icon-folder': !node.isLeaf,
							}})])]},{"node":node})]}},{key:"sidebar",fn:function({ node }){return [_vm._t("sidebar",null,{"node":node})]}},{key:"empty-node",fn:function({ node }){return [(!node.isLeaf && node.children.length == 0 && node.isExpanded)?_vm._t("empty-node",null,{"node":node}):_vm._e()]}}],null,true)}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"sl-vue-tree-cursor sl-vue-tree-cursor_after",style:({
					visibility:
					 _vm.cursorPosition &&
					 _vm.cursorPosition.node.pathStr === node.pathStr &&
					 _vm.cursorPosition.placement === 'after' ?
					 'visible' :
					 'hidden'
				 }),on:{"dragover":function($event){$event.preventDefault();}}})],1)}),_vm._v(" "),(_vm.isRoot)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDragging),expression:"isDragging"}],ref:"dragInfo",staticClass:"sl-vue-tree-drag-info"},[_vm._t("draginfo",function(){return [_vm._v("\n\t\t\t\tItems: "+_vm._s(_vm.selectionSize)+"\n\t\t\t")]})],2):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }