/**
 * The state.
 *
 * @type {Object}
 */
export default {
	userTrackingId: null,
	taskId: null,
    tracking: null,
    canTrack: false,
    currentlyActiveTask: null,
    events: null,
    estimate: null,
};
