/**
 * Internal Dependencies.
 */
import * as getters from './getters.js';
import * as actions from './actions.js';
import * as mutations from './mutations.js';

/**
 * Module State.
 *
 * @type {Object}
 */

const state = {
	loading: false,
	error: '',
};


/**
 * Export Module
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
