<template>
	<div v-bind:class="['form', { 'form-loading': loading }]">
		<form action="?" method="post" @submit="handleFormSubmit">
			<div class="form-body">
				<div class="form-content">
					<div class="table-payments">
						<table>
							<tr>
								<th>Заглавие</th>
								<th>Сума (USD)</th>
								<th>Добавен на</th>
								<th>Платено</th>
							</tr>

							<tr v-if="!existingPayments || (existingPayments && !existingPayments.length)">
								<td colspan="4">Няма добавени плащания</td>
							</tr>

							<tr v-for="payment in existingPayments">
								<td>{{payment.title}}</td>
								<td>{{payment.amount}}</td>
								<td>{{payment.date_added}}</td>
								<td>
									<i class="fa fa-check" v-if="JSON.parse(payment.payed)"></i>
									<i class="fa fa-times" v-else></i>
								</td>
							</tr>
						</table>
					</div>

					<payments v-bind:defaultPayment="defaultPayment" v-bind:submitted="submitted" />

					<input type="hidden" name="client_id" v-bind:value="clientId">
					<input type="hidden" name="project_id" v-bind:value="projectId">
				</div>
			</div>

			<div class="form-actions">
				<input type="submit" value="Запази" class="submit">
			</div>
		</form>
	</div>
</template>

<script>
import Payments from './Payments.vue'

export default {
	name: 'payments-form',

	props: ['clientId', 'projectId'],

	data() {
		return {
			loading: false,
			submitted: false,

			existingPayments: null,
			defaultPayment: {}
		};
	},

	computed: {
		quote() {
			return this.$store.state.quote;
		}
	},

	components: {
		Payments
	},

	created() {
		this.fetchAssociatedPayments();

		if (this.quote) {
			const acceptedOption = this.quote.delivery_options.find(option => option.name === this.quote.accepted_option.name);
			const name = this.quote.title + (this.quote.subtitle ? ` -- ${this.quote.subtitle}` : '');

			this.defaultPayment = {
				name: name,
				price: acceptedOption ? acceptedOption.price : 0,
				type: acceptedOption.name,
				upfront: acceptedOption.upfront,
			};
		}
	},

	methods: {
		resetPayments() {
			this.submitted = false;

			setTimeout(() => {
				this.submitted = true;
			}, 10);
		},

		fetchAssociatedPayments() {
			$.ajax({
				url: '/ajax/get-project-associated-payments',
				type: 'GET',
				data: {
					project_id: this.projectId,
				},

				success: (resp) => {
					this.existingPayments = resp;
				}
			});
		},

		handleFormSubmit(event) {
			event.preventDefault();

			const formData = $(event.target).serializeArray();
			this.loading = true;

			$.ajax({
				url: '/ajax/add-payments',
				method: 'POST',
				data: formData,

				success: () => {
					this.existingPayments = null;

					this.resetPayments();
					this.fetchAssociatedPayments();

					this.$parent.$emit('close');
					this.$store.dispatch('changeTab', 'tab-task');
					this.$store.dispatch('fetchPaymentsAmount', this.projectId);
				},

				complete: () => {
					this.loading = false;
				}
			});
		}
	},

	watch: {
		projectId(val) {
			if (val) {
				this.existingPayments = null;
				this.fetchAssociatedPayments();
			}
		}
	}
}
</script>

