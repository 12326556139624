/**
 * Internal Dependencies.
 */
import { normalizeComment } from './utils.js';


export const COMMENTS_FETCHING = (state, payload) => state.isFetchingComments = payload;

export const COMMENTS_FETCHING_SUCCESS = (state, payload) => {
	state.isFetchingComments = false;

	// normalize data
	payload.forEach((comment) => {
		state.comments.push(normalizeComment(comment));
	});
}

export const COMMENTS_FETCHING_ERROR = (state, payload) => {
	state.isFetchingComments = false;
	state.error              = payload; // TODO -- handle error
}

export const COMMENT_REFRESH = (state, payload) => {
	state.comments = state.comments.map((comment) => {
		if (comment.id !== payload.commentId) {
			return comment;
		}

		return normalizeComment(payload.comment);
	});
}

export const COMMENT_FETCHING_ERROR = (state, payload) => {
	state.isFetchingComment = false;
	state.error      = payload; // TODO -- handle error
}

export const COMMENT_ADD = (state, payload) => {
	state.comments.push(normalizeComment(payload));
}

export const COMMENT_REMOVE = (state, payload) => {
	state.comments = state.comments.filter((comment) => {
		return comment.id !== payload.commentId;
	});
}

export const UPDATE_TARGET_COMMENT = (state, payload) => {
	state.targetCommentId = payload;
}
