// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.company-email-wrap[data-v-51f37a45] { position: relative; padding: 25px;
}
.email-loader[data-v-51f37a45] { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255, 255, 255, 0.6);
}
.email-loader img[data-v-51f37a45] { position: absolute; top: 50%; left: 50%; transform: translate(-50%);
}
`, "",{"version":3,"sources":["webpack://./js/apps/CompanyEmail.vue"],"names":[],"mappings":";AAuCA,uCAAA,kBAAA,EAAA,aAAA;AAAA;AACA,iCAAA,kBAAA,EAAA,MAAA,EAAA,QAAA,EAAA,SAAA,EAAA,OAAA,EAAA,0CAAA;AAAA;AACA,qCAAA,kBAAA,EAAA,QAAA,EAAA,SAAA,EAAA,0BAAA;AAAA","sourcesContent":["<template>\n    <div class=\"company-email-wrap\">\n        <email-details v-if=\"details\" :details=\"details\" />\n        <create-email v-else />\n\n        <div class=\"email-loader\" v-if=\"loading\">\n            <img src=\"/css/img/loading.gif\" />\n        </div>\n    </div>\n</template>\n\n<script>\n/**\n * External dependencies:\n */\nimport { mapGetters } from 'vuex';\n\n/**\n * Internal dependencies:\n */\nimport EmailDetails from 'components/company-email/details.vue';\nimport CreateEmail from 'components/company-email/create.vue';\n\nexport default {\n    components: {\n        EmailDetails,\n        CreateEmail,\n    },\n\n    computed: {\n        ...mapGetters({\n            details: 'companyEmail/getEmailDetails',\n            loading: 'companyEmail/getLoading',\n        }),\n    },\n}\n</script>\n\n<style scoped>\n.company-email-wrap { position: relative; padding: 25px; }\n.email-loader { position: absolute; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(255, 255, 255, 0.6); }\n.email-loader img { position: absolute; top: 50%; left: 50%; transform: translate(-50%); }\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
