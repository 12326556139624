<template>
    <div class="note">
        <div class="note__head" @click="toggleNote">
            <div class="note__head__left">
                <span v-if="only !== 'sticky'">
                    <a href="#" @click.prevent="" v-if="isExpanded"><i class="fa fa-chevron-circle-down"></i></a>
                    <a href="#" @click.prevent="" v-else><i class="fa fa-chevron-circle-right"></i></a>
                </span>

                <span v-show="note.is_sticky && only !== 'sticky'"><i class="fa fa-thumb-tack" title="Sticky Note"></i></span>

                <h6 v-if="only === 'sticky'" class="note__title-sticky">{{ note.title }}</h6>
                <span v-else class="note__title">{{ note.title }}</span>

            </div>

            <div class="note__head__right">
                <div class="note__author" v-show="note.added_by_name">{{ note.added_by_name }},&nbsp;</div>
                <div class="note__date">{{ note.date_added }}</div>
            </div>
        </div>

        <div :class="{'note__body': true, open: isExpanded }">
            <div class="r">
                <a
                    v-if="!editing && only !== 'sticky'"
                    class="note__edit"
                    href="#"
                    title="Edit note"
                    @click.prevent.stop="startEditing"
                >
                    <i class="fa fa-pencil"></i>
                </a>
            </div>

            <note-form
                :note="note"
                v-if="editing"
                @cancel="onCancelEditing"
                @submit="onSubmit"
            />

            <div
                v-else
                class="note__text"
                v-html="note.note_beautified"
            ></div>

            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
/**
 * Internal dependencies:
 */
import NoteForm from 'components/client-notes/NoteForm.vue';
import { mapActions, mapMutations } from 'vuex';

export default {
    components: {
        NoteForm,
    },

    props: {
        note: Object,
        only: String,
    },

    data() {
        return {
            editing: false,
            open: this.note.is_sticky,
        };
    },

    computed: {
        isExpanded() {
            return this.editing || this.open;
        },
    },

    methods: {
        ...mapMutations({
            updateNote: 'clientNotes/updateNote',
        }),

        ...mapActions({
            saveNote: 'clientNotes/saveNote',
        }),

        toggleNote() {
            if (this.only === 'sticky' && this.note.is_sticky) {
                return;
            }

            this.open = !this.open;
        },

        startEditing() {
            this.editing = true;
        },

        onCancelEditing() {
            this.editing = false;
        },

        onSubmit({ title, body, excerpt, needed_permission_level, is_sticky }) {
            this.updateNote({
                id: this.note.id,
                fields: {
                    title,
                    note: body,
                    excerpt: excerpt,
                    needed_permission_level,
                    is_sticky,
                },
            });

            this.editing = false;

            this.saveNote({ id: this.note.id });
        },
    },
}
</script>
