/**
 * External dependencies:
 */
import { get } from 'lodash';

/**
 * Internal dependencies:
 */
import ajax from "services/ajax";

const getSuccess = response => get(response, 'data.success', false);

const showError = (prefix, response) => {
  const message = get(response, 'data.error', 'Unknown error');
  alert(prefix + message);
};

export const deleteProjectNote = ({ commit }, { id, type }) => {
  ajax.post("/project-notes/delete", {
    id,
    type,
  })
    .then((response) => {
      const success = getSuccess(response);
      if (!success) {
        return showError('There was an error while deleting the note: ', response);
      }

      commit('PROJECT_NOTES_DELETE', { id, type });
    })
    .catch(console.error);
};

export const addProjectNote = ({ commit }, payload) => {
  ajax.post('/project-notes/add', payload)
    .then(response => {
      const success = getSuccess(response);
      const note = get(response, 'data.data.note');
      if (!success || !note) {
        return showError('There was an error while adding the note: ', response);
      }

      commit('PROJECT_NOTES_ADD', note);
    })
    .catch(console.error);
};

export const editProjectNote = ({ commit }, payload) => {
  ajax.post("/project-notes/edit", payload)
    .then((response) => {
      const success = getSuccess(response);
      const note = get(response, 'data.data.note');
      if (!success || !note) {
        return showError('There was an error while editing the note: ', response);
      }

      commit('PROJECT_NOTES_EDIT', note);
    })
    .catch(console.error);
};
