<template>
	<monitor-milestones />
</template>

<script>
import Favico from 'favico.js'
import MonitorMilestones from '../components/MonitorMilestones.vue'

export default {
	name: 'monitor',

	components: {
		MonitorMilestones,
	},

	data() {
		return {
			favicon: new Favico({ bgColor: '#0a0' }),
			drainHighlightsTimeout: null
		};
	},

	computed: {
		milestonesHighlightCount() {
			return this.$store.state.milestonesHighlights.length;
		}
	},

	mounted() {
		this.bind();
		this.$store.dispatch('fetchMilestones');
		this.$store.commit('TOGGLE_TESTER_VIEW', window.$$monitorData.isTester);
	},

	methods: {
		bind() {
			window.onfocus = this.handleWindowFocus;
			window.onblur = this.handleWindowBlur;
		},

		handleWindowFocus() {
			if (this.milestonesHighlightCount > 0) {
				this.drainHighlightsTimeout = setTimeout(() => {
					this.$store.commit('DRAIN_MILESTONES_HIGHLIGHTS');
				}, 5000);
			}
		},

		handleWindowBlur() {
			clearTimeout(this.drainHighlightsTimeout);
		},
	},

	watch: {
		milestonesHighlightCount(count) {
			this.favicon.badge(count);
		}
	}
}
</script>
