<template>
	<div>
		<div v-if="project.project_id">
			<div class="add-task-section">
				<div class="add-task-row">
					<label class="add-task-label">Проект:</label>

					<span>{{project.project_title}}</span>
				</div>

				<div class="add-task-row">
					<label class="add-task-label">Клиент:</label>

					<span>{{project.client_name}}</span>

					<small v-if="project.company">({{project.company}})</small>
				</div>
			</div>

			<a href="#" class="button" @click.prevent="resetProject">
				<i class="fa fa-chevron-left"></i> Избери друг проект
			</a>

			<a href="#" class="button" @click.prevent="$store.dispatch('changeTab', 'tab-task')" v-bind:disabled="!project.project_id">
				<i class="fa fa-chevron-right"></i> Към добавяне на задача
			</a>
		</div>

		<div v-else class="form" v-bind:class="formState === 'loading' ? 'form-loading' : ''">
			<form action="?" method="post" @submit.prevent="handleFormSubmit">
				<div class="form-body">
					<div class="form-content">
						<div class="form-row">
							<label class="form-label">Заглавие:</label>

							<input type="text" name="title" value="" class="field" id="title_field" v-model="projectTitle" required>
						</div>

						<div class="form-row" v-if="clients.length > 0">
							<label class="form-label">
								Клиент:

								<div class="form-label-hint" v-if="isNewClient">
									<span>New Client</span>

									<input type="hidden" name="new_client_name" v-bind:value="selectedClient.name">
									<input type="hidden" name="new_client_email" v-bind:value="selectedClient.email">
									<input type="hidden" name="new_client_source_id" v-bind:value="quote.source_id">
									<input type="hidden" name="new_client_source_name" v-bind:value="client_source_name">
								</div>
							</label>

							<input
								type="hidden"
								id="client_id_field"
								name="client_id"
								:value="!!(selectedClient && selectedClient.id) && clientId || null"
							/>

							<select class="field" v-model="clientId">
								<option v-for="(client, index) in clients" v-bind:value="client.id || index">
									{{ client | formatClientAsOption }}
								</option>
							</select>
						</div>

						<template v-if="isNewClient">
							<div class="form-row">
								<label class="form-label" for="new_client_company">
									Компания:
									<i v-if="relatedCompaniesLoading" class="fa fa-spinner fa-spin" />
								</label>

								<template v-if="relatedCompanies.length">
									<select
										class="field"
										name="new_client_company"
										:disabled="!relatedCompanies.length || newCompany.enabled"
									>
										<option v-for="company in relatedCompanies" :value="company.id">
											{{company.title}}
										</option>

										<option v-if="!relatedCompanies.length">Няма намерени компании</option>
									</select>
								</template>

								<template v-else>
									<input
										v-autocomplete="{
											url: '/ajax/companies-search',
											parse: parseCompanyItems,
											formatItem: formatCompanyItems,
											resultHandler: handleCompanyResult
										}"
										v-model="companySearch"
										:disabled="newCompany.enabled"
										type="text"
										class="field"
										placeholder="Намери компания"
									/>

									<input
										v-if="!newCompany.enabled"
										:value="companyId"
										type="hidden"
										name="new_client_company"
									/>
								</template>
							</div>

							<div class="form-row">
								<label>
									<input type="checkbox" name="new_company" v-model="newCompany.enabled"> Добави нова компания
								</label>
							</div>

							<div v-if="newCompany.enabled" class="form-row columns">
								<div class="column column--third">
									<label for="new_company_title">Име на компанията:</label>

									<input
										v-model="newCompany.title"
										type="text"
										name="new_company_title"
										id="new_company_title"
										class="field"
									/>
								</div>

								<div class="column column--third">
									<label for="new_company_url">URL:</label>

									<input
										v-model="newCompany.url"
										type="text"
										name="new_company_url"
										id="new_company_url"
										class="field"
									/>
								</div>

								<div class="column column--third">
									<label for="new_company_manager">Мениджър:</label>

									<users-dropdown
										v-model="newCompany.manager"
										:users="managers"
										:returnOnlyValue="true"

										id="new_company_manager"
										name="new_company_manager"
									/>
								</div>
							</div>

							<hr class="client-company-separator" />
						</template>

						<div class="form-row" v-if="!clients || clients.length === 0">
							<label class="form-label">Клиент:</label>

							<input type="text" class="field" v-autocomplete="{ url: '/ajax/clients-search', parse: parseClientsItems, formatItem: formatClientsItems, resultHandler: handleClientResult }" v-model="clientName" required>

							<input type="hidden" name="client_id" id="client_id_field" v-bind:value="clientId">
							<input type="hidden" name="system_project_id" id="client_id_field" value="">
						</div>

						<div class="form-row">
							<label class="form-label">Мениджър:</label>

							<users-dropdown class="field" v-bind:users="managers" v-model="projectManagerId" :returnOnlyValue="true" />
							<input type="hidden" name="project_manager" class="field" id="project_manager_field" value="" v-bind:value="projectManagerId">
						</div>

						<div class="form-row">
							<label class="form-label">Краен Срок:</label>

							<datepicker name="dead_line" class="field" v-bind:numberOfMonths="2" dateFormat="yy-mm-dd" v-model="deadline" required />
						</div>

						<div class="form-row" v-if="!quote">
							<label class="form-label">Project Token:</label>

							<input type="text" name="project_token" class="field" id="project_token_field" value="">
						</div>

						<div class="form-row">
							<label class="form-label">Тип:</label>

							<select v-model="projectType" name="type" class="field">
								<option></option>
								<option v-for="(label, value) in projectTypes" :value="value">{{ label }}</option>
							</select>
						</div>

						<div class="form-row" v-for="(tags, group) in projectsTagsGrouped" v-if="!group || group === projectType">
							<label class="form-label">{{ group | capitalize }} Tags:</label>

							<project-tags :tags="tags" :required="!!group"></project-tags>
						</div>

						<add-task-notes />
					</div>

					<div class="form-aside">
						<quote-info />
					</div>
				</div>

				<div class="form-actions">
					<input type="submit" value="Запази проект" class="form-btn submit" :disabled="!clientId && (!clients || clients.length === 0)" :title="!clientId ? 'Избери клиент' : ''">
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import autocomplete from '../directives/autocomplete';
import { debounce, get } from 'lodash';

import Payments from './Payments.vue'
import Datepicker from './Datepicker.vue';
import Chosen from './Chosen.vue';
import UsersDropdown from './UsersDropdown.vue';
import QuoteInfo from './QuoteInfo.vue';
import ProjectTags from './ProjectTags.vue';
import AddTaskNotes from './AddTaskNotes.vue';
import { mapActions } from 'vuex';

const emailServiceDomains = [
	'gmail.com',
	'yahoo.com',
	'hotmail.com',
];

export default {
	name: 'add-project-form',

	props: ['users'],

	components: {
		Payments,
		Datepicker,
		Chosen,
		UsersDropdown,
		QuoteInfo,
		ProjectTags,
		AddTaskNotes,
	},

	data() {
		return {
			formState: '',

			clientId: null,
			clientName: null,

			relatedCompanies: [],
			relatedCompaniesLoading: false,

			newCompany: {
				enabled: false,
				title: null,
				url: null,
				manager: USER_ID,
			},

			companySearch: '',
			companyId: null,

			projectManagerId: USER_ID || 0,
			projectTitle: null,
			projectType: null,

			deadline: null
		};
	},

	computed: {
		project: {
			get() {
				return this.$store.state.project;
			},

			set(payload) {
				this.$store.dispatch('changeProject', payload);
			}
		},

		quote() {
			return this.$store.state.quote;
		},

        client_source_name() {
            return this.systemsProjects.source;
        },

		systemsProjects: {
			get() {
				return this.$store.state.systemsProjects;
			},

			set(payload) {
				this.$store.dispatch('changeSystemsProjects', payload);
			}
		},

		projectsTags() {
			return $$taskData.projectsTags;
		},

		projectsTagsGrouped() {
			return _.groupBy($$taskData.projectsTags, 'group');
		},

		projectTypes() {
			return $$taskData.projectTypes;
		},

		managers() {
			return $$taskData.users.filter( user => user.user_type === "admin" || user.user_type === "coordinator" );
		},

		clients() {
			const hasAssociatedClients = !!this.quote && this.quote.associated_clients.length;

			return hasAssociatedClients ? this.quote.associated_clients : [];
		},

		selectedClient() {
			if (!this.quote || !this.quote.associated_clients.length) {
				return null;
			}

			return this.quote.associated_clients.find(
				(client, index) => (client.id === this.clientId || index === this.clientId)
			);
		},

		/**
		 * Wheter selected client is a new client.
		 *
		 * @return {Boolean}
		 */
		isNewClient() {
			return this.selectedClient && !this.selectedClient.id;
		},

		/**
		 * Get new client's company url and title based on the client's email.
		 *
		 * @return {Object}
		 */
		newClientCompany() {
			if (!this.selectedClient || !this.isNewClient) {
				return null;
			}

			const url = this.selectedClient.email.match(/@(.+)$/)[1];
			const title = url && url.replace(/\.\w+$/, '');

			if (emailServiceDomains.includes(url.toLowerCase())) {
				return null;
			}

			return { url, title };
		},
	},

	watch: {
		newClientCompany(newVal, oldVal) {
			if (!newVal) {
				this.relatedCompanies = [];
				this.newCompany.url = null;
				this.newCompany.title = null;

				return;
			}

			const { url, title } = newVal;

			this.fetchRelatedCompanies(url);

			this.newCompany.url = url;
			this.newCompany.title = title;
		},

		projectManagerId: {
			handler() {
				this.updateTheProjectType();
			},
			immediate: true,
		},

		clientId: function(newClientId, oldClientId) {
			if (newClientId === oldClientId || !newClientId) {
				return;
			}

			this._fetchNotes({ client_id: newClientId });
		},
	},

	mounted() {
		this.populateFieldsFromQuote();
	},

	directives: {
		autocomplete,

		editor: {
			bind(el) {
				$(el).editor({
					preview: true
				});
			}
		}
	},

	methods: {
		...mapActions({
			fetchNotes: 'fetchNotes',
		}),

		_fetchNotes: debounce(function(...args) {
			this.fetchNotes.apply(this, args);
		}, 100),

		resetProject() {
			this.project = {
				project_id: null,
				svn_paths: []
			}
		},

		handleFormSubmit(event) {
			event.preventDefault();

			const formData = $(event.target).serializeArray();

			$.ajax({
				url: '/ajax/add-project',
				method: 'POST',
				data: formData,

				success: resp => {
					const project = resp;

					this.$store.dispatch('changeProject', project);

					this.$emit('success');
					this.$parent.$emit('close');
				},

				complete: () => {
					this.formState = '';
				}
			});
		},

		updateTheProjectType() {
			const userDepartment = this.getUserDepartment(this.projectManagerId);

			switch (userDepartment) {
				case 'php':
					this.projectType = 'wordpress';
					break;

				case 'htmlwp':
				case 'SPA':
					this.projectType = 'html';
					break;

				case 'graphicmama':
				case 'Web Design':
					this.projectType = 'design';
					break;

				default:
					if (this.projectTypes[userDepartment]) {
						this.projectType = userDepartment;
					}
			}
		},

		populateFieldsFromQuote() {
			if (this.quote) {
				const defaultClient = this.quote.associated_clients.length ? this.quote.associated_clients[0] : null;

				this.projectTitle = this.quote.title;
				this.clientId = defaultClient.id || 0;

				if (this.quote.accepted_option.delivery_date) {
					this.deadline = this.quote.accepted_option.delivery_date.replace(/T.+/, '');
				}

				if (this.quote.systems_project) {
					this.systemsProjects.id = this.quote.systems_project.id;
					this.systemsProjects.token = this.quote.posted_in_project;
					this.systemsProjects.source = this.quote.systems_project.source_system;
				}
			}
		},

		getClientLabel(client) {
			const clientLabel = [
				client.client_name
			];

			if (client.company_name) {
				clientLabel.push(`(${client.company_name})`);
			}

			if (client.client_email) {
				clientLabel.push(`- ${client.client_email}`);
			}

			return clientLabel.join(' ');
		},

		getUserDepartment(userId) {
			return $$taskData.users.find(user => user.id == userId).user_department;
		},

		formatClientsItems(client) {
			if (!client) {
				return;
			}

			return this.getClientLabel(client);
		},

		parseClientsItems(response) {
			return response;
		},

		handleClientResult(event, client) {
			this.clientId = client.id;
			this.clientName = this.getClientLabel(client);
		},

		/**
		 * Fetch related related companies for a gives url.
		 *
		 * @param  {String} url
		 * @return {Promise}
		 */
		fetchRelatedCompanies(url) {
			this.relatedCompaniesLoading = true;

			return axios
				.get('/ajax/get-related-companies', {
					params: { url }
				})
				.then(({ data }) => {
					if (data) {
						this.relatedCompanies = data;
					}
				})
				.then(() => {
					this.relatedCompaniesLoading = false;
				});
		},

		/**
		 * Format company results.
		 * @param  {Object} company
		 * @return {String}
		 */
		formatCompanyItems(company) {
			if (!company) {
				return '';
			}

			return `${company.title} (${company.url})`;
		},

		/**
		 * Parse company search results.
		 * @param  {Array} response
		 * @return {Array}
		 */
		parseCompanyItems(response) {
			return response.map(item => ({
				value: item.id,
				data: item,
			}));
		},

		/**
		 * Handle autocomplete result selection.
		 * @param  {Event} event
		 * @param  {Object} company
		 * @return {void}
		 */
		handleCompanyResult(event, company) {
			this.companyId = company.id;
			this.companySearch = company.title;
		},
	},

	filters: {
		formatClientAsOption(client) {
			let clientAsOption = `${client.name} - ${client.email}`;

			if (client.company_title) {
				clientAsOption = `${clientAsOption} (${client.company_title})`;
			}

			if (!client.id) {
				clientAsOption = `[new] ${clientAsOption}`;
			}

			return clientAsOption;
		},

		capitalize: function (value) {
			if (!value) return '';

			value = value.toString();

			return value.charAt(0).toUpperCase() + value.slice(1)
		},
	}
}
</script>
<style scoped>
	.client-company-separator { margin: 10px 0 20px; border: 0; border-top: 1px solid #eee; }
</style>
