export const getEntries = state => state.entries;

export const getEntry = state => id => state.entries.find(entry => entry.id === id);

export const getMessage = state => id => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return '';
	}

	return entry.message;
};

export const getMessageType = state => id => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return '';
	}

	return entry.messageType;
};

export const getIsLoading = state => id => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return false;
	}

	return entry.isLoading;
};

export const getVcsPath = state => state.vcsPath.path;

export const getIsAdding = state => state.isAdding;

export const getIsActive = state => id => {
	const entry = getEntry(state)(id);
	if (!entry) {
		return false;
	}

	return entry.isActive;
};

export const getMainEntries = state => state.entries.filter(entry => entry.is_main === true);

export const getFtpExplorer = state => state.ftpExplorer;

export const getFtpExplorerStatus = state => getFtpExplorer(state).status;

export const getFtpExplorerIsVisible = state => getFtpExplorer(state).isVisible;

export const getFtpExplorerFileTree = state => getFtpExplorer(state).fileTree;

export const getFtpExplorerCurrentDirectory = state => getFtpExplorer(state).currentDirectory;

export const getFtpExplorerDirectorySeparator = state => getFtpExplorer(state).directorySeparator;

export const getFtpExplorerEntryId = state => getFtpExplorer(state).entryId;

export const getFtpExplorerEntry = state => {
	const entryId = getFtpExplorerEntryId(state);
	if (!entryId) {
		return false;
	}

	const entry = getEntry(state)(entryId);
	if (!entry) {
		return false;
	}

	return entry;
}

export const getFtpExplorerConnectionInfo = state => {
	const entry = getFtpExplorerEntry(state);
	if (!entry) {
		return false;
	}

	let connectionInfo = {
		directory: entry.directory,
		host: entry.host,
		password: entry.password,
		port: entry.port,
		protocol: entry.protocol,
		username: entry.username,
	};
	connectionInfo.directory = getFtpExplorerCurrentDirectory(state);

	return connectionInfo;
};

export const getWpRoot = state => getFtpExplorer(state).wpRoot;

export const getAjaxSource = state => getFtpExplorer(state).ajaxSource;

export const showInputLoader = state => getFtpExplorer(state).showInputLoader;
