import './beam'
import './wp-depot'
import './clipboard-upload'

import Vue from 'vue'
import VueClipboard from 'vue-clipboard2';
import { mapActions } from 'vuex';

import AddTask from './apps/AddTask.vue'
import ChecklistContainer from './apps/ChecklistContainer.vue'
import Comments from './components/comments/Comments.vue'
import Notifications from './components/notifications/Notifications.vue'
import ProfileNotificationsSettings from './apps/ProfileNotificationsSettings.vue'
import PinABug from './apps/PinABug.vue'
import TimeTracking from './apps/TimeTracking.vue'
import TimeTrackingProfile from './apps/TimeTrackingProfile.vue'
import TimeTrackingStats from './apps/TimeTrackingStats.vue'
import EstimateTask from './apps/EstimateTask.vue'
import GitLabIssues from './apps/GitLabIssues.vue'
import Monitor from './apps/Monitor.vue'
import MigrateToGit from './apps/MigrateToGit.vue'
import UsersList from './apps/UsersList.vue'
import Search from './apps/Search.vue'
import SearchWidget from './apps/SearchWidget.vue'
import AccessDepot from './apps/AccessDepot.vue';
import WpDepot from './apps/WpDepot.vue';
import CompanyEmail from './apps/CompanyEmail.vue';
import ClientNotes from './apps/ClientNotes.vue';
import ProjectNotes from './apps/ProjectNotes.vue';
import ScheduleSummary from './components/ScheduleSummary.vue'

import addTaskStore from './store/store.addTask'
import monitorStore from './store/store.monitor'
import store from './store';

Vue.use(VueClipboard);

document.addEventListener('DOMContentLoaded', () => {
	// Add Task
	var addTaskContainer = '#add-task-container';

	if (document.querySelector(addTaskContainer) !== null) {
		new Vue({
			el: addTaskContainer,
			store: addTaskStore,
			render: h => h(AddTask)
		});
	}

	// Estimate Task
	var estimateTaskContainer = '#estimate-task-container';

	if (document.querySelector(estimateTaskContainer) !== null) {
		new Vue({
			el: estimateTaskContainer,
			render: h => h(EstimateTask)
		});
	}

	// Dashboard instance
	['#dashboard', '.has-schedule'].forEach(function(selector) {
		if (document.querySelector(selector)) {
			new Vue({
				el: selector,
				components: {
					ScheduleSummary
				}
			});
		}
	});

	// GitLab Issues App
	var gitlabIssues = '#gitlab-issues';

	if (document.querySelector(gitlabIssues) !== null) {

		new Vue({
			el: gitlabIssues,
			render: h => h(GitLabIssues)
		});
	}

	// Monitor
	var appMonitor = '#app-monitor';

	if (document.querySelector(appMonitor) !== null) {
		new Vue({
			el: appMonitor,
			store: monitorStore,
			render: h => h(Monitor)
		});
	}

	// Migrate to GIT block instance
	var migrateToGit = '#migrate-to-git';

	if (document.querySelector(migrateToGit) !== null) {
		new Vue({
			el: migrateToGit,
			components: {
				MigrateToGit,
			}
		});
	}

	// Checklists
	var checklistContainer = '#task-checklists';

	if (document.querySelector(checklistContainer) !== null) {

		new Vue({
			el: checklistContainer,
			// render: h => h(	ChecklistContainer ),
			components: {
				ChecklistContainer,
			}
		});
	}

	// Users List
	var usersListContainer = '#users-list';

	if (document.querySelector(usersListContainer) !== null) {
		new Vue({
			el: usersListContainer,
			render: h => h(	UsersList )
		});
	}

	// Search Widget App
	var appSearchWidget = '#app-search-widget';

	if (document.querySelector(appSearchWidget) !== null) {
		new Vue({
			el: appSearchWidget,
			render: h => h(SearchWidget)
		});
	}

	// Search Page App
	var appSearch = '#app-search';

	if (document.querySelector(appSearch) !== null) {
		new Vue({
			el: appSearch,
			render: h => h(Search)
		});
	}

	// Comments
	if (document.querySelector('#comments') !== null) {

		new Vue({
			/**
			 * Instance Name.
			 *
			 * @type {String}
			 */
			name: 'comments-app',

			/**
			 * DOM Element.
			 *
			 * @type {String}
			 */
			el: '#comments',

			/**
			 * Vuex Store.
			 */
			store,

			/**
			 * Child Components.
			 *
			 * @type {Object}
			 */
			components: { Comments },

			/**
			 * Component Methods.
			 */
			methods: {
				...mapActions({
					fetchCurrentUser: 'currentUser/fetchCurrentUser',
				})
			},

			/**
			 * Lifecycle Hook.
			 */
			mounted() {
				this.fetchCurrentUser();
			},
		})
	}

	// Notifications
	if (document.querySelector('#notifications') !== null) {
		new Vue({
			/**
			 * Instance Name.
			 *
			 * @type {String}
			 */
			name: 'notifications-app',

			/**
			 * DOM Element.
			 *
			 * @type {String}
			 */
			el: '#notifications',

			/**
			 * Vuex Store.
			 */
			store,

			/**
			 * Render Instance.
			 */
			render: h => h(Notifications),
		})
	}

	// Pin a Bug
	if (document.querySelector('#profile-pin-a-bug') !== null) {
		new Vue({
			/**
			 * Instance Name.
			 *
			 * @type {String}
			 */
			name: 'ping-a-bug-app',

			/**
			 * DOM Element.
			 *
			 * @type {String}
			 */
			el: '#profile-pin-a-bug',

			/**
			 * Vuex Store.
			 */
			store,

			/**
			 * Render Instance.
			 */
			render: h => h(PinABug),
		})
	}

	// Profile notifications
	if (document.querySelector('#profile-notifications') !== null) {
		new Vue({
			/**
			 * Instance Name.
			 *
			 * @type {String}
			 */
			name: 'profile-notifications-settings',

			/**
			 * DOM Element.
			 *
			 * @type {String}
			 */
			el: '#profile-notifications',

			/**
			 * Vuex Store.
			 */
			store,

			/**
			 * Render Instance.
			 */
			render: h => h(ProfileNotificationsSettings),
		})
	}

	// Time Tracking
	if (document.querySelector('#time-tracking') !== null) {
		const TimeTrackingConstr = Vue.extend(TimeTracking);
		new TimeTrackingConstr({
			/**
			 * Vuex Store.
			 */
			store,
		}).$mount('#time-tracking');
	}

	// Time Tracking
	if (document.querySelector('#time-tracking-profile') !== null) {
		new Vue({
			/**
			 * DOM Element.
			 *
			 * @type {String}
			 */
			el: '#time-tracking-profile',

			/**
			 * Vuex Store.
			 */
			store,

			/**
			 * Render Instance.
			 */
			render: h => h(TimeTrackingProfile),
		})
	}

	if (document.querySelector('#time-tracking-stats') !== null) {
		new Vue({
			/**
			 * DOM Element.
			 *
			 * @type {String}
			 */
			el: '#time-tracking-stats',

			/**
			 * Vuex Store.
			 */
			store,

			/**
			 * Render Instance.
			 */
			render: h => h(TimeTrackingStats),
		})
	}

	if (document.querySelector('.access-depot-container')) {
		new Vue({
			el: '.access-depot-container',
			render: h => h(AccessDepot),
			store,
		});
	}

    window.initializeWpDepot = () => {
        if (document.querySelector('.wp-depot-app')) {
            const el = document.querySelector( '.wp-depot-app');
            new Vue({
                el,
                render: h => h(WpDepot),
                data: () => Object.assign({}, el.dataset),
                store,
            });
        }
    }
    initializeWpDepot();

	if (document.querySelector('.company-email-app')) {
		new Vue({
			el: '.company-email-app',
			render: h => h(CompanyEmail),
			store,
		});
	}

	const $clientNotesApps = document.querySelectorAll('.client-notes-app');
	$clientNotesApps.forEach($app => {
		const only = $app.getAttribute("only") || '';

		new Vue({
			el: $app,
			template: `<ClientNotes only="${only}"/>`,
			store,
			components: {
				ClientNotes,
			},
		});
	});

	const $projectNotesApp = document.querySelector('.project-notes-root');
	if ($projectNotesApp) {
		new Vue({
			el: $projectNotesApp,
			render: h => h(ProjectNotes),
			store,
		});
	}
});
