<template>
    <div class="inactive-reservations">
        <h4>History</h4>
        <p class="seat" v-for="seat in visibleItems">
            {{ convertDate(seat) }}: От {{ seat.user.currentUser ? 'теб' : seat.user.name }}
        </p>
        <a
            v-if="entry.inactive_reservations.length > maxVisibleItems"
            href="#"
            class="inactive-reservations__reveal"
            @click.prevent="showMore = !showMore"
        >
            {{ buttonText }} 
            <i class="fa" :class="chevronClass"></i>
        </a>
    </div>
</template>

<script>
export default {
    props: [
        'entry',
        'showSeat'
    ],

    data() {
        return {
            showMore: false,
            maxVisibleItems: 2,
            dateStrings: {
                days: ['дни', 'ден'],
                hours: ['часа', 'час'],
                minutes: ['минути', 'минута'],
            }
        }
    },

    computed: {
        visibleItems() {
            return this.showMore ? this.entry.inactive_reservations : this.entry.inactive_reservations.slice(0, this.maxVisibleItems);
        },

        buttonText() {
            return this.showMore ? 'Скрий' : 'Покажи повече';
        },

        chevronClass() {
            return this.showMore ? 'fa-angle-up' : 'fa-angle-down';
        }
    },

    methods: {
        convertDate(seat) {
            const from = new Date(seat.reserved_from);
            const to = new Date(seat.reserved_to);

            const options = {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };

            const timeDifference = to - from;
            const remainingTime = {};

            if (timeDifference > 0) {
                remainingTime.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                remainingTime.hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
                remainingTime.minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
            }

            let output = '';
            
            output += this.getDateString('days', remainingTime);
            output += this.getDateString('hours', remainingTime);
            output += this.getDateString('minutes', remainingTime);
            
            const passedMinute = remainingTime.days > 0 || remainingTime.hours > 0 || remainingTime.minutes > 0;
            if(passedMinute) {
                output = ` (за ${output})`;
            } else {
                output = " (за по-малко от минута)"
            }

            return `${to.toLocaleDateString('bg-BG', options)}${output}`;
        },

        getDateString(target, remainingTime) {
            if(remainingTime[target] > 0) {
                const namedStringArr = this.dateStrings[target];
                const namedString = remainingTime[target] > 0 ? namedStringArr[0] : namedStringArr[1];
                return ` ${remainingTime[target]} ${namedString}`
            } 

            return '';
        },
    },
}
</script>