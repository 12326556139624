<template>
	<div>
		<p>
			This will request a migration of this SVN project to GIT.
		</p>

		<p>
			The GIT repository is going to live here:

			<code>{{gitPath}}</code>
		</p>

		<p>
			The estimated time for completion is {{migrationEta}} minutes.
		</p>

		<p>
			Once the migration is completed you'll need to setup your local working copies again.
		</p>

		<hr>

		<p>
			Are you sure you want to continue?
		</p>

		<a
			href="#"
			class="btn"
			@click="emitProceed"
		>
			Proceed
		</a>
	</div>
</template>

<script>

export default {
	name : 'migration-proceed',

	props : ['gitPath', 'migrationEta' ],

	methods : {
		emitProceed() {
			this.$emit( 'prepareMigration' );
		}
	}

}

</script>
