// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.spinner[data-v-73794d47] { text-align: center;
}
`, "",{"version":3,"sources":["webpack://./js/components/spinner/spinner.vue"],"names":[],"mappings":";AAwCA,4BAAA,kBAAA;AAAA","sourcesContent":["<template>\n\t<div class=\"spinner\">\n\t\t<i :class=\"classList\" aria-hidden=\"true\"></i>\n\t</div><!-- /.spinner -->\n</template>\n\n<style scoped>\n\t.spinner { text-align: center; }\n</style>\n\n<script>\n\texport default {\n\t\t/**\n\t\t * Component Name.\n\t\t *\n\t\t * @type {String}\n\t\t */\n\t\tname: 'spinner',\n\n\t\t/**\n\t\t * Component Props.\n\t\t *\n\t\t * @type {Object}\n\t\t */\n\t\tprops: {\n\t\t\tsize: String,\n\t\t},\n\n\t\t/**\n\t\t * Computed Data.\n\t\t *\n\t\t * @type {Object}\n\t\t */\n\t\tcomputed: {\n\t\t\tclassList() {\n\t\t\t\treturn 'fa fa-spinner fa-spin ' + this.size;\n\t\t\t}\n\t\t}\n\t}\n</script>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
