<template>
	<div class="site-info-field">
		<p class="field-label">{{ label }}</p>

		<div class="field-value">
            <FtpConnection
                droneLink="#"
                :connectionString="value"
                :droneLink="droneLink"
                :visibility="{ copyToClipboardButton: false }"
                v-if="type === 'ftp'"
            />

			<a :href="value" v-if="type === 'link'">{{ value ?? 'Loading...' }}</a>

			<div class="value-wrapper" @click="handleTextClick" v-if="type === 'text'">
				{{ value ?? 'Loading...' }} 
				<button v-clipboard:copy="value" @click="handleTextCopy()"><i class="fa" :class="copyIcon === 'copy' ? 'fa-copy' : 'fa-check'"></i></button>
			</div>

            <div v-if="type === 'list'">
                <ul>
                    <li v-for="item in value">
                        {{ item.label }}:
                        <a :href="item.value"
                            v-if="item.value.match(/^https?:\/\//)"
                            target="_blank"
                        >
                            {{ item.value }}
                        </a>
                        <code v-else @click="handleTextClick">{{ item.value }}</code>
                    </li>
                </ul>
            </div>
			
			<Copy :value="value" v-if="type === 'ftp'"/>
		</div>
	</div>
</template>

<script>
import FtpConnection from '~/components/FtpConnection.vue';
import Copy from '~/components/access-depot/Copy.vue';

export default {
	components: { FtpConnection, Copy },
	props: {
		label: {
			type: String
		},
		value: {
			required: true
		},
		type: {
			type: String,
			default: 'text'
		}
	},
	data() {
		return {
			copyIcon: 'copy'
		}
	},
	computed: {
		droneLink() {
            try {
                const params = {
                    command: 'filezilla',
                    targets: [
                        this.value,
                    ],
                };

                return `drone://${btoa(JSON.stringify(params))}`;
            } catch(error) {
                return '#';
            }
		}
	},
	methods: {
		handleTextClick(event) {
			window.getSelection().selectAllChildren(
				event.target
			);
		},
		handleTextCopy() {
			this.copyIcon = 'check';

			setTimeout(() => {
				this.copyIcon = 'copy';
			}, 2000);
		}
	}
}
</script>

<style scoped>
	.site-info-field {
		position: relative;
		padding-inline: 12px;
		padding-block: 4px;

		&:not(:last-child) {
			margin-bottom: 12px;
		}

		& .field-label {
			margin-bottom: 6px;
			font-size: 12px;
		}

		& .field-value {
			padding-left: 12px;
			max-width: 380px;
			display: grid;
			grid-template-rows: 0fr;
			overflow: hidden;
			transition: grid-template-rows 300ms ease-out;

			& ul {
				margin-block: 0;
				padding-left: 0;
				list-style: none;
			}

			& .value-wrapper {
				appearance: none;
				background: #f5f5f5;
				border: 1px solid #CCC;
				border-radius: 4px;
				padding: 2px 4px;
				min-height: 20px;
				position: relative;
				padding-right: 26px;

				& button {
					position: absolute;
					top: 4px;
					right: 4px;
					appearance: none;
					border: none;
					background: transparent;
					cursor: pointer;
					padding: 2px;
					transition: background-color 250ms ease-out;
					border-radius: 999px;

					@media (hover: hover) and (pointer: fine) {
						&:hover {
							background: #DDD;
						}
					}
				}
			}
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				background: #EEE;
				& .entry-copy {
					opacity: 1;
				}

				& .field-value {
					grid-template-rows: 1fr;
				}
			}
		}
	}
</style>