export const setEmailDetails = (state, details) => {
    state.emailDetails = details;
}

export const setLoading = (state, loading) => {
    state.loading = loading;
}

export const setError = (state, error) => {
    state.error = error;
}