<template>
	<div class="notifications">
		<notification
			v-for="notification in notifications"
			:key="notification.id"
			:notification="notification"
			@dismiss="onDismiss"
		/>
	</div><!-- /.notifications -->
</template>

<script>
	/**
	 * External Dependencies.
	 */
	import { mapGetters } from 'vuex';

	/**
	 * Internal Dependencies.
	 */
	import Notification from './Notification.vue';


	export default {
		/**
		 * Component Name.
		 *
		 * @type {String}
		 */
		name: 'notifications',

		/**
		 * Child Components.
		 *
		 * @type {Object}
		 */
		components: {
			Notification,
		},

		/**
		 * Computed Data.
		 *
		 * @type {Object}
		 */
		computed: {
			...mapGetters({
				notifications: 'notifications/getNotifications',
			}),
		},

		/**
		 * Component Methods.
		 */
		methods: {
			onDismiss() {
				// TODO
			}
		}
	}
</script>