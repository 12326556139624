<template>
    <div>
        <p>
            Можеш да се логнеш през <a href="https://email.2create.studio/mail/">webmail</a> със следните данни:
        </p>

        <table>
            <tr>
                <td>Email:</td>

                <td><input class="field credential-field" type="text" tabindex="1" :value="details.email" readonly></td>
            </tr>

            <tr>
                <td>Password:</td>

                <td><input class="field credential-field" type="text" tabindex="2" :value="details.password" readonly></td>
            </tr>

            <tr>
                <td>Webmail:</td>

                <td><input class="field credential-field" type="text" tabindex="3" value="https://email.2create.studio/mail/" readonly></td>
            </tr>
        </table>

        <p v-if="details.aliases && details.aliases.length">
            Всички имейли, които пристигат в тази кутия, ще бъдат препращани и към следните адреси:
            <ul class="company-mailbox-aliases">
                <li v-for="alias in details.aliases" :key="alias">
                    {{ alias }}
                </li>
            </ul>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        details: Object,
    },
}
</script>

<style scoped>
.field { min-width: 250px; }
table { margin: 15px 0; }
td:first-child { padding-right: 5px; }
ul li { list-style-position: inside; }
.company-mailbox-aliases { margin: 10px 20px }
</style>
