<template>
	<div class="schedule-summary" v-if="schedule && shifts">
		<div :class="['schedule-summary-trigger', `off-danger-level-${getNextDayOffDangerLevel(nextDayOff.in)}`]" :data-next-day-off="nextDayOff.date">
			<i class="fa fa-calendar-o" v-if="getNextDayOffDangerLevel(nextDayOff.in) < 2"></i>
            <span v-else style="line-height: 1.9;">{{ nextDayOff.reason }}</span>
		</div>

		<div class="schedule-summary-popup" v-show="false">
			<div class="remaining-work-hours">
				<i class="fa fa-clock-o"></i> Оставащи работни часове за днес: <strong>{{todayRemainingTime}}</strong>
			</div>

			<div class="bars">
				<div v-for="daySchedule in schedulePerDay" :class="['bar', { 'bar-weekend': isWeekendDay(daySchedule.date) }]">
					<span class="bar-date">{{getBarDate(daySchedule)}}</span>

					<div class="bar-body">
						<span class="start-time" :style="{ left: `${startTimePercents.start}%` }">
							{{renderTime(convertScheduleHourToMins(schedule.start_time))}}
						</span>

						<span class="end-time" :style="{ right: `${100 - startTimePercents.length}%` }">
							{{renderTime(convertScheduleHourToMins(schedule.end_time))}}
						</span>

						<div class="shift standart" :style="{ left: `${startTimePercents.start}%`, width: `${startTimePercents.length}%` }"></div>

						<div
							v-for="scheduleEntry in daySchedule.entries"
							class="shift"
							:style="{
								left: `${getEntryPercents(scheduleEntry).start}%`,
								width: `${getEntryPercents(scheduleEntry).length}%`,
								color: scheduleEntry.color,
								backgroundColor: scheduleEntry.color
							}"
							:data-title="scheduleEntry.title"
							:data-period="`${renderTime(scheduleEntry.start)}-${renderTime(scheduleEntry.end)}`"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'schedule-summary',

	props: ['shifts', 'schedule', 'transactionActivity', 'transactionType'],

	computed: {
		todayRemainingTime() {
			const todayRemainingMinutes = this.getTodayRemainingMinutes(this.schedulePerDay[0].entries, this.schedule);

			return this.renderTime(todayRemainingMinutes);
		},

		schedulePerDay() {
			const schedulePerDay = [];

			for (let date in this.shifts) {
				schedulePerDay.push(this.getUserDayScheduleByDate(date, this.shifts[date], this.schedule));
			}

			return schedulePerDay;
		},

		scheduleClamp() {
			const values = {
				min: Infinity,
				max: 0
			};

			for (let i = 0; i < this.schedulePerDay.length; i++) {
				values.min = Math.min(values.min, this.schedulePerDay[i].schedule.start);
				values.max = Math.max(values.max, this.schedulePerDay[i].schedule.end);

				for (let j = 0; j < this.schedulePerDay[i].entries.length; j++) {
					if (!this.schedulePerDay[i].entries[j].allDay) {
						values.min = Math.min(values.min, this.schedulePerDay[i].entries[j].start);
						values.max = Math.max(values.max, this.schedulePerDay[i].entries[j].end);
					}
				}
			}

			return values;
		},

		startTimePercents() {
			const startTimeMinutes = this.convertScheduleHourToMins(this.schedule.start_time);
			const endTimeMinutes = this.convertScheduleHourToMins(this.schedule.end_time);

			return {
				start: (startTimeMinutes - this.scheduleClamp.min) / (this.scheduleClamp.max - this.scheduleClamp.min) * 100,
				length: (endTimeMinutes - startTimeMinutes) / (this.scheduleClamp.max - this.scheduleClamp.min) * 100
			};
		},

		nextDayOff() {
			let offInDays = null;
			let reason = null;

			console.log(this.shifts)

			for (const date in this.shifts) {
				const dateShifts = this.shifts[date];
                let offType;

				for (let i = 0; i < dateShifts.length; i++) {
					switch (dateShifts[i].time_off_type) {
						case '1': // time off
						case '2': // unpaid time off
						case '3': // sickness
						case '7': // other
                            reason = {
                                "1": "🌴",
                                "2": "🚫",
                                "3": "🤒",
                                "7": "🚫",
                            }[dateShifts[i].time_off_type];
							
							if (offInDays !== null) {
								offInDays = Math.min(offInDays, this.diffDays(new Date(), dateShifts[i].start_time));
							} else {
								
								offInDays = this.diffDays(new Date(), dateShifts[i].start_time);
								
							}
							
							break;
					}
					if (offInDays !== null) break;
				}
				// we're looking for the first day, not the last ... 
				if (offInDays !== null) break;
			}

            const offDateDay = this.zeroPadded( new Date(new Date().getTime() + offInDays * (1000 * 3600 * 24)).getDate() );
            const offDateMonth = this.zeroPadded( new Date(new Date().getTime() + offInDays * 86400000).getMonth() + 1 );
            const offDate = `${offDateDay}/${offDateMonth}`;

			console.log({
				in: offInDays,
                date: offDate,
                reason
			});

			return {
				in: offInDays,
                date: offDate,
                reason
			};
		}
	},

	mounted() {
		const $triggerEl = $(this.$el).find('.schedule-summary-trigger');
		const $popupEl = $(this.$el).find('.schedule-summary-popup');

		$triggerEl.qtip({
			content: {
				text: $popupEl,
			},
			style: {
				classes: 'qtip-bootstrap qtip-overflow-visible'
			},
			hide: {
				event: 'mouseleave',
				fixed: true,
				delay: 250
			},
			position: {
				viewport: $('body'),
				effect: false
			}
		});
	},

	methods: {
		zeroPadded(num) {
			return num < 10 ? '0' + num : num;
		},

		renderTime(mins) {
			return this.zeroPadded( Math.floor(mins / 60) ) + ':' + this.zeroPadded( Math.floor(mins % 60) );
		},

		todayRelMins(mins) {
			return Math.max(mins, new Date().getHours() * 60 + new Date().getMinutes());
		},

		convertScheduleHourToMins(scheduleHour) {
			return scheduleHour.split(':')[0] * 60 + (scheduleHour.split(':')[1] - 0);
		},

		getUserDayScheduleByDate(date, shifts, mainSchedule) {
			const dateSchedule = {
				date: date,
				schedule: {
					start: this.convertScheduleHourToMins(mainSchedule.start_time),
					end: this.convertScheduleHourToMins(mainSchedule.end_time)
				},
				entries: [
					{
						off: true,
						start: this.convertScheduleHourToMins(mainSchedule.break_start_time),
						end: this.convertScheduleHourToMins(mainSchedule.break_end_time),
						title: 'почивка',
						color: '#efefef'
					}
				]
			};

			for (let i = 0; i < shifts.length; i++) {
				const timeObj = {
					start: this.convertScheduleHourToMins(shifts[i].start_time.split(' ')[1]),
					end: this.convertScheduleHourToMins(shifts[i].end_time.split(' ')[1]),
					type: shifts[i].time_off_type,
					title: shifts[i].title,
					color: shifts[i].color
				};

				switch (shifts[i].time_off_type) {
					case '5': // working off
						timeObj.off = false;
						break;

					case '4': // absence
					case '6': // training/conference
					case '7': // mentoring
						timeObj.off = true;
						break;

					case '1': // time off
					case '2': // unpaid time off
					case '3': // sickness
					case '7': // other
						timeObj.off = true;
						timeObj.allDay = true;
						break;
				}

				dateSchedule.entries.push(timeObj);
			}

			return dateSchedule;
		},

		getTodayRemainingMinutes(todayShifts, schedule) {
			let remainingMinutes = this.todayRelMins(this.convertScheduleHourToMins(this.schedule.end_time)) - this.todayRelMins(this.convertScheduleHourToMins(this.schedule.start_time));

			for (var i = 0; i < todayShifts.length; i++) {
				if (todayShifts[i].off) {
					remainingMinutes -= this.todayRelMins(todayShifts[i].end) - this.todayRelMins(todayShifts[i].start);
				} else {
					remainingMinutes += this.todayRelMins(todayShifts[i].end) - this.todayRelMins(todayShifts[i].start);
				}
			}

			return Math.max(0, remainingMinutes);
		},

		getBarDate(daySchedule) {
			return moment(daySchedule.date).format('ddd, DD.MM');
		},

		getEntryPercents(scheduleEntry) {
			const start = Math.max((scheduleEntry.start - this.scheduleClamp.min) / (this.scheduleClamp.max - this.scheduleClamp.min) * 100, 0);
			const length = Math.min((scheduleEntry.end - scheduleEntry.start) / (this.scheduleClamp.max - this.scheduleClamp.min) * 100, 100);

			return {
				start,
				length
			};
		},

		isWeekendDay(date) {
			const dayOfWeek = new Date(date).getDay();

			return dayOfWeek === 0 || dayOfWeek === 6;
		},

		diffDays(a, b) {
			return Math.ceil(
				Math.abs(
					new Date(new Date(a).setHours(0,0,0,0)).getTime() - 
					new Date(new Date(b).setHours(0,0,0,0)).getTime()
				) 
				/
				(1000 * 3600 * 24)
			);
		},

		getNextDayOffDangerLevel(offInDays) {
			if (offInDays !== null) {
				if (offInDays <= 2) {
					return 3;
				} else if (offInDays <= 4) {
					return 2;
				} else {
					return 1;
				}
			}
            return 0;
		}
	}
}
</script>
