<template>
	<div ref="select" class="users-multiselect">
		<a ref="trigger" href="#" class="users-multiselect__selected">{{selectedLabel}}</a>

		<div ref="dropdown" class="users-multiselect__dropdown">
			<ul class="users-multiselect__options">
				<li>
					<label>
						<input type="checkbox" v-model="selectAll" @change="handleSelectAllChange">

						<span>
							<img src="/avatars/default.jpg"> Всички
						</span>
					</label>
				</li>

				<li v-for="user in usersVisible">
					<label>
						<input type="checkbox" :value="user.id" v-model="innerValue">

						<span>
							<img :src="`/avatars/${user.username || 'default'}.jpg`"> {{user.user_real_name}}
						</span>
					</label>
				</li>
			</ul><!-- /.users-multiselect__options -->
		</div><!-- /.users-multiselect__dropdown -->
	</div><!-- /.users-multiselect -->
</template>

<script>
export default {
	name: 'users-multiselect',

	props: ['value', 'name', 'users'],

	data() {
		return {
			innerValue: typeof this.value === 'string' ? [].concat(this.value.split(',')) : [].concat(this.value),
			selectAll: false,
		};
	},

	mounted() {
		this.bind();
	},

	computed: {
		usersVisible() {
			return this.users;
		},

		selectedLabel() {
			const values = [].concat(this.innerValue).filter(id => id);

			if (values.length === 0) {
				return 'Всички';
			}

			return values
				.map(id => {
					const user = this.users.find(user => parseInt(user.id) === parseInt(id));

					if (!user) {
						return '';
					}

					return values.length > 1 ? user.name.split(' ')[0] : user.name;
				})
				.join(', ');
		}
	},

	methods: {
		bind() {
			$(this.$refs.trigger).on('click', (event) => {
				event.preventDefault();
				event.stopPropagation();

				$(this.$refs.select).toggleClass('open');
			});

			$(this.$refs.dropdown).on('click', (event) => {
				event.stopPropagation();
			});

			$(window).on('click', (event) => {
				$(this.$refs.select).removeClass('open');
			});
		},

		handleSelectAllChange() {
			if (this.selectAll) {
				this.innerValue = [];
			}
		},
	},

	watch: {
		value() {
			if (JSON.stringify(this.value) !== JSON.stringify(this.innerValue)) {
				if (typeof this.value === 'string') {
					this.innerValue = [].concat(this.value.split(','));
				} else {
					this.innerValue = [].concat(this.value);
				}
			}
		},

		innerValue() {
			if (this.innerValue.length !== 0) {
				this.selectAll = false;
			}

			const validValues = [].concat(this.innerValue).filter(id => id);
			const name = this.name;
			const value = validValues.length > 1 ? validValues : validValues[0];

			this.$emit('input', { name, value });
		},
	}
}
</script>

<style type="text/css" media="screen">
	.users-multiselect,
	.users-multiselect * { box-sizing: border-box; }

	.users-multiselect { position: relative; }

	.users-multiselect__selected { position: relative; display: block; height: 32px; line-height: 32px; padding: 0 20px 0 8px; background: #fff; border-radius: 3px; border: 1px solid #999; box-sizing: border-box; color: #222; text-decoration: none; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }
	.users-multiselect__selected:after { content: '\f0d7'; position: absolute; right: 8px; top: 50%; font-family: FontAwesome; transform: translateY(-50%); }

	.users-multiselect__dropdown { position: absolute; left: 0; min-width: 100%; top: 100%; margin-top: -1px; background: #fff; border: 1px solid #999; border-radius: 0 0 3px 3px; visibility: hidden; }

	.users-multiselect__options { padding: 4px; max-height: 300px; overflow-y: auto; list-style: none outside none; }
	.users-multiselect__options li { white-space: nowrap; }
	.users-multiselect__options label { display: block; padding: 1px 4px; cursor: pointer; }
	.users-multiselect__options label:hover { background: #eee; }
	.users-multiselect__options input { display: inline-block; vertical-align: middle; }
	.users-multiselect__options img { display: inline-block; vertical-align: middle; width: 24px; height: 24px; }
	.users-multiselect__options span { display: inline-block; vertical-align: middle; }

	.users-multiselect.open .users-multiselect__dropdown { visibility: visible; }
	.users-multiselect.open .users-multiselect__selected { border-radius: 3px 3px 0 0; }
</style>
