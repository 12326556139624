/**
 * Internal Dependencies.
 */
import {
	USER_ROLE_ADMIN,
	USER_ROLE_MENTOR,
	USER_ROLE_EMPLOYEE,
	USER_ROLE_FIRED,
	USER_ROLE_COORDINATOR
} from '~/lib/constants.js';


export const $can = (user, permission, entity) => {
	switch (permission) {
		case 'edit-task':
		case 'add-task':
		case 'touch-monitor':
		case 'sync-monitor':
		case 'delete-comment':
		case 'add-red-point':
		case 'edit-points':
		case 'edit-priority':
		case 'view-milestones':
		case 'edit-milestones':
		case 'add-comment-type':
		case 'see-pm-system':
		case 'see-client-stats':
		case 'change-client-note-visibility' :
		case 'add-user-cases':
			return user.type === USER_ROLE_ADMIN || user.type === USER_ROLE_COORDINATOR;

		case 'send-user-messages':
		case 'run-qa':
			return user.type === USER_ROLE_MENTOR || user.type === USER_ROLE_ADMIN || user.type === USER_ROLE_COORDINATOR;

		case 'request-screenshots':
			return user.type === USER_ROLE_MENTOR || user.type === USER_ROLE_ADMIN || user.type === USER_ROLE_EMPLOYEE || user.type === USER_ROLE_COORDINATOR;

		case 'run-mail-shots':
		case 'use-screenshots-service':
		case 'view-task': // Everybody can see everything ...
		case 'add-notes':
		case 'test-html':
		case 'use-wp-shuttle':
		case 'add-client-note':
			return true;

		case 'edit-comment': {
			// admins can edit all comments; regular users can edit their comments
			if (user.type === USER_ROLE_ADMIN || user.type === USER_ROLE_COORDINATOR) {
				return true;
			}

			if (entity.author.id === user.id) {
				return true;
			}

			return false;
		}
	}
}

/*
switch($action) {
	case 'change_user':
		if ($this->user_type=='Mentor') {
			if (isset($additional_args[0])) {
				$other_user = $additional_args[0];
				return $other_user["user_id"] == $this->id || $other_user["mentored_by"] == $this->id;
			} else {
				return true;
			}
		}

		if ($this->user_type != 'admin' && isset($additional_args[0])) {
			$other_user = $additional_args[0];

			$loggedDepartment = user()->user_department_id;
			$loggedDivision = user()->user_department_division_id;
			$viewedDepartment = $other_user['user_department_id'];
			$viewedDivision = $other_user['user_department_division_id'];

			if ($loggedDepartment === $viewedDepartment) {
				if ($loggedDivision != 0) {
					return $loggedDivision == $viewedDivision;
				} else {
					return true;
				}
			}
		}

		return $this->user_type=='admin';

	case 'watch_task':
		$task_id = $additional_args[0];
		return $this->is_allowed_to('view_task', $task_id);

	case 'deploy':
	case 'zip':
	case 'svn_update':
	case 'import_db':
		return $this->is_allowed_to_deploy_to_mt();


	case 'skip_not_todays_tasks':
		return false;
		// can't skip view all tasks
		if( $this->user_type == 'admin' ) {
			return false;
		}

		return $this->department_title == 'html';

	case 'view_client_note':
		// 1, 2, 6 -- admin, employee, mentor
		$note_permission_level = $additional_args[0];


		// Admins can see everything
		if( $this->user_type == 'admin' ) {
			return true;
		}

		// Mentors/Employees notes
		if( $note_permission_level == 2 ) {
			return true;
		}

		return false;

}*/