/**
 * Internal Dependencies.
 */
import api from 'services/api';


import {
    TRACKING_UPDATE_STATUS,
    TRACKING_FORCE_START,
    TRACKING_SET_EVENTS,
    TRACKING_SET_CURRENTLY_ACTIVE_TASK,
    TRACKING_SET_CURRENT_TASK_ESTIMATE,
} from './mutations';

/**
 * The actions.
 *
 * @type {Object}
 */
export default {
    /**
     * Update current tab tracking status.
     *
     * @param  {Object}   context
     * @param  {Function} context.commit
     * @param  {Object}   payload
     * @param  {Object}   payload.taskId
     * @return {Promise}
     */
    updateTimeTrackingStatus({ commit }, { action } = null) {
        if (action === 'update') {
            return;
        }

        return commit(TRACKING_UPDATE_STATUS, { action });
    },

    /**
     * Trigger a network request to force start tracking.
     *
     * @param  {Object}   context
     * @param  {Function} context.commit
     * @param  {Object}   payload
     * @param  {Object}   payload.taskId
     * @return {Promise}
     */
    forceStartTracking({ commit }, { taskId } = {}) {
        return api.post(
            `/task-time-tracking/force-start`,
            { task_id: taskId }
        );
    },

    /**
     * Trigger a network request to retrieve user tracked events.
     *
     * @param  {Object}   context
     * @param  {Function} context.commit
     * @param  {Object}   payload
     * @param  {Object}   payload.taskId
     * @return {Promise}
     */
    requestTrackingEvents({ commit }, { username, startDate, endDate } = {}) {
        const escapedStart = encodeURIComponent(startDate);
        const escapedEnd = encodeURIComponent(endDate);

        return api.get(`/task-time-tracking/events/${username}/?start=${escapedStart}&end=${escapedEnd}` )
            .then(({ data }) => commit(TRACKING_SET_EVENTS, data));
    },

    /**
     * Update currently active task.
     *
     * @param  {Object}   context
     * @param  {Function} context.commit
     * @param  {Object}   payload
     * @param  {Object}   payload.taskId
     * @return {Promise}
     */
    updateCurrentlyActiveTask({ commit }, taskData = null) {
        commit(TRACKING_SET_CURRENTLY_ACTIVE_TASK, taskData);
    },

    /**
     * Update current task estimate.
     *
     * @param  {Object}   context
     * @param  {Function} context.commit
     * @param  {Object}   payload
     * @return {Promise}
     */
    updateCurrentTaskEstimate({ commit }, { estimate } = {}) {
        if (estimate === 0) {
            window.location.reload();
        }
        $('#time-estimate').trigger('update', estimate);
        commit(TRACKING_SET_CURRENT_TASK_ESTIMATE, estimate);
    }
};
