<template>
	<div class="add-task-related">
		<template v-if="activeTasks">
			<h4 v-if="!activeTasks.length">Няма свързани задачи.</h4>

			<h4 v-else>Добави addition към съществуваща задача:</h4>

			<ul>
				<li v-for="task in activeTasks">
					<a v-bind:href="`/${task.username}/task/${task.id}/?system=${source}&additional_quote_id=${quote.id}#task-addition`" v-bind:title="task.title" target="_blank">
						<img v-bind:src="`/avatars/${task.username}.jpg`" alt="">
						{{task.title}}
					</a>
				</li>
			</ul>
		</template>

		<template v-else>
			<i class="fa fa-spinner fa-spin"></i>
		</template>
	</div>
</template>

<script>

export default {
	name: 'related-tasks',

	computed: {
		project() {
			return this.$store.state.project;
		},

		quote() {
			return this.$store.state.quote;
		},

		source() {
            if (this.quote && this.quote.systems_project) {
                return this.quote.systems_project.source_system;
            }

            return null;
		},

		activeTasks() {
			if (this.project && this.project.related_tasks) {
				return this.project.related_tasks.filter(task => {
					const dateFinished = new Date(task.date_finished);

					const now = new Date();
					const monthCurrent = now.getMonth();
					const yearCurrent = now.getYear();
					const yearFinished = dateFinished.getYear();
					const monthFinished = dateFinished.getMonth();

					return !yearFinished || (yearCurrent === yearFinished && monthCurrent === monthFinished);
				});
			}

			return null;
		},
	}
}
</script>
