<template>
    <div class="entry-title">
        <span
            v-if="entry.is_main"
            class="ac-star"
            title="Главна инстанция"
        >
            <i class="fa fa-circle"></i>
        </span>
        
        <div class="entry-title__heading">
            <span class="entry-heading">{{ entry.title }}</span>
            <div class="entry-title__type">
                <span>{{ capitalziedProtocol }}</span>
                <span v-if="firstSibling">
                    shared with <a href="#" @click.prevent="handleClick">{{ firstSibling.title }}</a>
                </span>
                <Status :entry="firstSibling" v-if="entry.protocol === 'seat' && firstSibling" />
            </div>
        </div>

        <div class="entry-utils" ref="utils" v-if="!isUnsaved && showUtils">
            <button @click.prevent="toggleActive" class="btn">{{ active ? 'Cancel Edit' : 'Edit'  }}</button>
            <button @click="activeDropdown = !activeDropdown" class="utils-more" :class="{'is-clicked': activeDropdown}">
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div class="entry-utils__dropdown" :class="{'is-active': activeDropdown}">
                <a href="#" @click.prevent="handleDelete">Delete</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ACCESS_DEPOT_UNSAVED_ID } from '~/lib/constants';
import Status from 'components/access-depot/Status.vue';

export default {
    props: [
        'entry',
        'active'
    ],

    components: {
        Status
    },

    computed: {
        ...mapGetters({
			isLoading: 'accessDepot/getIsLoading',
            getEntry: 'accessDepot/getEntry',
		}),

        isUnsaved() {
            return this.entry.id === ACCESS_DEPOT_UNSAVED_ID;
        },
        
        showUtils() {
            if(window.user_is_admin || this.entry.protocol !== 'seat') {
                return true
            }
            return false;
        },

        firstSibling() {
            return this.entry.sibling_seats && this.entry.sibling_seats.length ? this.entry.sibling_seats[0] : false;
        },

        capitalziedProtocol() {
            const capitalized = this.entry.protocol.charAt(0).toUpperCase() + this.entry.protocol.slice(1)
            return capitalized;
        }
    },

    data() {
        return {
            activeDropdown: false,
        };
	},
    
    methods: {
        handleDelete() {
			if (this.isLoading(this.entry.id)) {
				return;
			}

			if (!window.confirm('Сигурен ли си?')) {
				return;
			}

            this.activeDropdown = false;

			if (this.entry.id === ACCESS_DEPOT_UNSAVED_ID) {
				this.$store.dispatch('accessDepot/deleteUnsavedCredentials');
			} else {
				this.$store.dispatch('accessDepot/deleteAccessCredentials', { id: this.entry.id });
			}
		},

        toggleActive() {
			const active = this.active;
            this.activeDropdown = false;
			this.$store.commit('accessDepot/ACCESS_DEPOT_SET_IS_ACTIVE', {
				id: this.entry.id,
				isActive: !active,
			});
            if(active) {
                this.$store.dispatch('accessDepot/resetValues', {entry: {
                    ...this.entry.initialValues,
                    isActive: !active
                }});
            }
		},

        handleKeyDown(event) {
            if (event.key === 'Escape') {
                this.activeDropdown = false;
            }
        },

        closeDropdownOnClickOutside(event) {
            if (this.activeDropdown && !this.$refs.utils.contains(event.target)) {
                this.activeDropdown = false;
            }
        },

        handleClick() {
            this.$emit('focus', this.firstSibling);
        }
    },

    mounted() {
        document.addEventListener('click', this.closeDropdownOnClickOutside);
        document.addEventListener('keydown', this.handleKeyDown);
    },

    destroyed() {
        document.removeEventListener('click', this.closeDropdownOnClickOutside);
        document.removeEventListener('keydown', this.handleKeyDown);
    },
}
</script>