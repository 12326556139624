<template>
	<div class="add-task">
		<tabs v-model="activeTab">
			<tab name="Проект" id="tab-project" v-bind:hidden="addingIssueTask">
				<div class="add-task-body">
					<add-project-form v-show="view.addProjectEnabled || project.project_id" v-bind:users="users" @success="handleAddProjectSuccess" />

					<div v-if="!view.addProjectEnabled && !project.project_id">
						<input type="text" name="project" class="field" autofocus v-bind:disabled="addingIssueTask" v-model="projectTitle" v-autocomplete="{ url: '/ajax/task-popup-search-projects', parse: parseProjectsItems, formatItem: formatProjectsItems, resultHandler: handleProjectResult }">

						<a href="#" class="btn btn--separated" @click.prevent="view.addProjectEnabled = true">
							<i class="fa fa-plus"></i> Добави проект
						</a>

						<a href="#" class="btn btn--separated" @click.prevent="handleWithoutProjectClick">
							Без проект <i class="fa fa-angle-right"></i>
						</a>
					</div>
				</div>
			</tab>

			<tab name="Плащания" id="tab-payments" v-bind:hidden="!project.project_id || addingIssueTask">
				<payments-form v-bind:clientId="project.client_id" v-bind:projectId="project.project_id" />
			</tab>

			<tab name="Задача" id="tab-task" v-bind:hidden="!project.project_id && !noProject">
				<add-task-form v-bind:users="users" v-bind:addingIssueTask="addingIssueTask" />
			</tab>
		</tabs>
	</div>
</template>

<script>
import autocomplete from '../directives/autocomplete';

import Tabs from '../components/Tabs.vue';
import Tab from '../components/Tab.vue';
import AddProjectForm from '../components/AddProjectForm.vue';
import AddTaskForm from '../components/AddTaskForm.vue';
import PaymentsForm from '../components/PaymentsForm.vue';

export default {
	name: 'add-task',

	components: {
		Tabs,
		Tab,
		AddProjectForm,
		AddTaskForm,
		PaymentsForm,
	},

	created () {
		// Set Project Initial ID
		this.project.project_id = $$taskData.taskDefaultId;

		if (this.addingIssueTask) {
			this.switchToTab('tab-task');
		}

		this.fetchQuoteDetails();
	},

	data() {
		return {
			users: $$taskData.users,
			projectTitle: $$taskData.projectDefaultTitle,
			addingIssueTask: $$taskData.addingIssueTask,

			view: {
				addProjectEnabled: false
			},

			noProject: false,
		};
	},

	computed: {
		quoteQuery() {
			return $$taskData.quoteQuery;
		},

		vcsResponse() {
			return this.$store.state.vcsResponse;
		},

		usersOptions() {
			return this.users.map(user => {
				return {
					label: user.user_real_name + ' - ' + user.username,
					value: user.id
				};
			});
		},

		activeTab: {
			get() {
				return this.$store.state.activeTab;
			},

			set(payload) {
				this.$store.dispatch('changeTab', payload);
			}
		},

		project: {
			get() {
				return this.$store.state.project;
			},

			set(payload) {
				this.$store.dispatch('changeProject', payload);
			}
		},

		quote: {
			get() {
				return this.$store.state.quote;
			},

			set(payload) {
				this.$store.dispatch('changeQuote', payload);
			}
		},

		hasProjectsFromQuote() {
			return false;
		}
	},

	methods: {
		formatClientAndProject(project) {
			if (project.client_name && project.project_title) {
				const namespace = project.client_name + (project.company ? '(' + project.company + ')' :  '' );

				return namespace + ' - ' + project.project_title;
			}

			if (typeof project.label === 'string') {
				return project.label;
			}

			return '';
		},

		formatProjectsItems(data) {
			return this.formatClientAndProject(data);
		},

		parseProjectsItems(response) {
			return response;
		},

		fetchQuoteDetails() {
			this.quote = $$taskData.quote;

			if (this.quote && this.quote.related_projects.length) {
				const lastRelatedProjectIndex = this.quote.related_projects.length - 1;

				this.project = this.quote.related_projects[lastRelatedProjectIndex];
			} else if (this.quote) {
				this.view.addProjectEnabled = true;
			}
		},

		handleProjectResult(event, data) {
			if ( data.value === 'new_project' ) {
				this.view.modalProject = true;
				return;
			}

			this.project = data;
		},

		switchToTab(tab) {
			this.$store.dispatch('changeTab', tab);
		},

		handleAddProjectSuccess() {
			this.view.addProjectEnabled = false;
		},

		handleWithoutProjectClick() {
			this.noProject = true;
			this.switchToTab('tab-task');
		},
	},

	directives: {
		autocomplete
	},

	watch: {
		project: {
			handler(newProject, oldProject) {
				if (this.project.project_id && oldProject.project_id !== newProject.project_id) {
					this.projectTitle = this.formatClientAndProject(this.project);
					this.switchToTab('tab-payments');

					this.$store.dispatch('fetchRelatedTasks', this.project.project_id);
					this.$store.dispatch('fetchPaymentsAmount', this.project.project_id);
				}
			},

			deep: true
		}
	}
}
</script>
