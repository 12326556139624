/**
 * External Dependencies.
 */
import axios from 'axios';

export default axios.create({
	baseURL: '/ajax',
});

export const CancelToken = axios.CancelToken;

export const createFormData = args => {
	const formData = new FormData();

	Object.keys(args)
		.forEach(key => {
			formData.append(key, args[key]);
		});

	return formData;
};