var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"sidebar-row"},[_c('h3',{staticClass:"row-header"},[_c('strong',[_vm._v("Current Directory:")]),_vm._v(" "),_c('span',{attrs:{"title":_vm.currentDirectory}},[_vm._v(_vm._s(_vm.trimDirectoryPath(_vm.currentDirectory)))])]),_vm._v(" "),_c('div',{staticClass:"row-body"},[_c('connection',{attrs:{"entry":_vm.entry,"droneLink":_vm.droneLinks.currentDirectory,"connectionString":_vm.connectionStrings.current,"visibility":{
					'shuttleLinkButton': false,
					'ftpExplorerButton': false,
				}}}),_vm._v(" "),_c('ul',{staticClass:"row-actions"},[_c('li',[_c('a',{attrs:{"href":_vm.getArchiveActionUrl(),"target":"_blank"}},[_c('i',{staticClass:"fa fa-file-zip-o"}),_vm._v("\n\t\t\t\t\t\tИзтегли архив\n\t\t\t\t\t")])])])],1)]),_vm._v(" "),(_vm.getWpRoot())?_c('div',{staticClass:"sidebar-row"},[_c('h3',{staticClass:"row-header"},[_c('strong',[_vm._v("WP Instance:")]),_vm._v(" "),_c('span',{attrs:{"title":_vm.getWpRoot()}},[_vm._v(_vm._s(_vm.trimDirectoryPath(_vm.getWpRoot())))])]),_vm._v(" "),_c('div',{staticClass:"row-body"},[_c('connection',{attrs:{"entry":_vm.entry,"droneLink":_vm.droneLinks.wpRootDirectory,"connectionString":_vm.connectionStrings.wp,"visibility":{
					'shuttleLinkButton': false,
					'ftpExplorerButton': false,
				}}}),_vm._v(" "),_c('ul',{staticClass:"row-actions"},[_c('li',[_c('a',{attrs:{"href":_vm.getDumpDbUrl(),"target":"_blank"}},[_c('i',{staticClass:"fa fa-archive"}),_vm._v("\n\t\t\t\t\t\tИзтегли база данни\n\t\t\t\t\t")])])])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }