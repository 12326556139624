export const ADD_NOTIFICATION = (state, payload) => {
	let notificationId = Date.now();

	state.notifications.push({
		id: notificationId,
		...payload
	});
};

export const EDIT_NOTIFICATION = (state, payload) => {
	state.notifications = state.notifications.map((notification) => {
		if (notification.id === payload.id) {
			notification = {
				...notification,
				...payload.data
			};
		}

		return notification;
	});
};

export const REMOVE_NOTIFICATION = (state, id) => {
	state.notifications = state.notifications.filter((notification) => {
		return notification.id !== id;
	});
}