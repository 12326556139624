<template>
	<div class="site-info-container" v-show="siteData.url">		
		<div class="site-info-preview">
			<div class="site-info-preview-inner">
				<a :href="siteData.url" target="_blank">{{ siteData.url }}</a>
	
				<div class="site-info-actions">
					<div class="dropdown" ref="dropdown">
						<button class="submit" @click="actionsOpen = !actionsOpen"><i class="fa fa-ellipsis-v"></i></button>
	
						<div class="dropdown-content" :class="actionsOpen ? 'visible' : ''">
							<button @click="$emit('archive'); actionsOpen = false" v-if="archiveButtonVisible">Package wp-content</button>
		
							<button @click="handleWpDebug" :disabled="wpDebugLoading">
								<i class="fa fa-spinner" v-if="wpDebugLoading"></i>
								{{ wpDebugButtonText }}
							</button>
		
							<button @click="handleAdminLogin" :disabled="adminLoginLoading">
								<i class="fa fa-spinner" v-if="adminLoginLoading"></i>
								{{ adminLoginButtonText }}
							</button>
	
							<button ref="dialogButton" @click="$refs.dialog.showModal(); actionsOpen = false"> Delete Site</button>
						</div>
					</div>
	
					<button class="submit" @click="$emit('install')" v-if="installButtonVisible">{{ installButtonText }}</button>
				</div>
			</div>
		</div>

		<div class="site-info-wrapper">
			<div>
                <SiteInfoField
                    v-for="field of fields"
                    v-bind="field"
                    :key="'field_' + field.label"
                />
			</div>

			<div>
				<EventsList ref="siteEvents"
					:initPolling="activeEventId !== 0"
					:events="siteData.events"
					:activeEvent="activeEventId"
					:siteId="siteData.id"
					:wpDepotId="wpDepotId"
					@statusChange="handleStatusChange"
					v-if="siteData.events"
				/>
			</div>
		</div>

		<div :class="`notice-container ${noticeVisible ? 'visible' : ''}`">
			<div>
				<div class="notice">
					{{ noticeText }}
				</div>
			</div>
		</div>

		<dialog class="delete-confirmation" ref="dialog" >
			<div class="dialog-content">
				<h2>Are you sure?</h2>

				<p>Please type the name of this site ({{ siteDomain }}) to confirm its deletion.</p>

				<input type="text" class="field" v-model="confirmationValue" @keyup.enter="handleDeleteSite">

				<div class="dialog-buttons">
					<button class="submit primary" :disabled="!validConfirmation" @click="handleDeleteSite">Confirm</button>
	
					<button class="submit" @click="$refs.dialog.close()">Cancel</button>
				</div>
			</div>
		</dialog>
	</div>
</template>

<script>
import EventsList from '~/components/wp-depot/EventsList.vue';
import SiteInfoField from '~/components/wp-depot/SiteInfoField.vue';

export default {
	components: { EventsList, SiteInfoField },
	props: ['siteUrl', 'initialSiteData', 'activeEventId', 'wpDepotId'],
	emits: ['install', 'archive', 'delete'],
	data() {
		return {
			siteData: {},
			adminLoginLink: null,
			wpDebugLoading: false,
			adminLoginLoading: false,
			noticeText: '',
			noticeVisible: false,
			actionsOpen: false,
			confirmationValue: ''
		}
	},
	created() {
		window.addEventListener('click', (e) => {
			if (!this.$refs.dropdown.contains(e.target)){
				this.actionsOpen = false
			}

			if (!this.$refs.dialog.firstElementChild.contains(e.target) && !this.$refs.dialogButton.contains(e.target)) {
				this.$refs.dialog.close()
			}
		})
	},
	mounted() {
		if ( ! this.initialSiteData ) {
			fetch(this.siteUrl).then(r => r.json()).then(result => {
				this.siteData = result.site.data;
			})
		}
		else {
			this.siteData = this.initialSiteData;
		}
	},
	watch: {
		activeEventId(newValue, oldValue) {
			if ( newValue !== 0 ) {
				this.$refs.siteEvents.handleViewLogs(newValue);
			}
		},
		initialSiteData(newValue, oldValue) {
			this.siteData = newValue;
		},
		noticeText(newValue, oldValue) {
			this.noticeVisible = true;

			setTimeout(() => {
				this.noticeVisible = false;
			}, 10000);
		}
	},
	computed: {
		siteDomain() {
			const url = this.siteData.url ?? '';

			if ( ! url ) return '';
			return url.replaceAll(/https\:\/\/|\/$/g, '');
		},

        serverDefaultDomain() {
            return this.siteData?.url.replace(/^https?:\/\/[\w\-]*\.([^.]+\.\w+)\/?$/, '$1');
        },

		validConfirmation() {
			if ( ! this.siteDomain ) return false;
			return this.confirmationValue === this.siteDomain;
		},

        installButtonVisible() {
            return ! this.siteData?.events?.some(event => event.type === 'install' && event.status !== 'failed');
        },

        installButtonText() {
            return this.siteData?.events?.some(event => event.type === 'install') ? "Retry Install" : "Install";
        },

		archiveButtonVisible() {
			return ! this.siteData?.events?.some(event => event.type === 'archive' && ['in-progress', 'pending'].includes(event.status));
		},

		adminLoginButtonText() {
			if ( this.adminLoginLink ) {
				return 'Login as Admin';
			}
			
			if ( this.adminLoginLoading ) {
				return 'Generating...';
			}

			return 'Generate Admin Login';
		},

		wpDebugButtonText() {
			if ( this.wpDebugLoading ) {
				return 'Toggling...';
			}

			return 'Toggle WP Debug';
		},

		exportDatabaseCommand() {
			if ( !Object.hasOwn(this.siteData, 'url') ) {
				return null;
            }
            function createRandomString(length) {
                const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                let result = "";
                const randomArray = new Uint8Array(length);
                crypto.getRandomValues(randomArray);
                randomArray.forEach((number) => {
                    result += chars[number % chars.length];
                });
                return result;
            }


			const { ssh_user, url, host, port, document_root, ssh_host, domain } = this.siteData;
			const timestamp = Math.floor(Date.now() / 1000);
            const salt = createRandomString(12);
            const filename = `dump-${timestamp}-${salt}.sql.gz`;
			
            return `ssh ${ssh_user}@${ssh_host} 'cd ${document_root} && wp db export - | gzip | tee ${filename}' > ${filename} && echo "Remote Database dumped to ${filename}"`;
		},

		sshConnectionString() {
			if ( !Object.hasOwn(this.siteData, 'url') ) {
				return null;
			}

			const { ssh_user, url, host, port, document_root, ssh_host, domain } = this.siteData;
			
			return `ssh -t ${ssh_user}@${ssh_host} 'cd ${document_root}; /bin/bash -i'`;
		},
		connectionString() {
			if ( Object.hasOwn(this.siteData, 'url') ) {
                                const host = this.serverDefaultDomain;
				return getFilezillaConnectionString({
					protocol: 'sftp',
					username: this.siteData.ssh_user,
					password: this.siteData.ssh_password,
					host,
					port: 22,
					directory: this.siteData.document_root
				});
			}

			return '#';
		},
		fields() {
			if ( Object.hasOwn(this.siteData, 'url') ) {
				return [
					{ type: 'ftp', label: 'FileZilla Connection', value: this.connectionString },
                    { type: 'text', label: 'SSH Connection', value: this.sshConnectionString },
					{ type: 'text', label: 'Export dump command', value: this.exportDatabaseCommand },
                    
                    { type: 'list', label: 'SFTP / SSH', value: [
                        { label: "Host", value: this.siteData?.ssh_host },
                        { label: "User", value: this.siteData?.ssh_user },
                        { label: "Password", value: this.siteData?.ssh_password },
                    ] },

                    { type: 'list', label: 'Database', value: [
                        { label: "PhpMyAdmin", value: 'https://phpmyadmin.' + this.serverDefaultDomain },
                        { label: "DB User", value: this.siteData?.database?.user },
                        { label: "DB Password", value: this.siteData?.database?.password },
                        { label: "DB Name", value: this.siteData?.database?.name},
                    ]},


				]
			}
			return []
		}
	},
	methods: {
		handleCopy(password, type) {
			if(type === 'connection') {
				const result = getFilezillaConnectionString({
					...this.entry,
					password,
				})
				return this.$copyText(result)
			}

			return this.$copyText(password)
		},
		handleStatusChange(newStatus, siteData) {
			this.siteData = siteData
		},
		handleAdminLogin() {
			if ( this.adminLoginLink ) {
				window.open(this.adminLoginLink, '_blank');

				this.adminLoginLink = null;

				return;
			}

			this.adminLoginLoading = true;
            fetch(`/ajax/task/${window.TASK_ID}/wp-depot/${this.wpDepotId}/site/${this.siteData.id}/admin-login`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(result => {
				this.adminLoginLoading = false;
				if ( new Date(result.expires).valueOf() <= Date.now() ) return;

				this.adminLoginLink = result['login-link'];
            })
            .catch(error => {
				this.adminLoginLoading = false;
                console.error(error);
                alert("something is wrong! See console. ");
            })
		},
		handleWpDebug() {
			this.wpDebugLoading = true;
            fetch(`/ajax/task/${window.TASK_ID}/wp-depot/${this.wpDepotId}/site/${this.siteData.id}/wp-debug`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(result => {
				this.wpDebugLoading = false;
				this.noticeText = result.message;
            })
            .catch(error => {
				this.wpDebugLoading = false;
                console.error(error);
                alert("something is wrong! See console. ");
            })
		},
		handleDeleteSite() {
			this.$refs.dialog.close(); 
			this.$emit('delete');
		}
	}
}
</script>

<style scoped>
	.site-info-wrapper {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.site-info-preview {
		padding-inline: 20px;
		padding-block: 4px;
		text-align: center;
		display: flex;
		margin-bottom: 12px;
		justify-content: center;

		& .site-info-preview-inner {
			position: relative;
			width: fit-content;
		}

		& a {
			text-decoration: none;
			border: 1px solid #CCC;
			padding: 6px 10px;
			border-radius: 999px;
			display: block;

			@media (hover: hover) and (pointer: fine) {
				&:hover {
					background-color: #EEE;
				}
			}
		}
	}

	.site-info-delete {
		margin-top: 12px;
		display: flex;
		justify-content: flex-end;

		& button {
			transition: color 250ms ease-out, border-color 250ms ease-out;

			@media (hover: hover) and (pointer: fine) {
				&:hover {
					color: #FF3333;
					border-color: #FF3333;
				}
			}
		}
	}

	& dialog {
		margin: auto;
		border: 0;
		border-radius: 6px;

		&::backdrop {
			background: rgba(0,0,0,.5);
		}

		.dialog-content {
			border: 0;
			background: #EFEFEF;
			padding: 20px;
			max-width: 320px;
			text-align: center;
			display: flex;
			flex-direction: column;
			gap: 12px;
		}

		& input[type="text"] {
			background: #FFF;
			padding-inline: 8px;
		}

		.dialog-buttons {
			display: flex;
			justify-content: flex-end;
			gap: 8px;
			margin-top: 8px;

			.primary {
				background: color-mix(in srgb, #FF3333 90%, transparent);
				color: #FFF;
				transition: background-color 250ms ease-out;

				&[disabled] {
					background: color-mix(in srgb, #FF3333 60%, transparent);
				}

				@media (hover: hover) and (pointer: fine) {
					&:not([disabled]):hover {
						background: color-mix(in srgb, #FF3333 100%, transparent);
					}
				}
			}
		}
	}

	.site-info-actions {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 100%;
		margin-left: 8px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;

		&:not(:has(> button)):not(:has(> .dropdown)) {
			padding-bottom: 26px;
		}
	}

	.dropdown {
		position: relative;

        & > button {
            border: 1px solid #CCC;
            background-color: #fff;
			border-radius: 999px;
			padding-inline: 15px;
			padding-block: 9px;
			font-size: 13px;
			line-height: 1.538;

			@media (hover: hover) and (pointer: fine) {
				&:hover {
					border-color: #CCC;
					background-color: #EEE;
				}
			}
        }

		& > button > i {
			margin: 0;
		}
	}

	.dropdown-content {
		position: absolute;
		top: 100%;
		left: 50%;
		translate: -50% 0;
		margin-top: 6px;
		width: max-content;
		visibility: hidden;
		opacity: 0;
		transition: opacity 250ms ease-out, visibility 250ms;
		background: #f5f5f5;
		z-index: 10;
		border-radius: 6px;
		border: 1px solid #b2b2b2;
		overflow: hidden;

		&.visible {
			visibility: visible;
			opacity: 1;
			transition: opacity 250ms ease-out, visibility 0ms;
		}

		& button {
			display: block;
			width: 100%;
			padding: 8px 12px;
			border: none;
			background: none;
			cursor: pointer;
			width: 100%;
			text-align: right;
			position: relative;
			transition: background-color 250ms ease-out;

			&:hover {
				background-color: #EEE;
			}

			&:not(:last-child) {
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: 100%;
					left: 10px;
					right: 10px;
					height: 1px;
					background: rgba(178,178,178,0.5);
					z-index: 2;
				}
			}
		}
	}

	.submit {
		appearance: none;
		border: 1px solid #b2b2b2;
		border-radius: 4px;
		text-align: center;
		font-size: 12px;
		font-weight: 700;
		background: #f5f5f5;
		display: inline-block;
		vertical-align: top;
		padding: 4px 7px;

		&:not(:disabled) {
			cursor: pointer;
		}

		@media (hover: hover) and (pointer: fine) {
			&:not(:disabled):hover {
				border: 1px solid #888;
			}
		}

		& i {
			margin-left: 4px;
		}
	}

	& i.fa-spinner {
		animation: spin 1000ms infinite linear;
	}

	.notice-container {
		display: grid;
		grid-template-rows: 0fr;
		overflow: hidden;
		transition: grid-template-rows 400ms ease-out;

		& > div {
			min-height: 0;
		}
		
		& .notice {
			margin-bottom: 0;
		}

		&.visible {
			grid-template-rows: 1fr;
		}
	}

	@keyframes spin {
		0% {
			rotate: 0deg;
		}

		100% {
			rotate: 360deg;
		}
	}
</style>
