export const getNoteById = state => id => state.notes.find(note => note.id === id);

export const getNotes = state => state.notes;

export const getCanEditVisibility = state => state.can_change_note_visibility;

export const getCanMakeStickyNotes = state => state.can_make_sticky_client_notes;

export const getIsLoading = state => state.isLoading;

export const getClientId = state => state.client_id;

export const getProjectId = state => state.project_id;
