<template>
	<transition name="modal" @enter="handleModalEnter" @after-leave="handleModalLeave">
		<div class="modal">
			<div class="modal-wrapper" @click="handleModalClose">
				<div class="modal-container">
					<div class="modal-inner" :style="{ maxWidth: width }" @click.stop>
						<div class="modal-head">
							<div class="modal-title" @click.prevent="handleTitleClick" @keyup="handleTitleKeyUp">
								<slot name="title"></slot>
							</div>

							<a href="#" class="modal-close" @click="handleModalClose">
								<i class="fa fa-times"></i>
							</a>
						</div>

						<div class="modal-body">
							<slot></slot>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'modal',

	props: {
		title: {
			default: ''
		},

		width: {
			default: '800px'
		},
	},

	mounted() {
		window.addEventListener('keydown', this.handleEscapeKey);
	},

	destroyed() {
		window.removeEventListener('keydown', this.handleEscapeKey);
	},

	methods: {
		handleModalEnter() {
			$('body').addClass('has-visible-modal');
		},

		handleModalLeave() {
			$('body').toggleClass('has-visible-modal', $('.modal').is(':visible'));
		},

		handleModalClose(event) {
			event.preventDefault();
			this.$emit('close');
		},

		handleEscapeKey(event) {
			if (event.keyCode === 27) {
				this.$emit('close');
			}
		},

		handleTitleClick(event) {
			this.$emit('titleClick', event);
		},

		handleTitleKeyUp(event) {
			this.$emit('titleKeyUp', event);
		}
	}
}
</script>

<style>
	.has-visible-modal { overflow: hidden; position: fixed; width: 100%; height: auto; }
	.has-visible-modal #cnt { z-index: auto; }
</style>

<style scoped>
	.modal { position: fixed; z-index: 1000; left: 0; top: 0; width: 100%; height: 100%; padding: 10px; overflow-y: auto; box-sizing: border-box; background: rgba(255,255,255,.5); transition: all .2s ease-out; }

	.modal-wrapper { display: table; width: 100%; height: 100%; }
	.modal-container { display: table-cell; vertical-align: top; }
	.modal-inner { position: relative; max-width: 100%; margin: 0 auto; background: #fff; border-radius: 4px; box-shadow: 0 4px 12px 0 rgba(0,0,0,.2); transition: all .2s ease-out; pointer-events: all; }

	.modal-head { position: relative; padding: 12px; border-bottom: 1px solid #eee; }
	.modal-title { position: relative; color: #555; padding-right: 36px; }

	.modal-close { position: absolute; right: 0; top: 50%; width: 36px; height: 36px; margin-top: -18px; text-align: center; color: #555; font-size: 14px; }
	.modal-close i { vertical-align: middle; line-height: 36px; }

	.modal-body { padding: 12px; }
	.modal-actions { padding: 12px; border-top: 1px solid #eee; }

	.modal-enter,
	.modal-leave-active { opacity: 0; }

	.modal-enter .modal-inner,
	.modal-leave-active .modal-inner { transform: scale(1.05); }
</style>
