// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.error[data-v-85bea3aa] { margin-bottom: 20px; background-color: #f2dede; border-color: #ebccd1; color: #a94442;
}
.button-primary[data-v-85bea3aa] { margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./js/components/company-email/create.vue"],"names":[],"mappings":";AAuCA,0BAAA,mBAAA,EAAA,yBAAA,EAAA,qBAAA,EAAA,cAAA;AAAA;AACA,mCAAA,gBAAA;AAAA","sourcesContent":["<template>\n    <div>\n        <div class=\"alert error\" v-if=\"error && error.length\">{{ error }}</div>\n        <div class=\"cl\"></div>\n        Тук може да се създаде email кутия за компанията на този клиент. Тя може да се използва за регистриране на акаунти във външни услуги като GitHub, DigitalOcean, AWS, и подобни. \n\n        <div v-if=\"isAdmin\" >\n            <input type=\"button\" class=\"button button-primary\" @click.prevent=\"createEmail\" value=\"Създай\">\n        </div>\n        <div v-else>\n            Ако имаш нужда от такава кутия, помоли мениджъра ти да я направи.\n        </div>\n    </div>\n</template>\n\n<script>\n/**\n * External dependencies:\n */\nimport { mapGetters, mapActions } from 'vuex';\n\nexport default {\n    computed: {\n        ...mapGetters({\n            taskId: 'companyEmail/getTaskId',\n            isAdmin: 'companyEmail/getIsAdmin',\n            error: 'companyEmail/getError',\n        }),\n    },\n\n    methods: {\n        ...mapActions({\n            createEmail: 'companyEmail/createEmail',\n        }),\n    },\n}\n</script>\n\n<style scoped>\n.error { margin-bottom: 20px; background-color: #f2dede; border-color: #ebccd1; color: #a94442; }\n.button-primary { margin-top: 20px;  }\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
