<template>
	<div class="checklist__item" :title="canEdit && 'Click to edit'" @click="handleEditInit">
		<template v-if="checklistItem">
			<label class="checklist__item-check" @click.stop>
				<i v-if="checklistItem.state === 'included'" class="fa fa-check" title="Included"></i>
				<i v-else-if="checklistItem.state === 'canceled'" class="fa fa-times" title="Canceled"></i>
				<input v-else type="checkbox" :checked="checklistItem.state === 'checked'" :disabled="!canCheck" @change="handleChecklistItemChange">
			</label>

			<span v-if="!editing" :class="['checklist__item-label', { 'is-disabled': checklistItem.state === 'canceled' }]">
				{{checklistItem.title}}

				<template v-if="checklistItem.estimate > 0">
					({{checklistItem.getEstimateAsFloat()}})
				</template>
			</span>

			<div v-if="editing" class="checklist__item-edit">
				<input
					type="text"
					class="form-field"
					ref="fieldInput"
					v-model="checklistItemEdit.title"
					@keydown.enter="handleEditSave"
					@keydown.esc="handleEditCancel"
					@blur="handleEditCancel"
				>

				<button class="btn" @mousedown.prevent="handleEditSave">
					<i class="fa fa-save"></i> Save
				</button>

				<button class="btn" @mousedown.prevent="handleDelete">
					<i class="fa fa-trash"></i> Delete
				</button>
			</div>
		</template>
	</div>
</template>

<script>
import ChecklistEntry from '../models/ChecklistEntry';

export default {
	name: 'checklist-item',

	model: {
		prop: 'data',
		event: 'update',
	},

	props: {
		data: {
			required: true,
		},

		dataKey: {
			requried: true,
		},

		canEdit: {
			type: Boolean,
		},
	},

	data() {
		return {
			checklistItem:     null,
			checklistItemEdit: null,

			editing:           false,
		};
	},

	computed: {
		/**
		 * Whether current task is own task.
		 *
		 * @return {Boolean}
		 */
		isOwnTask() {
			return (
				window.task_user_id &&
				window.USER_ID &&
				window.USER_ID === window.task_user_id
			);
		},

		/**
		 * Whether user can check items.
		 *
		 * @return {Boolean}
		 */
		canCheck() {
			return this.canEdit || this.isOwnTask;
		}
	},

	methods: {
		handleChecklistItemChange() {
			const itemData = this.checklistItem.toggleState();

			this.$emit('log', {
				type: `item_${this.checklistItem.state}`,
				description: this.checklistItem.title,
				ideal_coding_time: this.checklistItem.estimate
			});

			this.$emit('update', itemData);
		},

		handleEditInit() {
			if (!this.canEdit) {
				return;
			}

			this.checklistItemEdit = new ChecklistEntry(this.data);
			this.editing = true;
		},


		handleEditCancel() {
			setTimeout(() => {
				this.editing = false;
			}, 10);
		},

		handleEditSave() {
			const newData = this.checklistItemEdit.toString();

			this.checklistItem = new ChecklistEntry(newData);
			this.$emit('update', newData);

			this.editing = false;
		},

		handleDelete() {
			this.$emit('log', {
				type: 'item_delete',
				description: this.checklistItem.title,
				ideal_coding_time: this.checklistItem.estimate
			});

			this.$emit('delete', this.dataKey);
		},
	},

	mounted() {
		this.checklistItem = new ChecklistEntry(this.data);
	},

	watch: {
		editing() {
			if (this.editing) {
				setTimeout(() => {
					this.$refs.fieldInput.select();
				}, 10);
			}
		},
	},
}
</script>
