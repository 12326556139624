<template>
	<div class="note-actions">
		<a href="#" class="note-actions__dropdown" @click.prevent="onDropdownClick">&nbsp;</a>

		<ul class="action-list" v-if="showDropdown">
			<li>
				<a href="#" class="edit" @click.prevent="onEdit">Редактирай</a>
			</li>

			<li>
				<a href="#" class="delete" @click.prevent="onDelete">Изтрий</a>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showDropdown: false,
		};
	},

	methods: {
		onDropdownClick() {
			this.showDropdown = true;
		},

		onBodyClick(event) {
			const $eventActions = event.target.closest('.note-actions');
			if (this.$el !== $eventActions) {
				this.showDropdown = false;
			}
		},

		onEdit() {
			this.$emit('edit');
			this.showDropdown = false;
		},

		onDelete() {
			this.$emit('delete');
			this.showDropdown = false;
		}
	},

	mounted() {
		document.body.addEventListener('click', this.onBodyClick);
	},

	destroyed() {
		document.body.removeEventListener('click', this.onBodyClick);
	},
}
</script>
