<template>
    <div class="entry-totp">
        <span class="entry-value entry-value--larger">{{ currentTotp }}</span>
        
        <div class="entry-totp-loader">
            <svg class="circle-loader" width="15" height="15" viewBox="0 0 100 100">
                <circle
                    class="circle"
                    cx="50"
                    cy="50"
                    :r="this.radius"
                    fill="none"
                    stroke="#C7C7C7"
                    stroke-width="22"
                ></circle>
                <circle
                    class="circle-overlay"
                    cx="50"
                    cy="50"
                    :r="this.radius"
                    fill="none"
                    stroke="#0075FF"
                    stroke-width="22"
                    :style="offsetStyleCalculation"
                ></circle>
            </svg>
            
            <small>{{ nextTotpEpoch }}</small>
        </div>
		<Copy :value="currentTotp" />
    </div>
</template>

<script>
import Copy from './Copy.vue';

export default {
    props: [
        'entry'
    ],

    data() {
        return {
			currentTime: this.getNow(),
			reloadTimeout: null,
			tickerTimeout: null,
            radius: 38,
		}
    },

	components: {
		Copy,
	},

    computed: {
        currentTotp() {
			if ( ! this.entry.totp_secret ) {
				return;
			}

			let code = '??????';
			let firstValidTotpIndex = -1;

			if ( this.entry.totps ) {
				firstValidTotpIndex = this.entry.totps.findIndex( totp => parseInt(totp.validity / 30) == this.currentTotpEpoch );
			}
			
			if (firstValidTotpIndex > -1) {
				code = this.entry.totps[firstValidTotpIndex].totp;
			}

			if (firstValidTotpIndex === -1 || firstValidTotpIndex === this.entry.totps.length - 1) {
				// we should load more totps
				this.loadTotps(this.entry);
			}
			
			return code;
		},

		currentTotpEpoch() {
			return parseInt(this.currentTime / 30);
		},

		nextTotpEpoch() {
			const nextTotpEpoch = this.currentTotpEpoch + 1;
			const nextTotpEpochTimestamp = nextTotpEpoch * 30;

			return nextTotpEpochTimestamp - this.currentTime;
		},

        circumference() {
            return 2 * Math.PI * this.radius;
        },

        offsetStyleCalculation() {
            const offset = this.circumference - (this.nextTotpEpoch / 30) * this.circumference;

            return {
                strokeDasharray: this.circumference,
                strokeDashoffset: offset
            };
        }
    },

    methods: {
		getNow() {
			return parseInt(Date.now() / 1000);
		},
		
		setCurrentTime() {
			requestAnimationFrame(() => {
				clearTimeout(this.tickerTimeout);
	
				this.currentTime = this.getNow();
	
				this.tickerTimeout = setTimeout(() => {
					this.setCurrentTime();
				}, 1000);
			});
		},

		loadTotps(payload) {
			this.$store.dispatch('accessDepot/loadTotps', payload)
				.catch(err => {
					clearTimeout(this.reloadTimeout);

					this.reloadTimeout = setTimeout(() => {
						this.loadTotps(payload);
					}, 5000);
				});
		}
	},

	mounted() {
		this.setCurrentTime();
	},

	beforeDestroy() {
		clearTimeout(this.tickerTimeout);
	},

    watch: {
		'entry.id'() {
			this.loadTotps(this.entry);
		},

		'entry.isLoading'(newValue) {
			if (!newValue) {
				this.loadTotps(this.entry);
			}
		}
	}
}
</script>