<template>
	<div class="monitor-filters">
		<div class="monitor-filters-section">
			<ul class="form-radio-group">
				<li>
					<div class="form-radio">
						<input type="radio" name="finished" id="finished-n" value="n" :checked="milestonesFilters.finished === 'n'" @change="handleInputFilterChange">

						<label for="finished-n">Активни</label>
					</div>
				</li>

				<li>
					<div class="form-radio">
						<input type="radio" name="finished" id="finished-y" value="y" :checked="milestonesFilters.finished === 'y'" @change="handleInputFilterChange">

						<label for="finished-y">Приключени</label>
					</div>
				</li>
			</ul>
		</div>

		<div class="monitor-filters-section">
			<div class="form-users-select">
				<users-multiselect
					v-if="isManagerView"
					name="manager_id"
					:users="admins"
					:value="milestonesFilters.manager_id"
					@input="handleSelectFilterChange" />

				<span v-else>
					<strong style="line-height: 32px">QA View</strong>
				</span>
			</div>
		</div>

		<div class="monitor-filters-section">
			<div class="monitor-search">
				<form @submit.prevent="handleSearchSubmit">
					<div class="form-controls">
						<input type="text" class="form-field" name="project_id" ref="searchField" v-model="searchQuery" v-autocomplete="{ url: '/ajax/task-popup-search-projects', formatItem: formatProjectsItems, resultHandler: handleProjectResult }" placeholder="Търсене">

						<a href="#" v-if="hasSearchQuery" class="form-reset" @click.prevent="resetFilters">
							<i class="fa fa-times"></i>
						</a>
					</div>

					<button type="submit" class="btn">
						<i class="fa fa-search"></i>
					</button>
				</form>
			</div>
		</div>

		<a href="#" class="monitor-trigger-new-modal btn" @click="handleNewMilestoneTrigger">
			<i class="fa fa-plus"></i> Нов Milestone
		</a>
	</div>
</template>

<script>
import _ from 'lodash';
import autocomplete from '../directives/autocomplete';
import UsersDropdown from '../components/UsersDropdown.vue';
import UsersMultiselect from '../components/UsersMultiselect.vue';

export default {
	name: 'monitor-milestones-filters',

	components: {
		UsersDropdown,
		UsersMultiselect,
	},

	data() {
		return {
			searchQuery: $$monitorData.filters.search_query,
			projectId: null
		};
	},

	computed: {
		milestonesFilters() {
			return this.$store.state.milestonesFilters;
		},

		admins() {
			return this.mapUsersArray($$monitorData.admins);
		},

		departments() {
			return $$monitorData.departments;
		},

		hasSearchQuery() {
			return this.$store.getters.hasSearchQuery;
		},

		isManagerView() {
			return !this.$store.state.isTesterView;
		}
	},

	created() {
		this.initFilters();
	},

	mounted() {
		this.bindKeys();
	},

	directives: {
		autocomplete,
	},

	methods: {
		initFilters() {
			const defaultFilters = $$monitorData.filters;

			// Set default value for finished parameter if one is not provided and default parameters are not for searchquery
			if (!defaultFilters.finished && !defaultFilters.project_id && !defaultFilters.search_query) {
				defaultFilters.finished = 'n';
			}

			if (defaultFilters && typeof defaultFilters.manager_id === 'string') {
				defaultFilters.manager_id = defaultFilters.manager_id.split(',');
			}

			this.$store.commit('CHANGE_FILTERS', defaultFilters);
		},

		bindKeys() {
			$(document).on('keypress', event => {
				switch(event.keyCode) {
					case 47: // Key '/'
						this.focusSearch(event);
						break;
				}
			});
		},

		focusSearch(event) {
			const $target = $(event.target);

			if ($target.is('input, textarea, select')) {
				return;
			}

			const $searchField = $(this.$refs.searchField);

			if ($searchField) {
				$searchField.focus().select();
				return;
			}
		},

		resetFilters() {
			const finished = $$monitorData.filters.finished || 'n';
			const manager_id = $$monitorData.managerId;

			const defaultFilters = {
				finished,
				manager_id,
			};

			this.searchQuery = null;

			this.$store.commit('RESET_FILTERS');
			this.$store.commit('CHANGE_FILTERS', defaultFilters);

			this.pushHistoryState();
			this.$store.dispatch('fetchMilestones');
		},

		handleInputFilterChange(event) {
			const property = event.target.name;
			const value = event.target.value;

			this.changeFilterProperty(property, value, true);
			this.pushHistoryState();
		},

		handleSelectFilterChange({ name, value }) {
			const property = name;

			this.changeFilterProperty(property, value, true);
			this.pushHistoryState();
		},

		handleProjectResult(event, data) {
			this.searchQuery = this.formatProjectsItems(data);

			setTimeout(() => {
				this.projectId = data.project_id;
				this.clearFilters();
				this.changeFilterProperty('search_query', null);
				this.changeFilterProperty('project_id', this.projectId, true);

				this.pushHistoryState();
			}, 10);
		},

		handleSearchSubmit(event) {
			this.changeFilterProperty('project_id', this.projectId);
			this.clearFilters();

			if (this.projectId) {
				this.changeFilterProperty('search_query', null, true);
			} else {
				this.changeFilterProperty('search_query', this.searchQuery, true);
			}

			this.pushHistoryState();
		},

		handleSearchReset(event) {
			this.projectId = null;
			this.searchQuery = '';

			this.changeFilterProperty('project_id', null);
			this.pushHistoryState();
		},

		formatProjectsItems(data) {
			return `${data.client_name} -- ${data.project_title}`;
		},

		mapUsersArray(usersArray) {
			const usersData = usersArray;
			const users = usersData.map(user => Object.assign(user, { user_real_name: user.name }));

			// users.unshift({ id: 'all', user_real_name: 'Всички' });
			return users;
		},

		pushHistoryState() {
			const filterEntries = _.toPairs(this.milestonesFilters);
			const queryString = filterEntries
				.filter( pair => !!pair[1] && pair[1] !== 'all' )
				.map( pair => (pair.join('=')) )
				.join('&');

			history.pushState({}, null, `${location.pathname}?${queryString}`);
		},

		changeFilterProperty(property, value, fetchNow = false) {
			this.$store.commit('CHANGE_FILTER_PROPERTY', { property, value });

			if (fetchNow) {
				this.$store.dispatch('fetchMilestones');
			}
		},

		clearFilters() {
			this.changeFilterProperty('finished', null);
			this.changeFilterProperty('manager_id', null);
		},

		handleNewMilestoneTrigger() {
			this.$store.commit('TOGGLE_MILESTONES_NEW_MODAL', true);
		}
	},

	watch: {
		searchQuery(newVal, oldVal) {
			if (this.projectId) {
				this.projectId = null;
			}
		}
	}
}
</script>
