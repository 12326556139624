/**
 * External dependencies:
 */
import ajax from 'services/ajax';
import { get } from 'lodash';

export const createEmail = ({
    commit,
    getters: {
        getLoading,
        getTaskId,
    },
}) => {
    if (getLoading) {
        return;
    }

    commit('setLoading', true);
    commit('setError', '');

    ajax.post('/create-task-email', {
        task_id: getTaskId,
    })
        .then(response => {
            const success = get(response, 'data.success', false);
            if (!success) {
                const message = get(
                    response,
                    'data.message',
                    'There was an error while performing the request.'
                );
                return commit('setError', message);
            }

            const details = get(response, 'data.emailDetails', null);
            commit('setEmailDetails', details);
        })
        .catch(error => {
            commit('setError', error.toString());
        })
        .finally(() => {
            commit('setLoading', false);
        });
};
