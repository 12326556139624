<template>
	<div>
		<div v-bind:class="[
			'checkboxes',
			'form-tags',
			{'form-tags-expanded': expanded || !expandable},
		]">
			<div class="checkbox form-tag" v-for="tag in tags">
				<label>
					<input v-model="value" type="checkbox" name="tag_id[]" :value="tag.id" :required="isRequired">
					<span>{{ tag.name }}</span>
				</label>
			</div>
		</div>

		<a href="#" class="button form-tags-expand" v-if="expandable && !expanded" @click.prevent="expanded = true">
			<i class="fa fa-chevron-right"></i> Покажи всички
		</a>
	</div>
</template>

<script>

export default {
	name: 'project-tags',

	props: {
		tags: {
			type: Array,
			required: true,
		},
		required: {
			type: Boolean,
			default: false,
		}
	},

	data() {
		return {
			value: [],
			expanded: false,
			hasTagSelected: false,
		};
	},

	computed: {
		expandable() {
			return this.tags.length > 12;
		},

		isRequired() {
			return this.required && this.value.length === 0;
		},
	},
}
</script>
