<template>
	<modal v-show="visible" width="500px" @close="$emit('close')">
		<h3 slot="title">Добави нов Milestone</h3>

		<div class="form-new-milestone">
			<form action="?" method="post" @submit.prevent="handleFormSubmit">
				<div class="form-body">
					<div class="form-controls">
						<label for="milestone-project">Проект:</label>

						<input type="text" name="project" class="form-field" v-model="projectSearch" v-autocomplete="{ url: '/ajax/task-popup-search-projects', formatItem: formatProjectsItems, resultHandler: handleProjectResult }" required>
					</div>

					<div class="form-controls">
						<label for="milestone-project">Заглавие:</label>

						<input type="text" name="milestone_name" class="form-field" v-model="milestoneName">
					</div>

					<div class="form-controls">
						<label for="milestone-project">Краен срок:</label>

						<datepicker name="dead_line" class="form-field" dateFormat="yy-mm-dd" v-model="deadline" required/>
					</div>

					<button type="submit" value="Submit" class="form-btn btn">Добави</button>
				</div><!-- /.form-body -->
			</form>

			<div class="form-loading" v-if="formState === 'loading'">
				<i class="fa fa-spinner fa-spin"></i>
			</div><!-- /.form-loading -->
		</div><!-- /.form-new-milestone -->
	</modal>
</template>

<script>
import autocomplete from '../directives/autocomplete';

import Datepicker from '../components/Datepicker.vue';
import Modal from '../components/Modal.vue';

export default {
	name: 'monitor-new-milestone',

	components: {
		Modal,
		Datepicker,
	},

	props: {
		visible: {
			default: false
		},
	},

	data() {
		return {
			formState: 'initial',
			projectId: null,
			projectSearch: '',
			milestoneName: '',
			deadline: null,
		};
	},

	directives: {
		autocomplete,
	},

	methods: {
		handleFormSubmit() {
			if (!this.projectId) {
				this.projectSearch = '';
				return;
			}

			const data = {
				project_id: this.projectId,
				milestone_name: this.milestoneName,
				dead_line: this.deadline,
			};

			this.formState = 'loading';

			return $.ajax({
				url: '/monitor/add/milestone',
				type: 'POST',
				data,

				success: (res) => {
					this.resetForm();
					this.$store.dispatch('fetchMilestones', { silent: true });
					this.$emit('close');
				},

				complete: (res) => {
					this.formState = 'loaded';
				},
			});
		},

		handleProjectResult(event, data) {
			this.projectSearch = this.formatProjectsItems(data);
			this.projectId = data.project_id;
		},


		formatProjectsItems(data) {
			return `${data.client_name} -- ${data.project_title}`;
		},

		resetForm() {
			this.projectSearch = '';
			this.milestoneTitle = '';
			this.deadline = null;
		},
	},
}
</script>
