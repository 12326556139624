/**
 * User Roles.
 */
export const USER_ROLE_ADMIN       = 'admin';
export const USER_ROLE_MENTOR      = 'mentor';
export const USER_ROLE_EMPLOYEE    = 'employee';
export const USER_ROLE_FIRED       = 'fired';
export const USER_ROLE_COORDINATOR = 'coordinator';

export const ACCESS_DEPOT_UNSAVED_ID = '_unsaved';

export const ACCESS_DEPOT_FTP_EXPLORER_EMPTY_DIR_DUMMY_FILE_NAME = 'empty';
