<template>
	<form action="/api/v2/generate-token" method="POST" class="form-horizontal profile-form profile-pin-a-bug" id="profile-pin-a-bug">
		<fieldset>
			<h2>Pin a Bug ( QA Tool )</h2>

			<div class="frow">
				<p>Този инструмент позволява да създавате issue за проектите от bookmarklet:</p>
			</div><!-- /.frow -->

      <div class="frow">
        <video width="430" height="263" controls>
          <source src="https://trello-attachments.s3.amazonaws.com/588e2dcf54d4effdc8fffda7/60d5d21998902a8f8a8accbc/f12681bd83e7239a135352d0075c201c/bookmarklet.mp4" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>

      <div class="frow">
        <div v-if="api_token">
          <a :href="'javascript:(' + generateScript() + ')()'" class="btn btn-primary">
            📌 Pin a Bug
          </a>
            Завлечете този бутон в bookmarks на browser-a си.
          </div>
        <div v-else class="">
          <input @click="requestGenerateToken" type="button" value="Генерирай bookmarklet" class="button submit-field">
          <i class="loading-animation fa fa-refresh fa-spin"></i>
        </div>
      </div>
      <div class="frow">
        <p>За Google Chrome: инсталирайте си  <a href="https://chrome.google.com/webstore/detail/orbit-screenshot-helper/hopiafjcihcgjhjgbdldbjhbdkklnoil" target="_blank">Orbit Screenshot Helper</a> за да получите автоматични screenshots към issues които докладвате.</p>
      </div>
    </fieldset>
	</form>
</template>

<style scoped>
    #profile-pin-a-bug .btn-primary {
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
    }
    #profile-pin-a-bug .btn {
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
    }
</style>

<script>
	export default {
	    data() {
	        return {
	            api_token: null,
            };
        },
		methods: {
            requestGenerateToken() {
                $.ajax({
                    url: '/api/v2/generate-token',
                    type: 'POST',
                    success: (response) => this.saveToken(response.api_token),

                    error: function (err) {
                        alert("Something went wrong. Please try again later. ");
                    },
                });
            },
            saveToken(token) {
                this.api_token = token;
            },
            generateScript() {
                window.$$OrbitToken = this.api_token;
                const pinABugScript =
                    () => {
                      if (window.$$OrbitApp) {
                        window.$$OrbitApp.$emit('tagging');
                      } else {
                        const s = document.createElement('script');
                        s.src = window.$$OrbitScript;
                        document.body.appendChild(s);
                      }
                    };
                const pinABugScriptAsString = pinABugScript.toString().trim();

                return `()=>{window.$$OrbitToken='${this.api_token}'; window.$$OrbitScript='${window.location.origin}/pin-a-bug/orbit.extension.js'; (${pinABugScriptAsString})();}`;
            }
		}
	}
</script>



