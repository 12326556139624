<template>
    <div class="entry-preview__item" :class="className">
        <h4 v-if="label">{{ label }}</h4>
        <span
            v-if="type === 'text'" 
            v-text="value" 
            :class="defaultClass" 
        />
        <div
            v-if="type === 'html'" 
            v-html="value"
            :class="defaultClass"
        />
        <code
            v-if="type === 'code'" 
            v-text="value"
            :class="defaultClass"
        />
        <a
            v-if="type === 'url'"
            :href="url"
            target="_blank"
            rel="noreferrer"
            :class="defaultClass"
        >{{ value }}</a>
        <slot v-if="type === 'slot'" />
        <Copy :value="value" v-if="copy" />
    </div>
</template>

<script>
import Copy from './Copy.vue';

export default {
    props: {
        type: {
            default: 'text'
        },
        value: {
            default: ''
        },
        label: {
            default: ''
        },
        url: {
            default: ''
        },
        copy: {
            default: true
        },
        className: {
            default: ''
        },
        larger: {
            default: true
        }
    },

    components: {
        Copy
    },

    computed: {
        defaultClass() {
            const className = 'entry-value';
            return this.larger ? `${className} entry-value--larger` : className;
        }
    }
}
</script>