<template>
	<div>
		<p>
			This tool migrates projects from the SVN repository to a new GIT repository without losing the history of the project in the process.
		</p>

		<p>
			If you'd like to proceed with a migration, you'd need first to archive all Client Preview and Carrier deployed sites associated with the SVN path.
		</p>

		<p>
			Go through the deployed sites and commit any changes of the files or the database, then archive the sited. Open the tool again and you'll be able to proceed with the migration.
		</p>
	</div>
</template>

<script>

export default {
	name : 'migration-clean',
}

</script>