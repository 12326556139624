<script>
/**
 * External dependencies:
 */
import { mapGetters, mapMutations } from 'vuex';

/**
 * Internal dependencies:
 */
import Note from '../components/project-notes/Note.vue';
import NoteForm from '../components/project-notes/Form.vue';

export default {
	computed: {
		...mapGetters({
			notes: 'projectNotes/getNotes',
			currentEditNote: 'projectNotes/getCurrentEditNote',
			projectId: 'projectNotes/getProjectId',
			taskId: 'projectNotes/getTaskId',
			showForm: 'projectNotes/getShowForm',
		}),
	},

	methods: {
		...mapMutations({
			updateCurrentEditNote: 'projectNotes/PROJECT_NOTES_UPDATE_CURRENT_EDIT_NOTE',
			updateShowForm: 'projectNotes/PROJECT_NOTES_UPDATE_SHOW_FORM',
		}),

		showNewNoteForm() {
			this.updateCurrentEditNote({ id: 0, body: '' });
			this.updateShowForm(true);
		},
	},

	components: {
		Note,
		NoteForm,
	},
}
</script>

<template>
	<div id="notes">
		<h4>Бележки</h4>

		<ul>
			<Note
				v-for="note in notes"
				:key="note.type + note.id"
				:note="note"
			/>
		</ul>

		<NoteForm v-if="showForm"/>

		<a v-else href="#" @click.prevent="showNewNoteForm">Добави Бележка</a>
	</div>
</template>
