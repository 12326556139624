import moment from 'moment';

/**
 * ChecklistItem Model
 */
export default class ChecklistEntry {
	/**
	 * Constructor
	 *
	 * @param  {String|Object} data
	 */
	constructor(data) {
		this.originalData = data ? '' + data : '';
		this.data = this.originalData;

		this.init();
	}

	/**
	 * Set checklist item initial properties.
	 *
	 * @return {void}
	 */
	init() {
		this.state    = this.getState();
		this.title    = this.getTitle();
		this.group    = this.getGroup();
		this.estimate = this.getEstimate();
	}

	/**
	 * Get checklist item title from data string.
	 *
	 * @return {String}
	 */
	getTitle() {
		return this.data.replace(/^\s*[☐☑☒✓✗]?(\s?.*\s\»)?\s*/, '').replace(/\((\d+[hm])?(\d+m)?\)$/, '').trim();
	}

	/**
	 * Get checklist item group if one is provided.
	 *
	 * @return {String}
	 */
	getGroup() {
		const matches = this.data.match(/\s*[☐☑☒✓✗]?\s?(.*)\s\»/);

		if (matches) {
			return matches[1];
		}

		return null;
	}

	/**
	 * Get checklist item state from state character.
	 *
	 * @return {String}
	 */
	getState() {
		const matches = this.data.match(/^\s*([☐☑☒✓✗])/, '');
		const character = matches ? matches[1] : null;

		switch(character) {
			case '☑':
				return 'checked';
			case '☒':
			case '✗':
				return 'canceled';
			case '✓':
				return 'included';
			case '☐':
			default:
				return 'unchecked'
		}
	}

	/**
	 * Get checklist item state character.
	 *
	 * @return {String}
	 */
	getStateCharacter() {
		switch(this.state) {
			case 'checked':
				return '☑';
			case 'canceled':
				return '☒';
			case 'unchecked':
			default:
				return '☐';
		}
	}

	/**
	 * Get checklist item estimate in minutes.
	 *
	 * @return {Number}
	 */
	getEstimate() {
		const timeMatches = this.data.match(/\((\d+[hm])(\d+m)?\)$/);

		if (!timeMatches) {
			return 0;
		}

		const time = timeMatches.slice(1).reduce((total, item) => {
			if (!item) {
				return total;
			}

			const minutes = item.indexOf('h') >= 0 ? parseInt(item) * 60 : parseInt(item);

			return total + minutes;
		}, 0);

		return time;
	}

	/**
	 * Get checklist item estimate as data string.
	 *
	 * @param  {Boolean} round
	 * @return {Number}
	 */
	getEstimateString(round = false) {
		let estimate = this.estimate || 0;

		if (round) {
			estimate = Math.floor(estimate / 10) * 10;
		}

		if (!estimate) {
			return null;
		}

		const hours = Math.floor(estimate / 60);
		const minutes = estimate % 60;

		const hoursString = hours > 0 ? `${hours}h` : '';
		const minutesString = minutes > 0 ? `${minutes}m` : '';

		return `(${hoursString}${minutesString})`;
	}

	/**
	 * Get checklist item estimate as float number.
	 *
	 * @param  {Number} precision
	 * @return {Number}
	 */
	getEstimateAsFloat(precision = 2) {
		return (this.estimate / 60).toFixed(precision);
	}

	/**
	 * Get checklist item string.
	 *
	 * @return {String}
	 */
	toString() {
		const stringBody = [ this.getStateCharacter() ];

		const group = this.group ? `${this.group} »` : '';
		if (!!group) {
			stringBody.push(group);
		}

		stringBody.push(this.title);

		if (this.estimate > 0) {
			stringBody.push(this.getEstimateString());
		}

		return stringBody.join(' ');
	}

	/**
	 * Toggle checklist item state.
	 *
	 * @param  {String} state
	 * @return {String}
	 */
	toggleState(state) {
		if (state) {
			this.state = state;
		} else {
			this.state = this.state === 'unchecked' ? 'checked' : 'unchecked';
		}

		return this.data = this.toString();
	}
}
