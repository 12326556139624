<template>
	<div class="payments-wrapper">
		<div class="payments-convert">
			<em class="help-text">курс: <a href="https://data.fixer.io/latest?base=USD&amp;symbols=BGN&amp;access_key=f21b617b86eb3dae78386e3e96e8297d" target="_blank">{{rateBGNReduced || 'N/A'}}</a></em>
		</div>

		<ul class="payments" v-if="payments.length">
			<li class="payment" v-for="(payment, index) in payments">
				<a href="#" class="payment-remove" @click="removePayment(index)">
					<i class="fa fa-remove"></i>
				</a>

				<div class="payment-cols">
					<div class="payment-col">
						<label>Заглавие:</label>

						<input type="text" v-bind:name="`payments[${index}][title]`" value="" class="field" id="title_field" v-model="payment.name" required>
					</div>

					<div class="payment-col payment-col-small">
						<label>Тип:</label>

						<select v-bind:name="`payments[${index}][type]`" class="field" v-model="payment.type">
							<option value="standard">Standard</option>
							<option value="express">Express</option>
						</select>
					</div>

					<div class="payment-col payment-col-small">
						<label>Сума:</label>

						<input type="text" v-bind:name="`payments[${index}][amount]`" autocomplete="off" class="field" v-model="payment.amountUSD">
					</div>

					<div class="payment-col payment-col-small">
						<label>Сума в лева:</label>

						<input type="text" v-bind:name="`payments[${index}][amount_bgn]`" autocomplete="off" class="field" v-model="rateBGNReduced ? Math.round(payment.amountUSD * rateBGNReduced) : payment.amountBGN">
					</div>

					<div class="payment-col payment-col-small">
						<label class="payment-payed">
							<span>&nbsp;</span>

							<input type="checkbox" v-bind:name="`payments[${index}][payed]`" v-model="payment.payed"> Платено
						</label>

					</div>
				</div>
			</li>
		</ul>

		<a href="#" class="button" @click.prevent="addPayment('', 0)">
			<i class="fa fa-plus-circle"></i> Добави още
		</a>
	</div>
</template>

<script>
export default {
	name: 'payments',

	data() {
		return {
			rateBGN: null,
			payments: []
		};
	},

	props: {
		submitted: {
			default: true
		},

		defaultPayment: {
			default() {
				return {};
			}
		}
	},

	computed: {
		/**
		 * Get the BGN rate reduced with static amount
		 * due to difference between buying and selling rates
		 *
		 * @return {Number}
		 */
		rateBGNReduced() {
			const reduceAmount = 0.03;

			return this.rateBGN && (this.rateBGN - reduceAmount).toFixed(2);
		},
	},

	mounted() {
		this.fetchRates();

		const defaultPayment = { ...this.defaultPayment };
		const upfront = parseInt(defaultPayment.upfront);

		/**
		 * If upfront is presented, add a separate payment
		 * and reduce its amount from the default payment
		 */
		if (upfront > 0) {
			this.addPayment(`${defaultPayment.name} (upfront)`, upfront, defaultPayment.type);

			defaultPayment.price = defaultPayment.price - upfront;
		}

		if (defaultPayment.price > 0) {
			this.addPayment(defaultPayment.name, defaultPayment.price, defaultPayment.type);
		}
	},

	methods: {
		fetchRates() {
			const vm = this;

			$.ajax({
				url: '/get-course',
				method: 'GET',

				success(resp) {
					vm.rateBGN = resp;
				}
			});
		},

		addPayment(name = '', price = 0, type = 'standard') {
			this.payments.push({
				name: name,
				amountUSD: price,
				amountBGN: 0,
				payed: false,
				type: type
			});
		},

		removePayment(index) {
			this.payments.splice(index, 1);
		}
	},

	watch: {
		submitted(isSubmitted) {
			if (isSubmitted) {
				this.payments = [];
			}
		}
	}
}
</script>

<style scoped>
	.payments { padding: 10px 0; list-style: none outside none; }
	.payments-convert { font-size: .9em; }
	.payments-convert input { display: inline-block; vertical-align: middle; }

	.payment { position: relative; margin-bottom: 10px; padding-right: 25px; }
	.payment:last-child { margin-bottom: 0; }

	.payment-remove { position: absolute; right: 0; bottom: 0; color: #c90000; }
	.payment-remove i { height: 32px; line-height: 32px; }

	.payment-cols { margin: 0 -10px; }
	.payment-cols:after { content: ''; display: table; clear: both; line-height: 0; }
	.payment-col { float: left; width: 33.33%; padding: 0 10px; box-sizing: border-box; }
	.payment-col-small { width: 16.6667%; }

	.payment-payed span { display: block; margin-bottom: 9px; }
</style>
