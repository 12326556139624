<template>
	<div class="tab" v-show="selected">
		<slot></slot>
	</div>
</template>

<script>

export default {
	name: 'tab',

	props: {
		id: { type: String, required: true },
		name: { type: String, required: true },
		hidden: { type: Boolean, default: false }
	},

	data() {
		return {
			selected: false
		};
	}
}
</script>

