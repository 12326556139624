<template>
	<div v-if="checklistData" class="checklist checklist--preview">
		<div v-if="!checklistData.valid" class="checklist__error">
			{{ checklistData.message }}
		</div><!-- /.checklist__error -->

		<checklist-items v-else :data="checklist" :title="'Checklist Preview'" />
	</div>
</template>

<script>
import yaml from 'js-yaml';

import ChecklistEntry from '../models/ChecklistEntry';
import ChecklistItems from './ChecklistItems.vue';

export default {
	name: 'checklist-preview',

	model: {
		prop: 'checklistData',
		event: 'update'
	},

	props: {
		checklistData: {
			type: Object,
		}
	},

	components: {
		ChecklistItems,
	},

	computed: {
		checklist() {
			let checklist = {};

			try {
				checklist = yaml.load(this.checklistData.checklist_yaml)
			} catch (e) {}

			return checklist;
		}
	},

	methods: {
		sumEstimates(data, state, onlyCount) {
			if (!data) {
				return 0;
			}

			let total = 0;

			if (typeof data === 'string') {
				const entry = new ChecklistEntry(data);

				if (!state || state === entry.state) {
					total += onlyCount ? 1 : entry.getEstimate();
				}
			} else {
				Object.keys(data).forEach(key => {
					total += this.sumEstimates(data[key], state, onlyCount);
				});
			}

			return total;
		},
	},

	mounted() {
		this.$emit('estimate:change', this.sumEstimates(this.checklist));
	},

	watch: {
		checklist() {
			this.$emit('estimate:change', this.sumEstimates(this.checklist));
		},
	},
}

</script>
