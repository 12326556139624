<template>
	<div :class="classList" @click.prevent="onClick">
		<p v-html="notification.message" />

		<button type="button" class="close" data-dismiss="alert" aria-label="Close" @click.prevent="onDismiss" v-if="notification.dismissable">
			<span aria-hidden="true">&times;</span>
		</button>
	</div><!-- /.notification -->
</template>

<script>
	export default {
		/**
		 * Component Name.
		 *
		 * @type {String}
		 */
		name: 'notification',

		/**
		 * Component Props.
		 *
		 * @type {Object}
		 */
		props: {
			notification: Object,
		},

		/**
		 * Computed Data.
		 *
		 * @type {Object}
		 */
		computed: {
			classList() {
				let classList = ['notification'];

				if (this.notification.dismissable) {
					classList.push('notification--dismissable');
				}

				if ('onClick' in this.notification) {
					classList.push('notification--clickable');
				}

				classList.push(`notification--${this.notification.type}`);
				
				return classList;
			}
		},

		/**
		 * Component Methods.
		 */
		methods: {
			onClick() {
				if ('onClick' in this.notification) {
					this.notification.onClick();
				}

				this.$emit('click');
			},

			onDismiss() {
				this.$emit('dismiss');
			}
		}
	}
</script>