<template>
    <div class="access-depot-entries__entry">
		<div :class="{ 'main-credentials': entry.is_main }">
            <EntryTitle 
                :entry="entry"
                :active="active"
                @focus="focus($event)"
            />
            <EntryPreview 
                v-if="!active"
                :entry="entry"
                :droneLink="droneLink"
                :connectionString="connectionString"
            />
        </div>

        <updateForm
            v-if="active"
            :entry="entry"
            :droneLink="droneLink"
			:focus="focus"
            :connectionString="connectionString"
            @showFtpExplorer="showFtpExplorer"
            @changedForm="changedForm"
        />
    </div>
</template>

<script>
/**
 * The internal dependencies.
 */
import { getConnectionInfo } from '../../helpers/access-depot';
import { ACCESS_DEPOT_UNSAVED_ID } from '~/lib/constants';
import EntryTitle from './EntryTitle.vue';
import EntryPreview from './EntryPreview.vue';
import UpdateForm from './UpdateForm.vue';

export default {
	components: {
        UpdateForm,
        EntryPreview,
        EntryTitle,
    },

	props: ['entry', 'deleted', 'focus'],

	computed: {
		droneLink() {
			try {
				const params = {
					command: 'filezilla',
					targets: [
						this.connectionString,
						this.$store.getters['accessDepot/getVcsPath'],
					],
				};

				return `drone://${btoa(JSON.stringify(params))}`;
			} catch(error) {
				return '#';
			}
		},

        active() {
			return this.$store.getters['accessDepot/getIsActive'](this.entry.id);
		},

		connectionString() {
			const info = getConnectionInfo(this.entry);

			return getFilezillaConnectionString(info);
		},

		canOpenFtpExplorer() {
			return this.entry.id !== ACCESS_DEPOT_UNSAVED_ID
		},

	},
    
	methods: {
		showFtpExplorer() {
			if (this.entry.formChanged) {
				alert('Формата беше променена. Моля съхрани промените преди да продължиш.');
				return;
			}

			const { id: entryId } = this.entry;
			this.$store.dispatch('accessDepot/openFtpExplorer', { entryId, lookupPath: this.entry.directory });
		},

        handleFocus(event) {
            this.focus(event);
        },

		changedForm(event) {
			if (this.entry.id === ACCESS_DEPOT_UNSAVED_ID) {
				return;
			}

			this.$store.commit('accessDepot/ACCESS_DEPOT_SET_FORM_CHANGED', {
				id: this.entry.id,
				changed: true
			});
		},
        
	},
};
</script>
