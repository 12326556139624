<template>
	<ol class="listview-paging paging" v-if="milestonesPagination.lastPage > 1">
		<li v-for="page in milestonesPagination.lastPage" :class="{ current: page === milestonesPagination.currentPage }">
			<a href="#" @click.prevent="changeMilestonesPage(page)">{{page}}</a>
		</li>
	</ol>
</template>

<script>
export default {
	name: 'monitor-milestones-paging',

	computed: {
		milestonesPagination() {
			return this.$store.state.milestonesPagination;
		},
	},

	methods: {
		changeMilestonesPage(page) {
			this.$store.commit('CHANGE_MILESTONES_PAGE', page);
			this.$store.dispatch('fetchMilestones');
		}
	}
}
</script>
