<template>
	<div class="inline-credentials-edit-row">
		<form
			method="POST"
			action="/ajax/update-access-credentials"
			@change="handleChange"
			@submit.prevent="handleSubmit"
			class="credentials-form"
			v-bind:class="{
				'shuttle-access-point': false,
				'credentials-form--has-connection': isFtpOrSftp,
				'credentials-form--general': !isFtpOrSftp,
				'credentials-form--has-totp': entry.is_totp_enabled
			}">
			<div class="acd-inline-edit-form">
				<Field 
					label="Име"
					key="adf-title"
					name="title"
					id="adf-title"
					placeholder="Staging FTP"
					:required="true"
					ref="title"
					v-model="entry.title"
				/>

				<Field 
					label="Главна инстанция"
					key="is_main"
					type="checkbox"
					name="is_main"
					v-model="entry.is_main"
				/>

				<Field 
					label="Тип"
					key="type"
				>
					<div class="acd-field">
						<div class="radio">
							<label>
								<input
									type="radio"
									name="protocol"
									id="f-protocol-ftp"
									value="ftp"
									class="credential-field"
									v-model="entry.protocol"
								>
								FTP
							</label>
						</div>

						<div class="radio">
							<label>
								<input
									type="radio"
									name="protocol"
									value="sftp"
									class="credential-field"
									v-model="entry.protocol"
								>
								SFTP
							</label>
						</div>

						<div class="radio">
							<label>
								<input
									type="radio"
									name="protocol"
									value="general"
									class="credential-field"
									v-model="entry.protocol"
								>
								Друго(напр. cPanel или WordPress администрация)
							</label>
						</div>

						<div class="radio" v-if="isAdmin">
							<label>
								<input
									type="radio"
									name="protocol"
									value="seat"
									class="credential-field"
									v-model="entry.protocol"
								>
								Seat(може да се запазва за ползване от 1 служител)
							</label>
						</div>
					</div>
				</Field>

				<Field 
					label="Host / URL"
					key="host"
				>
					<div class="acd-field host-port-pair">
						<input
							type="text"
							name="host"
							class="field credential-field"
							id="adf-host"
							required
							v-model="entry.host"
						>

						<span class="inline-sapf shuttle-access-point-field" v-if="isFtpOrSftp">
							: <input
								type="text"
								name="port"
								class="field small-field credential-field"
								placeholder="22"
								v-model="entry.port"
							>
						</span>
					</div>
				</Field>

				<Field 
					label="Username"
					key="adf-user"
					id="adf-user"
					name="username"
					placeholder="username"
					v-model="entry.username"
				/>

				<Field 
					label="Password"
					key="adf-password"
				>
					<div class="acd-field">
						<input
							type="text"
							name="password"
							id="adf-password"
							class="field"
							v-if="!entry.password_hidden"
							v-model="entry.password"
						>
						<RevealField :entry="entry" v-if="entry.password_hidden" />
					</div>
				</Field>

				<Field 
					v-if="!isFtpOrSftp"
					type="checkbox"
					label="Use 2 Factor Auth"
					name="is_totp_enabled"
					key="adf-use-totp"
					id="adf-use-totp"
					v-model="entry.is_totp_enabled"
				/>


				<Field 
					v-if="entry.is_totp_enabled && !entry.has_saved_secret && !isFtpOrSftp"
					key="adf-totp-secret"
					label="2FA Secret"
				>
					<div class="acd-field">
						<input
							type="text"
							name="totp_secret"
							id="adf-totp-secret"
							class="field credential-field"
							:value="entry.totp_secret || ''"
							@input="(e) => { entry.totp_secret = e.currentTarget.value }"
						>
						<div class="help-text" style="white-space: normal; color: #999; ">
							Това е текста който е encode-нат в QR кода. Ако системата ти дава само QR код, свали картинка и я качи на <a href="https://qrcode-decoder.com/" target="_blank">https://qrcode-decoder.com/</a>  -- резултата трябва да съдържа или secret-а, или URL, в което има secret.
						</div>
					</div>
				</Field>

				<Field 
					v-if="entry.totps && entry.totps.length && !isFtpOrSftp"
					label="2 Factor Auth Code"
					key="adf-totp-code"
				>
					<Totp :entry="entry" />
				</Field>

				<Field 
					v-if="isFtpOrSftp"
					key="adf-default-dir"
					type="text"
					label="Directory"
					id="adf-default-dir"
					name="directory"
					placeholder="/public_html/"
					v-model="entry.directory"
				/>

				<Field 
					v-if="isFtpOrSftp"
					key="adf-home-url"
					id="adf-home-url"
					type="url"
					fieldClass="field xl-field"
					label="URL"
					name="home-url"
					placeholder="http://example.com/"
					v-model="entry.home_url"
				/>

				<Field
					label="Бележки"
					key="adf-notes"
					id="adf-notes"
				>
					<div class="acd-field acd-field--textarea">
						<textarea
							name="notes"
							id="adf-notes"
							class="field credentials-notes-field"
							placeholder="Допълнителна информация за хората, които ще поддържат проекта в твое отсъствие"
							v-model="entry.notes"
						></textarea>
					</div>
				</Field>

				<Field
					key="ftp-string"
					label="Връзка"
					v-if="isFtpOrSftp"
				>
					<RevealField label="Reveal Connection" :entry="entry" v-if="entry.password_hidden" />
					<div class="acd-field connection-string-holder" v-else>
						<input
							type="text"
							class="connection-string-masked field"
							readonly
							:value="connectionString"
						/>
						<span
							title="Copy to clipboard"
							class="acd-item-button copy-to-clipboard"
							v-clipboard:copy="connectionString"
						>
							<i class="fa fa-copy"></i>
						</span>

						<a
							:href="droneLink"
							:title="droneLinkTitle"
							class="acd-item-button connect-via-filezilla"
						></a>
					</div>
				</Field>

				<div
					v-if="message(entry.id) && message(entry.id).length"
					:class="{
						alert: true,
						success: messageType(entry.id) === 'success',
						warning: messageType(entry.id) === 'warning',
						error: messageType(entry.id) === 'error'
					}"
					v-html="message(entry.id)"
				>
				</div>

				<div class="acd-frow">
					<div class="acd-submit">
						<div
							v-if="isLoading(entry.id)"
							class="loader"
						>
							<img src="/css/img/loading.gif" />
						</div>

						<input
							type="submit"
							class="button button-primary"
							name="commit"
							value="Запази"
							:disabled="isLoading(entry.id)"
						>

						<input
							v-if="isFtpOrSftp"
							type="button"
							class="button button-secondary test-credentials"
							name="test"
							value="Тествай"
							@click.prevent="handleTest"
							:disabled="isLoading(entry.id)"
						>
						&nbsp;
						<a
							href="#"
							:class="{
								'delete-access-credentials': true,
								'danger-link': true,
								'inactive': isLoading(entry.id),
							}"
							@click.prevent="handleDelete"
						>{{cancelButton}}</a>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
/**
 * The external dependencies.
 */
import { mapGetters } from 'vuex';

/**
 * The internal dependencies.
 */
import Totp from './Totp.vue';
import Field from './Field.vue';
import RevealField from './RevealField.vue';
import { filterEntryForAjaxRequest } from '~/helpers/access-depot';
import { ACCESS_DEPOT_UNSAVED_ID } from '~/lib/constants';

const messageString = {
	confirmNoURL: `За достъп до сайта е нужен URL. В много редки случаи няма такъв. Желаеш ли да продължиш?`,
	confirmMainInstance: `В момента има друга(други) инстанция зададена като главна. Да премахна ли флага "Главна инстанция" от тях?`,
	confirmDelete: 'Сигурен ли си?',
	droneError: 'Something went wrong when generating the drone link.',
	droneRequire: 'Connect via Filezilla(requires latest Drone)',
	cancelButton: ['Изтрий', 'Cancel']
}

export default {
	props: ['entry', 'droneLink', 'connectionString', 'focus'],

	components: {
		Totp,
		Field,
		RevealField,
	},

	methods: {
		handleSubmit() {
			if (this.isLoading(this.entry.id)) {
				return;
			}

			const payload = {
				credentials: filterEntryForAjaxRequest(this.entry),
			};

			if ( ( payload.credentials.protocol === 'ftp' || payload.credentials.protocol === 'sftp' ) && payload.credentials['home-url'] === '' ) {
				const confirm = window.confirm(messageString.confirmNoURL);
				if (!confirm) {
					return;
				}
			}

			const mainEntries = this.$store.getters['accessDepot/getMainEntries'];
			if (
				mainEntries.length > 1
				&& this.entry.is_main
				&& window.confirm(messageString.confirmMainInstance)
			) {
				payload.credentials.should_reset_main = true;
			} else {
				payload.credentials.should_reset_main = false;
			}

			this.$store.commit('accessDepot/ACCESS_DEPOT_SET_FORM_CHANGED', {
				id: this.entry.id,
				changed: false
			});

			if (this.entry.id === ACCESS_DEPOT_UNSAVED_ID) {
				this.$store.dispatch('accessDepot/addAccessCredentials', payload).then(res => {
					this.focus({
						id: res.id
					})
				})
			} else {
				this.$store.dispatch('accessDepot/updateAccessCredentials', {
					...payload,
					password_hidden: this.entry.password_hidden
				});

				if(payload.totp_secret != '') {
					this.$store.dispatch('accessDepot/loadTotps', this.entry)
				}
			}

			this.$store.commit('accessDepot/ACCESS_DEPOT_SET_IS_ACTIVE', {
				id: this.entry.id,
				isActive: false,
			});

		},

		handleChange() {
			this.$emit('changedForm');
		},

		handleTest() {
			if (this.isLoading(this.entry.id)) {
				return;
			}

			const credentials = filterEntryForAjaxRequest(this.entry);

			this.$store.dispatch('accessDepot/testAccessCredentials', {
				id: this.entry.id,
				credentials,
			});
		},

		handleDelete() {
			if (this.isLoading(this.entry.id)) {
				return;
			}

			if (!window.confirm(messageString.confirmDelete)) {
				return;
			}

			if (this.entry.id === ACCESS_DEPOT_UNSAVED_ID) {
				this.$store.dispatch('accessDepot/deleteUnsavedCredentials');
			} else {
				this.$store.dispatch('accessDepot/deleteAccessCredentials', { id: this.entry.id });
			}
		},
	},

	computed: {
		...mapGetters({
			isLoading: 'accessDepot/getIsLoading',
			message: 'accessDepot/getMessage',
			messageType: 'accessDepot/getMessageType',
			taskId: 'projectNotes/getTaskId',
		}),

		isFtpOrSftp() {
			return this.entry.protocol === 'ftp' || this.entry.protocol === 'sftp';
		},

		droneLinkTitle() {
			if (this.droneLink === '#') {
				return messageString.droneError;
			}

			return messageString.droneRequire;
		},

		isSaved() {
			return this.entry.id !== ACCESS_DEPOT_UNSAVED_ID;
		},

		cancelButton() {
			return this.isSaved ? messageString.cancelButton[0] : messageString.cancelButton[1];
		},

		isAdmin() {
			return window.user_is_admin;
		}
	},

	mounted() {
		this.entry.initialValues = JSON.parse(JSON.stringify(this.entry));
		if(!this.isSaved) {
			const input = this.$refs.title.$el.querySelector('input');
			if(input) {
				input.focus();
				input.select();
			}
		}
	}
};
</script>
