<template>
	<div class="checklist__items">
		<div class="checklist__items-head">
			<h5 :class="['checklist__items-title', { 'is-placeholder': !data }, { 'not-editable': !canEdit }]">{{title}}</h5><!-- /.checklist__items-title -->

			<div v-if="canEdit" class="checklist__items-actions">
				<a href="#" class="btn" v-if="!hasItems" @click.prevent="adding = 'group'">
					<i class="fa fa-list-ul"></i> Add Group
				</a>

				<a href="#" class="btn" v-if="!hasGroups" @click.prevent="adding = 'item'">
					<i class="fa fa-check-square-o"></i> Add Item
				</a>
			</div><!-- /.checklist__items-actions -->
		</div><!-- /.checklist__items-head -->

		<div class="checklist__items-body">
			<template v-if="hasItems" v-for="item in groupedItems">
				<template v-if="!!item.group">
					<div class="checklist__group">
						<h6>{{item.group}}</h6>

						<template v-for="checklistItem in item.items">
							<checklist-item
								v-model="data[checklistItem.key]"

								:key="checklistItem.key + data[checklistItem.key] + data.length"
								:dataKey="checklistItem.key"
								:canEdit="canEdit"

								@delete="handleItemDelete"
								@log="handleLog"
							/>
						</template>
					</div><!-- /.checklist__group -->
				</template>

				<checklist-item
					v-else
					v-model="data[item.key]"

					:key="item.key + data[item.key] + data.length"
					:dataKey="item.key"
					:canEdit="canEdit"

					@delete="handleItemDelete"
					@log="handleLog"
				/>
			</template>

			<template v-if="hasGroups" v-for="(checklistItem, key) in data">
				<checklist-items
					v-model="data[key]"

					:title="key"
					:key="key"
					:dataKey="key"
					:canEdit="canEdit"

					@delete="handleItemDelete"
					@log="handleLog"
				/>
			</template >

			<div class="checklist__items-new" v-if="adding">
				<input
					ref="inputField"
					type="text"
					class="form-field"

					v-model="addNewTitle"

					:placeholder="adding === 'group' ? 'Checklist Group' : 'Checklist Item'"

					@keydown.enter="handleAddNew"
					@keydown.esc="handleCancel"
					@blur="handleBlur"
				/>
			</div>
		</div><!-- /.checklist__items-body -->
	</div>
</template>

<script>
import _ from 'lodash';
import ChecklistItem from './ChecklistItem.vue';
import ChecklistEntry from '../models/ChecklistEntry';

export default {
	name: 'checklist-items',

	model: {
		prop: 'data',
		event: 'update',
	},

	components: {
		ChecklistItem,
	},

	props: {
		title: {
			type: String,
			required: true,
		},

		data: {
			required: true,
		},

		isNew: {
			type: Boolean,
		},

		canEdit: {
			type: Boolean,
		},
	},

	data() {
		return {
			adding: false,
			addNewTitle: null,
		};
	},

	computed: {
		hasItems() {
			return Object.keys(this.data || {}).some(key => {
				return typeof this.data[key] === 'string';
			});
		},

		hasGroups() {
			return Object.keys(this.data || {}).some(key => {
				return typeof this.data[key] !== 'string';
			});
		},

		groupedItems() {
			if (!this.hasItems) {
				return this.data;
			}

			const items = this.data.map(item => new ChecklistEntry(item));
			const itemsGrouped = [];

			let indexReducer = 1;

			items.forEach((item, index) => {
				const originalItem = this.data[index];
				const itemMapped = {
					key: index,
					item: originalItem,
				};

				if (item.group) {
					const group = item.group;
					const prevIndex = index - indexReducer;

					if (index > 0 && itemsGrouped[prevIndex].group === group) {
						itemsGrouped[prevIndex].items.push(itemMapped);
						indexReducer++;
					} else {
						itemsGrouped.push({
							group,
							items: [itemMapped]
						});
					}

					return;
				}

				itemsGrouped.push(itemMapped);
			});

			return itemsGrouped;
		},
	},

	methods: {
		handleAddNew(event) {
			if (!this.addNewTitle) {
				event.preventDefault();
				return;
			}

			let dataClone = _.cloneDeep(this.data);
			let idealCodingTime = null;

			if (this.adding === 'group' && (!dataClone || !dataClone[this.addNewTitle])) {
				dataClone = !dataClone ? {} : dataClone;
				dataClone[this.addNewTitle] = {};
			}

			if (this.adding === 'item') {
				const newEntry = new ChecklistEntry(this.addNewTitle);
				idealCodingTime = newEntry.estimate;

				dataClone = !Array.isArray(dataClone) ? [] : dataClone;
				dataClone.push(newEntry.toString());
			}

			this.$emit('log', {
				type: 'item_new',
				description: this.addNewTitle,
				ideal_coding_time: idealCodingTime
			});

			this.$emit('update', dataClone);

			// this.adding = false;
			this.addNewTitle = null;
		},

		handleItemDelete(key) {
			let dataClone = _.cloneDeep(this.data);
			delete dataClone[key];

			if (Array.isArray(dataClone)) {
				dataClone = dataClone.filter(item => item !== undefined);
			}

			this.$emit('update', dataClone);
		},

		handleCancel() {
			if (this.adding) {
				this.adding = false;
			}
		},

		handleBlur() {
			if (document.hasFocus()) {
				this.handleCancel();
			} else {
				$(window).one('focus', () => {
					this.$refs.inputField.focus();
				});
			}
		},

		handleLog(log) {
			this.$emit('log', log)
		},
	},

	mounted() {
		const hasActualData = !!this.data && Object.keys(this.data).length > 0;

		if (this.isNew && !hasActualData) {
			this.adding = 'item';
		}
	},

	watch: {
		adding() {
			if (this.adding) {
				setTimeout(() => {
					this.$refs.inputField.focus();
				}, 10);
			}
		}
	}
}
</script>
