<template>
	<div :class="['monitor-milestone', { 'is-highlighted': isHighlighted }, { 'monitor-milestone-finished': milestone.finished === 'y' }]">
		<transition name="milestone-fade">
			<div v-if="!milestoneFinishedToggled || shouldNotHide" class="listview-row">
				<div class="listview-cell monitor-milestone-main">
					<input type="checkbox" class="monitor-milestone-toggle" :value="milestone.id" :checked="milestone.finished === 'y'" @change="handleMilestoneToggleFinished" v-if="!isTesterView">

					<a :href="sourceLink" class="monitor-milestone-source" target="_blank">
						<i :class="`icon-${sourceTitle}`"></i>
					</a>

					<a href="#" class="monitor-milestone-title" @click.prevent="toggleCommentForm">
						<span v-if="milestone.project && project.client" class="monitor-milestone-client">
							{{project.client.title}}
							<template v-if="project.client.company">({{project.client.company.title}})</template>
						</span>
						<span class="monitor-milestone-project" v-if="project">{{project.title}}</span>
					</a>
				</div>

				<div class="listview-cell listview-cell-m monitor-milestone-name">
					<i class="fa fa-warning" v-if="isImportantMilestone"></i>
					<strong v-if="milestone.milestone_name">{{milestone.milestone_name}}</strong>
					<span v-else>Deadline</span>
				</div>

				<div class="listview-cell listview-cell-xs">
					<div class="dropdown-holder">
						<a href="#" :class="['btn', 'monitor-milestone-action', { 'is-danger': (milestone.project && milestone.project.delayed_projects.length > 0) || milestoneDelayedToggled }]" @click.prevent>
							<i class="fa fa-calendar-times-o"></i>
						</a>

						<div class="dropdown-inner">
							<div class="dropdown-menu">
								<h5>Delayed in stage:</h5>

								<ul>
									<li v-for="stage in delayedProjectStages">
										<a href="#" @click.prevent="handleDelayedProjectToggle(stage)">{{stage}}</a>
									</li>
								</ul>
							</div>
						</div>
					</div><!-- /.dropdown -->
				</div>

				<div class="listview-cell listview-cell-xs">
					<a href="#" :class="['btn', 'monitor-milestone-action', { 'is-success': milestone.send_mail === 'y' }]" :disabled="!milestone.send_mail" @click.prevent="handleProjectPropertyToggle('send_mail')">
						<i class="fa fa-envelope-o"></i>
					</a>
				</div>

				<div class="listview-cell monitor-tasks-status">
					<a href="#" :class="['monitor-tasks-indicator', { 'is-pending-qa': activeQATasks.length > 0 && isTesterView && activeQATasks.length === activeTasks.length ,'is-success': allTasksFinished }]" ref="projectTasksTrigger" @click.prevent>
						<i :class="['fa', allTasksFinished ? 'fa-check' : 'fa-refresh']"></i>

						<task-progress v-if="!allTasksFinished && milestone.task" :task="milestone.task"></task-progress>
						<span v-else-if="allTasksFinished">Завършен</span>
						<span v-else>
							Задачи: {{activeTasks.length}}
							<small v-if="activeQATasks.length">(QA: {{activeQATasks.length}})</small>
						</span>
					</a>
				</div>

				<div class="listview-cell listview-cell-s">
					<div class="monitor-milestone-deadline">
						<i class="fa fa-spinner fa-spin" v-if="deadlineStatus === 'loading'"></i>

						{{milestone.dead_line | dayOfMonth}}

						<datepicker v-if="!isTesterView" dateFormat="yy-mm-dd" :value="milestone.dead_line" :onSelect="handleDeadlineSelect" />
					</div>
				</div>
			</div>
		</transition>

		<monitor-milestone-tasks :tasks="relatedTasks" :monitor-id="milestoneId" :milestone-task-id="milestone.task && milestone.task.id" :src-site="sourceTitle" ref="projectTasks" />

		<monitor-milestone-comments :milestone-id="milestoneId" :comments="milestone.comments" v-if="commentFormVisible" />
	</div>
</template>
<style>
	.monitor-milestone .monitor-tasks-indicator.is-pending-qa { color: #e74c3c; } 
</style>
<script>
import _ from 'lodash';
import { dayOfMonth } from '../utils/filters';
import { getProjectUrl } from '../utils/helpers';
import MonitorMilestoneTasks from '../components/MonitorMilestoneTasks.vue';
import MonitorMilestoneComments from '../components/MonitorMilestoneComments.vue';
import UsersDropdown from '../components/UsersDropdown.vue';
import Datepicker from '../components/Datepicker.vue';
import TaskProgress from '../components/TaskProgress.vue';
import moment from 'moment';

export default {
	name: 'monitor-milestone',

	components: {
		MonitorMilestoneTasks,
		MonitorMilestoneComments,
		UsersDropdown,
		Datepicker,
		TaskProgress,
	},

	props: {
		milestoneId: {
			required: true
		}
	},

	data() {
		return {
			delayedProjectStages: ['initial', 'issues', 'updates'],
			commentFormVisible: false,
			milestoneFinishedToggled: false,
			milestoneDelayedToggled: false,
			deadlineStatus: null,
		}
	},

	computed: {
		users() {
			const usersData = $$monitorData.users;
			const users = usersData.map(user => Object.assign(user, { user_real_name: user.name }));

			return users;
		},

		milestone() {
			return this.$store.state.milestones[this.milestoneId];
		},

		isTesterView() {
			return this.$store.state.isTesterView;
		},

		project() {
			return this.milestone.project || {};
		},

		system_project() {
			return this.project.system_project || {};
		},

		sourceTitle() {
			if (this.project.client && this.project.client.client_source) {
				return this.project.client.client_source.title;
			}

			return null;
		},

		sourceLink() {
			const hasProjectToken = this.project && this.project.system_project;
			
			if (this.sourceTitle && hasProjectToken) {
				return getProjectUrl(this.project.system_project.source_system, this.project.system_project.project_token);
			}
		},

		tasks() {
			if ( this.milestone.task) {
				const result = [this.milestone.task];

				if (this.isTesterView) {
					const qaTask = this.project.tasks.find( t => t.parent === this.milestone.task.id );
					if (qaTask) {
						result.push(qaTask)
					}
				}
				
				return result;
			} else if (this.project.tasks) {
				return this.project.tasks;
			}

			return [];
		},

		allTasksFinished() {
			const hasTasks = this.tasks.length > 0;
			const milestone = this.milestone;

			if (this.isTesterView && milestone.task) {
				// Tester view for a milestone in the project(not a whole project)
				return parseInt(milestone.task.finished) !== 0;
			}
			
			const hasUnfinishedTask = this.tasks.some(task => parseInt(task.finished) === 0);

			return !hasTasks || !hasUnfinishedTask;
		},

		activeTasks() {
			return this.tasks.filter(task => !parseInt(task.finished));
		},

		activeQATasks() {
			return this.activeTasks.filter(task => task.type === 'qa');
		},

		relatedTasks() {
			if (this.project.tasks) {
				return _.sortBy( this.project.tasks, ['finished', 'date_added'] );
			}

			return [];
		},

		isHighlighted() {
			return this.$store.state.milestonesHighlights.includes(this.milestoneId);
		},

		isImportantMilestone() {
			const importantKeywords = ['deadline', 'client'];
			const milestoneName = this.milestone.milestone_name;

			if (!milestoneName) {
				return true;
			}

			return importantKeywords.some(keyword => milestoneName.toLowerCase().indexOf(keyword) > -1);
		},

		automatedComments() {
			return {
				send_mail: {
					y: '<span class="send_mail_msg">изпрати e-мейл!</span>',
					n: '<span class="send_mail_msg">e-мейла е изпратен!</span>',
				},

				send_payment: {
					y: '<span class="send_payment_msg">изпрати плащане!</span>',
					n: '<span class="send_payment_msg">плащането е изпратено!</span>',
				},
			};
		},

		currentMilestoneStatus() {
			return {
				milestoneId: this.milestoneId,
				allTasksFinished: this.allTasksFinished
			};
		},

		shouldNotHide() {
			return this.$store.getters.hasSearchQuery && !this.$store.state.milestonesFilters.finished;
		}
	},

	mounted() {
		this.initTasksPopout();
	},

	beforeDestroy() {
		this.destroyTasksPopout();
	},

	methods: {
		getProjectUrl,

		initTasksPopout() {
			const $projectTasks = $(this.$refs.projectTasks.$el);
			const $projectTasksTrigger = $(this.$refs.projectTasksTrigger);

			$projectTasksTrigger.qtip({
				content: {
					text: $projectTasks,
				},
				style: {
					classes: 'qtip-bootstrap qtip-overflow-visible'
				},
				show: {
					event: 'click',
					solo: true
				},
				hide: 'click unfocus',
				position: {
					my: 'top left',
					at: 'bottom left',
					adjust: {
						x: 32,
						y: -5
					},
					effect: false
				}
			});
		},

		destroyTasksPopout() {
			$(this.$refs.projectTasksTrigger).qtip('destroy', true);
		},

		changeProjectProperty(property, value, loading = false) {
			const innerValue = value ? 'y' : 'n';
			const hasAutomatedComment = property in this.automatedComments && innerValue in this.automatedComments[property];
			const commentEntry = hasAutomatedComment ? this.automatedComments[property][innerValue] : null;

			this.updateMilestone(property, innerValue, commentEntry, loading);
		},

		updateMilestone(property, value, commentEntry = null, loading = false) {
			const payload = {
				id: this.milestoneId,
				property,
				value,
				commentEntry,
				loading
			};

			return this.$store.dispatch('updateMilestone', payload);
		},

		handleDelayedProjectToggle(stage) {
			const payload = {
				project_id: this.milestone.project_id,
				delayed_in_stage: stage
			};

			this.milestoneDelayedToggled = true;
			this.updateMilestone('delayed_project', payload);
		},

		handleProjectPropertyToggle(property) {
			if (property in this.milestone) {
				this.changeProjectProperty(property, !(this.milestone[property] === 'y'), true);
			}
		},

		handleMilestoneToggleFinished(event) {
			const finishedValue = event.target.checked ? 'y' : 'n';

			this.milestoneFinishedToggled = true;
			this.updateMilestone('finished', finishedValue);
		},

		handleDeadlineSelect(deadline) {
			this.deadlineStatus = 'loading';

			this.updateMilestone('dead_line', deadline)
				.then(res => this.deadlineStatus = 'loaded')
				.catch(err => console.log(err));
		},

		toggleCommentForm() {
			this.commentFormVisible = !this.commentFormVisible;
		},
	},

	filters: {
		dayOfMonth,
	},

	watch: {
		currentMilestoneStatus(newStatus, oldStatus) {
			const isSameMilestone = oldStatus.milestoneId === newStatus.milestoneId;
			const hasChangedToFinished = oldStatus.allTasksFinished !== newStatus.allTasksFinished && newStatus.allTasksFinished;

			if (isSameMilestone && hasChangedToFinished) {
				// Add to highlighlighted milestones
				this.$store.commit('ADD_MILESTONE_HIGHLIGHT', this.milestone.id);

				Notification.requestPermission((permission) => {
					if (permission === 'granted') {
						const notification = new Notification(`${this.project.title} - ${this.milestone.name || '' }`, {
							body: 'Задачите за този milestone бяха приключени',
							icon: `/css/img/icon-${this.sourceTitle}.png`
						});
					}
				});
			}
		}
	}
}
</script>

<style scoped>
	.milestone-fade-leave-active { max-height: 100px; transition: all .2s; }
	.milestone-fade-leave-to { max-height: 0; opacity: 0; }
</style>
