<template>
	<div>
		<div class="events-list">
			<div class="events-list-wrapper">
				<div v-for="event of events" :key="event.id" :class="'event ' + event.status">
					<div class="event-row">
						<div class="event-initiator">
							<img :src="`/avatars/${event.initiator}.jpg`" :alt="event.initiator" width="26" height="26"/>
						</div>
						
						<p class="event-type">{{ renderTitle(event) }}</p>

						<p v-if="['pending', 'in-progress'].includes(event.status)">
							<i class="fa fa-spinner fa-spin"></i>
						</p>
					</div>

					<div class="event-actions">
						<button @click="handleViewLogs(event)" title="View Logs">Logs</button>

						<button @click="handleLink(event.meta.archive_url)" v-if="event.meta.archive_url && event.status === 'completed'" title="Download Archive">Download ZIP</button>

						<button @click="handleLink(event.meta.logs_url)" v-if="event.meta.logs_url && event.status === 'completed'" title="View Archive Logs">Archived file index</button>
					</div>
				</div>
			</div>
		</div>

		<dialog @click="$refs.dialog.close()" ref="dialog" class="event-log-popup" v-show="focusedEvent !== null">
			<div @click.stop class="event-log-popup-inner">
				<div class="event-log-popup-bar">
					<p>Logs for <span>{{ focusedEvent?.type }}</span> event initiated by {{ focusedEvent?.initiator }}</p>
	
					<button class="submit" @click="$refs.dialog.close()">Close</button>
				</div>
	
				<div class="site-logs-wrapper">
					<SiteLogs :initPolling="focusedEvent?.status === 'in-progress' || focusedEvent?.status === 'pending'" :eventId="focusedEvent?.id" :siteId="siteId" :wpDepotId="wpDepotId" @statusChange="handleStatusChange"/>
				</div>
			</div>
		</dialog>
	</div>
</template>

<script>
import SiteLogs from './SiteLogs.vue';

export default {
    components: { SiteLogs },
    props: ['events', 'siteId', 'wpDepotId'],
	emits: ['statusChange'],
    data() {
        return {
            focusedEvent: this.events.find(event => event.status === 'in-progress') ?? null
        };
    },
    methods: {
        renderTitle(event) {
            const type = event.type;

            let title = type;
            if (type === 'install') {
                title = 'Site installed from GIT';
            } else if (type === 'create') {
                title = 'WP instance created ';
            } else if (type === 'archive') {
                title = 'WP package created ';
            }

            return  `[${renderTimestamp(event.created_at)}] ${title}`;
        },

        handleViewLogs(event) {
            this.focusedEvent = event;
			this.$refs.dialog.showModal();
        },
		handleStatusChange(newStatus, siteData) {
			this.$emit('statusChange', newStatus, siteData);
		},
		handleLink(link) {
			window.open(link, '_blank');
		}
    }
}
</script>

<style scoped>
	.events-list {
		height: fit-content;
		max-height: 410px;
		overflow: auto;
	}

	.events-list-wrapper {
		display: flex;
		flex-direction: column-reverse;
	}

	& dialog {
		margin: auto;
		border: 0;
		border-radius: 6px;
		min-width: 1024px;
		max-width: 1024px;
		overflow: visible;

		&::backdrop {
			background: rgba(0,0,0,.5);
		}
	}

	.site-logs-wrapper {
		min-height: 300px;
		max-height: 720px;
	}

	.event-log-popup-bar {
		display: flex;
		align-items: center;
		padding-inline: 12px;
		padding-block: 8px;
		line-height: 1;
		background: #FCFCFC;
		border-radius: 4px 4px 0 0;

		& span {
			text-transform: capitalize;
		}

		& button {
			margin-left: auto;
		}
	}

	.event-row {
		display: flex;
		justify-items: center;
		align-items: center;
		text-align: center;
		gap: 8px;

		&:not(:last-child) {
			margin-bottom: 4px;
		}
	}

	.event-actions {
		display: flex;
		gap: 6px;
		margin-top: 6px;

		& button {
			appearance: none;
			background: #FFF;
			border: 1px solid #CCC;
			position: relative;
			place-content: center;
			padding: 2px 8px;
			border-radius: 4px;
			cursor: pointer;
			font-size: 13px;
			line-height: 1.2;
			transition: background-color 250ms ease-out;

			&:after {
				content: '';
				display: block;
				position: absolute;
				inset: 0;
				box-shadow: 0 0 6px rgba(0,0,0,.25);
				opacity: 0;
				transition: opacity 200ms ease-out;
				border-radius: 8px;
				pointer-events: none;
			}

			@media (hover: hover) and (pointer: fine) {
				&:hover {
					background: #EEE;
				}
			}

			& i {
				vertical-align: middle;
			}
		}
	}

	.event {
		&:not(:first-child) {
			border-bottom: 1px solid #CCC;
			margin-bottom: 8px;
			padding-bottom: 8px;
		}
	}

	.event-type {
		text-transform: capitalize;
	}

	.event-initiator {
		width: 26px;
		height: 26px;

		& img {
			width: 100%;
            border-radius: 50%;
			height: 100%;
			object-fit: contain;
		}
	}

	.submit {
		appearance: none;
		border: 1px solid #b2b2b2;
		border-radius: 4px;
		text-align: center;
		font-size: 12px;
		font-weight: 700;
		cursor: pointer;
		background: #f5f5f5;
		display: inline-block;
		vertical-align: top;
		padding: 4px 7px;

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				border: 1px solid #888;
			}
		}
	}
</style>