<script>
/**
 * External dependencies:
 */
import { mapActions, mapMutations } from 'vuex';

/**
 * Internal dependencies:
 */
import NoteActions from "./NoteActions.vue";

export default {
	props: ['note'],

	components: {
		NoteActions,
	},

	methods: {
		...mapActions({
			deleteNote: 'projectNotes/deleteProjectNote',
		}),

		...mapMutations({
			updateCurrentEditNote: 'projectNotes/PROJECT_NOTES_UPDATE_CURRENT_EDIT_NOTE',
			updateShowForm: 'projectNotes/PROJECT_NOTES_UPDATE_SHOW_FORM',
		}),

		onEdit() {
			this.updateCurrentEditNote(this.note);
			this.updateShowForm(true);
		},

		onDelete() {
			this.deleteNote({ id: this.note.id, type: this.note.type });
		},
	},
}

</script>
<template>
	<li>
		<strong>{{ note.user.name }}, {{ note.date }}</strong>

		<note-actions v-if="note.can_edit" @edit="onEdit" @delete="onDelete" />

		<p v-html="note.parsed_body" />
	</li>
</template>
