import moment from 'moment';
import momentLocale from 'moment/locale/bg';

export const calendarDay = date => {
	if ( parseInt(date) <= 0 ) {
		return 'Invalid Date';
	}

	return moment(date).calendar(null, {
		sameDay: 'Днес',
		nextDay: 'Утре',
		lastDay: 'Вчера',
		nextWeek: 'dddd',
		lastWeek: 'L'
	});
}

export const dayOfMonth = date => {
	if ( parseInt(date) <= 0 ) {
		return 'Invalid Date';
	}

	return moment(date).format('DD/MMM');
}

export const fromNow = date => {
	return moment(date).fromNow();
}

export const truncate = (text, length) => {
	if (!text || text.length <= length) {
		return text;
	}

	return `${text.slice(0, length)}...`;
}
