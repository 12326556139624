<template>
	<div>
		<div v-if="typeof migrationLog !== 'string'">
			<div v-for="entry in migrationLog">
				<span>{{entry.created_at}}</span>
				
				 - 
				
				<p 
					:style="{ display : 'inline-block' }"
					v-html="entry.event_log"
				></p>
			</div>
		</div>
		
		<div v-else>
			{{migrationLog}}
		</div>
		
		<div v-if="status === 'working'">
			<div class="migration-loader log"></div>
		</div>
	</div>
</template>

<script>

export default {
	name : 'migration-log',
	
	props : [ 'migrationLog', 'status' ],
		
	mounted() {
		let self = this;
		
		window.setInterval( function() {
			self.$emit( 'refresh' );
		}, 10000 );
	}
}	

</script>