/**
 * The types.
 *
 * @type {String}
 */
export const TRACKING_UPDATE_STATUS = 'time-tracking/update-status';
export const TRACKING_FORCE_START = 'time-tracking/force-start';
export const TRACKING_SET_EVENTS = 'time-tracking/set-events';
export const TRACKING_SET_CURRENTLY_ACTIVE_TASK = 'time-tracking/set-currently-active-task';
export const TRACKING_SET_CURRENT_TASK_ESTIMATE = 'time-tracking/set-current-task-estimate';

/**
 * The mutations.
 *
 * @type {Object}
 */
export default {
    [TRACKING_UPDATE_STATUS](state, { action }) {
        state.tracking = action === 'start';
    },
    [TRACKING_FORCE_START](state) {
    	state.tracking = true;
    },
    [TRACKING_SET_EVENTS](state, events) {
        state.events = events;
    },
    [TRACKING_SET_CURRENTLY_ACTIVE_TASK](state, data) {
        state.currentlyActiveTask = data;
    },
    [TRACKING_SET_CURRENT_TASK_ESTIMATE](state, estimate) {
        state.estimate = estimate;
    }
};