<template>
	<div class="users-list" @click.stop>
		<input type="text" class="users-list__search form-field" placeholder="Търси потребител" v-if="!showAll" v-model="filter">

		<ul class="users-list__groups">
			<li class="users-list__group" v-for="(users, group) in visibleUsers">
				<h4 class="users-list__title" v-if="filter.length < 2 && !!group">{{group}}</h4>

				<ul class="users-list__items">
					<li
						v-for="(user, index) in users"
						:class="['users-list__item', { 'users-list__item--active': !showAll && index === activeItem}]"
						:key="user.id"
					>
						<a :href="`/${user.username}`" class="users-list__link">
							<img :src="`/avatars/${user.username}.jpg`" alt="">

							<span>
								<strong>{{user.name}}</strong>

								<small>{{user.username}}</small>
							</span>
						</a>

						<a :href="`https://buzz.2c-studio.com/employee/?user=${user.username}`" class="users-list__info" target="_blank">
							<i class="fa fa-info-circle"></i>
						</a>
					</li>

					<li class="users-list__item" v-if="visibleUsers.length === 0">Няма резултати</li>
				</ul>
			</li>
		</ul>

		<a href="#" class="users-list__all" @click.prevent="showAll = !showAll">{{ !showAll ? 'Виж всички' : 'Скрий' }}</a>
	</div>
</template>

<script>
import _ from 'lodash';
import * as Fuse from 'fuse.js';

export default {
	name: 'users-list',

	components: {
	},

	data() {
		return {
			filter: '',
			showAll: false,
			activeItem: -1,
		};
	},

	computed: {
		users() {
			return window.$$usersList;
		},

		currentUser() {
			return this.users.find(user => user.username === window.username);
		},

		visibleUsers() {
			if (this.showAll) {
				return _.groupBy(this.users, 'department.title');
			}

			if (this.filter === '*') {
				return { 'All': this.users };
			}

			if (!this.filter || this.filter.length < 2) {
				const divisionUsers = this.users.filter(user => {
					const departmentId = parseInt(this.currentUser.user_department_id) || 0;
					const divisionId = parseInt(this.currentUser.user_department_division_id) || 0;

					if (!!divisionId) {
						return parseInt(user.user_department_division_id) === divisionId;
					}

					return parseInt(user.user_department_id) === departmentId;
				});

				const results = {};
				const resultsGroup = this.currentUser.division ? this.currentUser.division.title : this.currentUser.department.title;

				results[resultsGroup] = divisionUsers

				return results;
			} else {
				const fuse = new Fuse(this.users, {
					shouldSort: true,
					threshold: 0.2,
					keys: [
						'name',
						'username',
						'division.title'
					],
				});

				return { 'Results': fuse.search(this.filter) };
			}
		},

		visibleUsersCount() {
			const resultsKey = Object.keys(this.visibleUsers)[0];

			return this.visibleUsers[resultsKey].length;
		},

		activeVisibleUser() {
			if (this.activeItem > -1) {
				const resultsKey = Object.keys(this.visibleUsers)[0];

				return this.visibleUsers[resultsKey] ? this.visibleUsers[resultsKey][this.activeItem] : null;
			}

			return null;
		},
	},

	mounted() {
		this.bindHotkeys();
	},

	methods: {
		prevActive() {
			this.activeItem = this.activeItem > 0 ? this.activeItem - 1 : this.visibleUsersCount - 1;
		},

		nextActive() {
			this.activeItem = this.activeItem < this.visibleUsersCount ? this.activeItem + 1 : 0;
		},

		navigateToActive() {
			if (this.activeVisibleUser) {
				window.location = `/${this.activeVisibleUser.username}`;
			}
		},

		bindHotkeys() {
			$(window).on('keydown', (event) => {
				const isInside = $(event.target).closest('.users-list').length > 0;

				if (!this.showAll && isInside) {
					if (event.keyCode === 13) {
						this.navigateToActive();
					}

					if (event.keyCode === 40) {
						this.nextActive();
					}

					if (event.keyCode === 38) {
						this.prevActive();
					}
				}
			});
		},
	},

	watch: {
		showAll() {
			const $dropdownContainer = $(this.$el).closest('.user-list');

			$dropdownContainer.toggleClass('user-list-expanded', this.showAll);
		},

		filter() {
			// Reset active item when filter changes
			this.activeItem = this.filter.length > 1 ? 0 : -1;
		}
	}
}
</script>
