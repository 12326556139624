<template>
	<div class="editor">
		<form class="edit-in-place">
			<fieldset :disabled="disabled" ref="textarea">
				<textarea v-model="value" />

				<button type="submit" @click.prevent="handleSave" :disabled="value === content">OK <i v-if="isSaving" class="fa fa-refresh fa-spin" title="Loading..."></i></button>
				<button type="cancel" @click.prevent="handleCancel">Cancel</button>
			</fieldset>
		</form><!-- /.edit-in-place -->
	</div><!-- /.editor -->
</template>

<style scoped>
	.edit-in-place fieldset { border: 0; }
</style>

<script>
	export default {
		/**
		 * Component Name.
		 *
		 * @type {String}
		 */
		name: 'editor',

		/**
		 * Component Props.
		 *
		 * @type {Object}
		 */
		props: {
			content: String,

			disabled: {
				type: Boolean,
				default: false,
			},

			isSaving: {
				type: Boolean,
				default: false,
			}
		},

		/**
		 * Component Data.
		 *
		 * @return {Object}
		 */
		data() {
			return {
				value: this.content,

				$editor: null,
				$textarea: null,
				$editToolbar: null,
			}
		},

		/**
		 * Lifecycle Hook.
		 */
		mounted() {
			this.$editor   = $(this.$el);
			this.$textarea = this.$editor.find('textarea');

			this.$textarea.editor({
				taskListSupport: true,
			});

			this.$textarea.on('change', (event) => {
				this.value = event.target.value;
			});

			this.$textarea.focus();
		},

		/**
		 * Component Methods.
		 */
		methods: {
			handleSave() {
				this.$emit('save', this.value);
			},

			handleCancel() {
				if (this.content === this.value) {
					this.$emit('cancel');
					return;
				}

				if (window.confirm('Имате незапазени промени. Сигурни ли сте, че искате да откажете редакцията?')) {
					this.$emit('cancel');
					return;
				}
			}
		}
	}
</script>