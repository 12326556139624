<template>
	<div ref="codemirror" class="codemirror"></div>
</template>

<script>
import CodeMirror from 'codemirror';

import 'codemirror/mode/yaml/yaml';
import 'codemirror/lib/codemirror.css';

export default {
	name: 'codemirror',

	props: {
		value: {
			default: null,
		},

		options: {
			type: Object,
			default: () => {},
		},

		keymap: {
			type: Object,
			default: null,
		},
	},

	data () {
		return {
			innerValue: this.value || '',
			editor: null,
		};
	},

	methods: {
		init() {
			const options = Object.assign({
				value: this.innerValue,
			}, this.options);

			this.editor = CodeMirror(this.$refs.codemirror, options);

			if (this.keymap) {
				this.editor.addKeyMap(this.keymap);
			}

			this.editor.on('change', this.handleChange);
		},

		handleChange(val) {
			this.innerValue = this.editor.getValue();
			this.$emit('input', this.innerValue);
		},
	},

	mounted() {
		this.init();
	},

	beforeDestroy() {
		this.editor = null;
	},

	watch: {
		value() {
			if (this.value !== this.innerValue) {
				const cursor = this.editor.getCursor();
				const linesBeforeChange = this.editor.lineCount();

				this.editor.setValue(this.value);

				const linesAfterChange = this.editor.lineCount();
				const linesDiff = linesAfterChange - linesBeforeChange;

				this.editor.setCursor({line: cursor.line + linesDiff, ch: cursor.ch});
			}
		},
	},
};
</script>
