<template>
    <div class="acd-frow">
        <label :for="id">{{ label }}</label>

        <slot v-if="hasSlot" />

        <div class="acd-field" v-if="!hasSlot">
            <input
                :class="fieldClass"
                :type="type"
                :id="id"
                :name="name"
                :placeholder="placeholder"
                :required="required"
                :value="value"
                @input="$emit('input', $event.target.value)"
                v-if="type != 'checkbox'"
            >

            <input
                :class="fieldClass"
                :type="type"
                :id="id"
                :name="name"
                :placeholder="placeholder"
                :required="required"
                :checked="value"
                @change="$emit('input', $event.target.checked)"
                v-if="type === 'checkbox'"
            >
        </div>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            default: '',
        },
        type: {
            default: 'text'
        },
        id: {
            default: '',
        },
        name: {
            default: '',
        },
        placeholder: {
            default: '',
        },
        required: {
            default: false,
        },
        value: {
            default: '',
        },
        fieldClass: {
            default: 'field'
        }
    },

    computed: {
        hasSlot() {
            return this.$slots.default;
        }
    }
}
</script>
