<script>
/**
 * External dependencies:
 */
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
	props: ['body'],

	computed: {
		...mapGetters({
			currentEditNote: 'projectNotes/getCurrentEditNote',
			projectId: 'projectNotes/getProjectId',
			taskId: 'projectNotes/getTaskId',
			showForm: 'projectNotes/getShowForm',
		}),

		text: {
			get() {
				return this.currentEditNote.body;
			},

			set(value) {
				this.updateCurrentEditNote({ body: value });
			},
		},
	},

	methods: {
		...mapActions({
			addProjectNote: 'projectNotes/addProjectNote',
			editProjectNote: 'projectNotes/editProjectNote',
		}),

		...mapMutations({
			updateCurrentEditNote: 'projectNotes/PROJECT_NOTES_UPDATE_CURRENT_EDIT_NOTE',
			updateShowForm: 'projectNotes/PROJECT_NOTES_UPDATE_SHOW_FORM',
		}),

		onSubmit(e) {
			if (!this.currentEditNote.body) {
				return;
			}

			if (this.currentEditNote.id) {
				this.editProjectNote(this.currentEditNote);
			} else {
				this.addProjectNote({
					...this.currentEditNote,
					projectId: this.projectId,
					taskId: this.taskId,
				});
			}

			this.updateShowForm(false);
		},

		onCancel() {
			this.updateShowForm(false);
		},
	}
};
</script>

<template>
	<div>
		<form class="comment-form" @submit.prevent="onSubmit">
			<textarea
				name="long_desc"
				class="field"
				data-autosize-on="true"
				style="overflow: hidden; overflow-wrap: break-word; resize: horizontal; height: 250px;"
				spellcheck="false"
				v-model="text"
			></textarea>

			<input type="submit" name="commit" value="Submit" class="submit">
			<input type="button" value="cancel" class="cancel" @click="onCancel">
		</form>
	</div>
</template>