export const CURRENT_USER_FETCHING = (state, payload) => {
	state.isFetching = payload;
}

export const CURRENT_USER_FETCHING_SUCCESS = (state, payload) => {
	state.isFetching = false;
	state.user       = payload;
}

export const CURRENT_USER_FETCHING_ERROR = (state, payload) => {
	state.isFetching = false;
	state.error      = payload; // TODO -- handle error
}
