<template>
    <div class="ftp-connection">
        <input
            v-if="this.connectionStringField"
            type="text"
            class="connection-string-masked field quickconnect"
            :value="connectionString"
            readonly
        >

        <span
            v-if="this.copyToClipboardButton && !hideCopy"
            title="Copy to clipboard"
            class="acd-item-button copy-to-clipboard"
            v-clipboard:copy="connectionString"
        >
            <i class="fa fa-copy"></i>
        </span>

        <a
            v-if="this.droneLinkButton"
            :href="droneLink"
            :title="droneLinkTitle"
            class="acd-item-button connect-via-filezilla"
        ></a>

        <slot />
    </div>
</template>

<script>
export default {
	props: ['droneLink', 'connectionString', 'visibility', 'hideCopy'],

	data() {
		return {
			'connectionStringField': this.getItemVisibility('connectionStringField'),
			'copyToClipboardButton': this.getItemVisibility('copyToClipboardButton'),
			'droneLinkButton': this.getItemVisibility('droneLinkButton'),
		};
	},

	methods: {
		getItemVisibility(propertyName) {
			return this.visibility !== undefined && this.visibility.hasOwnProperty(propertyName) ? this.visibility[propertyName] : true;
		}
	},

	computed: {
		droneLinkTitle() {
			if (this.droneLink === '#') {
				return 'Something went wrong when generating the drone link.';
			}

			return 'Connect via Filezilla(requires latest Drone)';
		},
	},
};
</script>
