var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isVisible)?_c('ModalBase',{staticClass:"file-explorer-modal",attrs:{"width":"960px"},on:{"close":_vm.onClose}},[_c('template',{slot:"title"},[_c('h3',{domProps:{"innerHTML":_vm._s(_vm.getModalTitle())}})]),_vm._v(" "),_c('div',{staticClass:"ftp-explorer"},[(_vm.status.message)?_c('div',{staticClass:"message error",domProps:{"textContent":_vm._s(_vm.status.message)}}):_vm._e(),_vm._v(" "),(!_vm.status.message)?_c('div',{staticClass:"file-explorer"},[_c('div',{class:{
					'file-explorer-navigation': true,
					'is-loading': _vm.showInputLoader
				}},[_c('input',{class:{
						'file-explorer-path-input': true,
						'field': true,
						'disabled': _vm.status.isLoading,
					},attrs:{"type":"text","disabled":_vm.status.isLoading},domProps:{"value":this.currentDirectory},on:{"keyup":_vm.onFileExplorerInputKeyUp}}),_vm._v(" "),(_vm.showInputLoader)?_c('span',{staticClass:"loader"}):_vm._e()]),_vm._v(" "),_c('FileTree',{ref:"slVueTree",class:{
					'file-explorer-tree': true,
					'loading-in-progress': _vm.status.isLoading,
				},attrs:{"value":_vm.fileTree,"allowMultiselect":false},on:{"select":_vm.handleFileTreeSelect,"toggle":_vm.handleFileTreeToggle,"nodedblclick":_vm.handleFileTreeDoubleClick}})],1):_vm._e(),_vm._v(" "),(!_vm.status.message)?_c('Sidebar',{staticClass:"explorer-sidebar",attrs:{"droneLinks":_vm.droneLinks(),"currentDirectory":_vm.currentDirectory,"wpRoot":_vm.wpRoot,"entry":_vm.entry,"connectionStrings":_vm.connectionStrings()}}):_vm._e()],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }