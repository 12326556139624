<template>
	<div>
		<a href="#" class="metalink" title="Migrate SVN repo to GIT" @click="migrationAction( $event )">
			<i class="fa fa-git" ></i>{{buttonLabel ? buttonLabel : 'Loading'}}
		</a>


		<modal v-if="showInfo" @close="hideStatus">
			<h3 slot="totle">Migrate to GIT</h3>

			<div class="migration-loader" v-if="showLoader">
				<i class="fa fa-spinner fa-spin"></i>
			</div>

			<div class="migration-status-body">
				<migration-proceed
					v-if="initStatus === 'proceed'"
					:gitPath = "gitPath"
					:migrationEta = "migrationEta"
					@prepareMigration="prepareMigration"
				/>

				<migration-retry
					v-if="status === 'failed' && log.length > 0"
					:svnPath="svnPath"
					@refresh="refreshLog"
				/>

				<migration-clean v-if="initStatus === 'has_instance'"/>

				<migration-log
					v-if="initStatus === 'show-log'"
					:migrationLog="log"
					:status="status"
					@refresh="refreshLog"
				/>
			</div>
		</modal>
	</div>
</template>

<script>

import Modal from '../components/Modal.vue';
import MigrationProceed from '../migration-components/MigrationProceed.vue';
import MigrationClean from '../migration-components/MigrationClean.vue';
import MigrationLog from '../migration-components/MigrationLog.vue';
import MigrationRetry from '../migration-components/MigrationRetry.vue';

export default {
	name : 'migrate-to-git',

	props : [ 'svnPath', 'project', 'client', 'type' ],

	components : {
		Modal,
		MigrationProceed,
		MigrationClean,
		MigrationLog,
		MigrationRetry,
	},

	data() {
		return {
			showInfo     : false,
			showLoader   : false,
			statusBody   : '',
			status       : this.getStatus(),
			gitPath      : '',
			migrationEta : '',
			clickHandler : false,
			initStatus   : '',
			log          : '',
		};
	},

	computed : {
		buttonLabel() {
			let label = '';

			switch( this.status ) {
				case 'new':
					label = 'In Queue';
				break;

				case 'working':
					label = 'Migration in progress';
				break;

				case 'failed':
					label = 'Migration failed';
				break;

				case 'done':
					label = 'Migration Successful';
				break;

				default:
					label = 'Migrate to GIT';
				break;
			}

			return label;
		}
	},

	methods: {
		migrationAction( event ) {
			event.preventDefault();

			switch( this.status ) {
				case 'working':
				case 'new':
				case 'failed':
				case 'done':
					this.fetchMigrationLog();
				break;

				default:
					this.initMigration();
				break;
			}
		},

		initMigration() {
			this.showInfo = true;

			$.ajax({
				url: '/ajax/migration/init',
				type: 'POST',
				data: {
					'svn_path'   : this.svnPath,
				},
				beforeSend: () => {
					this.showInfo = true;

					if ( ! this.statusBody ) {
						this.showLoader = true;
					}
				},
				success: ( response ) => {
					this.showLoader = false;

					this.initStatus = response.status;

					if ( response.status === 'proceed' ) {
						this.gitPath      = response.git_path;
						this.migrationEta = response.migration_eta;
					}
				}
			} );
		},

		fetchMigrationLog() {
			let isLog = this.initStatus === 'show-log';

			$.ajax({
				url: '/ajax/migration/fetch-log/',
                data: {
                    svn_path: this.svnPath
                },
				beforeSend: () => {
					if ( !isLog ) {
						this.showInfo = true;
						this.initStatus = '';
						this.showLoader = true;
					}
				},
				success: ( response ) => {
					if ( !isLog ) {
						this.showLoader = false;
						this.initStatus = 'show-log'
					}

					this.log = response.log;
				}
			} );
		},

		prepareMigration() {
			$.ajax({
				url: '/ajax/migration/queue-migration',
				type: 'POST',
				data: {
					'svn_path'   : this.svnPath,
					'git_path'   : this.gitPath,
				},
				beforeSend: () => {
					this.showLoader = true;
					this.initStatus = '';
				},
				success: ( response ) => {
					this.showLoader = false;
					this.status = response.status;

					this.fetchMigrationLog();
				}
			} );
		},

		refreshLog() {
			this.fetchMigrationLog();
			this.getStatus();
		},

		getStatus() {
			$.ajax({
				url: '/ajax/migration/get-status/',
                data: {
                    svn_path: this.svnPath
                },
				success: ( response ) => {
					this.status = response;
				}
			} );
		},

		hideStatus( event ) {
			this.showInfo = false;
			this.initStatus = false;
		},
	}
}

</script>
