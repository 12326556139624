const autocomplete = {
	bind(el, binding) {
		const options = binding.value;

		const parse = typeof options.parse === 'function' ? options.parse : (response) => response;
		const formatItem = typeof options.formatItem === 'function' ? options.formatItem : (data) => data[Object.keys(data)[0]];

		$(el).autocomplete(options.url, {
			options: {
				dataType: 'json'
			},
			resultsClass: 'autocomplete-results-holder',
			dataType: 'json',
			parse,
			formatItem
		});

		if (options.resultHandler) {
			$(el).bind('result', options.resultHandler);
		}
	}
};

export default autocomplete;
