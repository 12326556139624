<template>
	<div v-if="shouldShow">
		<time-tracking-calendar />
	</div>
</template>

<script>
	/**
	 * The internal dependencies.
	 */
	import TimeTrackingCalendar from '~/time-tracking-components/Calendar.vue';

	export default {
		/**
		 * The local components.
		 *
		 * @type {Object}
		 */
		components: {
			TimeTrackingCalendar,
		},

		/**
		 * The local state.
		 *
		 * @return {Object}
		 */
		data() {
			return {
				shouldShow: null,
			};
		},

		/**
		 * The public API.
		 *
		 * @type {Object}
		 */
		methods: {
			getShouldShowValue() {
				return document.querySelector(
					'form.profile-time-tracking-calendar'
					)
					.classList
					.contains('active');
			},
		},

		mounted() {
			const setShouldShow = () => {
				this.shouldShow = this.getShouldShowValue();
			};

			window.jQuery(document).ready(setShouldShow);
			window.jQuery('.profile-sidebar-nav').on('click', setShouldShow);
		}
	}
</script>