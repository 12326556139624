/**
 * The internal dependencies.
 */
import { ACCESS_DEPOT_UNSAVED_ID } from '~/lib/constants';

export const filterEntryForAjaxRequest = (entry) => {
	const mapping = {
		id                : 'credentials-id',
		home_url          : 'home-url',
		username          : 'username',
		password          : 'password',
		is_totp_enabled   : 'is_totp_enabled',
		totp_secret       : 'totp_secret',
		directory         : 'directory',
		title             : 'title',
		notes             : 'notes',
		protocol          : 'protocol',
		host              : 'host',
		port              : 'port',
		svn_path          : 'svn-path',
		is_main           : 'is_main',
		should_reset_main : 'should_reset_main',
		active_reservations: 'active_reservations',
	};

	const result = {};
	const entryKeys = Object.keys(entry);

	for (let key of entryKeys) {
		if (!mapping.hasOwnProperty(key)) {
			continue;
		}

		const newKey = mapping[key];
		result[newKey] = entry[key];
	}

	return result;
};

export const createForAdding = ({ svn_path }) => {
	return {
		connection_info: {
			directory: '',
			host: '',
			password: '',
			port: '',
			protocol: 'sftp',
			username: '',
		},

		id: ACCESS_DEPOT_UNSAVED_ID,
		home_url: '',
		host: '',
		directory: '',
		isLoading: false,
		message: '',
		messageType: '',
		notes: '',
		password: '',
		totp_secret: '',
		has_saved_secret: false,
		totps: [],
		port: '',
		protocol: 'sftp',
		title: '',
		username: '',
		isActive: true,
		svn_path,
		is_main: false,
	};
};

export const getNodeDirectoryPathFromFileTree = (node, fileTree, separator) => {
	let currentDirectoryPath = separator;
	const result = getCurrentDirectoryFromPathParts(node.path, fileTree);
	if (result.length) {
		currentDirectoryPath = separator + result.join(separator) + separator;
	}

	return currentDirectoryPath;
}

export const getPathPartsIndexesFromFileTree = (directories, fileTree, reset = false) => {
	let parts          = [];
	let newDirectories = [];
	if (reset) {
		newDirectories = directories.slice(0);
	} else {
		newDirectories = directories;
	}

	let queriedDirName = newDirectories.shift();

	for (let fileTreeIndex in fileTree) {
		let item  = fileTree[fileTreeIndex];
		let found = false;

		if (item.title !== queriedDirName) {
			continue;
		}

		parts.push(parseInt(fileTreeIndex));

		if (item.hasOwnProperty('children') && item.children.length) {
			let newParts = getPathPartsIndexesFromFileTree(newDirectories, item.children, reset);
			if (newParts.length) {
				parts = parts.concat(newParts);
			}
		}
	}

	return parts;
};

export const generateFileTreeFromPath = (directories, fileTree, pathRoot) => {
	if (!directories.length) {
		return [];
	}

	let newPathRootParts = pathRoot.concat(directories.slice(0, 1));
	let path             = newPathRootParts.join('/');

	let dirName   = directories.shift();
	let children  = generateFileTreeFromPath(directories, fileTree, newPathRootParts);
	let isFetched = false;
	if (!children.length) {
		isFetched = true;
		children  = fileTree;
	}

	let newFileTree = [
		{
			title: dirName,
			isLeaf: false,
			isDraggable: false,
			children: children,
			data: {
				path,
				isFetched,
			},
		}
	];

	return newFileTree;
};

export const nodeMergeChildren = (existingChildren, newChildren) => {
	if (!existingChildren.length) {
		return newChildren;
	}

	let mergedChildren = [];

	for (let item of newChildren) {
		if (item.title === existingChildren[0].title) {
			item = existingChildren[0];
			item.data.isFetched = true;
		}

		mergedChildren.push(item);
	}

	return mergedChildren;
};

export const mergeFileTreesByDirectories = (firstFileTree, secondFileTree, directories) => {
	const pathParts = getPathPartsIndexesFromFileTree(directories, firstFileTree, true);
	let node        = getNodeFromFileTree(pathParts, firstFileTree);
	let partialFileTree = secondFileTree;
	let nodeIsFetched = true;

	// if true, some of the directories in the path
	// don't exist in the fileTree
	if (pathParts.length !== directories.length) {
		const partialPathToGenerateFrom = directories.slice(pathParts.length, directories.length);
		const pathRoot = directories.slice(0, pathParts.length);
		partialFileTree = generateFileTreeFromPath(partialPathToGenerateFrom, secondFileTree, pathRoot);
		nodeIsFetched = false;
	}

	node.isExpanded     = true;
	node.isSelected     = true;
	node.data.isFetched = nodeIsFetched;
	// merging children is needed, to avoid overwriting
	// children that have been already fetched and changed via the UI
	let children = nodeMergeChildren(node.children, partialFileTree);
	node.children       = children;

	// this is done to `tag` the parent directory, as we not have the directory contents
	for (let item of secondFileTree) {
		if (item.title === 'wp-config.php') {
			node.data.isWpRoot = true;
			break;
		}
	}

	return firstFileTree.slice(0);
};

export const getNodeFromFileTree = (pathParts, fileTree, selectedIndex = 0) => {
	let node = false;
	let queriedPathPartIndex = pathParts[selectedIndex];

	for (let branchIndex in fileTree) {
		// not the branch we're looking for - skip it
		if (queriedPathPartIndex != branchIndex) {
			continue;
		}

		let newFileTree = fileTree[queriedPathPartIndex];
		node = newFileTree;

		// if it's not a folder, we can't search through it
		if (!newFileTree.hasOwnProperty('children')) {
			continue;
		}

		if (!newFileTree.children.length) {
			continue;
		}

		selectedIndex++;

		let newNode = getNodeFromFileTree(pathParts, newFileTree['children'], selectedIndex);
		if (newNode) {
			node = newNode;
		}
	}

	return node;
};

export const getCurrentDirectoryFromPathParts = (pathParts, fileTree, partIndex = 0) => {
	let parts = [];
	let queriedPathPartIndex = pathParts[partIndex];

	for (let branchIndex in fileTree) {
		// not the branch we're looking for - skip it
		if (queriedPathPartIndex != branchIndex) {
			continue;
		}

		let newFileTree = fileTree[queriedPathPartIndex];

		// leafs are not folders
		if (newFileTree.hasOwnProperty('isLeaf') && newFileTree['isLeaf']) {
			return parts;
		}

		// add the name of the folder to the parts array
		parts.push(newFileTree['title']);

		// we're only adding folders to the parts array
		if (!newFileTree.hasOwnProperty('children')) {
			return parts;
		}

		partIndex++;

		let newParts = getCurrentDirectoryFromPathParts(pathParts, newFileTree['children'], partIndex);
		if (newParts.length) {
			parts = parts.concat(newParts);
		}
	}

	return parts;
};

export const resetSelectedDirectoriesInFileTree = (fileTree) => {
	for (let index in fileTree) {
		let item = fileTree[index];
		if (item.hasOwnProperty('children')) {
			item.isSelected = false;

			resetSelectedDirectoriesInFileTree(item.children);
		}
	}

	return fileTree;
};

export const getConnectionInfo = (entry) => {
	let port = entry.port
	if (!port) {
		if (entry.protocol === 'sftp') {
			port = '22';
		} else if (entry.protocol === 'ftp') {
			port = '21';
		}
	}

	return {
		host: entry.host,
		directory: entry.directory,
		port,
		username: entry.username,
		password: entry.password,
		protocol: entry.protocol,
	};
};

export const transformFileTree = (fileTree) => {
	let newFileTree = [];

	for (let index in fileTree) {
		let fileTreeItem         = fileTree[index];
		let fileTreeItemChildren = fileTreeItem.hasOwnProperty('children') && fileTreeItem.children;
		let hasChildren          = fileTreeItemChildren ? !!fileTreeItemChildren.length : false;
		let isDirectory          = fileTreeItem.type === 'dir';
		let fileName             = fileTreeItem.basename;
		let path;
		if (fileTreeItem.dirname === '') {
			if (fileTreeItem.type === 'file') {
				path = '/';
			} else {
				path = fileTreeItem.basename;
			}
		} else {
			if (fileTreeItem.type === 'file') {
				path = fileTreeItem.dirname;
			} else {
				path = fileTreeItem.path;
			}
		}

		let transformedFileTreeItem = {
			title: fileName,
			isLeaf: !isDirectory,
			isDraggable: false,
			data: {
				path,
			}
		};

		if (isDirectory) {
			transformedFileTreeItem.isExpanded = hasChildren;
			transformedFileTreeItem.data.isFetched = hasChildren;

			if (hasChildren) {
				let transformedFileTreeChildren = transformFileTree(fileTreeItemChildren);
				for (let item of transformedFileTreeChildren) {
					if (item.title === 'wp-config.php') {
						transformedFileTreeItem.data.isWpRoot = true;
						break;
					}
				}

				transformedFileTreeItem.children = transformedFileTreeChildren;
			} else {
				transformedFileTreeItem.children = [];
			}
		} else {
			transformedFileTreeItem.data.isHarmful = isNameHarmful(fileName);

			if (fileName === 'wp-config.php') {
				transformedFileTreeItem.data.isWpConfig = true;
			}

			if (fileTreeItem.type === 'dummy-file') {
				transformedFileTreeItem.data.isDummyFile = true;
			}
		}

		newFileTree.push(transformedFileTreeItem);
	}

	return newFileTree;
};

export const isNameHarmful = (name) => {
	let harmful = false;

	const patterns = [
        '(adminer|phpminiadmin|zip|backup).*\.php',
        '(dump|db|database)\.sql(\.gz)?',
	];

	for(let pattern of patterns) {
		let regexp = new RegExp(pattern);
		if (regexp.test(name)) {
			harmful = true;
			break;
		}
	}

	return harmful;
};

export const updateFileTreeNode = (updatedNode, fileTree) => {
	let oldNode = getNodeFromFileTree(updatedNode.path, fileTree, 0);
	_.assign(oldNode, updatedNode);

	return fileTree.slice(0);
};

export const selectFileTreeItemByPath = (fileTree, directoryParts) => {
	let firstDirectoryName = directoryParts[0];

	for (let item of fileTree) {
		if (item.isLeaf) {
			continue;
		}

		if (item.title === firstDirectoryName && item.children.length) {
			if (directoryParts.length === 1) {
				item.isSelected = true;
				break;
			}

			directoryParts.shift()
			selectFileTreeItemByPath(item.children, directoryParts);
		}
	}

	return fileTree;
};

export const getNodeWpPathFromFileTree = (pathParts, fileTree) => {
	let nodes = [];

	const firstDirIndex = pathParts.shift();
	for (let index in fileTree) {
		const item = fileTree[index];
		if (item.isLeaf) {
			continue;
		}

		if (index != firstDirIndex) {
			continue;
		}

		let node =  {
			'name': item.title,
			'isWpRoot': item.hasOwnProperty('data') && item.data.hasOwnProperty('isWpRoot') && item.data.isWpRoot,
		};

		nodes.push(node);

		let newNode = getNodeWpPathFromFileTree(pathParts, item.children);
		if (newNode) {
			nodes = nodes.concat(newNode);
		}
		break;
	}

	return nodes;
};

export const getWpRootPathPartsFromNodePath = (path, directorySeparator) => {
	let wpRoot = false;

	let newPath = path.slice(0).reverse();
	for (let index in newPath) {
		const dirName = newPath[index];
		if (!dirName.isWpRoot) {
			path.pop();
		} else {
			break;
		}
	}

	if (path.length) {
		wpRoot = directorySeparator + path.map(item => item.name).join(directorySeparator) + directorySeparator;
	}

	return wpRoot;
};

export const getWpRootFromPathParts = (pathParts, fileTree, directorySeparator) => {
	let wpRoot = false;

	const path = getNodeWpPathFromFileTree(pathParts, fileTree);
	if (path) {
		wpRoot = getWpRootPathPartsFromNodePath(path.slice(0), directorySeparator);
	}

	return wpRoot;
};

export const checkIsNodeFetched = (node) => {
	return node.hasOwnProperty('data') && node.data.hasOwnProperty('isFetched') && node.data.isFetched;
};

export const clearFileTreeNodeLoading = (fileTree) => {
	for (let index in fileTree) {
		let item = fileTree[index];
		if (item.data.hasOwnProperty('isLoading') && item.data.isLoading) {
			item.data.isLoading = false;
		}

		if (item.hasOwnProperty('children')) {
			clearFileTreeNodeLoading(item.children);
		}
	}

	return fileTree;
};

export const dispatchEvent = (event, options = null) => {
	const eventRef = new Event(event, options);
	document.dispatchEvent(eventRef);
}