<template>
    <div>
        <div class="alert error" v-if="error && error.length">{{ error }}</div>
        <div class="cl"></div>
        Тук може да се създаде email кутия за компанията на този клиент. Тя може да се използва за регистриране на акаунти във външни услуги като GitHub, DigitalOcean, AWS, и подобни. 

        <div v-if="isAdmin" >
            <input type="button" class="button button-primary" @click.prevent="createEmail" value="Създай">
        </div>
        <div v-else>
            Ако имаш нужда от такава кутия, помоли мениджъра ти да я направи.
        </div>
    </div>
</template>

<script>
/**
 * External dependencies:
 */
import { mapGetters, mapActions } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            taskId: 'companyEmail/getTaskId',
            isAdmin: 'companyEmail/getIsAdmin',
            error: 'companyEmail/getError',
        }),
    },

    methods: {
        ...mapActions({
            createEmail: 'companyEmail/createEmail',
        }),
    },
}
</script>

<style scoped>
.error { margin-bottom: 20px; background-color: #f2dede; border-color: #ebccd1; color: #a94442; }
.button-primary { margin-top: 20px;  }
</style>