import Vue from 'vue';
import Vuex from 'vuex';
import { get, debounce } from 'lodash';

Vue.use(Vuex);

const state = {
	activeTab: null,
	vcsResponse: {
		state: '',
		error: null
	},
	vcsPath: '',
	taskType: 'html-vite-sass',
	project: {},
	payments: {
		total_amount: 0,
		associated_amount: 0
	},
	systemsProjects: {
		id: null,
		token: null,
		source: null,
	},
	quote: null,
	notes: [],
}

const mutations = {
	VCS_RESPONSE(state, payload) {
		state.vcsResponse = payload;
	},

	CHANGE_TAB(state, payload) {
		state.activeTab = payload;
	},

	CHANGE_TASK_TYPE(state, payload) {
		state.taskType = payload;
	},

	CHANGE_VCS_PATH(state, payload) {
		state.vcsPath = payload;
	},

	CHANGE_PROJECT(state, payload) {
		state.project = payload;
	},

	UPDATE_RELATED_TASKS(state, payload) {
		state.project = Object.assign({}, state.project, { related_tasks: payload });
	},

	CHANGE_QUOTE(state, payload) {
		state.quote = payload;
	},

	CHANGE_PAYMENTS(state, payload) {
		state.payments = payload;
	},

	CHANGE_NOTES(state, payload) {
		state.notes = payload;
	},
}

const actions = {
	vcsResponse: ({ commit }, payload) => commit('VCS_RESPONSE', payload),
	changeTab: ({ commit }, payload) => commit('CHANGE_TAB', payload),
	changeTaskType: ({ commit }, payload) => commit('CHANGE_TASK_TYPE', payload),
	changeVcsPath: ({ commit }, payload) => commit('CHANGE_VCS_PATH', payload),
	changeProject: ({ commit }, payload) => commit('CHANGE_PROJECT', payload),
	changeQuote: ({ commit }, payload) => commit('CHANGE_QUOTE', payload),
	changePayments: ({ commit }, payload) => commit('CHANGE_PAYMENTS', payload),
	changeNotes: ({ commit }, payload) => commit('CHANGE_NOTES', payload),

	fetchPaymentsAmount: ({ commit }, payload) => {
		const projectId = payload;

		if (!projectId) {
			return;
		}

		if ( projectId != '' ) {
			let payments = {
				project_amount: 0
			};

			$.ajax({
				url: '/ajax/get-project-payments-amount',
				type: 'GET',
				data: {
					project_id: projectId,
				},

				success: (resp) => {
					if (resp) {
						payments = resp;
					}

					commit('CHANGE_PAYMENTS', payments);
				}
			});
		}
	},

	fetchRelatedTasks: ({ commit, state }) => {
		const projectId = state.project.project_id;

		if ( projectId != '' ) {
			$.ajax({
				url: '/ajax/get-related-tasks',
				type: 'GET',
				data: {
					project_id: projectId,
				},

				success: (tasks) => {
					commit('UPDATE_RELATED_TASKS', tasks);
				}
			});
		}
	},

	fetchNotes: ({ dispatch }, { client_id }) => {
		if (!client_id) {
			return;
		}

		$.ajax({
			url: '/ajax/get-new-task-notes',
			type: 'GET',
			data: {
				client_id,
			},

			success: (response) => {
				dispatch('changeNotes', get(response, 'data.notes', []));
			},
		});
	}
}

const getters = {
	getNotes: (state) => state.notes,
};

export default new Vuex.Store({
	state,
	actions,
	mutations,
	getters,
});
