<template>
	<div v-if="notes && notes.length">
		Добави важни бележки към проекта:
		<div class="project-notes">
			<div class="note" v-for="note in notes" :key="note.id">
				<label>
					<input type="checkbox" name="notes[]" :value="note.id">{{ note.title }}
				</label>

				<div class="excerpt">{{ note.excerpt }}</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: ['projectId'],

	computed: {
		...mapGetters({
			notes: 'getNotes',
		}),
	}
}
</script>

<style scoped>
.note { margin-top: 5px; }
.note label { font-size: 14px; }
.note input[type=checkbox] { margin-right: 8px; }
.note .excerpt { margin: 5px 0 10px; }
</style>