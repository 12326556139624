<template>
	<form @click.stop action="/search" method="get" class="search">
		<label for="q">Търси:</label>

		<input v-model="query" @keydown="handleInputNavigation" @focus="handleInputFocus" type="text" class="field" name="q" id="q" autocomplete="off" />

		<span v-if="isLoading" class="indicator">
			<i class="fa fa-circle-o-notch fa-spin"></i>
		</span>

		<input type="submit" class="button" name="" value="GO" id="" />

		<div v-if="expanded" class="results">
			<div
				v-for="(item, index) in items"
				:class="['result', { 'result--highlighted': index === highlighted }]"
				@click.prevent="navigateToTask(item)"
			>
				<div class="result__image">
					<img :src="`/avatars/${item.owner_username}.jpg`">
				</div>

				<div class="result__inner">
					<h4 class="result__title">
						<a :href="`/${item.owner_username}/task/${item.task_id}`" target="_blank">{{item.task_title}}</a>
					</h4>

					<ul v-if="item.task_finished !== undefined && item.comments !== undefined" class="result__meta">
						<li v-if="item.task_finished" :title="item.task_finished_at">
							<i class="fa fa-check"></i> Приключен {{item.task_finished_at | fromNow}}
						</li>

						<li v-if="!item.task_finished">
							<i class="fa fa-refresh"></i> Активен
						</li>
					</ul>

					<div class="result__entry">{{item.task_description | truncate(50)}}</div>
				</div>
			</div>

			<div v-if="items && items.length < total" class="result result--more">
				<a :href="`/search?q=${query}`">Виж всички резултати ({{total}})</a>
			</div>
		</div>
	</form>
</template>

<script>
import _ from 'lodash';
import { truncate, fromNow } from '../utils/filters';

export default {
	name: 'search',

	data() {
		return {
			state       : '',
			query       : null,
			items       : null,
			perPage     : 10,
			highlighted : 0,
			expanded    : false
		};
	},

	computed: {
		isLoading() {
			return this.state === 'loading';
		}
	},

	methods: {
		fetchResults() {
			if (this.isLoading) {
				return;
			}

			if (this.query) {
				this.state = 'loading';

				const request = $.ajax({
					url: '/search/get',
					type: 'GET',
					data: {
						q: this.query,
						per_page: this.perPage
					}
				});

				request.then((payload) => {
					this.state       = '';
					this.items       = payload.items;
					this.total       = payload.total;
					this.highlighted = 0;
					this.expanded    = payload.items && payload.items.length;
				});
			}
		},

		navigateToTask(item) {
			const location = `/${item.owner_username}/task/${item.task_id}`;

			window.location = location;
		},

		handleInputFocus() {
			if (this.items && this.items.length) {
				this.expanded = true;
			}
		},

		handleInputNavigation(event) {
			switch(event.keyCode) {
				case 13:
					if (this.expanded && this.items && this.items.length) {
						event.preventDefault();
						this.navigateToTask(this.items[this.highlighted]);
					}
					break;
				case 27:
					event.preventDefault();
					return this.expanded = false;
				case 38:
					event.preventDefault();
					return this.highlighted = this.highlighted > 0 ? this.highlighted - 1 : this.items.length - 1;
				case 40:
					event.preventDefault();
					return this.highlighted = this.highlighted < this.items.length - 1 ? this.highlighted + 1 : 0;
				default:
					break;
			}
		},

		handleQuerySubmit() {
			this.page = 0;
			this.fetchResults();
		},
	},

	created() {
		$(document).on('click', () => {
			this.expanded = false
		});
	},

	filters: {
		truncate,
		fromNow
	},

	watch: {
		query:  _.debounce(function() {
			this.fetchResults();
		}, 250)
	}
}
</script>
