import { getImageFromPasteEvent, dataURItoBlob, insertAtCaret } from './utils/helpers';

$(document).ready(() => {
	$('#writecomment .field').on('paste', event => {
		getImageFromPasteEvent(event.originalEvent).then(res => {
			const filename = new Date().getTime() + '.png';
			const placeholder = `[![image](uploading:${filename})]()`;

			insertAtCaret(event.target, placeholder);

			const blob = dataURItoBlob(res.base64string);
			const file = new File([blob], filename);

			const formData = new FormData();

			formData.append(0, file, filename);

			$.ajax({
				type: 'POST',
				url: '/ajax/upload-to-s3',
				data: formData,
				cache: false,
				contentType: false,
				processData: false,
				success: res => {
					const url = `[![image](${res})](${res})`;
					const currentPosition = event.target.selectionEnd;
					const newPosition = currentPosition + (url.length - placeholder.length);

					event.target.value = event.target.value.replace(placeholder, url);

					if (currentPosition > event.target.value.indexOf(url)) {
						event.target.setSelectionRange(newPosition, newPosition);
					} else {
						event.target.setSelectionRange(currentPosition, currentPosition);
					}
				}
			});
		});
	});
});
