<template>
	<div class="calendar-modal-wrapper">
		<modal @close="$emit('close')">
			<time-tracking-calendar />
		</modal>
	</div>
</template>

<script>
	/**
	 * The internal dependencies.
	 */
	import Modal from 'components/Modal.vue';
	import TimeTrackingCalendar from '~/time-tracking-components/Calendar.vue';

	export default {
		/**
		 * The local components.
		 *
		 * @type {Object}
		 */
		components: {
			Modal,
			TimeTrackingCalendar,
		},
	}
</script>

<style>
	.calendar-modal-wrapper .modal-inner { max-width: 900px !important; }
</style>