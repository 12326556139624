<template>
	<div id="wp-depot-container" class="wp-depot-container">
        <div class="wp-depot wp-depot--pending" v-if="status == 'pending'">
        	<h3>Създаване на хостинг пространство: <a>{{ targetSiteUrl }}</a></h3>

            <div class="row row--url">
                <label class="toggle">
                    <span>{{ useHttpsLabelText }}</span>
                    <input type="checkbox" class="field" v-model="useHttps">
                </label>

                <label>
                    <input type="text" class="field" @keyup.enter="handleCreateDepot" v-model="targetUrl">
                </label> 

				<span>{{ mainDomain }}</span>
            </div>

            <div class="row">
                <strong>Трябва ми:</strong>

                <div class="field field--radios">
                    <label>
                        <input type="radio" v-model="installOnCreate" :value="false" name="install-type" />
                        Базова WordPress инсталация, сам ще кача и настроя сайта през FTP / SSH
                    </label>

                    <label>
                        <input type="radio" v-model="installOnCreate" :value="true" checked name="install-type" />
                        
                        WordPress инсталация от &nbsp;<div class="monospace">{{ repoUrl }}</div>
                    </label>

                </div>
            </div>

            <div class="row">
                <button class="submit" @click="handleCreateDepot">Стартирай процеса &raquo;</button>
            </div>
        </div>

        <div class="wp-depot wp-depot--creating" v-if="status == 'creating'">
			<SiteLogs
				:eventId="activeEventId"
				:wpDepotId="wpDepotId"
				:siteId="siteId"
				:initPolling="true"
				v-if="activeEventId"
				@statusChange="handleStatusChange"
			/>
        </div>

        <div class="wp-depot wp-depot--created" v-if="status == 'created'">
			<SiteInfo
				v-if="Object.keys(siteData).length"
				:initialSiteData="siteData"
				:activeEventId="activeEventId"
				:wpDepotId="wpDepotId"
				@install="handleInstallDepot"
				@archive="handleArchive"
				@delete="handleDelete"
				@statusChange="handleStatusChange"
			/>

			<SiteInfo
				v-else
				:siteUrl="fetchSiteUrl"
				:activeEventId="activeEventId"
				:wpDepotId="wpDepotId"
				@install="handleInstallDepot"
				@archive="handleArchive"
				@delete="handleDelete"
				@statusChange="handleStatusChange"
			/>
        </div>

        <div class="wp-depot wp-depot--failed" v-if="status == 'failed'">
			<SiteLogs
				:eventId="activeEventId"
				:wpDepotId="wpDepotId"
				:siteId="siteId"
				v-if="activeEventId"
				@statusChange="handleStatusChange"
			/>

			<div class="wp-depot-wrapper">
				<div>
					<span>!</span> Something went wrong.
				</div>
	
				<div class="wp-depot-button">
					<button class="submit" @click="reset">Retry</button>
				</div>
			</div>
        </div>
	</div>
</template>

<script>
/**
 * The external dependencies.
 */
import { mapGetters } from 'vuex';

/**
 * The internal dependencies.
 */
import SiteLogs from '~/components/wp-depot/SiteLogs.vue';
import SiteInfo from '~/components/wp-depot/SiteInfo.vue';

export default {
	components: { SiteLogs, SiteInfo },
    data() {
        return {
            repoUrl: this.$parent.repoUrl,
            targetUrl: this.$parent.projectName,
			mainDomain: process.env.NODE_ENV === 'development' ? '.wp-depot.dev' : '.wp-staging.net',
            useHttps: true,
            wpDepotId: this.$parent.wpDepotId,
			siteId: this.$parent.wpDepotSiteId,
            status: this.$parent.wpDepotStatus,
			installOnCreate: true,
			activeEventId: 0,
			siteData: {}
        }
    },
    mounted() {
		const url = `/ajax/task/${window.TASK_ID}/wp-depot/${this.wpDepotId}/site/${this.siteId}`;
        if (this.status === 'creating') {
			fetch(url).then(r => r.json()).then(result => {
				this.activeEventId = result.site.data.events.find(x => x.type === 'create').id;
			})
        }
		else if (this.status === 'failed' ) {
			fetch(url).then(r => r.json()).then(result => {
				this.activeEventId = result.site.data.events.findLast(x => x.status === 'failed').id;
			})
		}
		else if (this.wpDepotId && this.siteId) {
			fetch(url).then(r => r.json()).then(result => {
				this.activeEventId = result.site.data.events.findLast(x => x.status === 'in-progress')?.id ?? 0;
				this.siteData = result.site.data;
				this.status = result.site.data.status;
			})
		}
    },
	computed: {
        validatedTargetUrl() {
            return this.targetUrl
                .replace(/[^a-z\-0-9\.]/g, '-')
                .replace(/-+/g, '-')
                .replace(/^-/, '')
                .replace(/\./g, '-')
                .replace(/-$/, '');
        },
        targetSiteUrl() {
            return `http${this.useHttps ? 's' : ''}://${this.validatedTargetUrl}${this.mainDomain}`;
        },
		finalSiteUrl() {
			return this.validatedTargetUrl + this.mainDomain;
		},
		fetchSiteUrl() {
			return `/ajax/task/${window.TASK_ID}/wp-depot/${this.wpDepotId}/site/${this.siteId}`;
		},
		useHttpsLabelText() {
			return this.useHttps ? 'https://' : 'http://';
		}
	},
    methods: {
		reset() {
			this.status = 'pending';
			this.activeEventId = 0;
		},
		handleStatusChange(siteEventNewStatus, siteData) {
			if ( siteData ) {
				this.status = siteData.status;
				this.siteData = siteData;
			}
		},

        handleCreateDepot() {
            fetch(`/ajax/task/${window.TASK_ID}/wp-depot/init/${this.wpDepotId}`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    target_domain: this.finalSiteUrl,
                    is_https: this.useHttps,
					install_on_create: this.installOnCreate
                })
            })
            .then(response => response.json())
            .then(result => {
				this.siteId = result.site_id;
				this.activeEventId = result.event_id;
                this.status = result.wp_depot_status;
            })
            .catch(error => {
                console.error(error);
                alert("something is wrong! See console. ");
            })
        },
        handleInstallDepot() {
            fetch(`/ajax/task/${window.TASK_ID}/wp-depot/${this.wpDepotId}/site/${this.siteId}/install`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(result => {
				this.siteData = result.site;
				this.activeEventId = result.site.events.findLast(event => event.type === 'install');
            })
            .catch(error => {
                console.error(error);
                alert("something is wrong! See console. ");
            })
        },
		handleArchive() {
			fetch(`/ajax/task/${window.TASK_ID}/wp-depot/${this.wpDepotId}/site/${this.siteId}/create-archive`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
			.then(response => response.json())
			.then(result => {
				this.siteData = result.site;
				this.activeEventId = result.site.events.findLast(event => event.type === 'archive');
			})
			.catch(error => {
				console.error(error);
				alert("something is wrong! See console. ");
			})
		},
		handleDelete() {
			fetch(`/ajax/task/${window.TASK_ID}/wp-depot/${this.wpDepotId}/site/${this.siteId}`, {
				method: "DELETE",
				headers: {
					'Content-Type': 'application/json'
				}
			})
			.then(response => response.json())
			.then(result => {
				this.siteData = result.site;
				this.status = result.site.status;
				this.activeEventId = result.site.events.findLast(event => event.type === 'delete');
			})
			.catch(error => {
				console.error(error);
				alert("something is wrong! See console. ");
			})
		}
    }
}
</script>

<style scoped>
	.wp-depot-container {
		padding: 5px;
		position: relative;
	}

    .row label {
        display: flex;

        & span {
            width: 130px;
        }
    }

	.wp-depot {
		& .wp-depot-button {
			margin-left: auto;
		}
	}

	.wp-depot--pending {
		display: flex;
		flex-direction: column;
		padding: 10px;

		& h3 {
			margin-bottom: 10px;
		}
	}

	.row:not(:last-child) {
		margin-bottom: 10px;
	}

	.row--url {
		display: inline-flex;
		width: fit-content;
		align-items: center;
		border: 1px solid #CCC;
		border-radius: 8px;
		overflow: hidden;
		padding-right: 12px;

		& input[type="text"] {
			border: 0;
			line-height: 20px;
			border-radius: 8px;
			padding-left: 4px;
			width: fit-content;
		}

		& label + span {
			color: #999;
		}
	}

	.toggle.toggle {
		display: inline-flex;
		border-radius: 0px 6px 6px 0px;
		transition: background-color 250ms ease-out, color 250ms ease-out;
		padding-inline: 10px 6px;
		padding-block: 3px;
		cursor: pointer;
		background: color-mix(in srgb, #0375FF var(--bg-opacity), transparent);
		--bg-opacity: 5%;
		z-index: 2;
		outline: 1px solid #CCC !important;
		user-select: none;

		& span {
			width: auto;
		}

		&:has(input:checked) {
			color: #FFF;
			--bg-opacity: 100%;

			@media (hover: hover) and (pointer: fine) {
				&:hover {
					--bg-opacity: 75%;
				}
			}		
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				--bg-opacity: 25%;
			}
		}

		& input {
			display: none;
		}
	}

	.wp-depot--pending .field[type="text"] {
		width: 240px;
	}

	.field--radios {
		padding: 10px;
		border-color: #CCC;

		& label {
			display: inline-flex;
			align-items: center;

			&:not(:last-child) {
				margin-bottom: 4px;
			}
		}
		& input {
			display: inline-block;
			margin-right: 6px;
			margin-bottom: 2px;
		}
	
		.monospace {
			display: inline-block;
			font-family: monospace;
			background: #E2E2E2;
			padding-inline: 6px;
			border-radius: 6px;
		}
	}

	.wp-depot--created {
		padding-block: 6px;
	}

	.wp-depot--failed .wp-depot-wrapper {
		display: flex;
		margin-top: 8px;

		& span {
			display: inline-grid;
			place-content: center;
			aspect-ratio: 1;
			width: 20px;
			font-weight: 900;
			font-family: Georgia, 'Times New Roman', Times, serif;
			margin-right: 4px;
			height: auto;
			line-height: 1;
			border: 1px solid #BBB;
			background: #EDEDED;
			color: #FF0000;
			border-radius: 4px;
		}
	}

	.submit {
		appearance: none;
		border: 1px solid #b2b2b2;
		border-radius: 4px;
		text-align: center;
		font-size: 12px;
		font-weight: 700;
		cursor: pointer;
		background: #f5f5f5;
		display: inline-block;
		vertical-align: top;
		padding: 4px 7px;

		@media (hover: hover) and (pointer: fine) {
			&:hover {
				border: 1px solid #888;
			}
		}
	}
</style>
