<template>
    <div>
        <note v-for="note in shownNotes" :key="note.id" :note="note" :only="only"/>
    </div>
</template>

<script>
/**
 * Internal dependencies:
 */
import Note from 'components/client-notes/Note.vue';

export default {
    props: {
        notes: Array,
        only: String,
    },

    components: {
        Note,
    },

    computed: {
        shownNotes() {
            if (this.only !== 'sticky') {
                return this.notes;
            }

            return this.notes.filter(note => note.is_sticky);
        },
    }
}
</script>
