<template>
	<div class="monitor-milestone-tasks" v-show="false">
		<template v-if="visibleTasks.length > 0">
			<ul>
				<li v-for="task in visibleTasks" :class="['monitor-milestone-task', { 'is-finished': parseInt(task.finished) }]">
					<a :href="getTaskUrl(task)" class="monitor-milestone-task-link" :title="task.title" target="_blank">
						<img v-if="task.user" :src="`/avatars/${task.user.username}.jpg`" alt="">
						<i class="fa fa-angle-double-right" v-if="milestoneTaskId === task.id"></i>
						<span>{{task.title}}</span>
					</a>
					<task-progress :task="task"></task-progress>

					<a :href="getDeployUrl(task)" class="btn btn-sm" target="_blank" :disabled="!getDeployUrl(task)"><i class="fa fa-upload"></i> Deploy</a>
				</li>
			</ul>

			<a href="#" class="monitor-milestone-tasks-toggle" v-if="visibleTasksCountDefault < tasks.length" @click.prevent="toggleVisibleTasksCount">
				<template v-if="visibleTasksCount < tasks.length">Покажи всички</template>
				<template v-else>Покажи последните {{visibleTasksCountDefault}}</template>
			</a>
		</template>

		<template v-else>
			<p>Няма задачи</p>
		</template>
	</div>
</template>

<script>
import _ from 'lodash';
import TaskProgress from '../components/TaskProgress.vue';

export default {
	name: 'monitor-milestone-tasks',

	components: {
		TaskProgress,
	},

	props: {
		tasks: {
			default: []
		},

		monitorId: {
			default: 0
		},


		milestoneTaskId: {
			default: null
		},

		srcSite: {
			default: ''
		}
	},

	data() {
		return {
			visibleTasksCountDefault: 10,
			visibleTasksCount: 10
		}
	},

	computed: {
		visibleTasks() {
			return _.chain(this.tasks)
				.orderBy(['finished', 'date_finished', 'id'], ['asc', 'desc', 'desc'])
				.slice(0, Math.min(this.tasks.length, this.visibleTasksCount))
				.value();
		}
	},

	methods: {
		toggleVisibleTasksCount() {
			this.visibleTasksCount = this.visibleTasksCount === this.visibleTasksCountDefault ? this.tasks.length : this.visibleTasksCountDefault;

			setTimeout(() => {
				$(window).trigger('scroll');
			}, 10);
		},

		getProgressPercentage(task) {
			const timeEstimated = parseInt(task.time_estimated) || 0;
			const timeRecorded = parseInt(task.time_recorded) || 0;

			const percent = timeEstimated === 0 ? 0 : Math.round((timeRecorded / timeEstimated) * 100);

			return `${percent}%`;
		},

		getTaskUrl(task) {
			if (task.user) {
				return `${task.user.username}/task/${task.id}`;
			}
		},

		getDeployUrl(task) {
			const base = '/deploy/frontend/act.php';
			const options = {
				act:      'deploy',
				src_site: this.srcSite,
				monitor:  this.monitorId,
				task_id:  task.id,
				vcs_path: task.svn_path,
				cloaked:  1
			};

			if (!options.vcs_path || !options.src_site) {
				return null;
			}

			const queryString = _.toPairs(options)
				.filter( pair => !!pair[1] )
				.map( pair => (
					pair.map(entity => encodeURIComponent(entity)).join('=')
				))
				.join('&');

			return `${base}?${queryString}`;
		}
	},
}
</script>
