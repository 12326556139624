<template>
	<div :style="{ paddingBottom : '15px' }">
		Previous migration attempt failed.
		
		<a 
			href="#" 
			class="proceed"
			@click="requeueMigration"
		>
			Retry Migration
		</a>
	</div>
</template>

<script>
	
export default {
	
	name : 'migration-retry',
	
	props : [ 'svnPath' ],
	
	methods : {
		requeueMigration( $event ) {
			$event.preventDefault();
			
			$.ajax({
				url: '/ajax/migration/requeue-migration',
				type: 'POST',
				data: {
					'svn_path'   : this.svnPath,
					
				},
				success: ( response ) => {
					this.$emit( 'refresh' );
				}
			} );
			
		}
	}
	
}

</script>