<template>
    <div>
        <a href="#" class="btn" button="button" @click.prevent="handleRevealPassword">{{label}}</a>
        <button type="button" class="entry-copy" @click="handleCopyClick" v-if="entry.password_hidden && show_copy">
            <span class="button">{{text}}</span>
        </button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        entry: {
            default: null
        },
        show_copy: {
            default: false
        },
        label: {
            default: 'Reveal password'
        }
    },

    computed: {
        ...mapGetters({
            taskId: 'projectNotes/getTaskId',
        })
    },

    data() {
        return {
            textChanges: ['Copy', 'Copied'],
            text: "Copy",
            time: 1000,
        }
    },

    methods: {
        handleRevealPassword() {
            this.$store.dispatch('accessDepot/getPassword', { id: this.entry.id, task_id: this.taskId });
        },

        handleCopyClick() {
            this.$store.dispatch('accessDepot/getPasswordWithoutReveal', { id: this.entry.id, task_id: this.taskId }).then(res => {
                // this.$copyText(res.password)
                this.$emit('get-password', res.password)
                this.copyTextUpdate();
            })
        },

        copyTextUpdate() {
            clearTimeout(this.timeout);
            this.text = this.textChanges[1];
            this.timeout = setTimeout(() => {
                this.text = this.textChanges[0];
            },this.time)
        }
    }
}
</script>