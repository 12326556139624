/**
 * Internal Dependencies.
 */
import * as getters from './getters.js';
import * as actions from './actions.js';
import * as mutations from './mutations.js';

/**
 * Export Module
 */
export default {
	namespaced: true,
	state: {
		notes: [],
		currentEditNote: {
			id: 0,
			body: '',
		},
		projectId: 0,
		showForm: false,
	},
	getters,
	actions,
	mutations,
};
