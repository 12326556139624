<template>
	<select>
		<slot></slot>
	</select>
</template>

<script>
export default {
	name: 'chosen',

	props: ['value', 'onMounted'],

	mounted() {
		const vm = this;

		$(this.$el)
			.val(this.value)
			.chosen()
			.on('change', function() {
				vm.$emit('input', { name: this.name, value: this.value });
			});

		this.doOnMounted();
	},

	watch: {
		value(newVal) {
			const value = typeof this.value === 'object' ? this.value.value : this.value;

			$(this.$el).val(value);
			$(this.$el).trigger('liszt:updated');

			this.doOnMounted();
		}
	},

	methods: {
		doOnMounted() {
			if (typeof this.onMounted === 'function') {
				this.onMounted(this.$el);
			}
		}
	},

	destroyed() {
		$(this.$el).off().chosen('destroy');
	}
}
</script>
