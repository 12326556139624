<template>
    <div>
        <div v-if="entry.active_reservations && entry.active_reservations.length > 0">
            <div class="seat-reserved" v-for="r in entry.active_reservations" v-if="!showSeat">
                <p>
                    Това място е запазено от {{ r.user.currentUser ? 'теб' : r.user.name }} до {{ convertDate(r.reserved_to) }}
                </p>
                <div v-if="r.user.currentUser">
                    <button class="btn btn--blue" @click="$emit('freeSeat')">Освободи</button>
                    <button class="btn" @click="$emit('toggleSeat')">Удължи резервацията</button>
                </div>
            </div>
        </div>

        <div v-if="showSeat" class="seat-reserve-inputs">
            <select class="field" v-model="reservationPeriod">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
            </select>
            <select class="field" v-model="reservationPeriodUnit">
                <option value="hours">Часа</option>
                <option value="days">Дни</option>
            </select>
            
            <button @click="reserveSeat(entry)" class="btn">Запази мястото</button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['entry', 'showSeat'],

    data() {
        return {
            reservationPeriod: 2,
            reservationPeriodUnit: "days",
            dateStrings: {
                days: ['дни', 'ден'],
                hours: ['часа', 'час'],
                minutes: ['минути', 'минута']
            }
        }
    },

    methods: {
        convertDate(value) {
            const date = new Date(value);

            const options = {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };

            const currentDate = new Date();

            const timeDifference = date - currentDate;
            const remainingTime = {};

            if (timeDifference > 0) {
                remainingTime.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                remainingTime.hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
                remainingTime.minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
            }

            let output = 'след';
            output += this.getDateString('days', remainingTime);
            output += this.getDateString('hours', remainingTime);
            output += this.getDateString('minutes', remainingTime);

            return `${date.toLocaleDateString('bg-BG', options)} (${output})`;
        },

        getDateString(target, remainingTime) {
            if(remainingTime[target] > 0) {
                const namedStringArr = this.dateStrings[target];
                const namedString = remainingTime[target] > 0 ? namedStringArr[0] : namedStringArr[1];
                return ` ${remainingTime[target]} ${namedString}`
            } 

            return '';
        },

        reserveSeat(entry) {
            this.$emit('toggleSeat');
            this.$store.dispatch(
                'accessDepot/reserveSeat',
                {
                    id: entry.id,
                    reservationPeriod: this.reservationPeriod,
                    reservationPeriodUnit: this.reservationPeriodUnit
                }
            );
        },
    }
}
</script>