<template>
	<div v-if="revisions.length" class="checklist__revisions">
		<ul class="checklist__versions">
			<li v-for="(revision, index) in visibleRevisions" :class="{ active: index === activeRevision }">
				<a href="#" @click.prevent="getDiff(revision.id)">Версия {{revisions.length - index}}</a>
				- {{users[revision.author_id]|onlyName}} {{revision.updated_at|fromNow}}
			</li>
		</ul>

		<div class="checklist__diff" ref="diff"></div>
	</div>
</template>

<script>
import moment from 'moment';

export default {
	name: 'checklist-revisions',

	props: {
		revisions: {
			default: () => [],
			type: Array,
		},
	},

	data() {
		return {
			activeRevision: null,
		};
	},

	computed: {
		visibleRevisions() {
			let visibleRevisions = [].concat(this.revisions);
			visibleRevisions.reverse();

			return visibleRevisions;
		},

		users() {
			let users = {};

			Object.keys(all_users).forEach(division => {
				users = Object.assign(users, all_users[division]);
			});

			return users;
		},
	},

	methods: {
		getDiff(checklistId) {
			const index = this.visibleRevisions.findIndex(revision => revision.id === checklistId);

			this.activeRevision = index >= 0 ? index : 0;

			$.ajax({
				url: '/ajax/checklists/diff',
				type: 'POST',
				data: {
					checklist_id: checklistId,
				},
				success: (resp) => {
					try {
						const diffHtml = Diff2Html.getPrettyHtml(resp);

						$(this.$refs.diff).html(diffHtml);
					} catch (e) {
						console.error(e);
					}
				},
			})
		},
	},

	filters: {
		fromNow(val) {
			return moment(val).fromNow();
		},

		onlyName(val) {
			return val.replace(/\s\-.+/, '');
		},
	},
}
</script>
