<template>
	<div class="search-page">
		<div class="search-page__form">
			<form @submit.prevent="handleQuerySubmit">
				<div class="search-page__form-inner">
					<input v-model="query" ref="search" type="text" class="form-field" placeholder="Search for...">

					<button type="submit" value="Submit" class="btn">
						<i class="fa fa-search"></i>
					</button>
				</div>
			</form>
		</div>

		<div class="search-page__results results">
			<template v-if="items && items.length">
				<div v-for="item in items" class="result">
					<div class="result__image">
						<img :src="`/avatars/${item.owner_username}.jpg`" alt="">
					</div>

					<div class="result__inner">
						<h4 class="result__title">
							<a :href="`/${item.owner_username}/task/${item.task_id}`" target="_blank">{{item.task_title}}</a>
						</h4>

						<ul v-if="item.task_finished !== undefined && item.comments !== undefined" class="result__meta">
							<li v-if="item.task_finished" :title="item.task_finished_at">
								<i class="fa fa-check"></i> Приключен {{item.task_finished_at | fromNow}}
							</li>

							<li v-if="!item.task_finished">
								<i class="fa fa-refresh"></i> Активен
							</li>

							<li>
								<i class="fa fa-comments"></i> {{item.comments ? item.comments.length : 0}}
							</li>
						</ul>

						<div class="result__entry">{{item.task_description | truncate(300)}}</div>
					</div>
				</div>
			</template>

			<div v-else-if="!isLoading" class="search-page__message">
				<p>Няма намерени резултати.</p>
			</div>

			<ul class="paging">
				<li v-for="(pageNum, index) in pages" :class="{ current: page === index }">
					<a @click.prevent="handlePageClick(index)" href="#">{{pageNum}}</a>
				</li>
			</ul>
		</div>

		<div v-if="isLoading" class="search-page__loading">
			<i class="fa fa-spin fa-circle-o-notch"></i>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';
import { truncate, fromNow } from '../utils/filters';

export default {
	name: 'search',

	data() {
		return {
			state   : '',
			query   : null,
			items   : null,
			total   : 0,
			page    : 0,
			perPage : 50
		};
	},

	computed: {
		isLoading() {
			return this.state === 'loading';
		},

		pages() {
			return Math.ceil(this.total / this.perPage);
		},
	},

	created() {
		this.setInitialData();
		this.fetchResults();
	},

	mounted() {
		this.$refs.search.focus();
	},

	methods: {
		getParams() {
			const paramsString = window.location.search.replace(/^\?/,'');

			return paramsString
				.split('&')
				.map((pair) => ({ key: pair.split('=')[0], value: pair.split('=')[1] }));
		},

		getQueryParam() {
			const queryParam = this.getParams().find(param => param.key === 'q');

			return queryParam && decodeURIComponent(queryParam.value);
		},

		getPageParam() {
			const pageParam = this.getParams().find(param => param.key === 'page');

			return pageParam && (parseInt(pageParam.value) - 1) || 0;
		},

		setInitialData() {
			const queryParam = this.getQueryParam();
			const pageParam = this.getPageParam();

			if (queryParam) {
				this.query = queryParam;
			}

			if (pageParam) {
				this.page = pageParam;
			}
		},

		fetchResults() {
			if (this.isLoading) {
				return;
			}

			if (this.query) {
				this.state = 'loading';
				this.pushHistoryState();

				const request = $.ajax({
					url: '/search/get',
					type: 'GET',
					data: {
						q: this.query,
						page: this.page,
						per_page: this.perPage
					}
				});

				request.then((payload) => {
					this.state = '';
					this.items = payload.items;
					this.total = payload.total;
				});
			}
		},

		pushHistoryState() {
			if (this.query) {
				let queryString = `q=${this.query}`;

				if (this.page) {
					queryString += `&page=${this.page + 1}`;
				}

				history.pushState({}, null, `${location.pathname}?${queryString}`);
			}
		},

		handlePageClick(page) {
			this.page = page;
			this.fetchResults();
		},

		handleQuerySubmit() {
			this.page = 0;
			this.fetchResults();
		},
	},

	filters: {
		truncate,
		fromNow
	}
}
</script>
