<template>
	<div class="tabs">
		<ul class="tabs-nav">
			<li
				v-for="tab in tabs"
				v-show="!tab.hidden"
				:class="{ 'active': tab.id === selectedTab.id }"
				@click.prevent="selectTab(tab.id)"
				:key="tab.id"
			>
				{{tab.name}}
			</li>
		</ul>

		<div class="tabs-body">
			<slot></slot>
		</div>
	</div>
</template>

<script>
import Tab from '../components/Tab.vue';

export default {
	name: 'tabs',

	props: {
		value: { default: null }
	},

	data() {
		return {
			tabs: [],
			innerValue: null
		};
	},

	computed: {
		selectedTab() {
			return this.tabs.find(tab => tab.id === (this.value || this.innerValue));
		}
	},

	created() {
		this.tabs = this.$children;
	},

	mounted() {
		this.init();
	},

	components: {
		Tab
	},

	methods: {
		init() {
			if (!this.value && this.tabs.length) {
				this.$emit('input', this.tabs[0].id);
				this.innerValue = this.tabs[0].id;
			}

			const selectedTab = this.tabs.find(tab => tab.id);
		},

		selectTab(selectedTab) {
			this.$emit('input', selectedTab);
			this.innerValue = selectedTab;
		}
	},

	watch: {
		selectedTab() {
			this.tabs.forEach(tab => {
				tab.selected = tab === this.selectedTab;
			});
		}
	}
}
</script>

<style scoped>
	.tabs {}

	.tabs-nav { list-style: none outside none; border-bottom: 1px solid #e9e9e9; }
	.tabs-nav:after { content: ''; display: table; clear: both; line-height: 0; }
	.tabs-nav li { float: left; min-width: 120px; padding: 10px 8px; margin: 0 4px -1px 0; border-left: 1px solid #e9e9e9; border-right: 1px solid #e9e9e9; border-top: 3px solid transparent; text-align: center; background: #e9e9e9; font-weight: bold; cursor: pointer; }
	.tabs-nav .active { border-top-color: #4da3da; background: #fff; }

	.tabs-body { border-width: 0 1px 1px; border-style: solid; border-color: #e9e9e9; }
	.tab { padding: 10px; }
</style>
