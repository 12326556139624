import Vue from 'vue';
import Vuex from 'vuex';

let request = null;

const filtersDefault = {
	finished: null,
	manager_id: null,
	user_department_id: null,
	project_id: null,
	search_query: null,
}

const state = {
	milestones: null,
	milestonesNewModalVisible: false,
	milestonesState: 'initial',
	milestonesFilters: { ...filtersDefault },
	milestonesPagination: {
		currentPage: 1
	},
	milestonesHighlights: [],
	isTesterView: null
};

const getters = {
	hasSearchQuery: state => {
		return !!state.milestonesFilters.project_id || !!state.milestonesFilters.search_query;
	}
};

const mutations = {
	LOAD_MILESTONES(state, payload) {
		state.milestones = payload;
	},

	TOGGLE_TESTER_VIEW(state, payload) {
		state.isTesterView = payload;
	},

	UPDATE_MILESTONES_PAGINATION(state, payload) {
		state.milestonesPagination = payload;
	},

	CHANGE_MILESTONES_PAGE(state, payload) {
		state.milestonesPagination.currentPage = payload;
	},

	CHANGE_MILESTONES_STATE(state, payload) {
		state.milestonesState = payload;
	},

	CHANGE_FILTER_PROPERTY(state, payload) {
		state.milestonesFilters[payload.property] = payload.value !== 'all' ? payload.value : null;

		// Reset currentPage
		state.milestonesPagination.currentPage = 1;
	},

	RESET_FILTERS(state, payload) {
		state.milestonesFilters = filtersDefault;
	},

	ADD_MILESTONE_HIGHLIGHT(state, payload) {
		state.milestonesHighlights = [...state.milestonesHighlights, payload];
	},

	DRAIN_MILESTONES_HIGHLIGHTS(state, payload) {
		state.milestonesHighlights = [];
	},

	CHANGE_FILTERS(state, payload) {
		state.milestonesFilters = { ...state.milestonesFilters, ...payload };
	},

	UPDATE_MILESTONE(state, { id, property, value }) {
		if (state.milestones[id]) {
			state.milestones[id][property] = value;
		}
	},

	TOGGLE_MILESTONES_NEW_MODAL(state, payload) {
		state.milestonesNewModalVisible = payload;
	},
};

const actions = {
	fetchMilestones: ({ commit, state }, options = {}) => {
		if (!options.silent) {
			commit('CHANGE_MILESTONES_STATE', 'loading');
		}

		if (request) {
			request.abort();
		}

		const page = state.milestonesPagination.currentPage;
		const filters = state.milestonesFilters;
		const data = Object.assign({ page }, filters);

		request = $.ajax({
			url: '/monitor/get',
			type: 'GET',
			data,

			success: (res) => {
				const milestones = res.items;
				const milestonesPagination = res.pagination;

				commit('LOAD_MILESTONES', milestones);
				commit('UPDATE_MILESTONES_PAGINATION', milestonesPagination);
				commit('CHANGE_MILESTONES_STATE', 'loaded');
			},
		});

		return request;
	},

	updateMilestone: ({ commit, dispatch }, { id, property, value, commentEntry = null, loading = false }) => {
		const url = `/monitor/set/${property}`;
		const data = {
			milestone_id: id,
			value,
			comment_entry: commentEntry
		};


		const tempValue = loading ? 'loading' : value;
		commit('UPDATE_MILESTONE', { id, property, value: tempValue });

		return $.ajax({
			type: 'POST',
			url,
			data,

			success: (resp) => {
				commit('UPDATE_MILESTONE', { id, property, value });
				dispatch('fetchMilestones', { silent: true });
			}
		});
	},
};

export default new Vuex.Store({
	state,
	getters,
	actions,
	mutations
});
