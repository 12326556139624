/**
 * Internal Dependencies.
 */
import * as getters from './getters.js';
import * as actions from './actions.js';
import * as mutations from './mutations.js';

/**
 * Module State.
 *
 * @type {Object}
 */

const state = {
	ftpExplorer: {
		isVisible: false,
		entryId: 0,
		fileTree: [],
		currentDirectory: '/',
		directorySeparator: '/',
		connectionInfo: {
			directory: '/',
			host: '',
			port: 21,
			username: '',
			password: '',
			protocol: 'ftp',
		},
		wpRoot: '',
		status: {
			isLoading: false,
			message: '',
		},
		showInputLoader: false,
		ajaxSource: false,
	},
};


/**
 * Export Module
 */
export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
