<template>
    <div>
        <div class="entry-preview">
            <FieldPreview
                v-if="isFtpOrSftp"
                type="slot"
                label="Connection"
                :copy="!entry.password_hidden"
                :value="connectionString"
            >
                <ftp-connection
                    :droneLink="droneLink"
                    :connectionString="connectionString"
                    :hideCopy="true"
                    v-if="!entry.password_hidden"
                />
                <RevealField
                    label="Reveal Connection"
                    :show_copy="true"
                    :entry="entry"
                    @get-password="handleCopy($event, 'connection')"
                    v-if="entry.password_hidden"
                />
            </FieldPreview>

            <FieldPreview 
                v-if="isFtpOrSftp && entry.home_url != ''"
                :value="entry.home_url"
                :url="homeUrl"
                label="Preview URL"
                type="url"
            />

            <FieldPreview 
                v-if="isFtpOrSftp"
                :value="entry.host"
                :label="hostLabel"
            />
            <FieldPreview 
                v-else
                :url="hostUrl"
                :value="entry.host"
                label="URL"
                type="url"
            />

            <FieldPreview 
                v-if="isFtpOrSftp && entry.port"
                :value="entry.port"
                label="Port"
            />

            <FieldPreview 
                v-if="entry.username"
                :value="entry.username"
                label="Username"
            />

            <template v-if="entry.protocol !== 'seat' && entry.password">
                <FieldPreview 
                    v-if="!entry.password_hidden"
                    @mouseenter.native="hovering.password = true"
                    @mouseleave.native="hovering.password = false"
                    :value="hovering.password ? entry.password : maskedPassword"
                    label="Password"
                    type="code"
                />
                <FieldPreview
                    v-else
                    label="Password"
                    type="slot"
                    :copy="!entry.password_hidden"
                >
                    <RevealField :entry="entry" :show_copy="true" @get-password="handleCopy($event, 'password')" />
                </FieldPreview>
            </template>

            <FieldPreview 
                v-if="isFtpOrSftp && entry.directory != ''"
                :value="entry.directory"
                label="Directory"
            />

            <FieldPreview
                v-if="entry.protocol === 'seat'"
                :copy="false"
                type="slot"
                @mouseenter.native="hovering.password = true"
                @mouseleave.native="hovering.password = false"
            >
                <Seat
                    :entry="entry"
                    :own="ownReserve"
                    :showSeat="showSeat"
                    :maskedValue="hovering.password ? entry.password : maskedPassword"
                    @toggleSeat="toggleSeat"
                    @freeSeat="freeSeat"
                />
            </FieldPreview>

            <FieldPreview
                v-if="show2FACode && this.entry.totp_secret"
                label="ONE TIME CODE"
                type="slot"
                :copy="false"
            >
                <Totp :entry="entry" />
            </FieldPreview>

            <FieldPreview 
                v-if="entry.notes"
                :value="convertNotesToLinks"
                :copy="false"
                :larger="false"
                className="entry-preview__item-note"
                label="Notes"
                type="html"
            />
            
            <FieldPreview
                v-if="entry.protocol === 'seat' && entry.inactive_reservations && entry.inactive_reservations.length"
                type="slot"
                :copy="false"
            >
                <InactiveReserved 
                    :entry="entry"
                    :showSeat="showSeat"
                />
            </FieldPreview>
            <FieldPreview
                v-if="ownReserve && entry.protocol === 'seat'"
                :copy="false"
                type="slot"
            >
                <SeatReserved 
                    :entry="entry"
                    :showSeat="showSeat"
                    @toggleSeat="toggleSeat"
                    @freeSeat="freeSeat"
                />
            </FieldPreview>
        </div>
        <small class="created">
            <strong>Създадено: </strong> {{ convertedDate }}
        </small>
    </div>
</template>

<script>
import { ACCESS_DEPOT_UNSAVED_ID } from '~/lib/constants';

import FtpConnection from '~/components/FtpConnection.vue';
import FieldPreview from './FieldPreview.vue';
import SeatReserved from './SeatReserved.vue';
import InactiveReserved from './InactiveReserved.vue';
import Totp from './Totp.vue';
import Seat from './Seat.vue';
import RevealField from './RevealField.vue';

const maybePrefixHttp = (url) => {
	if ( /^https?:\/\//.test(url) ) {
		return url;
	}

	return 'http://' + url;
};

export default {
    props: [
        'entry',
        'droneLink',
        'connectionString',
    ],

    components: {
        FtpConnection,
        FieldPreview,
        Totp,
        Seat,
        SeatReserved,
        InactiveReserved,
        RevealField,
    },

    data() {
        return {
            showSeat: false,
            interval: null,
            hovering: {
                password: false,
            }
        }
    },

    computed: {
        isFtpOrSftp() {
            return this.entry.protocol === 'sftp' || this.entry.protocol === 'ftp'
        },

        hostUrl() {
            return !this.isFtpOrSftp ? maybePrefixHttp(this.entry.host) : this.entry.host;
        },

        homeUrl() {
            return this.isFtpOrSftp ? maybePrefixHttp(this.entry.home_url) : this.entry.home_url;
        },

        hostLabel() {
            let label = 'FTP Host';
            if(this.entry.protocol === 'sftp') {
                label = 'S' + label;
            }
            return label;
        },

        maskedPassword() {
            const visibleContext = 2;
    
            // dummy passwords(e.g. 'demo') are not worth masking
            if (this.entry.password.length <= visibleContext * 2) {
                return this.entry.password;
            }
    
            const stars = new Array(this.entry.password.length - 2 * visibleContext).fill('·').join('');
            return this.entry.password.slice(0, visibleContext) + stars + this.entry.password.slice(-1 * visibleContext);
        },

        ownReserve() {
            if(this.entry.active_reservations) {
                return this.entry.active_reservations.find(item => item.user.currentUser);
            }

            return false;
        },

        show2FACode() {
            if(this.entry.totps && this.entry.totps.length && (this.ownReserve || this.entry.protocol != 'seat') && !this.isFtpOrSftp) {
                return true;
            }
            return false;
        },

        convertNotesToLinks() {
            return this.entry.notes_html || this.entry.notes;
        },

        convertedDate() {
            if(this.entry.id === ACCESS_DEPOT_UNSAVED_ID && !this.entry.created_at) {
                this.entry.created_at = new Date();
            }
            const date = new Date(this.entry.created_at);

            const options = {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
            };

            return `${date.toLocaleDateString('bg-BG', options)}`;
        },
    },

    methods: {
        toggleSeat() {
            this.showSeat = !this.showSeat;
        },

        freeSeat() {
            this.$store.dispatch('accessDepot/freeSeat', this.entry);
        },

        pullLatestEntries() {
            this.interval = setInterval(() => {
                if(this.entry.protocol === 'seat') {
                    this.$store.dispatch('accessDepot/getLatestReservation', { task_id: task_id, id: this.entry.id })
                }
            }, 5000)
        },

        handleCopy(password, type) {
            if(type === 'connection') {
                const result = getFilezillaConnectionString({
                    ...this.entry,
                    password,
                })
                return this.$copyText(result)
            }

            return this.$copyText(password)
        },

        stopPullLatestEntries() {
            clearInterval(this.interval);
        }
    },

    mounted() {
        this.pullLatestEntries();
    },

    destroyed() {
        this.stopPullLatestEntries();
    },
}
</script>