/**
 * Internal Dependencies.
 */
import api from 'services/api';


/**
 * Performs an AJAX Request to fetch Comment.
 *
 * @param  {Number} commentId
 * @return {Promise}
 */
export const fetchComment = (commentId) => {
	return api.get(`/comments/${commentId}`);
};

/**
 * Performs an AJAX Request to fetch the revisions of the given comment.
 * 
 * @param  {Number} commentId
 * @return {Promise}
 */
export const fetchCommentRevisions = (commentId) => {
	return api.get(`/comments/${commentId}/revisions`);
};

/**
 * Performs an AJAX Request to fetch the comment diff.
 *
 * @param  {Number} commentId
 * @return {Promise}
 */
export const fetchCommentDiff = ({ revision1, revision2 }) => {
	return api.get('/utils/revisions-diff', {
		params: {
			revision1,
			revision2,
		}
	});
};

/**
 * Performs an AJAX Request to update a tasklist item on a given comment.
 *
 * @param  {Number} commentId
 * @return {Promise}
 */
export const updateTaskListItem = ({ commentId, taskListIndex, isChecked, revision }) => {
	return api.patch(`/comments/${commentId}/tasklist`, {
		task_list_index: taskListIndex,
		checked: isChecked,
		revision: revision,
	});
}

/**
 * Performs an AJAX Request to update the given comment.
 *
 * @param {Number} commentId
 * @param {String} value
 *
 * @return {Promise}
 */
export const updateComment = ({ commentId, body, revision }) => {
	return api.post('/edit-comment?comment_id=' + commentId + '&comment_syntax=parsedown&task_id=' + window.TASK_ID, {
		value: body,
		revision: revision,
	});
}
