<template>
	<form action="/profile/notifications" method="POST" class="form-horizontal profile-form profile-notifications" id="profile-notifications">
		<fieldset>
			<h2>Нотификации</h2>

			<div class="frow">
				<p>При активиране на нотификациите, Вие ще започнете да получавате съобщения по слак за всеки добавен или преместен за днес таск в избрания интервал от време.</p>
			</div><!-- /.frow -->

			<div class="message message-success">
				Промените бяха запазени.
			</div>

			<div class="frow">
				<label>Желая да получавам нотификации за добавени задачи</label>
				<input type="checkbox" class="checkbox" id="notifications-enable" name="notifications_enable" value="Y" v-model="isEnabled">
			</div>

			<div class="frow"
				v-if="isEnabled"
			>
				<label for="field-notifications-start">От:</label>

				<select name="notifications_start" class="profile-field field" id="field-notifications-start"
					v-model="notificationsStart"
				>
					<option
						v-for="hour in hours"
						:value="hour"
					>{{ hour }}</option>
				</select>
			</div>

			<div class="frow"
				v-if="isEnabled"
			>
				<label for="field-notifications-end">До:</label>

				<select name="notifications_end" class="profile-field field" id="field-notifications-end"
					v-model="notificationsEnd"
				>
					<option
						v-for="hour in hours"
						v-if="shouldHide(hour)"
						:value="hour"
					>{{ hour }}</option>
				</select>
			</div>

			<div class="frow">
				<div class="form-controls">
					<input type="submit" name="commit" value="Запази" class="button submit-field">
					<i class="loading-animation fa fa-refresh fa-spin"></i>
				</div>
			</div>
		</fieldset>
	</form>
</template>

<script>
	export default {
		data() {
			var hoursKeys = Object.keys(window.userMeta.hours);

			window.userMeta.notificationsStart = window.userMeta.notificationsStart || window.userMeta.hours[hoursKeys[0]];
			window.userMeta.notificationsEnd   = window.userMeta.notificationsEnd || window.userMeta.hours[hoursKeys[hoursKeys.length - 1]];

			return window.userMeta;
		},
		methods: {
			shouldHide(value) {
				var notificationStartTime = parseFloat(this.getFloatFromTimeString(this.notificationsStart));
				var optionValue           = parseFloat(this.getFloatFromTimeString(value));

				return notificationStartTime < optionValue;
			},
			getFloatFromTimeString(timeString) {
				return parseFloat(timeString.replace(':', '.'));
			}
		}
	}
</script>

