/**
 * External Dependencies.
 */
import 'mousetrap';


/**
 * Helper, which allows registering a shortcut with a callback.
 *
 * @return {void}
 */
export const bindShortcut = (keys, cb) => {
	Mousetrap.bind(keys, (event, handler) => {
		cb(event, handler);
	});
}
