<template>
	<div class="add-task-vcs-inner">
		<div class="add-task-vcs-paths">
			<label v-for="(vcs, index) in vcsPaths">
				<input type="radio" name="svn_path_chooser" v-bind:value="vcs" v-model="vcsPath">

				<span><i v-bind:class="getVcsIcon(vcs)"></i> {{vcs}}</span>
			</label>

			<label>
				<input type="radio" name="svn_path_chooser" value="new" v-model="vcsPath" />

				<span><i class="fa fa-plus"></i> Новo хранилище</span>
			</label>
		</div>

		<div class="add-task-new-vcs" v-show="newRepoEnabled">
			<div class="add-task-vcs-fields">
				<div class="add-task-cols">
					<div class="add-task-col">
						<select name="vcs" class="field" v-model="vcsType">
							<option value="git">Git</option>
						</select>
					</div>

					<div class="add-task-col">
						<select name="ptype" class="ptype field" v-model="taskType">
							<option value="html-vite-sass">HTML (Sass) / Vite</option>
							<option value="webpack-sass">HTML (Sass) / Webpack</option>
							<option value="html-empty">HTML (Empty)</option>
							<option value="wordpress">WordPress</option>
							<option value="craftcms">Craft CMS</option>
							<option value="mail">Mail</option>
							<option value="mail-shopify">Mail (Shopify)</option>
							<option value="exo-2">Shopify</option>
							<option value="shopify-empty-new">Shopify (Empty)</option>

							<option value="design">Design</option>

							<optgroup label="Graphic Mama">
								<option value="gm-toon">Toon Character</option>
								<option value="gm-graphic-pack">Graphic Pack</option>
								<option value="gm-resource">Resource</option>
							</optgroup>
						</select>
					</div>

					<div class="add-task-col">
						<input type="text" class="field" name="svn_client" v-model="vcs.client">

						<span class="add-task-col-separator">/</span>
					</div>

					<div class="add-task-col">
						<input type="text" class="field" name="svn_project" v-model="vcs.project">
					</div>
				</div>

				<div class="add-task-vcs-paths">
					<label v-if="eligbleForWebpack(taskType)">
						<input type="checkbox" v-model="enableWebpack">

						<span>
							Enable webpack?
						</span>
					</label>
				</div>
			</div><!-- /.add-task-vcs-fields -->
		</div>

		<div class="add-task-vcs-confirm">
			<input type="button" value="OK" class="btn-ok submit btn-create-svn-path" @click="handleConfirmAction">
		</div>
	</div>
</template>

<script>
export default {
	name: 'select-vcs',

	props: ['vcs-paths', 'project'],

	mounted() {
		this.vcsPath = $$taskData.taskDefaultSvnPath || 'new';
	},

	data() {
		return {
			vcsType: 'git',
			vcsError: null,
			vcsExists: false,
			newRepoEnabled: false,
			enableWebpack: true
		};
	},

	computed: {
		vcs() {
			var client = this.project.company || this.project.client_name || '';
			var project = (this.project.project_title || '').replace(/[-+].*/, '');

			return {
				client: this.toSlug(client, '_'),
				project: this.toSlug(project, '-')
			};
		},

		clientFullName() {
			const clientCompany = this.project.company && this.project.company.toLowerCase() === 'unknown' ? undefined : this.project.company;
			const clientName = this.project.client_name;

			return clientCompany || clientName;
		},

		taskType: {
			get() {
				return this.$store.state.taskType;
			},

			set(value) {
				this.$store.dispatch('changeTaskType', value);
			}
		},

		vcsResponse: {
			get() {
				return this.$store.state.vcsResponse;
			},

			set(value) {
				return this.$store.dispatch('vcsResponse', value);
			}
		},

		vcsPath: {
			get() {
				return this.$store.state.vcsPath;
			},

			set(value) {
				return this.$store.dispatch('changeVcsPath', value);
			}
		},

		isSvnDisabled() {
			return this.taskType.indexOf('wordpress') > -1 || this.taskType.indexOf('craft-cms') > -1;
		}
 	},

	methods: {
		getVcsIcon(vcsPath) {
			if (vcsPath.match(/(\/|\@)(wordpress|wp)(\/|\.)/)) {
				return 'fa fa-wordpress';
			} else if (vcsPath.match(/(\/|\@)html(\/|\.)/)) {
				return 'fa fa-html5';
			} else if (vcsPath.match(/(\/|\@)mail(\/|\.)/)) {
				return 'fa fa-envelope';
			}

			return '';
		},

		toSlug(str, separator) {
			var slug = str.trim()
				.replace('&amp;', 'and')
				.replace(/[^\w]/g, separator)
				.toLowerCase()
				.replace(new RegExp('\\' + separator + '+', 'g'), separator)
				.replace(new RegExp('\\' + separator + '$', 'g'), separator)
				.replace(new RegExp('^\\' + separator + '', 'g'), separator);

			return slug;
		},

		handleConfirmAction() {
			if (this.newRepoEnabled) {
				this.handleCreateVcs();
			} else {
				this.$parent.$emit('close');
			}
		},

		handleCreateVcs() {
			var vcsData = {
				client:            this.vcs.client,
				project:           this.vcs.project,
				type:              this.taskType,
				vcs:               this.vcsType,
				client_full_name:  this.clientFullName,
				project_full_name: this.project.project_title,
				enable_webpack:    this.enableWebpack,
			}

			this.vcsResponse = { state: 'loading' };
			this.$parent.$emit('close');

			$.ajax({
				url: '/ajax/create-vcs-path',
				type: 'POST',
				data: vcsData,

				complete: (resp) => {
					const response = resp.responseJSON || {};
					let responseText = JSON.parse(resp.responseText);

					if (typeof responseText !== 'string') {
						responseText = JSON.stringify(responseText);
					}

					let vcsExist = false;
					if ( $.trim(responseText).match(/(^path_exists)|has already been taken|Project already exists/) ) {
						vcsExist = true;
						responseText = responseText.replace(/^(path_exists: )/, '');
					} else if ( $.trim(responseText).match(/(Internal error)|(already exists)/) || (response.status && response.status === 'Error') ) {
						this.vcsResponse = { state: '', error: response.data || responseText };
						return;
					}

					this.vcsResponse = { state: '', error: (vcsExist ? 'VCS Path already exists.' : null) };

					if (response.data) {
						responseText = response.data.replace(/^(Project already exists: )/, '');
					}

					this.vcsPaths.push(responseText);

					setTimeout(() => {
						this.vcsPath = responseText;
					}, 10);
				}
			});
		},

		eligbleForWebpack(type) {
			return type.startsWith('webpack');
		}
	},

	watch: {
		vcsPath() {
			this.newRepoEnabled = this.vcsPath === 'new';
		},

		vcsPaths(val) {
			this.vcsPath = this.vcsPaths.length ? this.vcsPaths[0] : 'new';
		},

		taskType(newValue, oldValue) {
			if (this.isSvnDisabled) {
				this.vcsType = 'git';
			}

			if (this.eligbleForWebpack(newValue)) {
				if (!this.eligbleForWebpack(oldValue)) {
					this.enableWebpack = true;
				}
			} else {
				if (this.enableWebpack) {
					this.enableWebpack = false;
				}
			}
		}
	}
}
</script>
