<template>
	<div>
		<select class="field" :name="name" @change="handleDeadlineChange">
			<option v-for="date in dates" :value="date.value">{{ date.label }}</option>
		</select>

		<datepicker class="field-date field-date-hidden" />
	</div>
</template>

<script>
import Datepicker from './Datepicker.vue';

export default {
	name: 'deadline-select',

	components: {
		Datepicker
	},

	props: ['name', 'dates'],

	methods: {
		handleDeadlineChange(event) {
			if (event.target.value === '_choose') {
				$(event.target).next('.field-date-hidden').datepicker('option', 'dateFormat', 'yy-mm-dd');
				$(event.target).next('.field-date-hidden').datepicker('option', 'onSelect', (selectedDate, ui) => {
					this.dates.push({
						value: selectedDate,
						label: selectedDate
					});

					setTimeout(() => {
						event.target.value = selectedDate;
					}, 10);
				}).focus();
			}
		}
	},
}
</script>
