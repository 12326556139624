<template>
    <div v-if="entry.protocol === 'seat'">
        <h4 v-if="entry.active_reservations && !entry.active_reservations.length || own">Password <span v-if="!own">- за да видиш паролата трябва да си запазиш мястото</span></h4>

        <button class="btn" @click="$emit('toggleSeat')" v-if="!showSeat && entry.active_reservations && !entry.active_reservations.length">Резервирай</button>

        <div v-if="own" class="seat-password">
            <code v-text="finalValue" class="entry-value entry-value--larger" v-if="!entry.password_hidden" />
            <RevealField :entry="entry" v-else :show_copy="true" @get-password="handleCopy" />
     
            <Copy :value="entry.password" v-if="!entry.password_hidden"  />
        </div>

        <SeatReserved 
            v-if="!own"
            :entry="entry"
            :showSeat="showSeat"
            @toggleSeat="$emit('toggleSeat')"
        />
    </div>
</template>

<script>
import Copy from './Copy.vue';
import SeatReserved from './SeatReserved.vue';
import RevealField from './RevealField.vue';

export default {
    props: [
        'entry',
        'own',
        'showSeat',
        'maskedValue'
    ],

    components: {
        Copy,
        SeatReserved,
        RevealField,
    },

    computed: {
        finalValue() {
            return this.maskedValue || this.entry.password;
        }
    },

    methods: {
        handleCopy(password) {
            this.$copyText(password)
        }
    }
}
</script>